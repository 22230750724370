import { PATH_DASHBOARD } from '@/routes/paths'

export const MORALIS_API_KEY = process.env.MORALIS_API_KEY || ''
export const APP_DOMAIN = process.env.APP_DOMAIN || ''
export const NEXTAUTH_SECRET = process.env.NEXTAUTH_SECRET || ''
export const REYIELD_BACKEND_API_URL = process.env.REYIELD_BACKEND_API_URL || ''
export const NEXT_PUBLIC_VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || ''
export const SLACK_BOT_TOKEN = process.env.SLACK_BOT_TOKEN || ''

export const getRealVercelDomain = () => {
  const domain = process.env.VERCEL_URL
    ? `https://${process.env.VERCEL_URL}`
    : 'http://localhost:8081'
  return domain
}

const getDomainByEnv = () => {
  switch (NEXT_PUBLIC_VERCEL_ENV) {
    case 'production':
      return 'https://reyield.fi'
    case 'preview':
      return 'https://preview.reyield.fi'
    case 'development':
      return 'https://dev.reyield.fi'
    default:
      return 'https://reyield.fi'
  }
}

export const currentDomain = getDomainByEnv()

export const OPTIMISM_API_URL = 'https://api-optimistic.etherscan.io'
export const OPTIMISM_APIKEYS = [
  '2URYC4IHUEF9XYEGS7XVEZEI33RIA9FAF8',
  'AZVQ73BNJ1HEFS8XX5H6U9F2VMGDEY3D4G',
  'Y4BZIREFJ27HSW3F4K3BS42B88YPPZW23V',
  '81C61UZK8ZDK9KXJ4HQYZ7IEXXKWSXWV9I',
  '5GCRJX44QUD2PB74PSPV14NMZ1HHSPNG47',
  'U6F18BJVZ9HAZ1S2YKB8V5WQS1VRJ5KWCT',
  '9M1HRUTCTRYWFA8A2ZXIRE9YVE8ZMYDDTE',
  'CK22QVNMK2YIBKRC5EQ7W8AJ6RYKW2ASH8',
  'AMI85DSGJ2ARISC9774HZ4BHVYX3SIS614',
  // registered using wayne@bitgin.net
  '92Q61MRW69GAXI3NTBFV83TACEY2CSGX8Q',
  '665VXD8DV2U7IUJWRRZX84DWIHE624JXYU',
  '6N61YSTJPKDSBE8W3Y1Y92ABGGCMHJ2FUG',
]
export const OPENSEA_API_URL = 'https://api.opensea.io/v2'
// Expiration for 1 year
export const OPENSEA_APIKEYS = [
  '7ae9473ca4ab43aca0f246401995cec6',
  '450b09c871394fa6908d99aed87f6a2c',
  '679d159e8dbe4cfe8d1697cd0d414471',
]

export const GIMCOOL_PAY = {
  GRAPHQL_ENDPOINT: process.env.GIMCOOL_GRAPHQL_ENDPOINT || '',
  API_KEY_SANDBOX: process.env.GIMCOOL_PAY_API_KEY_SANDBOX || '',
  API_SECRET_SANDBOX: process.env.GIMCOOL_PAY_API_SECRET_SANDBOX || '',
  API_KEY_PRODUCTION: process.env.GIMCOOL_PAY_API_KEY_PRODUCTION || '',
  API_SECRET_PRODUCTION: process.env.GIMCOOL_PAY_API_SECRET_PRODUCTION || '',
}

function convertToWebsocketUrl(url: string) {
  return url.replace(/(^https|^http)/, function (match, p1) {
    return p1 === 'https' ? 'wss' : 'ws'
  })
}

export const BFF = {
  HASURA_GRAPHQL_SOCKET_URL:
    convertToWebsocketUrl(process.env.HASURA_GRAPHQL_ENDPOINT || '') + '/v1/graphql',
  HASURA_GRAPHQL_ENDPOINT: (process.env.HASURA_GRAPHQL_ENDPOINT || '') + '/v1/graphql',
  HASURA_GRAPHQL_ADMIN_SECRET: process.env.HASURA_GRAPHQL_ADMIN_SECRET || '',
}

export const APIFY = {
  API_TOKEN: process.env.APIFY_API_TOKEN || '',
}

export const HOST_API_KEY = process.env.HOST_API_KEY || ''

export const FIREBASE_API = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APPID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}

export const COGNITO_API = {
  userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.AWS_COGNITO_CLIENT_ID,
}

export const AUTH0_API = {
  clientId: process.env.AUTH0_CLIENT_ID,
  domain: process.env.AUTH0_DOMAIN,
}

export const MAPBOX_API = process.env.MAPBOX_API

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.explore.investmentTools

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
}

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
}

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
}
