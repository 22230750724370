import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Stepper(theme: Theme) {
  return {
    MuiStepConnector: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            '& .MuiStepConnector-line': {
              borderColor: theme.reyieldPalette.secondary.main,
            },
          },
          '&.Mui-completed': {
            '& .MuiStepConnector-line': {
              borderColor: theme.reyieldPalette.secondary.main,
            },
          },
        },
        line: {
          borderLeftWidth: 2,
          borderColor: theme.reyieldPalette.primary.light,
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          borderLeft: `2px solid ${theme.reyieldPalette.primary.light}`,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: theme.reyieldPalette.primary.light,
          '&.Mui-completed': {
            color: theme.reyieldPalette.secondary.main,
          },
          '&.Mui-active': {
            color: theme.reyieldPalette.secondary.main,
          },
        },
        text: {
          fill: theme.reyieldPalette.primary.main,
          fontSize: 16,
          fontWeight: 700,
        },
      },
    },
  }
}
