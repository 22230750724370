const cn = {
  app: `App`,
  app_header_connect: `連線`,
  app_view_white_paper: `查看白皮書`,
  risk_all_tools: `全部`,
  risk_low_risk_tools: `低風險`,
  risk_medium_low_risk_tools: `中低風險`,
  risk_medium_risk_tools: `中風險`,
  risk_medium_high_risk_tools: `中高風險`,
  risk_high_risk_tools: `高風險`,
  page_app_investment_tool_header: `投資`,
  page_app_investment_tool_enable_all_message: `恭喜！所有 REYIELD 的工具已經啟用`,
  page_app_explorer_section_title: `REYIELD 簡化專業投資`,
  page_app_explorer_section_sub_title: `「暴漲 & 暴跌」並不是加密貨幣投資唯一獲利的方式，讓REYIELD 輕鬆實現專業投資！`,
  page_app_reyield_gpt_title: `現在就用 AI 開始獲利！`,
  page_app_reyield_gpt_subtitle: `REYIELDGPT 提供最適合您的投資計劃，利用 AI 的力量實現您的財務目標吧！`,
  page_app_reyield_gpt_counter_desc: `透過 REYILEDGPT 創立的投資組合開始獲利。`,
  page_app_reyield_gpt_banner_title_desktop: `過去的投資結果不理想？這次利用 AI 實現更智慧的投資`,
  page_app_reyield_gpt_banner_title_mobile: `利用 AI 開始獲利`,
  page_app_reyield_gpt_banner_sub_title: `REYIELDGPT 提供最適合您的投資計劃，利用 AI 的力量實現您的財務目標吧！`,
  page_app_reyield_gpt_banner_button: `立即探索`,
  page_app_my_portfolio_section_title: `我的投資組合`,
  page_app_my_portfolio_section_sub_title: `探索多樣化投資工具`,
  page_app_my_portfolio_total_profilt_chart_title: `總利潤`,
  page_app_my_portfolio_header: `我的工具`,
  page_app_my_portfolio_empty_data: `無 {{tab_value}} 策略`,
  page_app_my_portfolio_tabs_all_tools: `所有`,
  page_app_my_portfolio_tabs_defi_tools: `DeFi 工具`,
  page_app_my_portfolio_tabs_cefi_tools: `CeFi 工具`,
  page_app_my_portfolio_tabs_bitfinex_tools: `Bitfinex 工具`,
  page_app_my_portfolio_ongoing: `進行中`,
  page_app_my_portfolio_finished: `已完成`,
  page_app_my_portfolio_search: `搜尋`,
  page_app_my_portfolio_sortby: `排序方式`,
  page_app_my_portfolio_sortby_newest: `最新`,
  page_app_my_portfolio_sortby_highest_apy: `最高 APY`,
  page_app_my_portfolio_sortby_highest_roi: `最高 ROI`,
  page_app_rewards_title: `獎勵`,
  page_app_rewards_subtitle: `質押 {{stack_token_num}} REYLD 可獲得 {{rewards}} 額外獎勵，並免費使用所有REYIELD工具！`,
  page_app_rewards_yield_token: `YIELD Token`,
  page_app_rewards_coming_soon: `{{token_name}} 即將推出`,
  page_app_rewards_stake_reyld: `質押 REYLD`,
  page_app_rewards_stake_reyld_desc: `不同於需要收取年費的CeFi工具，在 REYIELD只要質押 2,000 REYLD，就能免費使用所有工具，並額外獲得 25% 的獎勵`,
  page_app_rewards_cashout: `提取 {{token_name}}`,
  page_app_rewards_cashout_desc: `輕鬆提取您的 REYLD `,
  page_app_rewards_deposit: `存入 {{token_name}}`,
  page_app_rewards_deposit_desc: `簡單步驟將加密貨幣從其他帳戶存入到 Reyield 帳戶。`,
  page_app_stake_title: `質押`,
  page_app_stake_subtitle: `以獲取特權`,
  page_app_stake_get_privilege_1: `1. 免費使用所有工具`,
  page_app_stake_get_privilege_2: `2. 讓 AI 成為您的投資助手`,
  page_app_stake_get_privilege_3: `3. 獲得額外 25% 的 REYLD 獎勵`,
  page_app_stake_privilege_activation: `特權啟動`,
  page_app_stake_privilege_not_activation: `特權未啟動`,
  page_app_stake_unstake_btn: `取消質押`,
  page_app_stake_stake_btn: `立即質押`,
  page_app_stake_balance: `餘額`,
  page_app_stake_coming_soon: `即將推出`,
  page_app_stake_staking_privilege: `質押特權`,
  page_app_vest_title: `購買 VEST`,
  page_app_vest_subtitle: `鎖定 REYLD 產生投票權，以決定如何分配 REYLD 獎勵，並透過買賣獲取額外利潤`,
  page_app_vest_btn: `購買 VEST`,
  page_app_vest_vesting: `Vesting`,
  page_app_vest_finished: `已完成`,
  page_app_vest_search: `搜索`,
  page_app_vest_vest_list: `VEST清單`,
  page_app_vest_empty_data: `暫無數據`,
  page_app_vest_start_date: `開始日期`,
  page_app_vest_end_date: `結束日期`,
  page_app_vest_list_header_title_vest: `VEST`,
  page_app_vest_list_header_title_vest_amount: `購買 VEST金額（YIELD）`,
  page_app_vest_list_header_title_vote_value: `投票價值（veYIELD）`,
  page_app_vest_list_header_title_vest_expire: `VEST到期`,
  page_app_vest_list_header_title_manage: `管理`,
  page_app_vest_list_finished_header_title_vest: `VEST`,
  page_app_vest_list_finished_header_title_vest_amount: `購買 VEST金額（YIELD）`,
  page_app_vest_list_finished_header_title_vote_value: `投票價值（veYIELD）`,
  page_app_vest_list_finished_header_title_unlock_time: `解鎖時間`,
  page_app_vest_list_finished_header_title_continue_vest: `繼續購買 VEST`,
  page_app_vote_title: `投票`,
  page_app_vote_subtitle: `您可以透過投票決定 REYLD 獎勵的分配方式，並從收買動作中獲得額外利潤`,
  page_app_vote_balance: `餘額`,
  page_app_vote_my_votes: `我的投票`,
  page_app_vote_voting_power_used: `已使用投票權`,
  page_app_vote_coming_soon: `即將推出`,
  page_app_vote_description: `每個 veREYLD 都將有一次投票的機會，投票者將獲得該期間內存入的所有收買份額，如果您在期間內增加了 veREYLD 餘額，建議重新投票完善配置您的投票權。`,
  page_app_vote_description_2: `詳細信息請參考我們的`,
  page_app_vote_description_doc: `文檔`,
  page_app_vote_list_empty_data: `暫無數據`,
  page_app_vote_list_header_title_tool: `工具`,
  page_app_vote_list_header_title_total_votes: `總投票數`,
  page_app_vote_list_header_title_voting_apr: `投票年化利率`,
  page_app_vote_list_header_title_bribes_fees: `收買 + 費用`,
  page_app_vote_list_header_title_bribes: `收買`,
  page_app_vote_list_header_title_my_votes: `我的投票`,
  page_app_vote_list_header_title_my_votes_percentage: `我的投票 %`,
  page_app_vote_list_filter: `篩選`,
  page_app_bribe_title: `收買`,
  page_app_bribe_subtitle: `需要更多交易量？馬上向投票者提供激勵，將交易量引導到這裡。`,
  page_app_bribe_btn: `收買`,
  page_app_setting_title: `設定`,
  page_app_setting_subtitle: `投資工具將在您自己的交易所帳戶中執行，輸入您的API金鑰啟用工具。`,
  page_app_setting_api_setting: `API設定`,
  page_app_setting_api_setting_api: `{{exchange}} API`,
  page_app_setting_api_setting_name: `名稱`,
  page_app_setting_api_setting_access_key: `訪問金鑰`,
  page_app_setting_api_setting_secret_key: `密鑰`,
  page_app_setting_api_setting_connect: `連接`,
  page_app_setting_api_setting_disconnect: `中斷連接`,
  page_app_setting_api_setting_view_tutorial: `查看教學`,
  nav_item_explore: `探索`,
  nav_item_children_investment_tool: `投資工具`,
  nav_item_children_reyield_gpt: `REYIELDGPT`,
  nav_item_my_portfolio: `我的投資組合`,
  nav_item_reward: `獎勵`,
  nav_item_stake_and_vest: `鎖定 & 投資`,
  nav_item_children_stake: `鎖定`,
  nav_item_children_vest: `投資`,
  nav_item_vote_and_bribe: `投票 & 買取`,
  nav_item_setting: `設定`,
  dialog_supporting_chain_support_network: `支持網絡`,
  dialog_supporting_chain_switch_to: `切換到`,
  dialog_welcome_reyield_title_the_future: `加密貨幣投資的未來`,
  dialog_welcome_reyield_title_a_investment_platform: `REYIELD – AI專業投資平台`,
  dialog_welcome_reyield_title_a_investment_platform_desc: `REYIELD 簡化專業投資，為投資者、KOL和交易所提供投資工具、白牌投資工具和AI投資助手，以提高利潤。`,
  dialog_welcome_reyield_reyield_gpt: `REYIELD GPT`,
  dialog_welcome_reyield_reyield_gpt_features: `在了解您的投資偏好後，GPT-4提供投資計劃，您可以輕鬆啟動最適合您的投資工具。`,
  dialog_welcome_reyield_start: `開始`,
  dialog_wallet_connect_header_title: `連接錢包`,
  dialog_wallet_connect_title: `從下面的錢包中開始連接。`,
  dialog_wallet_connect_request_connection: `請求連接`,
  dialog_wallet_connect_request_connection_confirm: `在您的錢包中確認此連接`,
  dialog_wallet_connect_request_connection_retry: `重試`,
  dialog_wallet_connect_connected_wallet: `已連接錢包`,
  dialog_wallet_connect_gas_fee_wallet: `Gas Fee錢包`,
  dialog_wallet_connect_telegram_bot_connect: `連接我們的Telegram機器人以獲得即時的Gas Fee通知。`,
  dialog_wallet_connect_telegram_bot_subscribe: `訂閱`,
  dialog_wallet_connect_balance_native_asset: `餘額`,
  dialog_wallet_connect_deposit: `存款`,
  dialog_wallet_connect_withdraw: `提款`,
  dialog_wallet_connect_tokens: `代幣`,
  dialog_wallet_connect_no_tokens: `無支持的代幣`,
  dialog_wallet_connect_gas_wallet_num_tools_ongoing: `進行中的工具數量：{{number_of_tools}}`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_1: `Gas Fee不足`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_2: `是`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_3: `，請盡快存款。`,
  dialog_wallet_connect_gas_wallet_num_tools_stop_rebalacing: `停止再平衡的工具數量：{{number_of_tools}}`,
  dialog_wallet_connect_gas_wallet_gas_low_1: `Gas Fee不足`,
  dialog_wallet_connect_gas_wallet_gas_low_2: `已接近用盡`,
  dialog_wallet_connect_gas_wallet_gas_low_3: `，請盡快存款。`,
  dialog_wallet_connect_gas_wallet_num_tools_will_stop_rebalacing: `即將停止再平衡的工具數量：{{number_of_tools}}`,
  dialog_wallet_connect_gas_wallet_create_btn: `創建Gas Fee錢包`,
  dialog_wallet_connect_gas_wallet_create_desc: `使用Gas Fee錢包支付將可節省30％ Gas Fee。`,
  dialog_strategy_enable_dialog_coming_soon: `即將推出`,
  dialog_strategy_enable_dialog_enable_tool_button: `啟用工具`,
  dialog_strategy_enable_dialog_lending_currency: `借貸幣種`,
  dialog_strategy_enable_dialog_tvl: `TVL（池中鎖定的總價值）`,
  dialog_strategy_enable_dialog_warning_minimum_investment_amount: `最低投資金額`,
  dialog_strategy_enable_dialog_warning_minimum_investment_amount_amount: `啟用工具的投資金額：最低{{amount}}美元`,
  dialog_strategy_enable_dialog_warning_title: `警告`,
  dialog_strategy_enable_dialog_warning_content_1: `1. 實際貸款金額將包括Bitfinex理財錢包中的原始USDT資產，您需要自行將資產轉至Bitfinex。`,
  dialog_strategy_enable_dialog_warning_content_2: `2. 實際貸款金額將包括Bitfinex理財錢包中的原始USDT資產，您需要自行將資產轉至Bitfinex。`,
  dialog_strategy_close_dialog_view_status_button: `查看狀態`,
  dialog_strategy_close_dialog_view_other_tools_button: `繼續查看其他工具`,
  dialog_strategy_close_dialog_snackbar_close_success_msg: `策略已成功關閉`,
  dialog_strategy_close_dialog_close_tool_button: `關閉工具`,
  dialog_strategy_close_dialog_close_tool_title: `您確定要關閉{{tool_name}}工具嗎？`,
  dialog_strategy_close_dialog_total_principal: `總本金`,
  dialog_strategy_close_dialog_total_value: `總值`,
  dialog_strategy_close_dialog_run_time: `運行時間`,
  dialog_strategy_close_dialog_back_button: `返回`,
  dialog_strategy_edit_dialog_title: `編輯工具`,
  dialog_strategy_edit_dialog_run_time: `運行時間`,
  dialog_strategy_edit_dialog_my_asset: `我的資產`,
  dialog_strategy_edit_dialog_increase_funds: `增加資金`,
  dialog_strategy_edit_dialog_current_principal: `當前本金`,
  dialog_strategy_edit_dialog_current_apy: `當前APY`,
  dialog_strategy_edit_dialog_estimated_monthly_earning: `估計每月收益`,
  dialog_strategy_edit_dialog_max: `最大值`,
  dialog_strategy_edit_dialog_deposit_warning: `請存入{{amount}} {{coin_symbol}}到連接錢包以啟動該工具。`,
  dialog_strategy_edit_dialog_deposit: `存款`,
  dialog_strategy_edit_dialog_advanced_options: `高級選項`,
  dialog_strategy_edit_dialog_cancel: `取消`,
  dialog_strategy_edit_dialog_loading: `加載中...`,
  dialog_strategy_edit_dialog_fund_retention: `資金保留`,
  dialog_strategy_edit_dialog_rate_mode: `利率模式`,
  dialog_strategy_edit_dialog_lending_period: `借貸期限`,
  dialog_strategy_edit_dialog_rate_mode_min: `最小值`,
  dialog_strategy_edit_dialog_days: `天`,
  dialog_strategy_edit_dialog_lending_period_min: `最小值`,
  dialog_strategy_edit_dialog_lending_period_max: `最大值`,
  dialog_strategy_edit_dialog_my_wallet_asset: `我的錢包資產`,
  dialog_strategy_increase_funds: `增加資金`,
  dialog_strategy_increase_funds_in_tool: `在{{tool}}中增加資金`,
  dialog_strategy_increase_funds_current_principal: `當前本金`,
  dialog_strategy_increase_funds_increase_amount: `+ 增加資金`,
  dialog_strategy_increase_funds_expected_total_principal: `預期總本金`,
  dialog_strategy_increase_funds_increase: `增加資金`,
  dialog_strategy_increase_funds_back: `返回`,
  dialog_strategy_information_view_tool_information: `查看工具信息`,
  dialog_strategy_information_view_tool_records: `查看工具記錄`,
  dialog_strategy_information_view_tool_start_time: `開始時間`,
  dialog_strategy_information_view_tool_close_time: `結束時間`,
  dialog_strategy_information_view_tool_run_time: `運行時間`,
  dialog_strategy_information_view_tool_profit_currency: `利潤 {{currency}}`,
  dialog_strategy_information_view_tool_profit_rate: `單次利潤率`,
  dialog_strategy_information_view_tool_loading: `加載中...`,
  dialog_strategy_information_view_tool_fully_loaded: `所有記錄已加載`,
  dialog_strategy_information_view_tool_back: `返回`,
  dialog_strategy_information_view_tool_principal: `本金`,
  dialog_strategy_information_view_tool_current_value: `當前價值`,
  dialog_deposit_deposit_by: `存款者`,
  dialog_deposit_via_blockchain_deposit: `存款`,
  dialog_deposit_via_blockchain_current_network: `當前網絡`,
  dialog_deposit_via_blockchain_address: `地址`,
  dialog_deposit_via_blockchain_snackbar_copied: `已複製`,
  dialog_deposit_cross_chain_cross_chain_transfer: `跨鏈轉帳`,
  dialog_deposit_cross_chain_cross_chain_transfer_description: `用於資產跨鏈轉移的跨鏈橋樑。您可以觀看上面的教程。`,
  dialog_deposit_cross_chain_cross_chain_transfer_cbridge: `cBRIDGE`,
  dialog_deposit_by_credit_card_buy_crypto: `購買加密貨幣`,
  dialog_deposit_by_credit_card_buy_crypto_next: `下一步`,
  dialog_buy_crypto_dialog_select_currency_next: `下一步`,
  dialog_buy_crypto_dialog_select_currency_buy: `購買`,
  dialog_buy_crypto_dialog_select_currency_buy_crypto: `購買加密貨幣`,
  dialog_reyield_gpt_title: `REYIELDGPT`,
  dialog_reyield_gpt_description: ` AI 幫助您創建更適合您的投資工具組合。`,
  dialog_reyield_gpt_button_start: `開始`,
  dialog_view_portfolios_dialog_title: `投資組合`,
  dialog_view_portfolios_dialog_title_reyield_gpt: `REYIELDGPT`,
  dialog_view_portfolios_dialog_description_reyield_gpt: `REYIELDGPT利用 AI 的力量，根據您的財務目標量身打造投資計劃。`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_portfolios: `投資組合`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_my_expected_return: `我的預期回報`,
  dialog_view_portfolios_dialog_reyield_allocation_of_investment_portfolios: `投資組合分配`,
  dialog_view_portfolios_dialog_reyield_gpt_comprehensive_apy: `綜合APY`,
  dialog_view_portfolios_dialog_reyield_gpt_expected_achieve_time: `預期實現時間`,
  dialog_view_portfolios_dialog_reyield_gpt_risk_tolerance_level: `風險承受值`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_tool_portfolios: `投資工具組合`,
  dialog_view_portfolios_dialog_warning_title: `警告`,
  dialog_view_portfolios_dialog_warning_content_1: `1. 加密貨幣市場中價格可能在短時間內急劇波動，大幅度波動可能導致重大投資損失。`,
  dialog_view_portfolios_dialog_warning_content_2: `2. 過去的投資表現不代表未來的結果，投資前請進行風險評估。`,
  dialog_view_portfolios_dialog_button_enable_portfolio: `啟用投資組合（即將推出）`,
  dialog_api_key_binding_tutorial_title: `您是否擁有Bitfinex帳戶？`,
  dialog_api_key_binding_tutorial_i_have_bitfinex_account: `我有Bitfinex帳戶。`,
  dialog_api_key_binding_tutorial_i_dont_have_bitfinex_account: `我沒有Bitfinex帳戶。`,
  dialog_create_tutorial_dialog_back: `返回`,
  dialog_create_tutorial_dialog_title: `從Bitfinex創建API金鑰`,
  dialog_create_tutorial_dialog_desc: `以下操作將在Bitfinex上執行，請將生成的API金鑰複製到REYIELD中，並注意不要洩露API金鑰。`,
  dialog_create_tutorial_dialog_go_to_bitfinex: `前往Bitfinex`,
  dialog_create_tutorial_dialog_next: `下一步`,
  dialog_create_tutorial_dialog_next_desc: `在REYIELD上設置API金鑰`,
  dialog_api_key_register_tutorial_title: `如何註冊Bitfinex帳戶`,
  dialog_api_key_register_tutorial_step_1: `步驟1：註冊Bitfinex帳戶並完成Bitfinex的KYC流程`,
  dialog_api_key_register_tutorial_step_2: `步驟2：生成Bitfinex API金鑰`,
  dialog_api_key_register_tutorial_step_3: `步驟3：在REYIELD上設置Bitfinex API金鑰`,
  dialog_api_key_register_tutorial_desc: `請先註冊Bitfinex賬戶並生成Bitfinex API密鑰。Bitfinex交易所需要進行KYC驗證，請在收到KYC通過郵件後返回並設置API密鑰。`,
  dialog_api_key_register_tutorial_go_to_bitfinex: `前往Bitfinex註冊`,
  dialog_api_key_setting_title: `設置APY密鑰`,
  dialog_api_key_setting_subtitle: `請在下方貼上API金鑰和API密鑰。`,
  dialog_tool_enable_connect_wallet_notice: `請連接您的錢包`,
  dialog_tool_enable_title: `啟用工具`,
  dialog_tool_enable_apy_history: `APY歷史`,
  dialog_tool_enable_coming_soon: `即將推出`,
  dialog_tool_enable_current_apy: `當前APY`,
  dialog_tool_enable_current_apy_description: `創建以來的實際年化收益率，包括幣價波動，不包括Gas Fee。`,
  dialog_tool_enable_7d_apy: `7天APY`,
  dialog_tool_enable_7d_apy_description: `過去7天的實際年化收益率，包括幣價波動，不包括Gas Fee`,
  dialog_tool_enable_24h_apy: `24小時APY`,
  dialog_tool_enable_24h_apy_description: `過去24小時的實際年化收益率，包括幣價波動，不包括Gas Fee`,
  dialog_tool_enable_tips_formula_details: `公式詳情`,
  dialog_tool_enable_title_creator: `創建者：`,
  dialog_tool_enable_title_running: `運行中：`,
  dialog_tool_enable_title_closed: `已關閉：`,
  dialog_tool_enable_title_duration: `持續時間：`,
  dialog_tool_enable_title_view_more: `查看更多`,
  dialog_tool_enable_input_amount_deposit: `存入`,
  dialog_tool_enable_input_amount_single_token: `單一`,
  dialog_tool_enable_input_amount_dual_token: `雙幣`,
  dialog_tool_enable_input_amount_token_balance: `餘額：`,
  dialog_tool_enable_input_amount_enable_tool: `啟用工具`,
  dialog_tool_enable_input_amount_increase_funds: `增加資金`,
  dialog_tool_enable_input_amount_price_impact_warning: `價格影響警告！`,
  dialog_tool_enable_input_amount_max_btn: `最大`,
  dialog_tool_enable_validation_required: `必填`,
  dialog_tool_enable_validation_token_greater_than_0: `值應大於0`,
  dialog_tool_enable_validation_token_allows_decimals: `{{symbol}}最多允許{{decimals}}位小數`,
  dialog_tool_enable_validation_token_apprive_required: `需要通過代幣授權`,
  dialog_tool_enable_validation_something_wrong: `出現問題，請稍後再試。`,
  dialog_tool_enable_validation_enable_tool_successfully: `成功啟用工具`,
  dialog_tool_enable_validation_increase_funds_successfully: `成功增加資金`,
  dialog_tool_enable_validation_deposit_token_notice: `請存入{{token}}以啟用工具。`,
  dialog_tool_enable_validation_deposit_token0_notice: `請存入`,
  dialog_tool_enable_validation_deposit_and_token_notice: ` 和 `,
  dialog_tool_enable_validation_deposit_token1_notice: `以啟用工具。`,
  dialog_tool_view_more_title: `工具資訊`,
  dialog_tool_view_more_view_tool_intro: `工具解釋`,
  dialog_tool_view_more_view_tool_creator: `工具創建者`,
  dialog_tool_view_more_tool_performance_fee: `工具績效費`,
  dialog_tool_view_more_tool_performance_fee_title: `績效費`,
  dialog_tool_view_more_tool_performance_fee_desc: `由創建者設置並根據淨利潤設置的績效費 `,
  dialog_tool_view_more_tool_performance_fee_formula_desc_1: `當餘額差異<0時，績效費`,
  dialog_tool_view_more_tool_performance_fee_formula_desc_2: `不會`,
  dialog_tool_view_more_tool_performance_fee_formula_desc_3: `收取`,
  dialog_tool_view_more_tool_performance_fee_formula_1: `績效費 = 餘額差異*X%`,
  dialog_tool_view_more_tool_performance_fee_formula_2: `餘額差異 = (當前token0 - 初始token0)*token0價格) + (當前token1 - 初始token1)*token1價格) X%=創建者設置的參數`,
  dialog_tool_view_more_view_tool_parameters: `工具參數`,
  dialog_tool_view_more_view_tool_parameters_title: `Uniswap上的工具參數`,
  dialog_tool_view_more_view_tool_parameters_platform: `平台`,
  dialog_tool_view_more_view_tool_parameters_blockchain: `區塊鏈`,
  dialog_tool_view_more_view_tool_parameters_pair: `交易對`,
  dialog_tool_view_more_view_tool_parameters_fee_tier: `費率層級`,
  dialog_tool_view_more_view_tool_parameters_price_range: `價格範圍`,
  dialog_tool_view_more_view_tool_parameters_pool_addr: `Uniswap 池地址`,
  dialog_tool_view_more_view_rebalance_records: `查看重新平衡記錄`,
  dialog_tool_view_more_range_predictor_ai: `價格範圍預測 AI`,
  dialog_tool_view_more_coming_soon: `即將推出`,
  dialog_tool_view_more_tool_creator_title: `關於創建者`,
  dialog_tool_view_more_tool_creator_other_tools: `創建者的其他工具`,
  dialog_tool_view_more_tool_creator_no_other_tools: `沒有其他工具`,
  dialog_tool_view_more_tool_creator_tool_chain: `區塊鏈：`,
  dialog_tool_view_more_tool_creator_tool_token: `代幣：`,
  dialog_tool_view_more_tool_creator_tool_apy: `APY`,
  dialog_tool_view_more_tool_creator_tool_tvl: `TVL`,
  dialog_tool_view_more_tool_intro_title: `工具介紹`,
  dialog_tool_view_more_tool_intro_creator: `創建者：`,
  dialog_tool_ongoing_title: `進行中的工具`,
  dialog_tool_ongoing_unrealized_pnl: `未實現盈虧`,
  dialog_tool_ongoing_unrealized_pnl_desc: `未實現盈虧包括貨幣波動、Uniswap 收益和績效費用。`,
  dialog_tool_ongoing_unrealized_pnl_income: `Uniswap 收益：`,
  dialog_tool_ongoing_unrealized_pnl_token_price_fluctuations: `代幣價格波動：`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_for_creator: `工具創建者的績效費用`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_of: `的`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_net_profit: `淨利潤`,
  dialog_tool_ongoing_unrealized_apy: `APY`,
  dialog_tool_ongoing_unrealized_apy_desc: `自創建以來的實際年化回報，包括幣價波動，不包括Gas Fee。`,
  dialog_tool_ongoing_deposit_balance: `存款餘額`,
  dialog_tool_ongoing_operate: `操作`,
  dialog_tool_ongoing_operate_increase_funds: `增加資金`,
  dialog_tool_ongoing_operate_view_tool_performance_fee: `查看工具績效費用`,
  dialog_tool_ongoing_operate_tool_parameters: `工具參數`,
  dialog_tool_ongoing_operate_close_tool: `關閉工具`,
  dialog_tool_ongoing_increase_funds: `增加資金`,
  dialog_tool_ongoing_clost_tool_desc: `請選擇要接收的代幣類型`,
  dialog_tool_ongoing_clost_tool_receive: `您將獲得`,
  dialog_tool_ongoing_clost_tool_btn: `關閉`,
  dialog_tool_finished_title: `已完成的工具`,
  dialog_formula_details_title: `公式詳情`,
  dialog_formula_details_current_apy_formula_title: `當前年化收益率 = (1 - ( 輸入金額 - 實際最高 APY 使用者的輸入金額) / 池子 TVL) * 最高實際 APY`,
  dialog_formula_details_current_apy_formula: `實際 APY=(current token0 - Initial token0)*token0 price) + (current token1 - initial token1)*token1 price)(run time / 365 * 24 * 60)`,
  dialog_formula_details_7d_apy_formula_title: `7天年化收益率 = 7天內的最高實際 APY`,
  dialog_formula_details_7d_apy_formula: `7天內實際 APY = (7D 餘額差異 / 7天前初始餘額) * 100`,
  dialog_formula_details_7d_apy_formula_2: `餘額差異=(current token0 - Initial token0)*token0 price) + (current token1 - Initial token1)*token1 price)`,
  dialog_formula_details_24h_apy_formula_title: `24小時年化收益率 = 24小時內的最高實際 APY`,
  dialog_formula_details_24h_apy_formula: `24小時內實際 APY = (24小時 餘額差異 / 24小時前初始餘額) * 100`,
  dialog_formula_details_24h_apy_formula_2: `餘額差異=(current token0 - Initial token0)*token0 price) + (current token1 - Initial token1)*token1 price)`,
  dialog_with_background_back: `返回`,
  about_reyield_rebalance_title: `REYIELD 自動再平衡機制`,
  about_reyield_rebalance_description: `自動再平衡工具讓您在 Uniswap v3 的流動性池中添加流動性更加容易。無需手動執行，用戶只需指定存入金額，REYIELD Uniswap 自動再平衡工具將自動處理。`,
  explore_card_coming_soon: `即將推出`,
  explore_card_apy: `年化收益率`,
  explore_card_pool_pair: `流動性池對`,
  explore_card_risk: `風險`,
  explore_card_total_users: `總用戶數`,
  explore_card_tvl: `鎖倉總值`,
  explore_card_pnl: `盈虧`,
  explore_card_balance: `餘額`,
  ai_completion_record_retry: `重試`,
  page_app_reyield_gpt_chat_history_empty: `無聊天記錄。`,
  page_app_reyield_gpt_chat_history_view_detail_and_enable_previous_portfolio: `查看詳情並啟用先前的投資組合`,
  investment_style_warren_buffett: `華倫·巴菲特風格`,
  investment_style_ray_dalio: `雷·達利奧風格`,
  investment_style_peter_lynch: `彼得·林奇風格`,
  investment_style_george_soros: `喬治·索羅斯風格`,
  investment_style_cathie_wood: `凱茜·伍德風格`,
  investment_style_michael_burry: `麥克·伯瑞風格`,
  investment_goal_of_buying_a_car: `購車`,
  investment_goal_of_buying_a_house: `購房`,
  investment_goal_of_buying_education_fund: `教育基金`,
  investment_goal_of_buying_a_wedding_fund: `結婚基金`,
  investment_goal_of_health_insurance: `健康保險`,
  investment_goal_of_travel_fund: `旅行基金`,
  investment_goal_of_startup_capital: `創業資本`,
  investment_goal_of_1000_usd: `1,000 美元`,
  investment_goal_of_5000_usd: `5,000 美元`,
  investment_goal_of_10000_usd: `10,000 美元`,
  investment_goal_of_50000_usd: `50,000 美元`,
  investment_goal_of_100000_usd: `100,000 美元`,
  investment_goal_of_300000_usd: `300,000 美元`,
  investment_goal_of_500000_usd: `500,000 美元`,
  investment_goal_of_1000000_usd: `1,000,000 美元`,
  investment_period_of_1_month: `1 個月`,
  investment_period_of_3_months: `3 個月`,
  investment_period_of_6_months: `6 個月`,
  investment_period_of_1_year: `1 年`,
  investment_period_of_2_years: `2 年`,
  investment_period_of_3_years: `3 年`,
  investment_period_of_5_years: `5 年及以上`,
  investment_preference_of_demo_en: `Due to the recent poor market conditions, I am very optimistic about ETH and want to go all in on ETH. I do not want to use CeFi, and I am hoping for an annual return of over 48%.`,
  investment_preference_of_demo_cn: `因為最近市場不好，非常看好ETH，想要 all in ETH，不想使用 CeFi, 然後希望年化收益48%以上。`,
  investment_preference_of_demo_jp: `最近の市場が良くないため、ETHに非常に期待しています。ETHに全力投資したいと思っていますが、CeFiは使用したくありません。そして、年間利回りが48％以上を望んでいます。`,
  investment_preference_of_demo_emoji: `🚀 🚀 🚀 `,
  investment_question_purpose: `您的投資目標是什麼？（目的）`,
  investment_question_goal_amount: `您已經回答了，答案是{{answer}}。\n下一個問題\n您需要多少錢才能達到您的投資目標（目的）？（以美元計）`,
  investment_question_available_amount: `您已經回答了，答案是{{answer}}。\n下一個問題\n您目前有多少錢可用於投資目的？`,
  investment_question_period: `您已經回答了，答案是{{answer}}。\n下一個問題\n您預計需要多長時間才能實現您的投資目標（目的）？`,
  investment_question_risk_tolerance: `您已經回答了，答案是{{answer}}。\n下一個問題\n考慮到以下利潤和風險情況，您最舒適的是哪一種？`,
  investment_question_risk_tolerance_bottom_desc: `請注意，這些排名基於對不同風險和投資偏好的一般理解。每位投資者的風險容忍度和投資目標各不相同，因此應根據個人情況評估並選擇最合適的投資風格。`,
  investment_question_preference: `您有任何想要補充的投資偏好嗎？`,
  conversation_already_received_answer_and_generate_portfolio: `您已經回答了，答案是{{answer}}。\n現在將幫助您生成 REYIELDGPT 推薦的投資組合。`,
  reyield_gpt_suggested_answer_title: `建議答案`,
  reyield_gpt_suggested_answer_highest_apr: `最高年利率`,
  reyield_gpt_suggested_answer_maximum_loss: `最大損失`,
  reyield_gpt_tab_chat_history: `聊天記錄`,
  reyield_gpt_tab_reyield_gpt: `REYIELDGPT`,
  reyield_gpt_tailoring_investment_plan_for_you: `為您量身定制投資計劃...`,
  reyield_gpt_view_detail_and_enable_portfolio: `查看詳情並啟用投資組合`,
  reyield_gpt_view_detail_start_over: `重新開始`,
  reyield_gpt_is_responding: `REYIELD GPT 正在回應...`,
  reyield_gpt_input_the_answer_manually: `手動輸入答案...`,
  nav_vertical_reyield_gpt: `REYIELDGPT`,
  nav_vertical_step_1: `步驟1：問卷`,
  nav_vertical_step_2: `步驟2：AI推薦投資組合`,
  nav_vertical_step_3: `步驟3：啟用投資組合`,
  nav_header_please_login_first: `請先登錄`,
  reyield_gpt_layout_nav_header_cta_mobile: `主頁`,
  reyield_gpt_layout_nav_header_cta_desktop: `返回搜索`,
  nav_header_usage_count_today_mobile: `用量`,
  nav_header_usage_count_today_desktop: `今日用量`,
}

export default cn
