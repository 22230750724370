import '../locales/i18n'
import 'simplebar/src/simplebar.css'
import 'react-image-lightbox/style.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-quill/dist/quill.snow.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import '@fontsource/space-grotesk'

import { ApolloProvider } from '@apollo/client'
import { CacheProvider, EmotionCache } from '@emotion/react'
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// next
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
// import { AuthProvider } from '../auth/Auth0Context';
// import { AuthProvider } from '../auth/FirebaseContext';
// import { AuthProvider } from '../auth/AwsCognitoContext';
import { Session } from 'next-auth'
import { ReactElement, ReactNode, useEffect } from 'react'
import TagManager from 'react-gtm-module'
// redux
import { Provider as ReduxProvider } from 'react-redux'

import { useApollo } from '@/apollo/client'
import { MotionLazyContainer } from '@/components/animate'
// components
import { StyledChart } from '@/components/chart'
import WalletProvider from '@/components/connect-wallet/WalletProvider'
import ProgressBar from '@/components/progress-bar'
import { SettingsProvider, ThemeSettings } from '@/components/settings'
import SnackbarProvider from '@/components/snackbar'
import { SnackbarUtilsConfigurator } from '@/components/snackbar/SnackbarUtils'
// theme
import ThemeProvider from '@/theme/index'

import { AuthProvider } from '../auth/JwtContext'
// locales
import ThemeLocalization from '../locales'
// redux
import { store } from '../redux/store'
// utils
import createEmotionCache from '../utils/createEmotionCache'

const clientSideEmotionCache = createEmotionCache()

type NextPageWithLayout = NextPage<PageProps> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type PageProps = { session?: Session } & AppProps['pageProps']

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  pageProps: PageProps
  Component: NextPageWithLayout
  initialApolloState: any
}

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps, emotionCache = clientSideEmotionCache, initialApolloState } = props
  const apolloClient = useApollo(initialApolloState)
  const getLayout = Component.getLayout ?? ((page) => page)

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
      TagManager.initialize({
        gtmId: process.env.GTM_ID ?? '',
        preview: process.env.GTM_PREVIEW ?? '',
        auth: process.env.GTM_AUTH ?? '',
      })
    }
  }, [])

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>REYIELD Finance</title>
      </Head>

      <WalletProvider session={pageProps.session}>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <ReduxProvider store={store}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                  <MotionLazyContainer>
                    <ThemeProvider>
                      <ThemeSettings>
                        <ThemeLocalization>
                          <SnackbarProvider>
                            <SnackbarUtilsConfigurator />
                            <StyledChart />
                            <ProgressBar />
                            {getLayout(<Component {...pageProps} />)}
                          </SnackbarProvider>
                        </ThemeLocalization>
                      </ThemeSettings>
                    </ThemeProvider>
                  </MotionLazyContainer>
                </SettingsProvider>
              </LocalizationProvider>
            </ReduxProvider>
          </AuthProvider>
        </ApolloProvider>
      </WalletProvider>
    </CacheProvider>
  )
}
