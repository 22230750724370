import { currentDomain } from '@/config'
import { EvmChain } from '@/generated/graphql.node'
import TokenImageURI from '@/generated/tokenImageURI.json'

import { Token } from '../interfaces/uniswap.interface'

export const getFeeTierPercentage = (tier: string): number => {
  if (tier === '100') return 0.01 / 100
  if (tier === '500') return 0.05 / 100
  if (tier === '3000') return 0.3 / 100
  if (tier === '10000') return 1 / 100
  return 0
}

interface ITickTypes {
  tickLowerDiff: number
  tickUpperDiff: number
  tickLowerDiffForContract: number
  tickUpperDiffForContract: number
}

export const getFeeTierTickSize = (tier: number): ITickTypes | null => {
  if (tier === 100)
    return {
      tickLowerDiff: 0.0001,
      tickUpperDiff: 0.0001,
      tickLowerDiffForContract: -1,
      tickUpperDiffForContract: 1,
    }
  if (tier === 500)
    return {
      tickLowerDiff: 0.001,
      tickUpperDiff: 0.001,
      tickLowerDiffForContract: -10,
      tickUpperDiffForContract: 10,
    }
  if (tier === 3000)
    return {
      tickLowerDiff: 0.006,
      tickUpperDiff: 0.006,
      tickLowerDiffForContract: -60,
      tickUpperDiffForContract: 60,
    }
  if (tier === 10000)
    return {
      tickLowerDiff: 0.02,
      tickUpperDiff: 0.02,
      tickLowerDiffForContract: -200,
      tickUpperDiffForContract: 200,
    }
  return null
}

export const getNativeAssetLogoURL = (platform: string | EvmChain): string => {
  let addr = '0x'
  switch (platform) {
    case 'OP Mainnet':
    case EvmChain.Optimism:
    case EvmChain.Ethereum: {
      addr = '0x0000000000000000000000000000000000000000'
      return getTokenLogoURL(EvmChain.Ethereum, addr)
    }
    case EvmChain.Polygon:
      addr = '0x0000000000000000000000000000000000001010'
      break
    default:
      break
  }

  return getTokenLogoURL(platform, addr)
}

export const getTokenLogoURL = (platform: string | EvmChain, address: string): string => {
  const mapper = TokenImageURI as any
  const imageURL = mapper[platform.toLowerCase()]?.[address.toLowerCase()]

  if (imageURL) return imageURL

  return `${currentDomain}/images/fi-cnsuxl-question-mark.png`
}

export const sortTokens = (token0: Token, token1: Token): Token[] => {
  if (token0.id < token1.id) {
    return [token0, token1]
  }
  return [token1, token0]
}

export const sortTokensByAddress = (token0: string, token1: string): string[] => {
  if (token0 < token1) {
    return [token0, token1]
  }
  return [token1, token0]
}

// return unique string in string[]
export const getUniqueItems = (arr: string[]): string[] =>
  arr.filter((v, i, a) => a.indexOf(v) === i)

interface IUniswapUrlGeneratorParams {
  chain: EvmChain
  poolAddress: string
}
export const uniswapPoolInfoUrlGenerator = (params: IUniswapUrlGeneratorParams) => {
  const { chain, poolAddress } = params

  let urlNameMapping = chain.toLowerCase()
  switch (chain) {
    case EvmChain.Ethereum:
      urlNameMapping = 'ethereum'
      break
    case EvmChain.Polygon:
      urlNameMapping = 'polygon'
      break
    case EvmChain.Optimism:
      urlNameMapping = 'optimism'
      break
    case EvmChain.Arbitrum:
      urlNameMapping = 'arbitrum'
      break
    default:
      break
  }

  return `https://info.uniswap.org/#/${urlNameMapping}/pools/${poolAddress}`
}
