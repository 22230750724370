import { REYIELD_COLORS } from './palette'

// ----------------------------------------------------------------------

interface ReyieldColorInterface {
  lighter?: string
  light?: string
  main?: string
  dark?: string
  darker?: string
  contrastText?: string
}

interface ReyieldPaletteOptions {
  primary: ReyieldColorInterface
  secondary: ReyieldColorInterface
  warning: ReyieldColorInterface
  error: ReyieldColorInterface
  stroke: ReyieldColorInterface
}

declare module '@mui/material/styles' {
  interface Theme {
    reyieldPalette: ReyieldPaletteOptions
  }
  interface ThemeOptions {
    reyieldPalette?: ReyieldPaletteOptions
  }
}

// ----------------------------------------------------------------------

function createPalette() {
  return {
    primary: {
      lighter: REYIELD_COLORS.primary.lighter,
      light: REYIELD_COLORS.primary.light,
      main: REYIELD_COLORS.primary.main,
      dark: REYIELD_COLORS.primary.dark,
      darker: REYIELD_COLORS.primary.darker,
      contrastText: REYIELD_COLORS.primary.contrastText,
    },
    secondary: REYIELD_COLORS.secondary,
    warning: REYIELD_COLORS.warning,
    error: REYIELD_COLORS.error,
    stroke: REYIELD_COLORS.stroke,
  }
}

export default function reyieldPalette() {
  return createPalette()
}
