// @mui
import { CssBaseline } from '@mui/material'
import { createTheme, ThemeOptions, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { useMemo } from 'react'

// components
import { useSettingsContext } from '../components/settings'
import customShadows from './customShadows'
import GlobalStyles from './globalStyles'
import componentsOverride from './overrides'
//
import palette from './palette'
import reyieldPalette from './reyieldPalette'
import shadows from './shadows'
import typography from './typography'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettingsContext()

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(themeMode),
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode),
      reyieldPalette: reyieldPalette(),
    }),
    [themeDirection, themeMode]
  )

  const theme = createTheme(themeOptions)

  theme.components = componentsOverride(theme)

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </MUIThemeProvider>
  )
}
