import { Box, ButtonProps, Typography, useTheme } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useAccount, useWaitForTransaction } from 'wagmi'

import { toReadableAmount } from '@/apis/backendReyield/uniswap/utils/conversion'
import {
  useErc20Allowance,
  useErc20Approve,
  useErc20Decimals,
  useErc20Symbol,
  useErc20TotalSupply,
} from '@/generated/contracts'
import { ReyieldButton } from '@/theme/overrides/Button'
import reyieldPalette from '@/theme/reyieldPalette'

import Iconify from '../iconify'

export enum AllowanceState {
  Initial = 'Initial',
  Enough = 'Enough',
  NotEnough = 'NotEnough',
  // this is the case where the user is approving the token
  Processing = 'Processing',
  Error = 'Error',
}

interface ApproveTokenProps {
  tokenAddress: `0x${string}`
  targetContractAddress: `0x${string}`
  wishToUseReadableAmount?: string
  onStateChange?: (state: AllowanceState) => void
}

const approveButtonProps: ButtonProps = {
  variant: 'contained',
  sx: {
    minWidth: '100px',
    height: '26px',
    background: reyieldPalette().warning.main,
    '&:hover': {
      background: reyieldPalette().warning.main,
    },
  },
}

const ApproveToken = (props: ApproveTokenProps) => {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const { tokenAddress, targetContractAddress, wishToUseReadableAmount, onStateChange } = props
  const { isConnected, address } = useAccount()
  const [hash, setHash] = useState<`0x${string}` | undefined>(undefined)

  const [allowanceState, setAllowanceState] = useState<AllowanceState>(AllowanceState.Initial)

  const { writeAsync: erc20ApproveWriteAsync, error: erc20ApproveWriteAsyncError } =
    useErc20Approve({
      address: tokenAddress,
      onSuccess: (data) => {
        setHash(data.hash)
      },
      onError: (error) => {
        setAllowanceState(AllowanceState.Error)
      },
    })

  useEffect(() => {
    if (erc20ApproveWriteAsyncError?.message) {
      if (erc20ApproveWriteAsyncError?.message.includes('User rejected the request')) {
        enqueueSnackbar('Request cancelled', {
          variant: 'info',
        })
      } else {
        enqueueSnackbar(erc20ApproveWriteAsyncError?.message, {
          variant: 'info',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [erc20ApproveWriteAsyncError])

  const {
    data: erc20Allowance,
    refetch: erc20AllowanceRefetch,
    isFetching: erc20AllowanceIsFetching,
  } = useErc20Allowance({
    address: tokenAddress,
    args: address ? [address, targetContractAddress] : undefined,
  })

  const { data: erc20Decimals, isFetching: erc20DecimalsIsFetching } = useErc20Decimals({
    address: tokenAddress,
  })
  const { data: erc20Symbol, isFetching: erc20SymbolIsFetching } = useErc20Symbol({
    address: tokenAddress,
  })

  const { data: erc20TotalSupply, isFetching: erc20TotalSupplyIsFetching } = useErc20TotalSupply({
    address: tokenAddress,
  })

  useWaitForTransaction({
    hash,
    onSuccess: () => {
      enqueueSnackbar(`Approved ${erc20Symbol} successfully`, {
        variant: 'success',
      })
      erc20AllowanceRefetch()
    },
  })

  const isFetching =
    erc20AllowanceIsFetching ||
    erc20DecimalsIsFetching ||
    erc20TotalSupplyIsFetching ||
    erc20SymbolIsFetching

  const allowance = erc20Decimals
    ? toReadableAmount(erc20Allowance?.toString() || 0, erc20Decimals)
    : undefined

  useEffect(() => {
    if (allowance && wishToUseReadableAmount) {
      if (Number(allowance) >= Number(wishToUseReadableAmount)) {
        if (allowanceState === AllowanceState.Enough) return
        setAllowanceState(AllowanceState.Enough)
        onStateChange && onStateChange(AllowanceState.Enough)
      } else {
        if (allowanceState === AllowanceState.NotEnough) return
        setAllowanceState(AllowanceState.NotEnough)
        onStateChange && onStateChange(AllowanceState.NotEnough)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowance, wishToUseReadableAmount])

  if (!isConnected || Number(wishToUseReadableAmount) <= 0) return <div />
  const disableState =
    allowanceState === AllowanceState.Enough || isFetching || Number(wishToUseReadableAmount) <= 0

  return (
    <ReyieldButton
      {...approveButtonProps}
      style={{
        display: disableState ? 'none' : 'flex',
      }}
      loading={allowanceState === AllowanceState.Processing}
      onClick={async () => {
        if (!erc20TotalSupply) return
        setAllowanceState(AllowanceState.Processing)
        await erc20ApproveWriteAsync({
          args: [targetContractAddress, erc20TotalSupply],
        })
        enqueueSnackbar(`Approving ${erc20Symbol} ...`, {
          variant: 'info',
        })
      }}
    >
      <Box display="flex" alignItems="center" gap="4px">
        <Iconify icon="material-symbols:error-rounded" width={16} />
        <Typography variant="caption" fontWeight={500}>
          Approve Token
        </Typography>
        <Iconify icon="ic:round-arrow-forward" width="16px" height="16px" />
      </Box>
    </ReyieldButton>
  )
}

export default ApproveToken
