import { Session } from 'next-auth'
import { SessionProvider, signOut, useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { createConfig, useDisconnect, WagmiConfig } from 'wagmi'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

import { chains, publicClient } from '@/chains'

import { OkxConnector } from './OkxConnector'

type Props = {
  children: React.ReactNode
  session?: Session
}

const metaMaskConnector = new MetaMaskConnector({
  chains,
})

const okxConnector = new OkxConnector({
  chains,
})

const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    projectId: '52ac9c99e8bc2cbe3332e3fe90a07a5b',
  },
})

const config = createConfig({
  publicClient,
  autoConnect: true,
  connectors: [metaMaskConnector, okxConnector, walletConnectConnector],
})

const SessionChecker = () => {
  const { data: sessionData } = useSession()
  const { disconnectAsync } = useDisconnect()

  useEffect(() => {
    async function handleSignOut() {
      await disconnectAsync()
      await signOut({
        redirect: false,
      })
    }
    if (typeof window !== 'undefined' && sessionData && sessionData?.error) {
      handleSignOut()
    }
  }, [sessionData])
  return <div />
}

export default function WalletProvider({ children, session }: Props) {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])

  return (
    <WagmiConfig config={config}>
      <SessionProvider session={session} refetchInterval={0}>
        {mounted && children}
        <SessionChecker />
      </SessionProvider>
    </WagmiConfig>
  )
}
