const key = {
  app: `app`,
  app_view_white_paper: `app_view_white_paper`,
  app_header_connect: `app_header_connect`,
  risk_all_tools: `risk_all_tools`,
  risk_low_risk_tools: `risk_low_risk_tools`,
  risk_medium_low_risk_tools: `risk_medium_low_risk_tools`,
  risk_medium_risk_tools: `risk_medium_risk_tools`,
  risk_medium_high_risk_tools: `risk_medium_high_risk_tools`,
  risk_high_risk_tools: `risk_high_risk_tools`,
  page_app_investment_tool_header: `page_app_investment_tool_header`,
  page_app_investment_tool_enable_all_message: `page_app_investment_tool_enable_all_message`,
  page_app_explorer_section_title: `page_app_explorer_section_title`,
  page_app_explorer_section_sub_title: `page_app_explorer_section_sub_title`,
  page_app_reyield_gpt_title: `page_app_reyield_gpt_title`,
  page_app_reyield_gpt_subtitle: `page_app_reyield_gpt_subtitle`,
  page_app_reyield_gpt_counter_desc: `page_app_reyield_gpt_counter_desc`,
  page_app_reyield_gpt_banner_title_desktop: `page_app_reyield_gpt_banner_title_desktop`,
  page_app_reyield_gpt_banner_title_mobile: `page_app_reyield_gpt_banner_title_mobile`,
  page_app_reyield_gpt_banner_sub_title: `page_app_reyield_gpt_banner_sub_title`,
  page_app_reyield_gpt_banner_button: `page_app_reyield_gpt_banner_button`,
  page_app_my_portfolio_section_title: `page_app_my_portfolio_section_title`,
  page_app_my_portfolio_section_sub_title: `page_app_my_portfolio_section_sub_title`,
  page_app_my_portfolio_total_profilt_chart_title: `page_app_my_portfolio_total_profilt_chart_title`,
  page_app_my_portfolio_header: `page_app_my_portfolio_header`,
  page_app_my_portfolio_empty_data: `page_app_my_portfolio_empty_data`,
  page_app_my_portfolio_tabs_all_tools: `page_app_my_portfolio_tabs_all_tools`,
  page_app_my_portfolio_tabs_defi_tools: `page_app_my_portfolio_tabs_defi_tools`,
  page_app_my_portfolio_tabs_cefi_tools: `page_app_my_portfolio_tabs_cefi_tools`,
  page_app_my_portfolio_tabs_bitfinex_tools: `page_app_my_portfolio_tabs_bitfinex_tools`,
  page_app_my_portfolio_ongoing: `page_app_my_portfolio_ongoing`,
  page_app_my_portfolio_finished: `page_app_my_portfolio_finished`,
  page_app_my_portfolio_search: `page_app_my_portfolio_search`,
  page_app_my_portfolio_sortby: `page_app_my_portfolio_sortby`,
  page_app_my_portfolio_sortby_newest: `page_app_my_portfolio_sortby_newest`,
  page_app_my_portfolio_sortby_highest_apy: `page_app_my_portfolio_sortby_highest_apy`,
  page_app_my_portfolio_sortby_highest_roi: `page_app_my_portfolio_sortby_highest_roi`,
  page_app_rewards_title: `page_app_rewards_title`,
  page_app_rewards_subtitle: `page_app_rewards_subtitle`,
  page_app_rewards_yield_token: `page_app_rewards_yield_token`,
  page_app_rewards_coming_soon: `page_app_rewards_coming_soon`,
  page_app_rewards_stake_reyld: `page_app_rewards_stake_reyld`,
  page_app_rewards_stake_reyld_desc: `page_app_rewards_stake_reyld_desc`,
  page_app_rewards_cashout: `page_app_rewards_cashout`,
  page_app_rewards_cashout_desc: `page_app_rewards_cashout_desc`,
  page_app_rewards_deposit: `page_app_rewards_deposit`,
  page_app_rewards_deposit_desc: `page_app_rewards_deposit_desc`,
  page_app_stake_title: `page_app_stake_title`,
  page_app_stake_subtitle: `page_app_stake_subtitle`,
  page_app_stake_get_privilege_1: `page_app_stake_get_privilege_1`,
  page_app_stake_get_privilege_2: `page_app_stake_get_privilege_2`,
  page_app_stake_get_privilege_3: `page_app_stake_get_privilege_3`,
  page_app_stake_privilege_activation: `page_app_stake_privilege_activation`,
  page_app_stake_privilege_not_activation: `page_app_stake_privilege_not_activation`,
  page_app_stake_unstake_btn: `page_app_stake_unstake_btn`,
  page_app_stake_stake_btn: `page_app_stake_stake_btn`,
  page_app_stake_balance: `page_app_stake_balance`,
  page_app_stake_coming_soon: `page_app_stake_coming_soon`,
  page_app_stake_staking_privilege: `page_app_stake_staking_privilege`,
  page_app_vest_title: `page_app_vest_title`,
  page_app_vest_subtitle: `page_app_vest_subtitle`,
  page_app_vest_btn: `page_app_vest_btn`,
  page_app_vest_vesting: `page_app_vest_vesting`,
  page_app_vest_finished: `page_app_vest_finished`,
  page_app_vest_search: `page_app_vest_search`,
  page_app_vest_vest_list: `page_app_vest_vest_list`,
  page_app_vest_empty_data: `page_app_vest_empty_data`,
  page_app_vest_start_date: `page_app_vest_start_date`,
  page_app_vest_end_date: `page_app_vest_end_date`,
  page_app_vest_list_header_title_vest: `page_app_vest_list_header_title_vest`,
  page_app_vest_list_header_title_vest_amount: `page_app_vest_list_header_title_vest_amount`,
  page_app_vest_list_header_title_vote_value: `page_app_vest_list_header_title_vote_value`,
  page_app_vest_list_header_title_vest_expire: `page_app_vest_list_header_title_vest_expire`,
  page_app_vest_list_header_title_manage: `page_app_vest_list_header_title_manage`,
  page_app_vest_list_finished_header_title_vest: `page_app_vest_list_finished_header_title_vest`,
  page_app_vest_list_finished_header_title_vest_amount: `page_app_vest_list_finished_header_title_vest_amount`,
  page_app_vest_list_finished_header_title_vote_value: `page_app_vest_list_finished_header_title_vote_value`,
  page_app_vest_list_finished_header_title_unlock_time: `page_app_vest_list_finished_header_title_unlock_time`,
  page_app_vest_list_finished_header_title_continue_vest: `page_app_vest_list_finished_header_title_continue_vest`,
  page_app_vote_title: `page_app_vote_title`,
  page_app_vote_subtitle: `page_app_vote_subtitle`,
  page_app_vote_balance: `page_app_vote_balance`,
  page_app_vote_my_votes: `page_app_vote_my_votes`,
  page_app_vote_voting_power_used: `page_app_vote_voting_power_used`,
  page_app_vote_coming_soon: `page_app_vote_coming_soon`,
  page_app_vote_description: `page_app_vote_description`,
  page_app_vote_description_2: `page_app_vote_description_2`,
  page_app_vote_description_doc: `page_app_vote_description_doc`,
  page_app_vote_list_empty_data: `page_app_vote_list_empty_data`,
  page_app_vote_list_header_title_tool: `page_app_vote_list_header_title_tool`,
  page_app_vote_list_header_title_total_votes: `page_app_vote_list_header_title_total_votes`,
  page_app_vote_list_header_title_voting_apr: `page_app_vote_list_header_title_voting_apr`,
  page_app_vote_list_header_title_bribes_fees: `page_app_vote_list_header_title_bribes_fees`,
  page_app_vote_list_header_title_bribes: `page_app_vote_list_header_title_bribes`,
  page_app_vote_list_header_title_my_votes: `page_app_vote_list_header_title_my_votes`,
  page_app_vote_list_header_title_my_votes_percentage: `page_app_vote_list_header_title_my_votes_percentage`,
  page_app_vote_list_filter: `page_app_vote_list_filter`,
  page_app_bribe_title: `page_app_bribe_title`,
  page_app_bribe_subtitle: `page_app_bribe_subtitle`,
  page_app_bribe_btn: `page_app_bribe_btn`,
  page_app_setting_title: `page_app_setting_title`,
  page_app_setting_subtitle: `page_app_setting_subtitle`,
  page_app_setting_api_setting: `page_app_setting_api_setting`,
  page_app_setting_api_setting_api: `page_app_setting_api_setting_api`,
  page_app_setting_api_setting_name: `page_app_setting_api_setting_name`,
  page_app_setting_api_setting_access_key: `page_app_setting_api_setting_access_key`,
  page_app_setting_api_setting_secret_key: `page_app_setting_api_setting_secret_key`,
  page_app_setting_api_setting_connect: `page_app_setting_api_setting_connect`,
  page_app_setting_api_setting_disconnect: `page_app_setting_api_setting_disconnect`,
  page_app_setting_api_setting_view_tutorial: `page_app_setting_api_setting_view_tutorial`,
  nav_item_explore: `nav_item_explore`,
  nav_item_children_investment_tool: `nav_item_children_investment_tool`,
  nav_item_children_reyield_gpt: `nav_item_children_reyield_gpt`,
  nav_item_my_portfolio: `nav_item_my_portfolio`,
  nav_item_reward: `nav_item_reward`,
  nav_item_stake_and_vest: `nav_item_stake_and_vest`,
  nav_item_children_stake: `nav_item_children_stake`,
  nav_item_children_vest: `nav_item_children_vest`,
  nav_item_vote_and_bribe: `nav_item_vote_and_bribe`,
  nav_item_setting: `nav_item_setting`,
  dialog_supporting_chain_support_network: `dialog_supporting_chain_support_network`,
  dialog_supporting_chain_switch_to: `dialog_supporting_chain_switch_to`,
  dialog_welcome_reyield_title_the_future: `dialog_welcome_reyield_title_the_future`,
  dialog_welcome_reyield_title_a_investment_platform: `dialog_welcome_reyield_title_a_investment_platform`,
  dialog_welcome_reyield_title_a_investment_platform_desc: `dialog_welcome_reyield_title_a_investment_platform_desc`,
  dialog_welcome_reyield_reyield_gpt: `dialog_welcome_reyield_reyield_gpt`,
  dialog_welcome_reyield_reyield_gpt_features: `dialog_welcome_reyield_reyield_gpt_features`,
  dialog_welcome_reyield_start: `dialog_welcome_reyield_start`,
  dialog_wallet_connect_header_title: `dialog_wallet_connect_header_title`,
  dialog_wallet_connect_title: `dialog_wallet_connect_title`,
  dialog_wallet_connect_request_connection: `dialog_wallet_connect_request_connection`,
  dialog_wallet_connect_request_connection_confirm: `dialog_wallet_connect_request_connection_confirm`,
  dialog_wallet_connect_request_connection_retry: `dialog_wallet_connect_request_connection_retry`,
  dialog_wallet_connect_connected_wallet: `dialog_wallet_connect_connected_wallet`,
  dialog_wallet_connect_gas_fee_wallet: `dialog_wallet_connect_gas_fee_wallet`,
  dialog_wallet_connect_telegram_bot_connect: `dialog_wallet_connect_telegram_bot_connect`,
  dialog_wallet_connect_telegram_bot_subscribe: `dialog_wallet_connect_telegram_bot_subscribe`,
  dialog_wallet_connect_balance_native_asset: `dialog_wallet_connect_balance_native_asset`,
  dialog_wallet_connect_deposit: `dialog_wallet_connect_deposit`,
  dialog_wallet_connect_withdraw: `dialog_wallet_connect_withdraw`,
  dialog_wallet_connect_tokens: `dialog_wallet_connect_tokens`,
  dialog_wallet_connect_no_tokens: `dialog_wallet_connect_no_tokens`,
  dialog_wallet_connect_gas_wallet_num_tools_ongoing: `dialog_wallet_connect_gas_wallet_num_tools_ongoing`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_1: `dialog_wallet_connect_gas_wallet_gas_insuficent_1`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_2: `dialog_wallet_connect_gas_wallet_gas_insuficent_2`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_3: `dialog_wallet_connect_gas_wallet_gas_insuficent_3`,
  dialog_wallet_connect_gas_wallet_num_tools_stop_rebalacing: `dialog_wallet_connect_gas_wallet_num_tools_stop_rebalacing`,
  dialog_wallet_connect_gas_wallet_gas_low_1: `dialog_wallet_connect_gas_wallet_gas_low_1`,
  dialog_wallet_connect_gas_wallet_gas_low_2: `dialog_wallet_connect_gas_wallet_gas_low_2`,
  dialog_wallet_connect_gas_wallet_gas_low_3: `dialog_wallet_connect_gas_wallet_gas_low_3`,
  dialog_wallet_connect_gas_wallet_num_tools_will_stop_rebalacing: `dialog_wallet_connect_gas_wallet_num_tools_will_stop_rebalacing`,
  dialog_wallet_connect_gas_wallet_create_btn: `dialog_wallet_connect_gas_wallet_create_btn`,
  dialog_wallet_connect_gas_wallet_create_desc: `dialog_wallet_connect_gas_wallet_create_desc`,
  dialog_strategy_enable_dialog_coming_soon: `dialog_strategy_enable_dialog_coming_soon`,
  dialog_strategy_enable_dialog_enable_tool_button: `dialog_strategy_enable_dialog_enable_tool_button`,
  dialog_strategy_enable_dialog_lending_currency: `dialog_strategy_enable_dialog_lending_currency`,
  dialog_strategy_enable_dialog_tvl: `dialog_strategy_enable_dialog_tvl`,
  dialog_strategy_enable_dialog_warning_minimum_investment_amount: `dialog_strategy_enable_dialog_warning_minimum_investment_amount`,
  dialog_strategy_enable_dialog_warning_minimum_investment_amount_amount: `dialog_strategy_enable_dialog_warning_minimum_investment_amount_amount`,
  dialog_strategy_enable_dialog_warning_title: `dialog_strategy_enable_dialog_warning_title`,
  dialog_strategy_enable_dialog_warning_content_1: `dialog_strategy_enable_dialog_warning_content_1`,
  dialog_strategy_enable_dialog_warning_content_2: `dialog_strategy_enable_dialog_warning_content_2`,
  dialog_strategy_close_dialog_view_status_button: `dialog_strategy_close_dialog_view_status_button`,
  dialog_strategy_close_dialog_view_other_tools_button: `dialog_strategy_close_dialog_view_other_tools_button`,
  dialog_strategy_close_dialog_snackbar_close_success_msg: `dialog_strategy_close_dialog_snackbar_close_success_msg`,
  dialog_strategy_close_dialog_close_tool_button: `dialog_strategy_close_dialog_close_tool_button`,
  dialog_strategy_close_dialog_close_tool_title: `dialog_strategy_close_dialog_close_tool_title`,
  dialog_strategy_close_dialog_total_principal: `dialog_strategy_close_dialog_total_principal`,
  dialog_strategy_close_dialog_total_value: `dialog_strategy_close_dialog_total_value`,
  dialog_strategy_close_dialog_run_time: `dialog_strategy_close_dialog_run_time`,
  dialog_strategy_close_dialog_back_button: `dialog_strategy_close_dialog_back_button`,
  dialog_strategy_edit_dialog_title: `dialog_strategy_edit_dialog_title`,
  dialog_strategy_edit_dialog_run_time: `dialog_strategy_edit_dialog_run_time`,
  dialog_strategy_edit_dialog_my_asset: `dialog_strategy_edit_dialog_my_asset`,
  dialog_strategy_edit_dialog_increase_funds: `dialog_strategy_edit_dialog_increase_funds`,
  dialog_strategy_edit_dialog_current_principal: `dialog_strategy_edit_dialog_current_principal`,
  dialog_strategy_edit_dialog_current_apy: `dialog_strategy_edit_dialog_current_apy`,
  dialog_strategy_edit_dialog_estimated_monthly_earning: `dialog_strategy_edit_dialog_estimated_monthly_earning`,
  dialog_strategy_edit_dialog_max: `dialog_strategy_edit_dialog_max`,
  dialog_strategy_edit_dialog_deposit_warning: `dialog_strategy_edit_dialog_deposit_warning`,
  dialog_strategy_edit_dialog_deposit: `dialog_strategy_edit_dialog_deposit`,
  dialog_strategy_edit_dialog_advanced_options: `dialog_strategy_edit_dialog_advanced_options`,
  dialog_strategy_edit_dialog_cancel: `dialog_strategy_edit_dialog_cancel`,
  dialog_strategy_edit_dialog_loading: `dialog_strategy_edit_dialog_loading`,
  dialog_strategy_edit_dialog_fund_retention: `dialog_strategy_edit_dialog_fund_retention`,
  dialog_strategy_edit_dialog_rate_mode: `dialog_strategy_edit_dialog_rate_mode`,
  dialog_strategy_edit_dialog_lending_period: `dialog_strategy_edit_dialog_lending_period`,
  dialog_strategy_edit_dialog_rate_mode_min: `dialog_strategy_edit_dialog_rate_mode_min`,
  dialog_strategy_edit_dialog_days: `dialog_strategy_edit_dialog_days`,
  dialog_strategy_edit_dialog_lending_period_min: `dialog_strategy_edit_dialog_lending_period_min`,
  dialog_strategy_edit_dialog_lending_period_max: `dialog_strategy_edit_dialog_lending_period_max`,
  dialog_strategy_edit_dialog_my_wallet_asset: `dialog_strategy_edit_dialog_my_wallet_asset`,
  dialog_strategy_increase_funds: `dialog_strategy_increase_funds`,
  dialog_strategy_increase_funds_in_tool: `dialog_strategy_increase_funds_in_tool`,
  dialog_strategy_increase_funds_current_principal: `dialog_strategy_increase_funds_current_principal`,
  dialog_strategy_increase_funds_increase_amount: `dialog_strategy_increase_funds_increase_amount`,
  dialog_strategy_increase_funds_expected_total_principal: `dialog_strategy_increase_funds_expected_total_principal`,
  dialog_strategy_increase_funds_increase: `dialog_strategy_increase_funds_increase`,
  dialog_strategy_increase_funds_back: `dialog_strategy_increase_funds_back`,
  dialog_strategy_information_view_tool_information: `dialog_strategy_information_view_tool_information`,
  dialog_strategy_information_view_tool_records: `dialog_strategy_information_view_tool_records`,
  dialog_strategy_information_view_tool_start_time: `dialog_strategy_information_view_tool_start_time`,
  dialog_strategy_information_view_tool_close_time: `dialog_strategy_information_view_tool_close_time`,
  dialog_strategy_information_view_tool_run_time: `dialog_strategy_information_view_tool_run_time`,
  dialog_strategy_information_view_tool_profit_currency: `dialog_strategy_information_view_tool_profit_currency`,
  dialog_strategy_information_view_tool_profit_rate: `dialog_strategy_information_view_tool_profit_rate`,
  dialog_strategy_information_view_tool_loading: `dialog_strategy_information_view_tool_loading`,
  dialog_strategy_information_view_tool_fully_loaded: `dialog_strategy_information_view_tool_fully_loaded`,
  dialog_strategy_information_view_tool_back: `dialog_strategy_information_view_tool_back`,
  dialog_strategy_information_view_tool_principal: `dialog_strategy_information_view_tool_principal`,
  dialog_strategy_information_view_tool_current_value: `dialog_strategy_information_view_tool_current_value`,
  dialog_deposit_deposit_by: `dialog_deposit_deposit_by`,
  dialog_deposit_via_blockchain_deposit: `dialog_deposit_via_blockchain_deposit`,
  dialog_deposit_via_blockchain_current_network: `dialog_deposit_via_blockchain_current_network`,
  dialog_deposit_via_blockchain_address: `dialog_deposit_via_blockchain_address`,
  dialog_deposit_via_blockchain_snackbar_copied: `dialog_deposit_via_blockchain_snackbar_copied`,
  dialog_deposit_cross_chain_cross_chain_transfer: `dialog_deposit_cross_chain_cross_chain_transfer`,
  dialog_deposit_cross_chain_cross_chain_transfer_description: `dialog_deposit_cross_chain_cross_chain_transfer_description`,
  dialog_deposit_cross_chain_cross_chain_transfer_cbridge: `dialog_deposit_cross_chain_cross_chain_transfer_cbridge`,
  dialog_deposit_by_credit_card_buy_crypto: `dialog_deposit_by_credit_card_buy_crypto`,
  dialog_deposit_by_credit_card_buy_crypto_next: `dialog_deposit_by_credit_card_buy_crypto_next`,
  dialog_buy_crypto_dialog_select_currency_next: `dialog_buy_crypto_dialog_select_currency_next`,
  dialog_buy_crypto_dialog_select_currency_buy: `dialog_buy_crypto_dialog_select_currency_buy`,
  dialog_buy_crypto_dialog_select_currency_buy_crypto: `dialog_buy_crypto_dialog_select_currency_buy_crypto`,
  dialog_reyield_gpt_title: `dialog_reyield_gpt_title`,
  dialog_reyield_gpt_description: `dialog_reyield_gpt_description`,
  dialog_reyield_gpt_button_start: `dialog_reyield_gpt_button_start`,
  dialog_view_portfolios_dialog_title: `dialog_view_portfolios_dialog_title`,
  dialog_view_portfolios_dialog_title_reyield_gpt: `dialog_view_portfolios_dialog_title_reyield_gpt`,
  dialog_view_portfolios_dialog_description_reyield_gpt: `dialog_view_portfolios_dialog_description_reyield_gpt`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_portfolios: `dialog_view_portfolios_dialog_reyield_gpt_investment_portfolios`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_my_expected_return: `dialog_view_portfolios_dialog_reyield_gpt_investment_my_expected_return`,
  dialog_view_portfolios_dialog_reyield_allocation_of_investment_portfolios: `dialog_view_portfolios_dialog_reyield_allocation_of_investment_portfolios`,
  dialog_view_portfolios_dialog_reyield_gpt_comprehensive_apy: `dialog_view_portfolios_dialog_reyield_gpt_comprehensive_apy`,
  dialog_view_portfolios_dialog_reyield_gpt_expected_achieve_time: `dialog_view_portfolios_dialog_reyield_gpt_expected_achieve_time`,
  dialog_view_portfolios_dialog_reyield_gpt_risk_tolerance_level: `dialog_view_portfolios_dialog_reyield_gpt_risk_tolerance_level`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_tool_portfolios: `dialog_view_portfolios_dialog_reyield_gpt_investment_tool_portfolios`,
  dialog_view_portfolios_dialog_warning_title: `dialog_view_portfolios_dialog_warning_title`,
  dialog_view_portfolios_dialog_warning_content_1: `dialog_view_portfolios_dialog_warning_content_1`,
  dialog_view_portfolios_dialog_warning_content_2: `dialog_view_portfolios_dialog_warning_content_2`,
  dialog_view_portfolios_dialog_button_enable_portfolio: `dialog_view_portfolios_dialog_button_enable_portfolio`,
  dialog_api_key_binding_tutorial_title: `dialog_api_key_binding_tutorial_title`,
  dialog_api_key_binding_tutorial_i_have_bitfinex_account: `dialog_api_key_binding_tutorial_i_have_bitfinex_account`,
  dialog_api_key_binding_tutorial_i_dont_have_bitfinex_account: `dialog_api_key_binding_tutorial_i_dont_have_bitfinex_account`,
  dialog_create_tutorial_dialog_back: `dialog_create_tutorial_dialog_back`,
  dialog_create_tutorial_dialog_title: `dialog_create_tutorial_dialog_title`,
  dialog_create_tutorial_dialog_desc: `dialog_create_tutorial_dialog_desc`,
  dialog_create_tutorial_dialog_go_to_bitfinex: `dialog_create_tutorial_dialog_go_to_bitfinex`,
  dialog_create_tutorial_dialog_next: `dialog_create_tutorial_dialog_next`,
  dialog_create_tutorial_dialog_next_desc: `dialog_create_tutorial_dialog_next_desc`,
  dialog_api_key_register_tutorial_title: `dialog_api_key_register_tutorial_title`,
  dialog_api_key_register_tutorial_step_1: `dialog_api_key_register_tutorial_step_1`,
  dialog_api_key_register_tutorial_step_2: `dialog_api_key_register_tutorial_step_2`,
  dialog_api_key_register_tutorial_step_3: `dialog_api_key_register_tutorial_step_3`,
  dialog_api_key_register_tutorial_desc: `dialog_api_key_register_tutorial_desc`,
  dialog_api_key_register_tutorial_go_to_bitfinex: `dialog_api_key_register_tutorial_go_to_bitfinex`,
  dialog_api_key_setting_title: `dialog_api_key_setting_title`,
  dialog_api_key_setting_subtitle: `dialog_api_key_setting_subtitle`,
  dialog_tool_enable_connect_wallet_notice: `dialog_tool_enable_connect_wallet_notice`,
  dialog_tool_enable_title: `dialog_tool_enable_title`,
  dialog_tool_enable_apy_history: `dialog_tool_enable_apy_history`,
  dialog_tool_enable_coming_soon: `dialog_tool_enable_coming_soon`,
  dialog_tool_enable_current_apy: `dialog_tool_enable_current_apy`,
  dialog_tool_enable_current_apy_description: `dialog_tool_enable_current_apy_description`,
  dialog_tool_enable_7d_apy: `dialog_tool_enable_7d_apy`,
  dialog_tool_enable_7d_apy_description: `dialog_tool_enable_7d_apy_description`,
  dialog_tool_enable_24h_apy: `dialog_tool_enable_24h_apy`,
  dialog_tool_enable_24h_apy_description: `dialog_tool_enable_24h_apy_description`,
  dialog_tool_enable_tips_formula_details: `dialog_tool_enable_tips_formula_details`,
  dialog_tool_enable_title_creator: `dialog_tool_enable_title_creator`,
  dialog_tool_enable_title_running: `dialog_tool_enable_title_running`,
  dialog_tool_enable_title_closed: `dialog_tool_enable_title_closed`,
  dialog_tool_enable_title_duration: `dialog_tool_enable_title_duration`,
  dialog_tool_enable_title_view_more: `dialog_tool_enable_title_view_more`,
  dialog_tool_enable_input_amount_deposit: `dialog_tool_enable_input_amount_deposit`,
  dialog_tool_enable_input_amount_single_token: `dialog_tool_enable_input_amount_single_token`,
  dialog_tool_enable_input_amount_dual_token: `dialog_tool_enable_input_amount_dual_token`,
  dialog_tool_enable_input_amount_token_balance: `dialog_tool_enable_input_amount_token_balance`,
  dialog_tool_enable_input_amount_enable_tool: `dialog_tool_enable_input_amount_enable_tool`,
  dialog_tool_enable_input_amount_increase_funds: `dialog_tool_enable_input_amount_increase_funds`,
  dialog_tool_enable_input_amount_price_impact_warning: `dialog_tool_enable_input_amount_price_impact_warning`,
  dialog_tool_enable_input_amount_max_btn: `dialog_tool_enable_input_amount_max_btn`,
  dialog_tool_enable_validation_required: `dialog_tool_enable_validation_required`,
  dialog_tool_enable_validation_token_greater_than_0: `dialog_tool_enable_validation_token_greater_than_0`,
  dialog_tool_enable_validation_token_allows_decimals: `dialog_tool_enable_validation_token_allows_decimals`,
  dialog_tool_enable_validation_token_apprive_required: `dialog_tool_enable_validation_token_apprive_required`,
  dialog_tool_enable_validation_something_wrong: `dialog_tool_enable_validation_something_wrong`,
  dialog_tool_enable_validation_enable_tool_successfully: `dialog_tool_enable_validation_enable_tool_successfully`,
  dialog_tool_enable_validation_increase_funds_successfully: `dialog_tool_enable_validation_increase_funds_successfully`,
  dialog_tool_enable_validation_deposit_token_notice: `dialog_tool_enable_validation_deposit_token_notice`,
  dialog_tool_enable_validation_deposit_token0_notice: `dialog_tool_enable_validation_deposit_token0_notice`,
  dialog_tool_enable_validation_deposit_and_token_notice: `dialog_tool_enable_validation_deposit_and_token_notice`,
  dialog_tool_enable_validation_deposit_token1_notice: `dialog_tool_enable_validation_deposit_token1_notice`,
  dialog_tool_view_more_title: `dialog_tool_view_more_title`,
  dialog_tool_view_more_view_tool_intro: `dialog_tool_view_more_view_tool_intro`,
  dialog_tool_view_more_view_tool_creator: `dialog_tool_view_more_view_tool_creator`,
  dialog_tool_view_more_tool_performance_fee: `dialog_tool_view_more_tool_performance_fee`,
  dialog_tool_view_more_tool_performance_fee_title: `dialog_tool_view_more_tool_performance_fee_title`,
  dialog_tool_view_more_tool_performance_fee_desc: `dialog_tool_view_more_tool_performance_fee_desc`,
  dialog_tool_view_more_tool_performance_fee_formula_desc_1: `dialog_tool_view_more_tool_performance_fee_formula_desc_1`,
  dialog_tool_view_more_tool_performance_fee_formula_desc_2: `dialog_tool_view_more_tool_performance_fee_formula_desc_2`,
  dialog_tool_view_more_tool_performance_fee_formula_desc_3: `dialog_tool_view_more_tool_performance_fee_formula_desc_3`,
  dialog_tool_view_more_tool_performance_fee_formula_1: `dialog_tool_view_more_tool_performance_fee_formula_1`,
  dialog_tool_view_more_tool_performance_fee_formula_2: `dialog_tool_view_more_tool_performance_fee_formula_2`,
  dialog_tool_view_more_view_tool_parameters: `dialog_tool_view_more_view_tool_parameters`,
  dialog_tool_view_more_view_tool_parameters_title: `dialog_tool_view_more_view_tool_parameters_title`,
  dialog_tool_view_more_view_tool_parameters_platform: `dialog_tool_view_more_view_tool_parameters_platform`,
  dialog_tool_view_more_view_tool_parameters_blockchain: `dialog_tool_view_more_view_tool_parameters_blockchain`,
  dialog_tool_view_more_view_tool_parameters_pair: `dialog_tool_view_more_view_tool_parameters_pair`,
  dialog_tool_view_more_view_tool_parameters_fee_tier: `dialog_tool_view_more_view_tool_parameters_fee_tier`,
  dialog_tool_view_more_view_tool_parameters_price_range: `dialog_tool_view_more_view_tool_parameters_price_range`,
  dialog_tool_view_more_view_tool_parameters_pool_addr: `dialog_tool_view_more_view_tool_parameters_pool_addr`,
  dialog_tool_view_more_view_rebalance_records: `dialog_tool_view_more_view_rebalance_records`,
  dialog_tool_view_more_range_predictor_ai: `dialog_tool_view_more_range_predictor_ai`,
  dialog_tool_view_more_coming_soon: `dialog_tool_view_more_coming_soon`,
  dialog_tool_view_more_tool_creator_title: `dialog_tool_view_more_tool_creator_title`,
  dialog_tool_view_more_tool_creator_other_tools: `dialog_tool_view_more_tool_creator_other_tools`,
  dialog_tool_view_more_tool_creator_no_other_tools: `dialog_tool_view_more_tool_creator_no_other_tools`,
  dialog_tool_view_more_tool_creator_tool_chain: `dialog_tool_view_more_tool_creator_tool_chain`,
  dialog_tool_view_more_tool_creator_tool_token: `dialog_tool_view_more_tool_creator_tool_token`,
  dialog_tool_view_more_tool_creator_tool_apy: `dialog_tool_view_more_tool_creator_tool_apy`,
  dialog_tool_view_more_tool_creator_tool_tvl: `dialog_tool_view_more_tool_creator_tool_tvl`,
  dialog_tool_view_more_tool_intro_title: `dialog_tool_view_more_tool_intro_title`,
  dialog_tool_view_more_tool_intro_creator: `dialog_tool_view_more_tool_intro_creator`,
  dialog_tool_ongoing_title: `dialog_tool_ongoing_title`,
  dialog_tool_ongoing_unrealized_pnl: `dialog_tool_ongoing_unrealized_pnl`,
  dialog_tool_ongoing_unrealized_pnl_desc: `dialog_tool_ongoing_unrealized_pnl_desc`,
  dialog_tool_ongoing_unrealized_pnl_income: `dialog_tool_ongoing_unrealized_pnl_income`,
  dialog_tool_ongoing_unrealized_pnl_token_price_fluctuations: `dialog_tool_ongoing_unrealized_pnl_token_price_fluctuations`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_for_creator: `dialog_tool_ongoing_unrealized_pnl_performance_fee_for_creator`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_of: `dialog_tool_ongoing_unrealized_pnl_performance_fee_of`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_net_profit: `dialog_tool_ongoing_unrealized_pnl_performance_fee_net_profit`,
  dialog_tool_ongoing_unrealized_apy: `dialog_tool_ongoing_unrealized_apy`,
  dialog_tool_ongoing_unrealized_apy_desc: `dialog_tool_ongoing_unrealized_apy_desc`,
  dialog_tool_ongoing_deposit_balance: `dialog_tool_ongoing_deposit_balance`,
  dialog_tool_ongoing_operate: `dialog_tool_ongoing_operate`,
  dialog_tool_ongoing_operate_increase_funds: `dialog_tool_ongoing_operate_increase_funds`,
  dialog_tool_ongoing_operate_view_tool_performance_fee: `dialog_tool_ongoing_operate_view_tool_performance_fee`,
  dialog_tool_ongoing_operate_tool_parameters: `dialog_tool_ongoing_operate_tool_parameters`,
  dialog_tool_ongoing_operate_close_tool: `dialog_tool_ongoing_operate_close_tool`,
  dialog_tool_ongoing_increase_funds: `dialog_tool_ongoing_increase_funds`,
  dialog_tool_ongoing_clost_tool_desc: `dialog_tool_ongoing_clost_tool_desc`,
  dialog_tool_ongoing_clost_tool_receive: `dialog_tool_ongoing_clost_tool_receive`,
  dialog_tool_ongoing_clost_tool_btn: `dialog_tool_ongoing_clost_tool_btn`,
  dialog_tool_finished_title: `dialog_tool_finished_title`,
  dialog_formula_details_title: `dialog_formula_details_title`,
  dialog_formula_details_current_apy_formula_title: `dialog_formula_details_current_apy_formula_title`,
  dialog_formula_details_current_apy_formula: `dialog_formula_details_current_apy_formula`,
  dialog_formula_details_7d_apy_formula_title: `dialog_formula_details_7d_apy_formula_title`,
  dialog_formula_details_7d_apy_formula: `dialog_formula_details_7d_apy_formula`,
  dialog_formula_details_7d_apy_formula_2: `dialog_formula_details_7d_apy_formula_2`,
  dialog_formula_details_24h_apy_formula_title: `dialog_formula_details_24h_apy_formula_title`,
  dialog_formula_details_24h_apy_formula: `dialog_formula_details_24h_apy_formula`,
  dialog_formula_details_24h_apy_formula_2: `dialog_formula_details_24h_apy_formula_2`,
  dialog_with_background_back: `dialog_with_background_back`,
  about_reyield_rebalance_title: `about_reyield_rebalance_title`,
  about_reyield_rebalance_description: `about_reyield_rebalance_description`,
  explore_card_coming_soon: `explore_card_coming_soon`,
  explore_card_apy: `explore_card_apy`,
  explore_card_pool_pair: `explore_card_pool_pair`,
  explore_card_risk: `explore_card_risk`,
  explore_card_total_users: `explore_card_total_users`,
  explore_card_tvl: `explore_card_tvl`,
  explore_card_pnl: `explore_card_pnl`,
  explore_card_balance: `explore_card_balance`,
  ai_completion_record_retry: `ai_completion_record_retry`,
  page_app_reyield_gpt_chat_history_empty: `page_app_reyield_gpt_chat_history_empty`,
  page_app_reyield_gpt_chat_history_view_detail_and_enable_previous_portfolio: `page_app_reyield_gpt_chat_history_view_detail_and_enable_previous_portfolio`,
  investment_style_warren_buffett: `investment_style_warren_buffett`,
  investment_style_ray_dalio: `investment_style_ray_dalio`,
  investment_style_peter_lynch: `investment_style_peter_lynch`,
  investment_style_george_soros: `investment_style_george_soros`,
  investment_style_cathie_wood: `investment_style_cathie_wood`,
  investment_style_michael_burry: `investment_style_michael_burry`,
  investment_goal_of_buying_a_car: `investment_goal_of_buying_a_car`,
  investment_goal_of_buying_a_house: `investment_goal_of_buying_a_house`,
  investment_goal_of_buying_education_fund: `investment_goal_of_buying_education_fund`,
  investment_goal_of_buying_a_wedding_fund: `investment_goal_of_buying_a_wedding_fund`,
  investment_goal_of_health_insurance: `investment_goal_of_health_insurance`,
  investment_goal_of_travel_fund: `investment_goal_of_travel_fund`,
  investment_goal_of_startup_capital: `investment_goal_of_startup_capital`,
  investment_goal_of_1000_usd: `investment_goal_of_1000_usd`,
  investment_goal_of_5000_usd: `investment_goal_of_5000_usd`,
  investment_goal_of_10000_usd: `investment_goal_of_10000_usd`,
  investment_goal_of_50000_usd: `investment_goal_of_50000_usd`,
  investment_goal_of_100000_usd: `investment_goal_of_100000_usd`,
  investment_goal_of_300000_usd: `investment_goal_of_300000_usd`,
  investment_goal_of_500000_usd: `investment_goal_of_500000_usd`,
  investment_goal_of_1000000_usd: `investment_goal_of_1000000_usd`,
  investment_period_of_1_month: `investment_period_of_1_month`,
  investment_period_of_3_months: `investment_period_of_3_months`,
  investment_period_of_6_months: `investment_period_of_6_months`,
  investment_period_of_1_year: `investment_period_of_1_year`,
  investment_period_of_2_years: `investment_period_of_2_years`,
  investment_period_of_3_years: `investment_period_of_3_years`,
  investment_period_of_5_years: `investment_period_of_5_years`,
  investment_preference_of_demo_en: `investment_preference_of_demo_en`,
  investment_preference_of_demo_cn: `investment_preference_of_demo_cn`,
  investment_preference_of_demo_jp: `investment_preference_of_demo_jp`,
  investment_preference_of_demo_emoji: `investment_preference_of_demo_emoji`,
  investment_question_purpose: `investment_question_purpose`,
  investment_question_goal_amount: `investment_question_goal_amount`,
  investment_question_available_amount: `investment_question_available_amount`,
  investment_question_period: `investment_question_period`,
  investment_question_risk_tolerance: `investment_question_risk_tolerance`,
  investment_question_risk_tolerance_bottom_desc: `investment_question_risk_tolerance_bottom_desc`,
  investment_question_preference: `investment_question_preference`,
  conversation_already_received_answer_and_generate_portfolio: `conversation_already_received_answer_and_generate_portfolio`,
  reyield_gpt_suggested_answer_title: `reyield_gpt_suggested_answer_title`,
  reyield_gpt_suggested_answer_highest_apr: `reyield_gpt_suggested_answer_highest_apr`,
  reyield_gpt_suggested_answer_maximum_loss: `reyield_gpt_suggested_answer_maximum_loss`,
  reyield_gpt_tab_chat_history: `reyield_gpt_tab_chat_history`,
  reyield_gpt_tab_reyield_gpt: `reyield_gpt_tab_reyield_gpt`,
  reyield_gpt_tailoring_investment_plan_for_you: `reyield_gpt_tailoring_investment_plan_for_you`,
  reyield_gpt_view_detail_and_enable_portfolio: `reyield_gpt_view_detail_and_enable_portfolio`,
  reyield_gpt_view_detail_start_over: `reyield_gpt_view_detail_start_over`,
  reyield_gpt_is_responding: `reyield_gpt_is_responding`,
  reyield_gpt_input_the_answer_manually: `reyield_gpt_input_the_answer_manually`,
  nav_vertical_reyield_gpt: `nav_vertical_reyield_gpt`,
  nav_vertical_step_1: `nav_vertical_step_1`,
  nav_vertical_step_2: `nav_vertical_step_2`,
  nav_vertical_step_3: `nav_vertical_step_3`,
  nav_header_please_login_first: `nav_header_please_login_first`,
  reyield_gpt_layout_nav_header_cta_mobile: `reyield_gpt_layout_nav_header_cta_mobile`,
  reyield_gpt_layout_nav_header_cta_desktop: `reyield_gpt_layout_nav_header_cta_desktop`,
  nav_header_usage_count_today_mobile: `nav_header_usage_count_today_mobile`,
  nav_header_usage_count_today_desktop: `nav_header_usage_count_today_desktop`,
}

export default key
