const en = {
  app: `app`,
  app_header_connect: `Connect`,
  app_view_white_paper: `View Whitepaper`,
  risk_all_tools: `All`,
  risk_low_risk_tools: `Low Risk`,
  risk_medium_low_risk_tools: `Medium Low Risk`,
  risk_medium_risk_tools: `Medium Risk`,
  risk_medium_high_risk_tools: `Medium High Risk`,
  risk_high_risk_tools: `High Risk`,
  page_app_investment_tool_header: `Investment`,
  page_app_investment_tool_enable_all_message: `Congratulations! All REYIELD's Tool Already Enabled`,
  page_app_explorer_section_title: `REYIELD simplifies pro investment`,
  page_app_explorer_section_sub_title: `Pump & Dump isn't the only way to profit in crypto. REYIELD helps you to invest like a pro with few clicks`,
  page_app_reyield_gpt_title: `Let's make profits with AI now !`,
  page_app_reyield_gpt_subtitle: `REYIELDGPT makes investment plans that fit you perfectly. Let's achieve your financial goal with the power of AI`,
  page_app_reyield_gpt_counter_desc: `use counts opened portfolios and earned profits through REYILEDGPT.`,
  page_app_reyield_gpt_banner_title_desktop: `Loss a lot of money before? Let's make a profit with AI this time`,
  page_app_reyield_gpt_banner_title_mobile: `Let's make a profit with AI`,
  page_app_reyield_gpt_banner_sub_title: `REYIELDGPT makes investment plans that fit you perfectly. Let's achieve your financial goal with the power of AI`,
  page_app_reyield_gpt_banner_button: `Explore Now`,
  page_app_my_portfolio_section_title: `My Portfolio`,
  page_app_my_portfolio_section_sub_title: `Discover my journey through diverse investment tools and accomplishments.`,
  page_app_my_portfolio_total_profilt_chart_title: `Total Profits`,
  page_app_my_portfolio_header: `My Tools`,
  page_app_my_portfolio_empty_data: `No {{tab_value}} Strategy`,
  page_app_my_portfolio_tabs_all_tools: `All`,
  page_app_my_portfolio_tabs_defi_tools: `DeFi Tools`,
  page_app_my_portfolio_tabs_cefi_tools: `CeFi Tools`,
  page_app_my_portfolio_tabs_bitfinex_tools: `Bitfinex Tools`,
  page_app_my_portfolio_ongoing: `Ongoing`,
  page_app_my_portfolio_finished: `Finished`,
  page_app_my_portfolio_search: `Search`,
  page_app_my_portfolio_sortby: `Sort By`,
  page_app_my_portfolio_sortby_newest: `Newest`,
  page_app_my_portfolio_sortby_highest_apy: `Highest APY`,
  page_app_my_portfolio_sortby_highest_roi: `Highest ROI`,
  page_app_rewards_title: `Rewards`,
  page_app_rewards_subtitle: `Stake {{stack_token_num}} REYLD to get {{rewards}} extra rewards and use the tools for free!`,
  page_app_rewards_yield_token: `YIELD Token`,
  page_app_rewards_coming_soon: `{{token_name}} coming soon`,
  page_app_rewards_stake_reyld: `Stake REYLD`,
  page_app_rewards_stake_reyld_desc: `The CeFi tools could cost you a fortune every year. Now on REYIELD, once you staked 2,000 REYLD you can use all the tools for free and enjoy 25% extra rewards`,
  page_app_rewards_cashout: `Cashout {{token_name}}`,
  page_app_rewards_cashout_desc: `Got bills to pay? Cashout your REYLD is much more easier than you think`,
  page_app_rewards_deposit: `Deposit {{token_name}}`,
  page_app_rewards_deposit_desc: `Conveniently deposit crypto from another account to your Reyield account.`,
  page_app_stake_title: `Stake`,
  page_app_stake_subtitle: `to get the privileges`,
  page_app_stake_get_privilege_1: `1. Use all tools for free`,
  page_app_stake_get_privilege_2: `2. Have AI as your investment assistant`,
  page_app_stake_get_privilege_3: `3. Earn 25% extra REYLD rewards`,
  page_app_stake_privilege_activation: `Privilege Activating`,
  page_app_stake_privilege_not_activation: `Privilege Not Activating`,
  page_app_stake_unstake_btn: `Unstake`,
  page_app_stake_stake_btn: `Stake Now`,
  page_app_stake_balance: `Balance`,
  page_app_stake_coming_soon: `Coming soon`,
  page_app_stake_staking_privilege: `Staking Privilege`,
  page_app_vest_title: `VEST`,
  page_app_vest_subtitle: `Lock your REYLD to generate votes to determine how REYLD rewards allocate, and earn extra profits from bribes`,
  page_app_vest_btn: `Vest`,
  page_app_vest_vesting: `Vesting`,
  page_app_vest_finished: `Finished`,
  page_app_vest_search: `Search`,
  page_app_vest_vest_list: `Vest List`,
  page_app_vest_empty_data: `No Data`,
  page_app_vest_start_date: `Start Date`,
  page_app_vest_end_date: `End Date`,
  page_app_vest_list_header_title_vest: `Vest`,
  page_app_vest_list_header_title_vest_amount: `Vest Amount(YIELD)`,
  page_app_vest_list_header_title_vote_value: `Vote Value(veYIELD)`,
  page_app_vest_list_header_title_vest_expire: `Vest Expires`,
  page_app_vest_list_header_title_manage: `Manage`,
  page_app_vest_list_finished_header_title_vest: `Vest`,
  page_app_vest_list_finished_header_title_vest_amount: `Vest Amount(YIELD)`,
  page_app_vest_list_finished_header_title_vote_value: `Vote value(veYIELD)`,
  page_app_vest_list_finished_header_title_unlock_time: `Unlock time`,
  page_app_vest_list_finished_header_title_continue_vest: `Continue Vest`,
  page_app_vote_title: `Vote`,
  page_app_vote_subtitle: `You can decide how REYLD rewards allocate by voting and enjoy extra profits from bribes`,
  page_app_vote_balance: `Balance`,
  page_app_vote_my_votes: `My Votes`,
  page_app_vote_voting_power_used: `Voting power used`,
  page_app_vote_coming_soon: `Coming Soon`,
  page_app_vote_description: `For the upcoming epoch, each veREYLD will have the opportunity to cast a vote once. Your vote will utilize 100% of your veREYLD's voting power. Any votes that remain unchanged will carry over into the next epoch. As a voter, you will earn all the bribes that are deposited during the epoch. If you have increased your veREYLD's balance during the epoch, it is recommended to recast your vote in order to fully allocate your vote power.`,
  page_app_vote_description_2: `For details refer to our`,
  page_app_vote_description_doc: `doc`,
  page_app_vote_list_empty_data: `No Data`,
  page_app_vote_list_header_title_tool: `Tool`,
  page_app_vote_list_header_title_total_votes: `Total Votes`,
  page_app_vote_list_header_title_voting_apr: `Voting APR`,
  page_app_vote_list_header_title_bribes_fees: `Bribes + Fees`,
  page_app_vote_list_header_title_bribes: `Bribes`,
  page_app_vote_list_header_title_my_votes: `My votes`,
  page_app_vote_list_header_title_my_votes_percentage: `My votes %`,
  page_app_vote_list_filter: `Filter`,
  page_app_bribe_title: `Bribe`,
  page_app_bribe_subtitle: `Need more trading volume? Provide incentives to the voters and drive trading volume to you.`,
  page_app_bribe_btn: `Bribe`,
  page_app_setting_title: `Setting`,
  page_app_setting_subtitle: `Investment tools can be executed in your own exchange account. Please enter your API keys to enable it.`,
  page_app_setting_api_setting: `API Setting`,
  page_app_setting_api_setting_api: `{{exchange}} API`,
  page_app_setting_api_setting_name: `Name`,
  page_app_setting_api_setting_access_key: `Access Key`,
  page_app_setting_api_setting_secret_key: `Secret Key`,
  page_app_setting_api_setting_connect: `Connect`,
  page_app_setting_api_setting_disconnect: `Disconnect`,
  page_app_setting_api_setting_view_tutorial: `View tutorial`,
  nav_item_explore: `Explore`,
  nav_item_children_investment_tool: `Investment Tools`,
  nav_item_children_reyield_gpt: `REYIELDGPT`,
  nav_item_my_portfolio: `My Portfolio`,
  nav_item_reward: `Reward`,
  nav_item_stake_and_vest: `Stake & Vest`,
  nav_item_children_stake: `Stake`,
  nav_item_children_vest: `Vest`,
  nav_item_vote_and_bribe: `Vote & Bribe`,
  nav_item_setting: `Setting`,
  dialog_supporting_chain_support_network: `Supporting Network`,
  dialog_supporting_chain_switch_to: `Switch to`,
  dialog_welcome_reyield_title_the_future: `The Future of Cryptocurrency Investing`,
  dialog_welcome_reyield_title_a_investment_platform: `REYIELD - an AI Investment Platform`,
  dialog_welcome_reyield_title_a_investment_platform_desc: `REYIELD simplifies professional investment and provides investment tools, Whitelabel investment tools, and AI investment assistants to boost profits for investors, influencers, and exchanges.`,
  dialog_welcome_reyield_reyield_gpt: `REYIELD GPT`,
  dialog_welcome_reyield_reyield_gpt_features: `Effortless Portfolio Balancing. After knowing your investment preferences, an investment plan is provided by GPT-4. You can initiate their investment tool with a single click.`,
  dialog_welcome_reyield_start: `Start`,
  dialog_wallet_connect_header_title: `Connect wallet`,
  dialog_wallet_connect_title: `Start by connecting with one of the wallets below.`,
  dialog_wallet_connect_request_connection: `Requesting Connection`,
  dialog_wallet_connect_request_connection_confirm: `Confirm this connection in your wallet`,
  dialog_wallet_connect_request_connection_retry: `Retry`,
  dialog_wallet_connect_connected_wallet: `Connected Wallet`,
  dialog_wallet_connect_gas_fee_wallet: `Gas Fee Wallet`,
  dialog_wallet_connect_telegram_bot_connect: `Connect our Telegram bot for instant gas fee notifications.`,
  dialog_wallet_connect_telegram_bot_subscribe: `Subscribe`,
  dialog_wallet_connect_balance_native_asset: `Balance (Native Asset)`,
  dialog_wallet_connect_deposit: `Deposit`,
  dialog_wallet_connect_withdraw: `Withdraw`,
  dialog_wallet_connect_tokens: `Tokens`,
  dialog_wallet_connect_no_tokens: `No Supporting Tokens`,
  dialog_wallet_connect_gas_wallet_num_tools_ongoing: `Number of Ongoing Tools： {{number_of_tools}}`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_1: `The gas fee is`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_2: `insufficient`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_3: `, please deposit as soon as possible.`,
  dialog_wallet_connect_gas_wallet_num_tools_stop_rebalacing: `Number of tools stop rebalancing: {{number_of_tools}}`,
  dialog_wallet_connect_gas_wallet_gas_low_1: `The gas fee is`,
  dialog_wallet_connect_gas_wallet_gas_low_2: `running low`,
  dialog_wallet_connect_gas_wallet_gas_low_3: `, please deposit as soon as possible.`,
  dialog_wallet_connect_gas_wallet_num_tools_will_stop_rebalacing: `Number of tools will stop rebalancing: {{number_of_tools}}`,
  dialog_wallet_connect_gas_wallet_create_btn: `Create a Gas Fee Wallet`,
  dialog_wallet_connect_gas_wallet_create_desc: `Using the Gas Fee Wallet to pay for gas fees can save up to 30%.`,
  dialog_strategy_enable_dialog_coming_soon: `Coming Soon`,
  dialog_strategy_enable_dialog_enable_tool_button: `Enable Tool`,
  dialog_strategy_enable_dialog_lending_currency: `Lending Currency`,
  dialog_strategy_enable_dialog_tvl: `TVL (Total value locked in pool)`,
  dialog_strategy_enable_dialog_warning_minimum_investment_amount: `Minimum Investment Amount`,
  dialog_strategy_enable_dialog_warning_minimum_investment_amount_amount: `Enable Tool Investment Amount: Minimum {{amount}} USDT`,
  dialog_strategy_enable_dialog_warning_title: `Warning`,
  dialog_strategy_enable_dialog_warning_content_1: `1. The actual loan amount will include the original USDT assets in the Bitfinex financing wallet, and you need to transfer assets to Bitfinex by yourself.`,
  dialog_strategy_enable_dialog_warning_content_2: `2. The actual loan amount will include the original USDT assets in the Bitfinex financing wallet, and you need to transfer assets to Bitfinex by yourself.`,
  dialog_strategy_close_dialog_view_status_button: `View The Status`,
  dialog_strategy_close_dialog_view_other_tools_button: `Continue to View Other Tools`,
  dialog_strategy_close_dialog_snackbar_close_success_msg: `Strategy closed successfully`,
  dialog_strategy_close_dialog_close_tool_button: `Close Tool`,
  dialog_strategy_close_dialog_close_tool_title: `Are you sure you want to close the tool {{tool_name}}?`,
  dialog_strategy_close_dialog_total_principal: `Total Principal`,
  dialog_strategy_close_dialog_total_value: `Total Value`,
  dialog_strategy_close_dialog_run_time: `Run Time`,
  dialog_strategy_close_dialog_back_button: `Back`,
  dialog_strategy_edit_dialog_title: `Edit Tool`,
  dialog_strategy_edit_dialog_run_time: `Run Time`,
  dialog_strategy_edit_dialog_my_asset: `My Asset`,
  dialog_strategy_edit_dialog_increase_funds: `Increase Funds`,
  dialog_strategy_edit_dialog_current_principal: `Current Principal`,
  dialog_strategy_edit_dialog_current_apy: `Current APY`,
  dialog_strategy_edit_dialog_estimated_monthly_earning: `Estimated Monthly Earning`,
  dialog_strategy_edit_dialog_max: `Max`,
  dialog_strategy_edit_dialog_deposit_warning: `Please deposit {{amount}} {{coin_symbol}} to connecting wallet to start the tool.`,
  dialog_strategy_edit_dialog_deposit: `Deposit`,
  dialog_strategy_edit_dialog_advanced_options: `Advanced Options`,
  dialog_strategy_edit_dialog_cancel: `Cancel`,
  dialog_strategy_edit_dialog_loading: `Loading ...`,
  dialog_strategy_edit_dialog_fund_retention: `Fund Retention`,
  dialog_strategy_edit_dialog_rate_mode: `Rate Mode`,
  dialog_strategy_edit_dialog_lending_period: `Lending Period`,
  dialog_strategy_edit_dialog_rate_mode_min: `Min`,
  dialog_strategy_edit_dialog_days: `Days`,
  dialog_strategy_edit_dialog_lending_period_min: `Min`,
  dialog_strategy_edit_dialog_lending_period_max: `Max`,
  dialog_strategy_edit_dialog_my_wallet_asset: `My Wallet Asset`,
  dialog_strategy_increase_funds: `Increase Funds`,
  dialog_strategy_increase_funds_in_tool: `Increase Funds in {{tool}}`,
  dialog_strategy_increase_funds_current_principal: `Current Principal`,
  dialog_strategy_increase_funds_increase_amount: `+ Increase Funds`,
  dialog_strategy_increase_funds_expected_total_principal: `Expected total principal`,
  dialog_strategy_increase_funds_increase: `Increase Funds`,
  dialog_strategy_increase_funds_back: `Back`,
  dialog_strategy_information_view_tool_information: `View Tool Information`,
  dialog_strategy_information_view_tool_records: `View Tool Records`,
  dialog_strategy_information_view_tool_start_time: `Start Time`,
  dialog_strategy_information_view_tool_close_time: `Close Time`,
  dialog_strategy_information_view_tool_run_time: `Run Time`,
  dialog_strategy_information_view_tool_profit_currency: `Profit {{currency}}`,
  dialog_strategy_information_view_tool_profit_rate: `Single profit rate`,
  dialog_strategy_information_view_tool_loading: `Loading ...`,
  dialog_strategy_information_view_tool_fully_loaded: `All records loaded`,
  dialog_strategy_information_view_tool_back: `Back`,
  dialog_strategy_information_view_tool_principal: `Principal`,
  dialog_strategy_information_view_tool_current_value: `Current Value`,
  dialog_deposit_deposit_by: `Deposit by`,
  dialog_deposit_via_blockchain_deposit: `Deposit`,
  dialog_deposit_via_blockchain_current_network: `Current Network`,
  dialog_deposit_via_blockchain_address: `Address`,
  dialog_deposit_via_blockchain_snackbar_copied: `Copied`,
  dialog_deposit_cross_chain_cross_chain_transfer: `Cross-Chain Transfer`,
  dialog_deposit_cross_chain_cross_chain_transfer_description: `To the cross-chain bridge for asset cross-chain transfer. you can watch the tutorial above.`,
  dialog_deposit_cross_chain_cross_chain_transfer_cbridge: `cBRIDGE`,
  dialog_deposit_by_credit_card_buy_crypto: `Buy crypto`,
  dialog_deposit_by_credit_card_buy_crypto_next: `Next`,
  dialog_buy_crypto_dialog_select_currency_next: `Next`,
  dialog_buy_crypto_dialog_select_currency_buy: `Buy`,
  dialog_buy_crypto_dialog_select_currency_buy_crypto: `Buy Crypto`,
  dialog_reyield_gpt_title: `REYIELDGPT`,
  dialog_reyield_gpt_description: `Let us help you create a portfolio of investment tools that are more suitable for you through AI.`,
  dialog_reyield_gpt_button_start: `Start`,
  dialog_view_portfolios_dialog_title: `Portfolios`,
  dialog_view_portfolios_dialog_title_reyield_gpt: `REYIELDGPT`,
  dialog_view_portfolios_dialog_description_reyield_gpt: `REYIELDGPT utilizes the power of AI to create personalized investment plans tailored to your specific financial goals. Let's work together to achieve your objectives with intelligent and customized Investment Portfolios.`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_portfolios: `Investment Portfolios`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_my_expected_return: `My expected return`,
  dialog_view_portfolios_dialog_reyield_allocation_of_investment_portfolios: `Allocation of Investment Portfolios`,
  dialog_view_portfolios_dialog_reyield_gpt_comprehensive_apy: `Comprehensive APY`,
  dialog_view_portfolios_dialog_reyield_gpt_expected_achieve_time: `Expected achieve time`,
  dialog_view_portfolios_dialog_reyield_gpt_risk_tolerance_level: `Risk tolerance level`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_tool_portfolios: `Investment tool Portfolios`,
  dialog_view_portfolios_dialog_warning_title: `Warning`,
  dialog_view_portfolios_dialog_warning_content_1: `1. Cryptocurrency markets are highly volatile, and prices can fluctuate dramatically in a short period. This volatility can result in significant investment losses.`,
  dialog_view_portfolios_dialog_warning_content_2: `2. Investing involves risks. Past performance doesn't guarantee future results. Evaluate before investing.`,
  dialog_view_portfolios_dialog_button_enable_portfolio: `Enable the Portfolio (Coming Soon)`,
  dialog_api_key_binding_tutorial_title: `Do you have a Bitfinex Account ?`,
  dialog_api_key_binding_tutorial_i_have_bitfinex_account: `I have a Bitfinex account.`,
  dialog_api_key_binding_tutorial_i_dont_have_bitfinex_account: `I don't have a Bitfinex account.`,
  dialog_create_tutorial_dialog_back: `Back`,
  dialog_create_tutorial_dialog_title: `Create API Key from Bitfinex`,
  dialog_create_tutorial_dialog_desc: `The following operations will be performed on Bitfinex and copied the pasted generated API Key into REYIELD. Please be careful not to disclose the API Key.`,
  dialog_create_tutorial_dialog_go_to_bitfinex: `Go to Bitfinex`,
  dialog_create_tutorial_dialog_next: `Next`,
  dialog_create_tutorial_dialog_next_desc: `Set API key on REYIELD`,
  dialog_api_key_register_tutorial_title: `How to register a Bitfinex account`,
  dialog_api_key_register_tutorial_step_1: `Step 1 : Register For A Bitfinex And Complete Bitfinex's KYC Process`,
  dialog_api_key_register_tutorial_step_2: `Step 2 : Generating A Bitfinex API Key`,
  dialog_api_key_register_tutorial_step_3: `Step 3 : Set Bitfinex API Key On REYIELD`,
  dialog_api_key_register_tutorial_desc: `You need to register for a Bitfinex account, before generating a Bitfinex API key. Since Bitfinex is a centralized exchange and requires KYC verification, please come back and set the API after receiving the KYC pass email.`,
  dialog_api_key_register_tutorial_go_to_bitfinex: `Bitfinex Sign Up`,
  dialog_api_key_setting_title: `Set APY Key`,
  dialog_api_key_setting_subtitle: `Please paste the API key and API key secret in the field below.`,
  dialog_tool_enable_connect_wallet_notice: `Please connect your wallet`,
  dialog_tool_enable_title: `Enable Tool`,
  dialog_tool_enable_apy_history: `APY History`,
  dialog_tool_enable_coming_soon: `coming soon`,
  dialog_tool_enable_current_apy: `Current APY`,
  dialog_tool_enable_current_apy_description: `Actual annualized return since created, Including currency fluctuations, excluding gas fee.`,
  dialog_tool_enable_7d_apy: `7D APY`,
  dialog_tool_enable_7d_apy_description: `Actual annualized return over the past 7 days, including currency fluctuations, excluding gas fee.`,
  dialog_tool_enable_24h_apy: `24H APY`,
  dialog_tool_enable_24h_apy_description: `Actual annualized return over the past 24 hours, including currency fluctuations, excluding gas fee.`,
  dialog_tool_enable_tips_formula_details: `Formula Details`,
  dialog_tool_enable_title_creator: `Creator:`,
  dialog_tool_enable_title_running: `Running:`,
  dialog_tool_enable_title_closed: `Closed:`,
  dialog_tool_enable_title_duration: `Duration:`,
  dialog_tool_enable_title_view_more: `View more`,
  dialog_tool_enable_input_amount_deposit: `Deposit`,
  dialog_tool_enable_input_amount_single_token: `Single`,
  dialog_tool_enable_input_amount_dual_token: `Dual`,
  dialog_tool_enable_input_amount_token_balance: `Balance:`,
  dialog_tool_enable_input_amount_enable_tool: `Enable Tool`,
  dialog_tool_enable_input_amount_increase_funds: `Increase Funds`,
  dialog_tool_enable_input_amount_price_impact_warning: `Price Impact Warning!`,
  dialog_tool_enable_input_amount_max_btn: `MAX`,
  dialog_tool_enable_validation_required: `Required`,
  dialog_tool_enable_validation_token_greater_than_0: `Value should be greater than 0`,
  dialog_tool_enable_validation_token_allows_decimals: `{{symbol}} allows up to {{decimals}} decimals`,
  dialog_tool_enable_validation_token_apprive_required: `Need token approve`,
  dialog_tool_enable_validation_something_wrong: `Something went wrong, please try again later.`,
  dialog_tool_enable_validation_enable_tool_successfully: `Enable tool successfully`,
  dialog_tool_enable_validation_increase_funds_successfully: `Increase funds successfully`,
  dialog_tool_enable_validation_deposit_token_notice: `Please deposit {{token}} to enable the tool.`,
  dialog_tool_enable_validation_deposit_token0_notice: `Please deposit`,
  dialog_tool_enable_validation_deposit_and_token_notice: ` and `,
  dialog_tool_enable_validation_deposit_token1_notice: `to enable the tool.`,
  dialog_tool_view_more_title: `Tool Information Disclosure`,
  dialog_tool_view_more_view_tool_intro: `Tool Explanation`,
  dialog_tool_view_more_view_tool_creator: `Tool Creator`,
  dialog_tool_view_more_tool_performance_fee: `Tool Performance Fee`,
  dialog_tool_view_more_tool_performance_fee_title: `Performance Fee`,
  dialog_tool_view_more_tool_performance_fee_desc: `The performance fee, based on net profit, is set by the creator and received accordingly.`,
  dialog_tool_view_more_tool_performance_fee_formula_desc_1: `When Balance difference < 0, the performance fee`,
  dialog_tool_view_more_tool_performance_fee_formula_desc_2: `will not`,
  dialog_tool_view_more_tool_performance_fee_formula_desc_3: `be charged`,
  dialog_tool_view_more_tool_performance_fee_formula_1: `Performance Fee = Balance difference*X%`,
  dialog_tool_view_more_tool_performance_fee_formula_2: `Balance difference=(current token0 - Initial token0)*token0 price) + (current token1 - Initial token1)*token1 price)X%=Parameters set by the creator`,
  dialog_tool_view_more_view_tool_parameters: `Tool Parameters`,
  dialog_tool_view_more_view_tool_parameters_title: `Tool’s Parameters on Uniswap`,
  dialog_tool_view_more_view_tool_parameters_platform: `Platform`,
  dialog_tool_view_more_view_tool_parameters_blockchain: `Blockchain`,
  dialog_tool_view_more_view_tool_parameters_pair: `Pair`,
  dialog_tool_view_more_view_tool_parameters_fee_tier: `Fee Tier`,
  dialog_tool_view_more_view_tool_parameters_price_range: `Price Range`,
  dialog_tool_view_more_view_tool_parameters_pool_addr: `Uniswap Pool Address`,
  dialog_tool_view_more_view_rebalance_records: `View Rebalance Records`,
  dialog_tool_view_more_range_predictor_ai: `Range Predictor AI`,
  dialog_tool_view_more_coming_soon: `Coming Soon`,
  dialog_tool_view_more_tool_creator_title: `About Creator`,
  dialog_tool_view_more_tool_creator_other_tools: `Creator's other tools`,
  dialog_tool_view_more_tool_creator_no_other_tools: `No other tools`,
  dialog_tool_view_more_tool_creator_tool_chain: `Chain:`,
  dialog_tool_view_more_tool_creator_tool_token: `Token:`,
  dialog_tool_view_more_tool_creator_tool_apy: `APY`,
  dialog_tool_view_more_tool_creator_tool_tvl: `TVL`,
  dialog_tool_view_more_tool_intro_title: `Tool Introduction`,
  dialog_tool_view_more_tool_intro_creator: `Creator:`,
  dialog_tool_ongoing_title: `Ongoing Tool`,
  dialog_tool_ongoing_unrealized_pnl: `Unrealized PnL`,
  dialog_tool_ongoing_unrealized_pnl_desc: `Unrealized PnL includes currency fluctuations Uniswap income and performance fee.`,
  dialog_tool_ongoing_unrealized_pnl_income: `Uniswap Income:`,
  dialog_tool_ongoing_unrealized_pnl_token_price_fluctuations: `Token Price Fluctuations:`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_for_creator: `Performance Fee for Tool Creator`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_of: `of`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_net_profit: `Net Profit`,
  dialog_tool_ongoing_unrealized_apy: `APY`,
  dialog_tool_ongoing_unrealized_apy_desc: `Actual annualized return since created, Including currency fluctuations, excluding gas fee.`,
  dialog_tool_ongoing_deposit_balance: `Deposit Balance`,
  dialog_tool_ongoing_operate: `Operate`,
  dialog_tool_ongoing_operate_increase_funds: `Increase Fund`,
  dialog_tool_ongoing_operate_view_tool_performance_fee: `View Tool Performance Fee`,
  dialog_tool_ongoing_operate_tool_parameters: `Tools Parameters`,
  dialog_tool_ongoing_operate_close_tool: `Close Tool`,
  dialog_tool_ongoing_increase_funds: `Increase Funds`,
  dialog_tool_ongoing_clost_tool_desc: `Please select the type of token to receive`,
  dialog_tool_ongoing_clost_tool_receive: `You will receive`,
  dialog_tool_ongoing_clost_tool_btn: `Close`,
  dialog_tool_finished_title: `Finished Tool`,
  dialog_formula_details_title: `Formula Details`,
  dialog_formula_details_current_apy_formula_title: `Current APY = (1 - ( Input amount - The actual highest APY user's input amount) / Pool TVL) * Highest Actual APY`,
  dialog_formula_details_current_apy_formula: `Actual APY=(current token0 - Initial token0)*token0 price) + (current token1 - initial token1)*token1 price)(run time / 365 * 24 * 60)`,
  dialog_formula_details_7d_apy_formula_title: `7D APY = Highest Actual APY within 7 days`,
  dialog_formula_details_7d_apy_formula: `Actual APY within 7 days = (7D Balance difference / 7 days prior Initial balance) * 100`,
  dialog_formula_details_7d_apy_formula_2: `Balance difference=(current token0 - Initial token0)*token0 price) + (current token1 - Initial token1)*token1 price)`,
  dialog_formula_details_24h_apy_formula_title: `24H APY = Highest Actual APY within 24 hours`,
  dialog_formula_details_24h_apy_formula: `Actual APY within 24 hours = (24 hours Balance difference / 24 hours prior Initial balance) * 100`,
  dialog_formula_details_24h_apy_formula_2: `Balance difference=(current token0 - Initial token0)*token0 price) + (current token1 - Initial token1)*token1 price)`,
  dialog_with_background_back: `Back`,
  about_reyield_rebalance_title: `REYIELD Auto-Rebalance Mechanism`,
  about_reyield_rebalance_description: `The auto-rebalance tool makes adding liquidity to the pool on Uniswap v3 easier. It automates the process, eliminating the need for manual execution. Users simply specify the amount to contribute, and the REYIELD Uniswap Auto-Rebalance Tool will handle the rest.`,
  explore_card_coming_soon: `Coming Soon`,
  explore_card_apy: `APY`,
  explore_card_pool_pair: `Pool Pair`,
  explore_card_risk: `Risk`,
  explore_card_total_users: `Total Users`,
  explore_card_tvl: `TVL`,
  explore_card_pnl: `PnL`,
  explore_card_balance: `Balance`,
  ai_completion_record_retry: `Retry`,
  page_app_reyield_gpt_chat_history_empty: `There is not chat history.`,
  page_app_reyield_gpt_chat_history_view_detail_and_enable_previous_portfolio: `View Detail And Enable Previous Portfolio`,
  investment_style_warren_buffett: `Warren Buffett Style`,
  investment_style_ray_dalio: `Ray Dalio Style`,
  investment_style_peter_lynch: `Peter Lynch Style`,
  investment_style_george_soros: `George Soros Style`,
  investment_style_cathie_wood: `Cathie Wood Style`,
  investment_style_michael_burry: `Michael Burry Style`,
  investment_goal_of_buying_a_car: `Buying A Car`,
  investment_goal_of_buying_a_house: `Buying A House`,
  investment_goal_of_buying_education_fund: `Education Fund`,
  investment_goal_of_buying_a_wedding_fund: `Wedding Fund`,
  investment_goal_of_health_insurance: `Health Insurance`,
  investment_goal_of_travel_fund: `Travel Fund`,
  investment_goal_of_startup_capital: `Startup Capital`,
  investment_goal_of_1000_usd: `1,000 USD`,
  investment_goal_of_5000_usd: `5,000 USD`,
  investment_goal_of_10000_usd: `10,000 USD`,
  investment_goal_of_50000_usd: `50,000 USD`,
  investment_goal_of_100000_usd: `100,000 USD`,
  investment_goal_of_300000_usd: `300,000 USD`,
  investment_goal_of_500000_usd: `500,000 USD`,
  investment_goal_of_1000000_usd: `1,000,000 USD`,
  investment_period_of_1_month: `1 Month`,
  investment_period_of_3_months: `3 Months`,
  investment_period_of_6_months: `6 Months`,
  investment_period_of_1_year: `1 Year`,
  investment_period_of_2_years: `2 Years`,
  investment_period_of_3_years: `3 Years`,
  investment_period_of_5_years: `5 Years And Above`,
  investment_preference_of_demo_en: `Due to the recent poor market conditions, I am very optimistic about ETH and want to go all in on ETH. I do not want to use CeFi, and I am hoping for an annual return of over 48%.`,
  investment_preference_of_demo_cn: `因為最近市場不好，非常看好ETH，想要 all in ETH，不想使用 CeFi, 然後希望年化收益48%以上。`,
  investment_preference_of_demo_jp: `最近の市場が良くないため、ETHに非常に期待しています。ETHに全力投資したいと思っていますが、CeFiは使用したくありません。そして、年間利回りが48％以上を望んでいます。`,
  investment_preference_of_demo_emoji: `🚀 🚀 🚀 `,
  investment_question_purpose: `What is your investment goal (purpose)?`,
  investment_question_goal_amount: `Already received your answer that is {{answer}}.\nNext Question\nHow much money do you need to achieve your investment goal (purpose)? (in USD)`,
  investment_question_available_amount: `Already received your answer that is {{answer}}.\nNext Question\nHow much money do you currently have available for investment purposes?`,
  investment_question_period: `Already received your answer that is {{answer}}.\nNext Question\nHow long do you expect it will take to achieve your investment goal (purpose)?`,
  investment_question_risk_tolerance: `Already received your answer that is {{answer}}.\nNext Question\nConsidering the following profit and risk scenarios, which one are you most comfortable with?`,
  investment_question_risk_tolerance_bottom_desc: `Please note that these rankings are based on a general understanding of different risk and investment preferences. Every investor has a different risk tolerance and investment objectives, so the most suitable investment style should be evaluated and chosen based on individual circumstances.`,
  investment_question_preference: `Any investment preference you want to add?`,
  conversation_already_received_answer_and_generate_portfolio: `Already received your answer that is {{answer}}. \nNow will help you generate REYIELDGPT recommended portfolio.`,
  reyield_gpt_suggested_answer_title: `Suggested Answer`,
  reyield_gpt_suggested_answer_highest_apr: `Highest APR`,
  reyield_gpt_suggested_answer_maximum_loss: `Maximum loss`,
  reyield_gpt_tab_chat_history: `Chat History`,
  reyield_gpt_tab_reyield_gpt: `REYIELDGPT`,
  reyield_gpt_tailoring_investment_plan_for_you: `Tailoring investment plan for you ...`,
  reyield_gpt_view_detail_and_enable_portfolio: `View Detail And Enable Portfolio`,
  reyield_gpt_view_detail_start_over: `Start Over`,
  reyield_gpt_is_responding: `REYIELD GPT is responding ...`,
  reyield_gpt_input_the_answer_manually: `input the answer manually ...`,
  nav_vertical_reyield_gpt: `REYIELDGPT`,
  nav_vertical_step_1: `Step1 : Questionnaire`,
  nav_vertical_step_2: `Step2 : AI-recommended portfolio`,
  nav_vertical_step_3: `Step3 : Enable portfolio`,
  nav_header_please_login_first: `Please login first`,
  reyield_gpt_layout_nav_header_cta_mobile: `Home`,
  reyield_gpt_layout_nav_header_cta_desktop: `Back to Explore`,
  nav_header_usage_count_today_mobile: `Usage`,
  nav_header_usage_count_today_desktop: `Usage Count Today`,
}

export default en
