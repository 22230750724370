const jp = {
  app: `アプリ`,
  app_header_connect: `接続`,
  app_view_white_paper: `ホワイトペーパーを表示`,
  risk_all_tools: `全てのツール`,
  risk_low_risk_tools: `低リスク`,
  risk_medium_low_risk_tools: `中低リスク`,
  risk_medium_risk_tools: `中リスク`,
  risk_medium_high_risk_tools: `中高リスク`,
  risk_high_risk_tools: `高リスク`,
  page_app_investment_tool_header: `投資`,
  page_app_investment_tool_enable_all_message: `おめでとうございます！REYIELDのツールがすでに有効になっています`,
  page_app_explorer_section_title: `REYIELDはプロ投資を簡素化します`,
  page_app_explorer_section_sub_title: `暴騰と暴落は暗号通貨で利益を得る唯一の方法ではありません。REYIELDは、クリック数回でプロ並みの投資ができるようサポートします。`,
  page_app_reyield_gpt_title: `AIで利益を上げよう！`,
  page_app_reyield_gpt_subtitle: `REYIELDGPTはあなたに最適な投資プランをご提案します。AIの力であなたの資産目標を達成しましょう。`,
  page_app_reyield_gpt_counter_desc: `REYILEDGPTでポートフォリオを開設し、利益を得る`,
  page_app_reyield_gpt_banner_title_desktop: `今度はAIで利益を出しましょう`,
  page_app_reyield_gpt_banner_title_mobile: `AIで利益を上げよう`,
  page_app_reyield_gpt_banner_sub_title: ` REYIELDGPTはあなたにぴったりの投資プランをご提案します。AIの力であなたのファイナンシャル・ゴールを達成しましょう。`,
  page_app_reyield_gpt_banner_button: `今すぐ探索`,
  page_app_my_portfolio_section_title: `私のポートフォリオ`,
  page_app_my_portfolio_section_sub_title: `あなたの旅を始めるための多様な投資ツール`,
  page_app_my_portfolio_total_profilt_chart_title: `総利益`,
  page_app_my_portfolio_header: `私のツール`,
  page_app_my_portfolio_empty_data: `{{tab_value}}のストラテジーはありません`,
  page_app_my_portfolio_tabs_all_tools: `全て`,
  page_app_my_portfolio_tabs_defi_tools: `DeFiツール`,
  page_app_my_portfolio_tabs_cefi_tools: `CeFiツール`,
  page_app_my_portfolio_tabs_bitfinex_tools: `Bitfinexツール`,
  page_app_my_portfolio_ongoing: `進行中`,
  page_app_my_portfolio_finished: `完了`,
  page_app_my_portfolio_search: `検索`,
  page_app_my_portfolio_sortby: `ソート`,
  page_app_my_portfolio_sortby_newest: `新着順`,
  page_app_my_portfolio_sortby_highest_apy: `最高APY`,
  page_app_my_portfolio_sortby_highest_roi: `最高ROI`,
  page_app_rewards_title: `報酬`,
  page_app_rewards_subtitle: `{{stack_token_num}} REYLDをステークすると{{rewards}}の追加リワードが得られます。そしてツールを無料で利用できます！`,
  page_app_rewards_yield_token: `YIELD token`,
  page_app_rewards_coming_soon: `{{token_name}} coming soon`,
  page_app_rewards_stake_reyld: `REYLDをステーキング`,
  page_app_rewards_stake_reyld_desc: `REYIELDでは、2,000 REYLDをステーキングすると、すべてのツールを無料で利用し、25％の追加報酬を楽しむことができます。`,
  page_app_rewards_cashout: `{{token_name}}をキャッシュアウト`,
  page_app_rewards_cashout_desc: `REYLDのキャッシュアウトは思っているよりも簡単です`,
  page_app_rewards_deposit: `{{token_name}}をデポジット`,
  page_app_rewards_deposit_desc: `他のアカウントからReyieldアカウントに仮想通貨を便利に入金できます。`,
  page_app_stake_title: `ステーク`,
  page_app_stake_subtitle: `特権を得る`,
  page_app_stake_get_privilege_1: `1. すべてのツールを無料で利用`,
  page_app_stake_get_privilege_2: `2. AIを投資のアシスタントとして利用`,
  page_app_stake_get_privilege_3: `3. 25%追加のREYLD報酬を獲得`,
  page_app_stake_privilege_activation: `特権の行使`,
  page_app_stake_privilege_not_activation: `特権の行使していません`,
  page_app_stake_unstake_btn: `ステーク解除`,
  page_app_stake_stake_btn: `今すぐステーク`,
  page_app_stake_balance: `残高`,
  page_app_stake_coming_soon: `coming soon`,
  page_app_stake_staking_privilege: `ステーキング特権`,
  page_app_vest_title: `ベスティング（権利確定）`,
  page_app_vest_subtitle: `REYLDをロックして、REYLDの報酬の配分を決定するための票を生成し、買取から追加な利益を得る。`,
  page_app_vest_btn: `ベスティング（権利確定）`,
  page_app_vest_vesting: `ベスティング`,
  page_app_vest_finished: `完了`,
  page_app_vest_search: `検索`,
  page_app_vest_vest_list: `ベスティングリスト`,
  page_app_vest_empty_data: `データなし`,
  page_app_vest_start_date: `開始日`,
  page_app_vest_end_date: `終了日`,
  page_app_vest_list_header_title_vest: `ベスティング`,
  page_app_vest_list_header_title_vest_amount: `ベスティング量（YIELD）`,
  page_app_vest_list_header_title_vote_value: `投票価値（veYIELD）`,
  page_app_vest_list_header_title_vest_expire: `ベスティング有効期限`,
  page_app_vest_list_header_title_manage: `管理`,
  page_app_vest_list_finished_header_title_vest: `ベスティング`,
  page_app_vest_list_finished_header_title_vest_amount: `ベスティング量（YIELD）`,
  page_app_vest_list_finished_header_title_vote_value: `投票価値（veYIELD）`,
  page_app_vest_list_finished_header_title_unlock_time: `ロック解除時間`,
  page_app_vest_list_finished_header_title_continue_vest: `ベスティングを継続`,
  page_app_vote_title: `投票`,
  page_app_vote_subtitle: `投票によってREYLDの報酬の配分を決定し、買取による追加利益を得ることができる。`,
  page_app_vote_balance: `残高`,
  page_app_vote_my_votes: `私の投票`,
  page_app_vote_voting_power_used: `使用済み投票力`,
  page_app_vote_coming_soon: `coming soon`,
  page_app_vote_description: `各 veREYLD は 1 回投票することができます。あなたの投票は、あなたのveREYLDの議決権の100％を使用します。投票に変更がない場合は、次回に持ち越されます。投票者であるあなたは、その間に預けられた買収金をすべて獲得することができます。 もしその間にあなたのveREYLDの残高が増えた場合は、投票権を完全に配分するため、投票をやり直すことをお勧めします。`,
  page_app_vote_description_2: `詳細はこちらをご参照ください：`,
  page_app_vote_description_doc: `ドキュメント`,
  page_app_vote_list_empty_data: `データなし`,
  page_app_vote_list_header_title_tool: `ツール`,
  page_app_vote_list_header_title_total_votes: `総投票数`,
  page_app_vote_list_header_title_voting_apr: `投票APR`,
  page_app_vote_list_header_title_bribes_fees: `買取 + 手数料`,
  page_app_vote_list_header_title_bribes: `買取`,
  page_app_vote_list_header_title_my_votes: `私の投票`,
  page_app_vote_list_header_title_my_votes_percentage: `私の投票 %`,
  page_app_vote_list_filter: `フィルター`,
  page_app_bribe_title: `買取`,
  page_app_bribe_subtitle: `より多くの取引量が必要ですか？投票者にインセンティブを提供し、取引量をあなたに引き付けましょう。`,
  page_app_bribe_btn: `買取`,
  page_app_setting_title: `設定`,
  page_app_setting_subtitle: `投資ツールはあなた自身の取引所アカウントで実行できます。APIキーを入力して有効にしてください`,
  page_app_setting_api_setting: `API設定`,
  page_app_setting_api_setting_api: `{{exchange}} API`,
  page_app_setting_api_setting_name: `名前`,
  page_app_setting_api_setting_access_key: `アクセスキー`,
  page_app_setting_api_setting_secret_key: `シークレットキー`,
  page_app_setting_api_setting_connect: `接続`,
  page_app_setting_api_setting_disconnect: `接続解除`,
  page_app_setting_api_setting_view_tutorial: `チュートリアルを見る`,
  nav_item_explore: `探索`,
  nav_item_children_investment_tool: `投資ツール`,
  nav_item_children_reyield_gpt: `REYIELDGPT`,
  nav_item_my_portfolio: `私のポートフォリオ`,
  nav_item_reward: `報酬`,
  nav_item_stake_and_vest: `ステーキング＆ベスティング`,
  nav_item_children_stake: `ステーク`,
  nav_item_children_vest: `ベスティング`,
  nav_item_vote_and_bribe: `投票＆買取`,
  nav_item_setting: `設定`,
  dialog_supporting_chain_support_network: `サポートネットワーク`,
  dialog_supporting_chain_switch_to: `切り替える`,
  dialog_welcome_reyield_title_the_future: `仮想通貨投資の未来`,
  dialog_welcome_reyield_title_a_investment_platform: `REYIELD - AI投資プラットフォーム`,
  dialog_welcome_reyield_title_a_investment_platform_desc: ` REYIELDはプロの投資を簡素化し、投資ツール、ホワイトラベル投資ツール、AI投資アシスタントを提供し、投資家、インフルエンサー、取引所の利益を高める。`,
  dialog_welcome_reyield_reyield_gpt: `REYIELD GPT`,
  dialog_welcome_reyield_reyield_gpt_features: ` GPT-4は、あなたの投資の好みを把握した上で、投資プランを提供します。一回のクリックで投資ツールを開始することができます。`,
  dialog_welcome_reyield_start: `開始`,
  dialog_wallet_connect_header_title: `ウォレットを接続`,
  dialog_wallet_connect_title: `以下のウォレットの1つに接続して開始してください。`,
  dialog_wallet_connect_request_connection: `接続要求中`,
  dialog_wallet_connect_request_connection_confirm: `ウォレットでこの接続を確認してください`,
  dialog_wallet_connect_request_connection_retry: `リトライ`,
  dialog_wallet_connect_connected_wallet: `接続されたウォレット`,
  dialog_wallet_connect_gas_fee_wallet: `ガス代ウォレット`,
  dialog_wallet_connect_telegram_bot_connect: `Telegramボットに接続すると、ガス代の即時通知を受け取ります。`,
  dialog_wallet_connect_telegram_bot_subscribe: `購読`,
  dialog_wallet_connect_balance_native_asset: `残高（ネイティブ資産）`,
  dialog_wallet_connect_deposit: `入金`,
  dialog_wallet_connect_withdraw: `出金`,
  dialog_wallet_connect_tokens: `トークン`,
  dialog_wallet_connect_no_tokens: `サポートされているトークンはありません`,
  dialog_wallet_connect_gas_wallet_num_tools_ongoing: `進行中のツールの数：{{number_of_tools}}`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_1: `ガス代が`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_2: `不足しています`,
  dialog_wallet_connect_gas_wallet_gas_insuficent_3: `。できるだけ早く入金してください。`,
  dialog_wallet_connect_gas_wallet_num_tools_stop_rebalacing: `リバランスが停止するツールの数：{{number_of_tools}}`,
  dialog_wallet_connect_gas_wallet_gas_low_1: `ガス代が`,
  dialog_wallet_connect_gas_wallet_gas_low_2: `不足しています`,
  dialog_wallet_connect_gas_wallet_gas_low_3: `。できるだけ早く入金してください。`,
  dialog_wallet_connect_gas_wallet_num_tools_will_stop_rebalacing: `リバランスが停止するツールの数：{{number_of_tools}}`,
  dialog_wallet_connect_gas_wallet_create_btn: `ガス代ウォレットを作成`,
  dialog_wallet_connect_gas_wallet_create_desc: `ガス代ウォレットを使用してガス代を支払うと、最大30%節約できます。`,
  dialog_strategy_enable_dialog_coming_soon: `coming soon`,
  dialog_strategy_enable_dialog_enable_tool_button: `ツールを有効にする`,
  dialog_strategy_enable_dialog_lending_currency: `貸出通貨`,
  dialog_strategy_enable_dialog_tvl: `TVL（プールにロックされた合計価値）`,
  dialog_strategy_enable_dialog_warning_minimum_investment_amount: `最小投資額`,
  dialog_strategy_enable_dialog_warning_minimum_investment_amount_amount: `ツールの有効化投資額：最小 {{amount}} USDT`,
  dialog_strategy_enable_dialog_warning_title: `警告`,
  dialog_strategy_enable_dialog_warning_content_1: `1. 実際の融資額には、Bitfinex融資ウォレットにある元のUSDT資産が含まれるため、資産は自分でBitfinexに送金する必要があります。`,
  dialog_strategy_enable_dialog_warning_content_2: `2. 実際の融資額には、Bitfinex融資ウォレットにある元のUSDT資産が含まれるため、資産は自分でBitfinexに送金する必要があります。`,
  dialog_strategy_close_dialog_view_status_button: `ステータスを表示`,
  dialog_strategy_close_dialog_view_other_tools_button: `他のツールを表示する`,
  dialog_strategy_close_dialog_snackbar_close_success_msg: `ストラテジーが正常に閉じられました`,
  dialog_strategy_close_dialog_close_tool_button: `ツールを閉じる`,
  dialog_strategy_close_dialog_close_tool_title: `ツール {{tool_name}} を閉じもすか？`,
  dialog_strategy_close_dialog_total_principal: `総元本`,
  dialog_strategy_close_dialog_total_value: `総額`,
  dialog_strategy_close_dialog_run_time: `実行時間`,
  dialog_strategy_close_dialog_back_button: `戻る`,
  dialog_strategy_edit_dialog_title: `ツールを編集`,
  dialog_strategy_edit_dialog_run_time: `実行時間`,
  dialog_strategy_edit_dialog_my_asset: `私の資産`,
  dialog_strategy_edit_dialog_increase_funds: `資金を増やす`,
  dialog_strategy_edit_dialog_current_principal: `現在の元本`,
  dialog_strategy_edit_dialog_current_apy: `現在のAPY`,
  dialog_strategy_edit_dialog_estimated_monthly_earning: `予想月間収益`,
  dialog_strategy_edit_dialog_max: `最大`,
  dialog_strategy_edit_dialog_deposit_warning: `ツールを開始するには、接続ウォレットに{{amount}} {{coin_symbol}} を入金してください。`,
  dialog_strategy_edit_dialog_deposit: `入金`,
  dialog_strategy_edit_dialog_advanced_options: `詳細オプション`,
  dialog_strategy_edit_dialog_cancel: `キャンセル`,
  dialog_strategy_edit_dialog_loading: `読み込み中...`,
  dialog_strategy_edit_dialog_fund_retention: `資金保持`,
  dialog_strategy_edit_dialog_rate_mode: `料金モード`,
  dialog_strategy_edit_dialog_lending_period: `貸出期間`,
  dialog_strategy_edit_dialog_rate_mode_min: `最小`,
  dialog_strategy_edit_dialog_days: `日`,
  dialog_strategy_edit_dialog_lending_period_min: `最小`,
  dialog_strategy_edit_dialog_lending_period_max: `最大`,
  dialog_strategy_edit_dialog_my_wallet_asset: `私のウォレット資産`,
  dialog_strategy_increase_funds: `資金を増やす`,
  dialog_strategy_increase_funds_in_tool: `{{tool}} で資金を増やす`,
  dialog_strategy_increase_funds_current_principal: `現在の元本`,
  dialog_strategy_increase_funds_increase_amount: `+ 資金を増やす`,
  dialog_strategy_increase_funds_expected_total_principal: `予想総元本`,
  dialog_strategy_increase_funds_increase: `資金を増やす`,
  dialog_strategy_increase_funds_back: `戻る`,
  dialog_strategy_information_view_tool_information: `ツール情報を表示`,
  dialog_strategy_information_view_tool_records: `ツールの記録を表示`,
  dialog_strategy_information_view_tool_start_time: `開始時間`,
  dialog_strategy_information_view_tool_close_time: `終了時間`,
  dialog_strategy_information_view_tool_run_time: `実行時間`,
  dialog_strategy_information_view_tool_profit_currency: `利益 {{currency}}`,
  dialog_strategy_information_view_tool_profit_rate: `単利益率`,
  dialog_strategy_information_view_tool_loading: `読み込み中...`,
  dialog_strategy_information_view_tool_fully_loaded: `すべての記録が読み込まれました`,
  dialog_strategy_information_view_tool_back: `戻る`,
  dialog_strategy_information_view_tool_principal: `元本`,
  dialog_strategy_information_view_tool_current_value: `現在の価値`,
  dialog_deposit_deposit_by: `入金者`,
  dialog_deposit_via_blockchain_deposit: `入金`,
  dialog_deposit_via_blockchain_current_network: `現在のネットワーク`,
  dialog_deposit_via_blockchain_address: `アドレス`,
  dialog_deposit_via_blockchain_snackbar_copied: `コピーしました`,
  dialog_deposit_cross_chain_cross_chain_transfer: `クロスチェーン転送`,
  dialog_deposit_cross_chain_cross_chain_transfer_description: `アセットのクロスチェーン転送のためのクロスチェーンブリッジへ。上記のチュートリアルをご覧いただけます。`,
  dialog_deposit_cross_chain_cross_chain_transfer_cbridge: `cBRIDGE`,
  dialog_deposit_by_credit_card_buy_crypto: `暗号通貨の購入`,
  dialog_deposit_by_credit_card_buy_crypto_next: `次へ`,
  dialog_buy_crypto_dialog_select_currency_next: `次へ`,
  dialog_buy_crypto_dialog_select_currency_buy: `購入`,
  dialog_buy_crypto_dialog_select_currency_buy_crypto: `暗号通貨を購入`,
  dialog_reyield_gpt_title: `REYIELDGPT`,
  dialog_reyield_gpt_description: `AIを介して、あなたにより適した投資ツールのポートフォリオ作成を手助けさせてください。`,
  dialog_reyield_gpt_button_start: `開始`,
  dialog_view_portfolios_dialog_title: `ポートフォリオ`,
  dialog_view_portfolios_dialog_title_reyield_gpt: `REYIELDGPT`,
  dialog_view_portfolios_dialog_description_reyield_gpt: `REYIELDGPTはAIの力を活用して、あなたの特定の財務目標に合わせたパーソナライズされた投資計画を作成します。インテリジェントでカスタマイズされた投資ポートフォリオで目標を達成しましょう。`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_portfolios: `投資ポートフォリオ`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_my_expected_return: `期待される収益`,
  dialog_view_portfolios_dialog_reyield_allocation_of_investment_portfolios: `投資ポートフォリオの配分`,
  dialog_view_portfolios_dialog_reyield_gpt_comprehensive_apy: `総合APY`,
  dialog_view_portfolios_dialog_reyield_gpt_expected_achieve_time: `目標達成予定時期`,
  dialog_view_portfolios_dialog_reyield_gpt_risk_tolerance_level: `リスク許容度レベル`,
  dialog_view_portfolios_dialog_reyield_gpt_investment_tool_portfolios: `投資ツールのポートフォリオ`,
  dialog_view_portfolios_dialog_warning_title: `警告`,
  dialog_view_portfolios_dialog_warning_content_1: `1. 暗号通貨市場は非常に変動が激しく、価格は短期間で大幅に変動することがあります。この変動は大きな投資損失を引き起こす可能性があります。`,
  dialog_view_portfolios_dialog_warning_content_2: `2. 投資にはリスクが伴います。過去のパフォーマンスは将来の結果を保証するものではありません。投資前に評価してください。`,
  dialog_view_portfolios_dialog_button_enable_portfolio: `ポートフォリオを有効にする（coming soon）`,
  dialog_api_key_binding_tutorial_title: `Bitfinexアカウントをお持ちですか？`,
  dialog_api_key_binding_tutorial_i_have_bitfinex_account: `Bitfinexアカウントを持っています。`,
  dialog_api_key_binding_tutorial_i_dont_have_bitfinex_account: `Bitfinexアカウントを持っていません。`,
  dialog_create_tutorial_dialog_back: `戻る`,
  dialog_create_tutorial_dialog_title: `BitfinexでAPIキーを作成する`,
  dialog_create_tutorial_dialog_desc: `Bitfinex 上で以下の操作を行い、生成された API Key を REYIELD に貼り付けます。APIキーは公開しないようご注意ください。`,
  dialog_create_tutorial_dialog_go_to_bitfinex: `Bitfinexへ移動`,
  dialog_create_tutorial_dialog_next: `次へ`,
  dialog_create_tutorial_dialog_next_desc: `REYIELDにAPIキーを設定する`,
  dialog_api_key_register_tutorial_title: `Bitfinexアカウントの登録方法`,
  dialog_api_key_register_tutorial_step_1: `ステップ1：Bitfinexに登録し、BitfinexのKYCプロセスを完了する`,
  dialog_api_key_register_tutorial_step_2: `ステップ2：Bitfinex APIキーの生成`,
  dialog_api_key_register_tutorial_step_3: `ステップ3：REYIELDでBitfinex APIキーを設定する`,
  dialog_api_key_register_tutorial_desc: `BitfinexのAPIキーを生成する前にBitfinexアカウントに登録する必要があります。Bitfinexは中央集権型取引所であり、KYCの確認が必要です。KYC承認メールを受け取った後に、APIを設定してください。`,
  dialog_api_key_register_tutorial_go_to_bitfinex: `Bitfinex登録`,
  dialog_api_key_setting_title: `APYキーを設定する`,
  dialog_api_key_setting_subtitle: `以下のフィールドにAPIキーとAPIキーシークレットを貼り付けてください。`,
  dialog_tool_enable_connect_wallet_notice: `ウォレットに接続してください`,
  dialog_tool_enable_title: `ツールを有効にする`,
  dialog_tool_enable_apy_history: `APY履歴`,
  dialog_tool_enable_coming_soon: `coming soon`,
  dialog_tool_enable_current_apy: `現在のAPY`,
  dialog_tool_enable_current_apy_description: `作成以来の実際の年率リターン、通貨の変動を含む、ガス代を除く。`,
  dialog_tool_enable_7d_apy: `7日間のAPY`,
  dialog_tool_enable_7d_apy_description: `過去7日間の実際の年率リターン、通貨の変動を含む、ガス代を除く。`,
  dialog_tool_enable_24h_apy: `24時間のAPY`,
  dialog_tool_enable_24h_apy_description: `過去24時間の実際の年率リターン、通貨の変動を含む、ガス代を除く。`,
  dialog_tool_enable_tips_formula_details: `数式の詳細`,
  dialog_tool_enable_title_creator: `作成者：`,
  dialog_tool_enable_title_running: `実行中：`,
  dialog_tool_enable_title_closed: `終了済み：`,
  dialog_tool_enable_title_duration: `期間：`,
  dialog_tool_enable_title_view_more: `詳細を表示`,
  dialog_tool_enable_input_amount_deposit: `入金`,
  dialog_tool_enable_input_amount_single_token: `単一`,
  dialog_tool_enable_input_amount_dual_token: `二重`,
  dialog_tool_enable_input_amount_token_balance: `残高：`,
  dialog_tool_enable_input_amount_enable_tool: `ツールを有効にする`,
  dialog_tool_enable_input_amount_increase_funds: `資金を増やす`,
  dialog_tool_enable_input_amount_price_impact_warning: `価格への影響警告！`,
  dialog_tool_enable_input_amount_max_btn: `最大`,
  dialog_tool_enable_validation_required: `必須項目です`,
  dialog_tool_enable_validation_token_greater_than_0: `値は0より大きい必要があります`,
  dialog_tool_enable_validation_token_allows_decimals: `{{symbol}} は最大{{decimals}}桁の小数を許可します`,
  dialog_tool_enable_validation_token_apprive_required: `トークン承認が必要です`,
  dialog_tool_enable_validation_something_wrong: `問題が発生しました。後でもう一度お試しください。`,
  dialog_tool_enable_validation_enable_tool_successfully: `ツールを正常に有効にしました`,
  dialog_tool_enable_validation_increase_funds_successfully: `資金を正常に増やしました`,
  dialog_tool_enable_validation_deposit_token_notice: `ツールを有効にするには{{token}}を入金してください。`,
  dialog_tool_enable_validation_deposit_token0_notice: `入金してください`,
  dialog_tool_enable_validation_deposit_and_token_notice: ` と `,
  dialog_tool_enable_validation_deposit_token1_notice: `を入金してください。`,
  dialog_tool_view_more_title: `ツール情報開示`,
  dialog_tool_view_more_view_tool_intro: `ツールの説明`,
  dialog_tool_view_more_view_tool_creator: `ツールの作成者`,
  dialog_tool_view_more_tool_performance_fee: `ツール運用手数料`,
  dialog_tool_view_more_tool_performance_fee_title: `運用手数料`,
  dialog_tool_view_more_tool_performance_fee_desc: `ネット利益に基づく運用手数料は、作成者によって設定され、それに応じて受け取ります。`,
  dialog_tool_view_more_tool_performance_fee_formula_desc_1: `差額バランスが0未満の場合、運用手数料は`,
  dialog_tool_view_more_tool_performance_fee_formula_desc_2: `請求されません`,
  dialog_tool_view_more_tool_performance_fee_formula_desc_3: `料金を請求される`,
  dialog_tool_view_more_tool_performance_fee_formula_1: `運用手数料 = 差額バランス*X%`,
  dialog_tool_view_more_tool_performance_fee_formula_2: `差額バランス = (現在のtoken0 - 初期のtoken0)*token0価格) + (現在のtoken1 - 初期のtoken1)*token1価格)X%=作成者によって設定されたパラメータ`,
  dialog_tool_view_more_view_tool_parameters: `ツールのパラメータ`,
  dialog_tool_view_more_view_tool_parameters_title: `Uniswapのツールのパラメータ`,
  dialog_tool_view_more_view_tool_parameters_platform: `プラットフォーム`,
  dialog_tool_view_more_view_tool_parameters_blockchain: `ブロックチェーン`,
  dialog_tool_view_more_view_tool_parameters_pair: `ペア`,
  dialog_tool_view_more_view_tool_parameters_fee_tier: `手数料ティア`,
  dialog_tool_view_more_view_tool_parameters_price_range: `価格帯`,
  dialog_tool_view_more_view_tool_parameters_pool_addr: `Uniswapプールアドレス`,
  dialog_tool_view_more_view_rebalance_records: `リバランス記録を表示`,
  dialog_tool_view_more_range_predictor_ai: `レンジ予測AI`,
  dialog_tool_view_more_coming_soon: `coming soon`,
  dialog_tool_view_more_tool_creator_title: `クリエーターについて`,
  dialog_tool_view_more_tool_creator_other_tools: `クリエーターの他のツール`,
  dialog_tool_view_more_tool_creator_no_other_tools: `他のツールはありません`,
  dialog_tool_view_more_tool_creator_tool_chain: `チェーン：`,
  dialog_tool_view_more_tool_creator_tool_token: `トークン：`,
  dialog_tool_view_more_tool_creator_tool_apy: `APY`,
  dialog_tool_view_more_tool_creator_tool_tvl: `TVL`,
  dialog_tool_view_more_tool_intro_title: `ツールの紹介`,
  dialog_tool_view_more_tool_intro_creator: `作成者：`,
  dialog_tool_ongoing_title: `進行中のツール`,
  dialog_tool_ongoing_unrealized_pnl: `未実現のPnL`,
  dialog_tool_ongoing_unrealized_pnl_desc: `未実現のPnLには、通貨の変動、Uniswapの収入、およびパフォーマンス料金が含まれます。`,
  dialog_tool_ongoing_unrealized_pnl_income: `Uniswap収入:`,
  dialog_tool_ongoing_unrealized_pnl_token_price_fluctuations: `トークン価格の変動:`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_for_creator: `ツール作成者のパフォーマンス料金`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_of: `の`,
  dialog_tool_ongoing_unrealized_pnl_performance_fee_net_profit: `純利益`,
  dialog_tool_ongoing_unrealized_apy: `APY`,
  dialog_tool_ongoing_unrealized_apy_desc: `通貨の変動を含む実際の年間利回り、ガス料金を除く。`,
  dialog_tool_ongoing_deposit_balance: `デポジット残高`,
  dialog_tool_ongoing_operate: `操作`,
  dialog_tool_ongoing_operate_increase_funds: `資金増加`,
  dialog_tool_ongoing_operate_view_tool_performance_fee: `ツールのパフォーマンス料金を表示`,
  dialog_tool_ongoing_operate_tool_parameters: `ツールのパラメータ`,
  dialog_tool_ongoing_operate_close_tool: `ツールを閉じる`,
  dialog_tool_ongoing_increase_funds: `資金増加`,
  dialog_tool_ongoing_clost_tool_desc: `受け取るトークンのタイプを選択してください`,
  dialog_tool_ongoing_clost_tool_receive: `受け取ることができます`,
  dialog_tool_ongoing_clost_tool_btn: `閉じる`,
  dialog_tool_finished_title: `完了したツール`,
  dialog_formula_details_title: `式の詳細`,
  dialog_formula_details_current_apy_formula_title: `現在のAPY = (1 - ( 入力量 - 実際の最高APYユーザーの入力量) / プールTVL) * 最高実際のAPY`,
  dialog_formula_details_current_apy_formula: `実際のAPY=(現在のtoken0 - 初期のtoken0)*token0価格) + (現在のtoken1 - 初期のtoken1)*token1価格)(稼働時間 / 365 * 24 * 60)`,
  dialog_formula_details_7d_apy_formula_title: `7日間のAPY = 7日間の最高実際のAPY`,
  dialog_formula_details_7d_apy_formula: `7日間の実際のAPY = (7日間の残高差分 / 7日前の初期残高) * 100`,
  dialog_formula_details_7d_apy_formula_2: `残高差分=(現在のtoken0 - 初期のtoken0)*token0価格) + (現在のtoken1 - 初期のtoken1)*token1価格)`,
  dialog_formula_details_24h_apy_formula_title: `24時間のAPY = 24時間の最高実際のAPY`,
  dialog_formula_details_24h_apy_formula: `24時間の実際のAPY = (24時間の残高差分 / 24時間前の初期残高) * 100`,
  dialog_formula_details_24h_apy_formula_2: `残高差分=(現在のtoken0 - 初期のtoken0)*token0価格) + (現在のtoken1 - 初期のtoken1)*token1価格)`,
  dialog_with_background_back: `戻る`,
  about_reyield_rebalance_title: `REYIELDの自動リバランスメカニズム`,
  about_reyield_rebalance_description: `自動リバランスツールは、Uniswap v3のプールへの流動性の追加を簡素化します。プロセスを自動化し、手動実行が不要になります。ユーザーは単に投稿する金額を指定し、REYIELD Uniswap Auto-Rebalance Toolが残りの作業を処理します。`,
  explore_card_coming_soon: `coming soon`,
  explore_card_apy: `APY`,
  explore_card_pool_pair: `プールペア`,
  explore_card_risk: `リスク`,
  explore_card_total_users: `総ユーザー数`,
  explore_card_tvl: `TVL`,
  explore_card_pnl: `PnL`,
  explore_card_balance: `残高`,
  ai_completion_record_retry: `再試行`,
  page_app_reyield_gpt_chat_history_empty: `チャット履歴はありません。`,
  page_app_reyield_gpt_chat_history_view_detail_and_enable_previous_portfolio: `詳細を表示して前のポートフォリオを有効にする`,
  investment_style_warren_buffett: `ウォーレン・バフェットのスタイル`,
  investment_style_ray_dalio: `レイ・ダリオのスタイル`,
  investment_style_peter_lynch: `ピーター・リンチのスタイル`,
  investment_style_george_soros: `ジョージ・ソロスのスタイル`,
  investment_style_cathie_wood: `キャシー・ウッドのスタイル`,
  investment_style_michael_burry: `マイケル・バーリーのスタイル`,
  investment_goal_of_buying_a_car: `車の購入`,
  investment_goal_of_buying_a_house: `家の購入`,
  investment_goal_of_buying_education_fund: `教育基金`,
  investment_goal_of_buying_a_wedding_fund: `結婚基金`,
  investment_goal_of_health_insurance: `健康保険`,
  investment_goal_of_travel_fund: `旅行基金`,
  investment_goal_of_startup_capital: `スタートアップ資本`,
  investment_goal_of_1000_usd: `1,000 USD `,
  investment_goal_of_5000_usd: `5,000 USD `,
  investment_goal_of_10000_usd: `10,000 USD `,
  investment_goal_of_50000_usd: `50,000 USD `,
  investment_goal_of_100000_usd: `100,000 USD `,
  investment_goal_of_300000_usd: `300,000 USD `,
  investment_goal_of_500000_usd: `500,000 USD `,
  investment_goal_of_1000000_usd: `1,000,000 USD `,
  investment_period_of_1_month: `1 か月`,
  investment_period_of_3_months: `3 か月`,
  investment_period_of_6_months: `6 か月`,
  investment_period_of_1_year: `1 年`,
  investment_period_of_2_years: `2 年`,
  investment_period_of_3_years: `3 年`,
  investment_period_of_5_years: `5 年以上`,
  investment_preference_of_demo_en: `Due to the recent poor market conditions, I am very optimistic about ETH and want to go all in on ETH. I do not want to use CeFi, and I am hoping for an annual return of over 48%.`,
  investment_preference_of_demo_cn: `因為最近市場不好，非常看好ETH，想要 all in ETH，不想使用 CeFi, 然後希望年化收益48%以上。`,
  investment_preference_of_demo_jp: `最近の市場が良くないため、ETHに非常に期待しています。ETHに全力投資したいと思っていますが、CeFiは使用したくありません。そして、年間利回りが48％以上を望んでいます。`,
  investment_preference_of_demo_emoji: `🚀 🚀 🚀`,
  investment_question_purpose: `投資の目標（目的）は何ですか？`,
  investment_question_goal_amount: `すでに回答を受け取りました。{{answer}}です。\n次の質問\n投資の目標（目的）を達成するために必要な金額はいくらですか？（USDで）`,
  investment_question_available_amount: `すでに回答を受け取りました。{{answer}}です。\n次の質問\n現在、投資目的で利用可能な資金はいくらですか？`,
  investment_question_period: `すでに回答を受け取りました。{{answer}}です。\n次の質問\n投資目標（目的）を達成するのにかかる期間はどのくらいですか？`,
  investment_question_risk_tolerance: `すでに回答を受け取りました。{{answer}}です。\n次の質問\n以下の利益とリスクのシナリオを考慮した場合、どれが最も快適ですか？`,
  investment_question_risk_tolerance_bottom_desc: `これらのランキングは異なるリスクや投資の嗜好についての一般的な理解に基づいています。投資家それぞれのリスク許容度や投資目的は異なるため、最も適した投資スタイルは個々の状況に基づいて評価および選択されるべきです。`,
  investment_question_preference: `追加したい投資の好みはありますか？`,
  conversation_already_received_answer_and_generate_portfolio: `すでに回答を受け取りました。{{answer}}です。\nREYIELDGPT 推奨のポートフォリオを生成します。`,
  reyield_gpt_suggested_answer_title: `推奨される回答`,
  reyield_gpt_suggested_answer_highest_apr: `最高年利率`,
  reyield_gpt_suggested_answer_maximum_loss: `最大損失`,
  reyield_gpt_tab_chat_history: `チャット履歴`,
  reyield_gpt_tab_reyield_gpt: `REYIELDGPT`,
  reyield_gpt_tailoring_investment_plan_for_you: `あなたのための投資計画をカスタマイズ中...`,
  reyield_gpt_view_detail_and_enable_portfolio: `詳細を表示してポートフォリオを有効にする`,
  reyield_gpt_view_detail_start_over: `最初から始める`,
  reyield_gpt_is_responding: `REYIELD GPT が応答中...`,
  reyield_gpt_input_the_answer_manually: `回答を手動で入力...`,
  nav_vertical_reyield_gpt: `REYIELDGPT`,
  nav_vertical_step_1: `ステップ1: アンケート`,
  nav_vertical_step_2: `ステップ2: AI 推奨ポートフォリオ`,
  nav_vertical_step_3: `ステップ3: ポートフォリオを有効にする`,
  nav_header_please_login_first: `まずログインしてください`,
  reyield_gpt_layout_nav_header_cta_mobile: `ホーム`,
  reyield_gpt_layout_nav_header_cta_desktop: `探索に戻る`,
  nav_header_usage_count_today_mobile: `使用`,
  nav_header_usage_count_today_desktop: `今日の使用回数`,
}

export default jp
