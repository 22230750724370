import { configureChains } from 'wagmi'
import { arbitrum, goerli, mainnet, optimism, optimismGoerli, polygon } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'

import { NEXT_PUBLIC_VERCEL_ENV } from '@/config'
import { NFT_MINT_INFO } from '@/nfts/config'

const chainsForDevelopment = [mainnet, polygon, optimism, arbitrum, goerli, optimismGoerli]

const { publicClient, chains } = configureChains(
  NFT_MINT_INFO.IS_MINT_TESTING
    ? chainsForDevelopment
    : NEXT_PUBLIC_VERCEL_ENV !== 'production'
    ? chainsForDevelopment
    : [mainnet, polygon, optimism, arbitrum],
  [publicProvider()]
)

export { chains, publicClient }
