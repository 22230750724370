import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Tooltip(theme: Theme) {
  const isLight = theme.palette.mode === 'light'

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.reyieldPalette.primary.dark,
        },
        arrow: {
          color: theme.reyieldPalette.primary.dark,
        },
      },
    },
  }
}
