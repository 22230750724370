import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql'
import { GraphqlContext } from '../pages/api/graphql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  jsonb: any
  timestamptz: any
  uuid: any
}

export type ApyHistory = {
  __typename?: 'ApyHistory'
  rate: Scalars['String']
  time: Scalars['String']
}

export enum ApyHistoryPeriod {
  D7 = 'd7',
  D30 = 'd30',
  H24 = 'h24',
}

export type Attributes = {
  __typename?: 'Attributes'
  traitType?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type Campaign = {
  __typename?: 'Campaign'
  createdAt?: Maybe<Scalars['String']>
  endTtime?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  type?: Maybe<CampaignType>
  updatedAt?: Maybe<Scalars['String']>
}

export type CampaignAirdrop = {
  __typename?: 'CampaignAirdrop'
  campaign: Campaign
  id?: Maybe<Scalars['String']>
  toolIds: Array<Scalars['String']>
  totalAirdropToken: Scalars['String']
  tvl: Scalars['String']
}

export type CampaignAirdropExplore = {
  __typename?: 'CampaignAirdropExplore'
  currentHourlyAirdropToken?: Maybe<Scalars['String']>
  id: Scalars['String']
  totalAirdropToken?: Maybe<Scalars['String']>
  tvl?: Maybe<Scalars['String']>
}

export type CampaignAirdropTask = {
  __typename?: 'CampaignAirdropTask'
  endTime: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
  startTime: Scalars['String']
  tweetLink: Scalars['String']
  userTaskFinishedAt?: Maybe<Scalars['String']>
  userTaskUserId?: Maybe<Scalars['String']>
}

export enum CampaignType {
  Airdrop = 'airdrop',
  Unknown = 'unknown',
}

export type CefiDepositAddress = {
  __typename?: 'CefiDepositAddress'
  address: Scalars['String']
  chain: Chain
}

export type CefiDepositAddressInput = {
  chain: Chain
  currency: Scalars['String']
  exchange: Exchange
  wallet?: InputMaybe<WalletType>
}

export type CefiWallet = {
  __typename?: 'CefiWallet'
  exchange: Scalars['String']
  walletBalances: Array<WalletBalances>
}

export type CefiWalletFilterInput = {
  exchange: Exchange
  hideTinyUSDValue?: InputMaybe<Scalars['Int']>
}

export enum Chain {
  Bitcoin = 'Bitcoin',
  Ethereum = 'Ethereum',
  Polygon = 'Polygon',
  Tron = 'Tron',
}

export type CloseUniswapv3PositionInput = {
  positionId: Scalars['String']
  txHash: Scalars['String']
}

export type CoinPrice = {
  __typename?: 'CoinPrice'
  symbol: Scalars['String']
  usdtPrice: Scalars['String']
}

export type CreateAiCompletionRecordInput = {
  currentItems?: InputMaybe<Array<CreateAiCompletionRecordItemInput>>
  expectedReturn: Scalars['String']
  goal: Scalars['String']
  investmentAmount: Scalars['String']
  investmentPeriod: Scalars['String']
  items: Array<CreateAiCompletionRecordItemInput>
  language?: InputMaybe<Scalars['String']>
  otherPreferenceDescription?: InputMaybe<Scalars['String']>
  promptVersion?: InputMaybe<PromptVersion>
  riskToleranceLevel: RiskToleranceLevelName
}

export type CreateAiCompletionRecordItemInput = {
  desc: Scalars['String']
  highestAnnualReturn: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  performanceFee?: InputMaybe<Scalars['String']>
  sid: Scalars['String']
}

export type CreateUniswapv3PositionInput = {
  toolId: Scalars['String']
  txHash: Scalars['String']
}

export type CreateUniswapv3ToolInput = {
  chain: EvmChain
  description?: InputMaybe<Scalars['String']>
  feeTier: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  outOfLowerRange: OutOfRangeInfoInput
  outOfUpperRange: OutOfRangeInfoInput
  tickLowerDiff: Scalars['Float']
  tickUpperDiff: Scalars['Float']
  token0Address: Scalars['String']
  token1Address: Scalars['String']
}

export type Creator = {
  __typename?: 'Creator'
  address: Scalars['String']
  id: Scalars['String']
}

export type CurrencyAmount = {
  __typename?: 'CurrencyAmount'
  amount: Scalars['String']
  currency: Scalars['String']
}

export type CurrencyBalance = {
  __typename?: 'CurrencyBalance'
  available: Scalars['String']
  total: Scalars['String']
}

export type CurrencyBalances = {
  __typename?: 'CurrencyBalances'
  balance: CurrencyBalance
  currency: Scalars['String']
}

export type CurrencyInfo = {
  __typename?: 'CurrencyInfo'
  decimals: Scalars['Int']
  name: Scalars['String']
  symbol: Scalars['String']
}

export type DefiTokensBalanceAmountInput = {
  chain: EvmChain
  chainId: Scalars['Int']
  tokenAddresses: Array<Scalars['String']>
}

export type DefiWallet = {
  __typename?: 'DefiWallet'
  balance: Scalars['String']
  balanceComputed?: Maybe<Scalars['String']>
  balanceComputedWithUnit?: Maybe<Scalars['String']>
  chain: EvmChain
  currencyInfo: CurrencyInfo
  id: Scalars['String']
}

export type DefiWalletFilterInput = {
  address: Scalars['String']
}

export type DefiWalletsFilterInput = {
  chains?: InputMaybe<Array<EvmChain>>
}

export type DelistUserOwnedUniswapv3ToolInput = {
  toolId: Scalars['String']
}

export enum EvmChain {
  Arbitrum = 'Arbitrum',
  Ethereum = 'Ethereum',
  Goerli = 'Goerli',
  Optimism = 'Optimism',
  OptimismGoerli = 'OptimismGoerli',
  Polygon = 'Polygon',
  Unknown = 'Unknown',
}

export enum Exchange {
  Bitfinex = 'Bitfinex',
  Tradingview = 'Tradingview',
  Uniswap = 'Uniswap',
  Unknown = 'Unknown',
}

export type ExternalApiKey = {
  __typename?: 'ExternalAPIKey'
  createdAt?: Maybe<Scalars['String']>
  exchange: Exchange
  id: Scalars['ID']
  isRevoked: Scalars['Boolean']
  key: Scalars['String']
  name: Scalars['String']
  user?: Maybe<UserInfo>
  userId?: Maybe<Scalars['String']>
}

export type ExternalApiKeyCreateInput = {
  exchange: Exchange
  key: Scalars['String']
  name: Scalars['String']
  secret: Scalars['String']
}

export type ExternalApiKeyDeleteInput = {
  id: Scalars['String']
}

export type ExternalApiKeysFilter = {
  exchange?: InputMaybe<Exchange>
  isRevoked?: InputMaybe<Scalars['Boolean']>
}

export type FrontendVersion = {
  __typename?: 'FrontendVersion'
  commitHash: Scalars['String']
  smartContracts: Array<SmartContract>
  version: Scalars['String']
}

export type GimCoolPayPaymentInfo = {
  __typename?: 'GimCoolPayPaymentInfo'
  blockchain: GimCoolPayPaymentSupportingBlockchain
  coinAmount?: Maybe<Scalars['String']>
  coinType: GimCoolPayPaymentSupportingCoinType
  fiatAmount?: Maybe<Scalars['String']>
  fiatType: GimCoolPayPaymentSupportingFiatType
  paymentHref: Scalars['String']
  price?: Maybe<GimCoolPayPriceResponse>
  walletAddress: Scalars['String']
  walletAddressTag?: Maybe<Scalars['String']>
}

export type GimCoolPayPaymentInfoInput = {
  blockchain: GimCoolPayPaymentSupportingBlockchain
  coinAmount: Scalars['String']
  coinType: GimCoolPayPaymentSupportingCoinType
  fiatType: GimCoolPayPaymentSupportingFiatType
  isSandBox?: InputMaybe<Scalars['Boolean']>
}

export enum GimCoolPayPaymentSupportingBlockchain {
  Eth = 'ETH',
  Unknown = 'Unknown',
}

export enum GimCoolPayPaymentSupportingCoinType {
  Eth = 'ETH',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Unknown = 'Unknown',
}

export enum GimCoolPayPaymentSupportingFiatType {
  Usd = 'USD',
  Unknown = 'Unknown',
}

export type GimCoolPayPriceResponse = {
  __typename?: 'GimCoolPayPriceResponse'
  prices?: Maybe<Array<GimCoolPayReadyToPurchasePrice>>
  spotPrice: Scalars['String']
}

export type GimCoolPayReadyToPurchasePrice = {
  __typename?: 'GimCoolPayReadyToPurchasePrice'
  coinAmount: Scalars['String']
  coinCode: Scalars['String']
  feeAmount: Scalars['String']
  fiatAmount: Scalars['String']
  fiatCode: Scalars['String']
  networkFee: Scalars['String']
  paymentMethodId: Scalars['String']
  spotPriceFee: Scalars['String']
  spotPriceIncludingFee: Scalars['String']
  type: Scalars['String']
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>
  _gt?: InputMaybe<Scalars['Int']>
  _gte?: InputMaybe<Scalars['Int']>
  _in?: InputMaybe<Array<Scalars['Int']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Int']>
  _lte?: InputMaybe<Scalars['Int']>
  _neq?: InputMaybe<Scalars['Int']>
  _nin?: InputMaybe<Array<Scalars['Int']>>
}

export type ListUserOwnedUniswapv3ToolInput = {
  toolId: Scalars['String']
  txHash: Scalars['String']
}

export type Metric = {
  __typename?: 'Metric'
  createdAt: Scalars['String']
  id: Scalars['String']
  positionManagersInfo?: Maybe<Array<PositionManagerInfo>>
  positions?: Maybe<Array<MetricPosition>>
}

export type MetricPosition = {
  __typename?: 'MetricPosition'
  amountUsd: Scalars['String']
  chain: EvmChain
  createdAt: Scalars['String']
  feeUsd: Scalars['String']
  id: Scalars['String']
  positionId: Scalars['Int']
  positionIdTotalCount: Scalars['Int']
  positionIsRunning: Scalars['Boolean']
  positionManagerContractAddress: Scalars['String']
  positionOwnerAddress: Scalars['String']
  strategyId: Scalars['String']
  strategyProvider: Scalars['String']
  sumUsd: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  closeUniswapv3Position?: Maybe<Scalars['String']>
  createAICompletionRecord: AICompletionRecords
  /** Reyield Backend will monitor this position with the txHash (either pending or confirmed) */
  createUniswapv3Position?: Maybe<Scalars['String']>
  createUniswapv3Tool?: Maybe<Scalars['String']>
  delistUserOwnedUniswapv3Tool?: Maybe<Scalars['String']>
  externalAPIKeyCreate: ExternalApiKey
  externalAPIKeyDelete: ExternalApiKey
  generateMetrics: Scalars['Boolean']
  listUserOwnedUniswapv3Tool?: Maybe<Scalars['String']>
  refreshUserUniswapV3Tool?: Maybe<UserUniswapPositionInfo>
  strategyStart: StrategyStartResponse
  strategyStop: StrategyStopResponse
  strategyUpdate: StrategyUpdateResponse
  updateUserOwnedUniswapv3ToolsInfo?: Maybe<Scalars['String']>
}

export type MutationCloseUniswapv3PositionArgs = {
  input: CloseUniswapv3PositionInput
}

export type MutationCreateAiCompletionRecordArgs = {
  input: CreateAiCompletionRecordInput
}

export type MutationCreateUniswapv3PositionArgs = {
  input: CreateUniswapv3PositionInput
}

export type MutationCreateUniswapv3ToolArgs = {
  input: CreateUniswapv3ToolInput
}

export type MutationDelistUserOwnedUniswapv3ToolArgs = {
  input: DelistUserOwnedUniswapv3ToolInput
}

export type MutationExternalApiKeyCreateArgs = {
  input: ExternalApiKeyCreateInput
}

export type MutationExternalApiKeyDeleteArgs = {
  input: ExternalApiKeyDeleteInput
}

export type MutationListUserOwnedUniswapv3ToolArgs = {
  input: ListUserOwnedUniswapv3ToolInput
}

export type MutationRefreshUserUniswapV3ToolArgs = {
  input: RefreshUniswapv3ToolInput
}

export type MutationStrategyStartArgs = {
  input: StrategyStartInput
}

export type MutationStrategyStopArgs = {
  input: StrategyStopInput
}

export type MutationStrategyUpdateArgs = {
  input: StrategyUpdateInput
}

export type MutationUpdateUserOwnedUniswapv3ToolsInfoArgs = {
  input: UpdateUserOwnedUniswapv3ToolsInfoInput
}

export type NftCollections = {
  __typename?: 'NFTCollections'
  attributes?: Maybe<Array<Maybe<Attributes>>>
  contract: Scalars['String']
  description?: Maybe<Scalars['String']>
  imageUrl: Scalars['String']
  name?: Maybe<Scalars['String']>
  tokenId: Scalars['String']
}

export type NftMerkleTreeHexProof = {
  __typename?: 'NFTMerkleTreeHexProof'
  hexProof?: Maybe<Array<Scalars['String']>>
}

export type NftWhitelistInfoByPhrase = {
  __typename?: 'NFTWhitelistInfoByPhrase'
  id: Scalars['String']
  isWhitelisted: Scalars['Boolean']
  phrase: Nft_Round
  userAddress: Scalars['String']
}

export enum Nft_Round {
  Airdrop = 'AIRDROP',
  AllowWhitelistMint = 'ALLOW_WHITELIST_MINT',
  GuaranteeWhitelistMint = 'GUARANTEE_WHITELIST_MINT',
  SoldOut = 'SOLD_OUT',
  WhitelistCheck = 'WHITELIST_CHECK',
}

export type OutOfRangeInfoInput = {
  earnSpendRate: Scalars['Int']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export type PaginationInput = {
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export enum PoolsWithArgumentsFlag {
  /** This will use default value to filter */
  Default = 'Default',
  /** This will include selected Tokens (Ex: BTC, ETH ...) */
  Generic = 'Generic',
  /** This will include stable tokens (Ex: USDT, USDC ...) */
  Stables = 'Stables',
}

export type PositionManagerInfo = {
  __typename?: 'PositionManagerInfo'
  chain: EvmChain
  count: Scalars['Int']
  id: Scalars['String']
}

export enum PromptVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
  V4 = 'V4',
}

export enum PublicUniswapV3ToolStatus {
  Deprecated = 'deprecated',
  Listed = 'listed',
  Unknown = 'unknown',
}

export type Query = {
  __typename?: 'Query'
  campaignAirdrop?: Maybe<CampaignAirdrop>
  campaignAirdropExplore: CampaignAirdropExplore
  campaignAirdropTools?: Maybe<Array<UniswapToolInfo>>
  coinPrices?: Maybe<Array<CoinPrice>>
  me: UserInfo
  metric?: Maybe<Array<Metric>>
  poolsWithArguments?: Maybe<Array<UniswapPoolInfo>>
  rateHistorical?: Maybe<Array<RateHistorical>>
  strategies: Array<StrategyInfo>
  tools: Array<ToolInfo>
  toolsSupportingTokens?: Maybe<Array<TokenDisplayInfo>>
  uniswapQuote?: Maybe<UniswapQuote>
  uniswapTokens?: Maybe<Array<UniswapToolToken>>
  uniswapV3Backtest?: Maybe<UniswapV3BacktestResult>
  version: FrontendVersion
  wallet?: Maybe<DefiWallet>
}

export type QueryCampaignAirdropExploreArgs = {
  campaignId: Scalars['String']
}

export type QueryCampaignAirdropToolsArgs = {
  filter?: InputMaybe<ToolFilterInput>
}

export type QueryPoolsWithArgumentsArgs = {
  chain: EvmChain
  first?: InputMaybe<Scalars['Int']>
  flag?: InputMaybe<PoolsWithArgumentsFlag>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: InputMaybe<Scalars['String']>
  poolIds?: InputMaybe<Array<Scalars['String']>>
  skip?: InputMaybe<Scalars['Int']>
  token0s?: InputMaybe<Array<Scalars['String']>>
  token1s?: InputMaybe<Array<Scalars['String']>>
}

export type QueryRateHistoricalArgs = {
  filter: RateHistoricalFilterInput
}

export type QueryStrategiesArgs = {
  filter?: InputMaybe<StrategyFilterInput>
}

export type QueryToolsArgs = {
  filter?: InputMaybe<ToolFilterInput>
}

export type QueryToolsSupportingTokensArgs = {
  input?: InputMaybe<ToolsSupportingTokensInput>
}

export type QueryUniswapQuoteArgs = {
  input: UniswapQuoteInput
  source?: InputMaybe<QuoteDataSource>
}

export type QueryUniswapTokensArgs = {
  chain: EvmChain
}

export type QueryUniswapV3BacktestArgs = {
  compound: Scalars['Boolean']
  days: Scalars['Int']
  diff: Scalars['Float']
  investmentAmount: Scalars['Float']
  period: UniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: EvmChain
}

export type QueryWalletArgs = {
  input?: InputMaybe<DefiWalletFilterInput>
}

export enum QuoteDataSource {
  Blockchain = 'blockchain',
  Thegraph = 'thegraph',
}

export type RateHistorical = {
  __typename?: 'RateHistorical'
  rate: Scalars['String']
  time: Scalars['String']
}

export type RateHistoricalFilterInput = {
  period: Scalars['String']
  sid?: InputMaybe<Scalars['String']>
}

export enum RecordsType {
  Revenue = 'revenue',
  Unknown = 'unknown',
}

export type RefreshUniswapv3ToolInput = {
  positionId: Scalars['String']
}

export type ReyieldLicense = {
  __typename?: 'ReyieldLicense'
  numberOfLicense: Scalars['Float']
  numberOfUsedLicense: Scalars['Float']
}

export enum RiskLevel {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  MediumHigh = 'medium_high',
  MediumLow = 'medium_low',
  Unknown = 'unknown',
}

export enum RiskToleranceLevelName {
  Aggressive = 'Aggressive',
  Conservative = 'Conservative',
  ExtremelyAggressive = 'ExtremelyAggressive',
  ExtremelyConservative = 'ExtremelyConservative',
  Moderate = 'Moderate',
  VeryConservative = 'VeryConservative',
}

export type SmartContract = {
  __typename?: 'SmartContract'
  address: Scalars['String']
  chain: EvmChain
  name: Scalars['String']
}

export type StrategyFilterInput = {
  riskLevel?: InputMaybe<RiskLevel>
  sid?: InputMaybe<Scalars['String']>
  type?: InputMaybe<StrategyType>
}

export type StrategyInfo = {
  __typename?: 'StrategyInfo'
  currency: Scalars['String']
  description: Scalars['String']
  earningCurrency: Scalars['String']
  exchanges: Array<Exchange>
  expectedAPY: Scalars['String']
  id: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  parameters: Array<StrategyParameters>
  riskLevel: RiskLevel
  sid: Scalars['String']
  stage: StrategyInfoStage
  suggestionPeriod: Scalars['String']
  target: Scalars['String']
  type: StrategyType
}

export enum StrategyInfoStage {
  Active = 'Active',
  Beta = 'Beta',
  Mock = 'Mock',
}

export type StrategyInfos = {
  __typename?: 'StrategyInfos'
  currency: Scalars['String']
  earningCurrency: Scalars['String']
  exchange?: Maybe<Exchange>
  minEnableAmount: Scalars['String']
  name: Scalars['String']
}

export enum StrategyParameterType {
  Bool = 'bool',
  Num = 'num',
  Str = 'str',
  Unknown = 'unknown',
}

export type StrategyParameters = {
  __typename?: 'StrategyParameters'
  defaultValue?: Maybe<Scalars['String']>
  description: Scalars['String']
  key: Scalars['String']
  maxValue?: Maybe<Scalars['String']>
  minValue?: Maybe<Scalars['String']>
  name: Scalars['String']
  type: StrategyParameterType
  updatable: Scalars['Boolean']
}

export type StrategyPerformance = {
  __typename?: 'StrategyPerformance'
  amount: Scalars['String']
  apy: Scalars['String']
  currency: Scalars['String']
  ror: Scalars['String']
}

export type StrategyProgress = {
  __typename?: 'StrategyProgress'
  createdAt: Scalars['String']
  id: Scalars['ID']
  index: Scalars['Int']
  status: StrategyProgressStatus
  steps: Array<Maybe<StrategyProgressStep>>
  type: StrategyProgressType
  updatedAt: Scalars['String']
}

export enum StrategyProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type StrategyProgressStep = {
  __typename?: 'StrategyProgressStep'
  status: StrategyProgressStepStatus
  type: StrategyProgressStepType
}

export enum StrategyProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum StrategyProgressStepType {
  CexDepositCheck = 'cex_deposit_check',
  CexSwap = 'cex_swap',
  CexWalletsTransfer = 'cex_wallets_transfer',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export enum StrategyProgressType {
  StrategyFinalize = 'strategy_finalize',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export type StrategyStartInput = {
  parameters: Array<StrategyStartParamenters>
  sid: Scalars['String']
}

export type StrategyStartParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type StrategyStartResponse = {
  __typename?: 'StrategyStartResponse'
  strategy: UserStrategyInfo
}

export enum StrategyStatus {
  Error = 'error',
  Preparing = 'preparing',
  Running = 'running',
  Stopped = 'stopped',
  Stopping = 'stopping',
  Unknown = 'unknown',
  WaitForUserAction = 'wait_for_user_action',
}

export type StrategyStopInput = {
  isForce?: InputMaybe<Scalars['Boolean']>
  strategyID: Scalars['String']
}

export type StrategyStopResponse = {
  __typename?: 'StrategyStopResponse'
  strategy: UserStrategyInfo
}

export enum StrategyType {
  Arbitrage = 'arbitrage',
  Defi = 'defi',
  Hedging = 'hedging',
  Lending = 'lending',
  Unknown = 'unknown',
}

export type StrategyUpdateInput = {
  parameters: Array<StrategyUpdateParamenters>
  strategyID: Scalars['String']
}

export type StrategyUpdateParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type StrategyUpdateResponse = {
  __typename?: 'StrategyUpdateResponse'
  strategy: UserStrategyInfo
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

export type TokenBalance = {
  __typename?: 'TokenBalance'
  address: Scalars['String']
  amount: Scalars['String']
  amountReadable: Scalars['String']
  chain: EvmChain
  decimals: Scalars['Int']
  id: Scalars['String']
  symbol: Scalars['String']
}

export type TokenDisplayInfo = {
  __typename?: 'TokenDisplayInfo'
  address: Scalars['String']
  chain: EvmChain
  id: Scalars['String']
  imageURL: Scalars['String']
  name: Scalars['String']
  symbol: Scalars['String']
  usdtPrice?: Maybe<Scalars['String']>
}

export type ToolFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']>>
}

export type ToolInfo = StrategyInfo | UniswapToolInfo

export type ToolsSupportingTokensInput = {
  chains?: InputMaybe<Array<EvmChain>>
}

export type TotalProfits = {
  __typename?: 'TotalProfits'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
}

export type UniswapOnchainPositionAmountInfo = {
  __typename?: 'UniswapOnchainPositionAmountInfo'
  /** this data is from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Amount: Scalars['String']
  token0AmountUsdValue: Scalars['String']
  /** this data is from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Amount: Scalars['String']
  token1AmountUsdValue: Scalars['String']
}

export type UniswapPoolInfo = {
  __typename?: 'UniswapPoolInfo'
  averageTradingVolumeUSD?: Maybe<Scalars['String']>
  chain: EvmChain
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  collectedFeesUSD: Scalars['String']
  createdAtBlockNumber: Scalars['String']
  createdAtTimestamp: Scalars['String']
  feeGrowthGlobal0X128: Scalars['String']
  feeGrowthGlobal1X128: Scalars['String']
  feeTier: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  /** this part is from smart contract developed by reyield */
  isPoolActiveForReyield?: Maybe<Scalars['Boolean']>
  liquidity: Scalars['String']
  liquidityProviderCount: Scalars['String']
  observationIndex: Scalars['String']
  positions?: Maybe<Array<UniswapPoolPosition>>
  sqrtPrice: Scalars['String']
  tick?: Maybe<Scalars['String']>
  ticks?: Maybe<Array<UniswapPoolTick>>
  token0?: Maybe<UniswapToolToken>
  token0Price: Scalars['String']
  token1?: Maybe<UniswapToolToken>
  token1Price: Scalars['String']
  totalValueLockedETH: Scalars['String']
  totalValueLockedToken0: Scalars['String']
  totalValueLockedToken1: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volumeToken0: Scalars['String']
  volumeToken1: Scalars['String']
  volumeUSD: Scalars['String']
}

export type UniswapPoolInfoAverageTradingVolumeUsdArgs = {
  numberOfDays: Scalars['Int']
}

export type UniswapPoolPosition = {
  __typename?: 'UniswapPoolPosition'
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  depositedToken0: Scalars['String']
  depositedToken1: Scalars['String']
  feeGrowthInside0LastX128: Scalars['String']
  feeGrowthInside1LastX128: Scalars['String']
  id: Scalars['ID']
  liquidity: Scalars['String']
  tickLowerFeeGrowthOutside0X128: Scalars['String']
  tickLowerFeeGrowthOutside1X128: Scalars['String']
  tickLowerTickIdx: Scalars['String']
  tickUpperFeeGrowthOutside0X128: Scalars['String']
  tickUpperFeeGrowthOutside1X128: Scalars['String']
  tickUpperTickIdx: Scalars['String']
  transactionTimestamp: Scalars['String']
}

export type UniswapPoolTick = {
  __typename?: 'UniswapPoolTick'
  id: Scalars['String']
  liquidityNet: Scalars['String']
  price0: Scalars['String']
  price1: Scalars['String']
  tickIdx: Scalars['String']
}

export type UniswapQuote = {
  __typename?: 'UniswapQuote'
  chain: EvmChain
  fromToken: UniswapToolToken
  fromTokenAddress: Scalars['String']
  id: Scalars['String']
  inputAmount: Scalars['String']
  inputReadableAmount: Scalars['String']
  outputAmount: Scalars['String']
  outputReadableAmount: Scalars['String']
  toToken: UniswapToolToken
  toTokenAddress: Scalars['String']
}

export type UniswapQuoteInput = {
  chain: EvmChain
  fromTokenAddress: Scalars['String']
  inputReadableAmount: Scalars['String']
  toTokenAddress: Scalars['String']
}

export type UniswapToolFeeTier = {
  __typename?: 'UniswapToolFeeTier'
  feeForContract: Scalars['String']
  feeForDisplay: Scalars['String']
  id: Scalars['String']
}

export type UniswapToolInfo = {
  __typename?: 'UniswapToolInfo'
  apyHighest7d: Scalars['String']
  apyHighest24h: Scalars['String']
  apyHighest30d: Scalars['String']
  apyHighestCurrent: Scalars['String']
  apyHistory?: Maybe<Array<ApyHistory>>
  chain: EvmChain
  createdAt: Scalars['String']
  creator?: Maybe<Creator>
  creatorAddress: Scalars['String']
  creatorDescription?: Maybe<Scalars['String']>
  creatorId: Scalars['String']
  creatorName?: Maybe<Scalars['String']>
  description: Scalars['String']
  expectedAPY: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  performanceFee: Scalars['String']
  poolAddress: Scalars['String']
  /** this data is from thegraph */
  poolInfo?: Maybe<UniswapPoolInfo>
  riskLevel: RiskLevel
  status: PublicUniswapV3ToolStatus
  tags?: Maybe<Array<UniswapToolTag>>
  tickLowerDiff: Scalars['String']
  tickLowerDiffForContract: Scalars['String']
  tickUpperDiff: Scalars['String']
  tickUpperDiffForContract?: Maybe<Scalars['String']>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  tvlPool: Scalars['String']
  tvlTool: Scalars['String']
}

export type UniswapToolInfoApyHistoryArgs = {
  period: ApyHistoryPeriod
}

export enum UniswapToolPositionStatus {
  Closed = 'CLOSED',
  Closing = 'CLOSING',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Unknown = 'UNKNOWN',
}

export enum UniswapToolStatus {
  Created = 'CREATED',
  Deprecated = 'DEPRECATED',
  Listed = 'LISTED',
  Listing = 'LISTING',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
}

export type UniswapToolTag = {
  __typename?: 'UniswapToolTag'
  id: Scalars['String']
  name: Scalars['String']
  type: UniswapToolTagType
}

export enum UniswapToolTagType {
  Chain = 'chain',
  Custom = 'custom',
  IsOfficial = 'isOfficial',
  Uniswapv3 = 'uniswapv3',
  Unknown = 'unknown',
}

export type UniswapToolToken = {
  __typename?: 'UniswapToolToken'
  chain: EvmChain
  decimals: Scalars['String']
  derivedETH: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  poolCount: Scalars['String']
  priceChart?: Maybe<UniswapToolTokenPriceChart>
  symbol: Scalars['String']
  totalSupply: Scalars['String']
  totalValueLocked: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volume: Scalars['String']
  volumeUSD: Scalars['String']
}

export type UniswapToolTokenPriceChart = {
  __typename?: 'UniswapToolTokenPriceChart'
  currentPriceUSD?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  prices?: Maybe<Array<UniswapToolTokenPriceChartPrice>>
}

export type UniswapToolTokenPriceChartPrice = {
  __typename?: 'UniswapToolTokenPriceChartPrice'
  timestamp: Scalars['String']
  value: Scalars['String']
}

export enum UniswapV3BacktestPeriod {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type UniswapV3BacktestResult = {
  __typename?: 'UniswapV3BacktestResult'
  activeLiquidityPercent: Scalars['Float']
  annualReturn: Scalars['Float']
  compound: Scalars['Boolean']
  createdAt: Scalars['String']
  dailyReturn: Scalars['Float']
  dataPointsCount: Scalars['String']
  days: Scalars['Int']
  diff: Scalars['Float']
  fees?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  impermanentLoss: Scalars['Float']
  investmentAmount: Scalars['Float']
  maxDrawdown?: Maybe<Scalars['Float']>
  period: UniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: EvmChain
  totalReturn?: Maybe<Scalars['Float']>
  volatility?: Maybe<Scalars['Float']>
}

export type UpdateUserOwnedUniswapv3ToolsInfoInput = {
  description: Scalars['String']
  name: Scalars['String']
  toolId: Scalars['String']
}

export type UserAiCompletionRecordsFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserCampaignAirdropPortfolio = {
  __typename?: 'UserCampaignAirdropPortfolio'
  id: Scalars['String']
  tasks?: Maybe<Array<CampaignAirdropTask>>
  userTotalEarnedReyieldTokens?: Maybe<Scalars['String']>
  userTotalInvestingAmount?: Maybe<Scalars['String']>
  userTotalProfit?: Maybe<Scalars['String']>
}

export type UserInfo = {
  __typename?: 'UserInfo'
  aICompletionRecords?: Maybe<Array<AICompletionRecords>>
  avatar?: Maybe<Scalars['String']>
  campaignAirdropPortfolio?: Maybe<UserCampaignAirdropPortfolio>
  campaignAirdropPositions?: Maybe<Array<UserUniswapPositionInfo>>
  cefiDepositAddress?: Maybe<CefiDepositAddress>
  cefiWallets?: Maybe<Array<CefiWallet>>
  defiTokensBalanceAmount?: Maybe<Array<Maybe<TokenBalance>>>
  defiWallets?: Maybe<Array<DefiWallet>>
  externalAPIKeys?: Maybe<Array<ExternalApiKey>>
  gimCoolPayPaymentInfo?: Maybe<GimCoolPayPaymentInfo>
  id: Scalars['String']
  nftCollections?: Maybe<Array<NftCollections>>
  nftMerkleTreeHexProof?: Maybe<NftMerkleTreeHexProof>
  nftWhitelistInfoByPhrases?: Maybe<Array<NftWhitelistInfoByPhrase>>
  numOfListedTool?: Maybe<Scalars['Int']>
  numOfListingLicense?: Maybe<Scalars['Int']>
  ownedToolsSetting?: Maybe<Array<UserUniswapToolSettingInfo>>
  privilege?: Maybe<Scalars['Boolean']>
  reyieldLicense?: Maybe<ReyieldLicense>
  strategies?: Maybe<Array<UserStrategyInfo>>
  strategyPerformance?: Maybe<UserStrategyPerformance>
  strategyProfit?: Maybe<UserStrategyProfit>
  strategyProgress?: Maybe<StrategyProgress>
  strategyRecords?: Maybe<Array<UserStrategyRecord>>
  tools?: Maybe<Array<UserToolInfo>>
}

export type UserInfoAiCompletionRecordsArgs = {
  filter?: InputMaybe<UserAiCompletionRecordsFilterInput>
}

export type UserInfoCampaignAirdropPortfolioArgs = {
  campaignId: Scalars['String']
}

export type UserInfoCampaignAirdropPositionsArgs = {
  campaignId: Scalars['String']
}

export type UserInfoCefiDepositAddressArgs = {
  filter?: InputMaybe<CefiDepositAddressInput>
}

export type UserInfoCefiWalletsArgs = {
  filter?: InputMaybe<CefiWalletFilterInput>
}

export type UserInfoDefiTokensBalanceAmountArgs = {
  input: DefiTokensBalanceAmountInput
}

export type UserInfoDefiWalletsArgs = {
  filter?: InputMaybe<DefiWalletsFilterInput>
}

export type UserInfoExternalApiKeysArgs = {
  filter?: InputMaybe<ExternalApiKeysFilter>
}

export type UserInfoGimCoolPayPaymentInfoArgs = {
  input?: InputMaybe<GimCoolPayPaymentInfoInput>
}

export type UserInfoStrategiesArgs = {
  filter?: InputMaybe<UserStrategyFilterInput>
}

export type UserInfoStrategyPerformanceArgs = {
  strategyID: Scalars['String']
}

export type UserInfoStrategyProfitArgs = {
  filter?: InputMaybe<UserStrategyProfitFilterInput>
}

export type UserInfoStrategyProgressArgs = {
  strategyID: Scalars['String']
}

export type UserInfoStrategyRecordsArgs = {
  filter?: InputMaybe<UserStrategyRecordsFilterInput>
}

export type UserStrategyFilterInput = {
  sid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status?: InputMaybe<Array<InputMaybe<StrategyStatus>>>
  strategyID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UserStrategyInfo = {
  __typename?: 'UserStrategyInfo'
  ceFiStatus: StrategyStatus
  closedAt?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  errMsg?: Maybe<Scalars['String']>
  id: Scalars['String']
  infos?: Maybe<StrategyInfos>
  parameters?: Maybe<Array<UserStrategyParameters>>
  performance?: Maybe<UserStrategyPerformance>
  profits?: Maybe<UserStrategyProfit>
  sid: Scalars['String']
  updatedAt: Scalars['String']
}

export type UserStrategyParameters = {
  __typename?: 'UserStrategyParameters'
  key: Scalars['String']
  value: Scalars['String']
}

export type UserStrategyPerformance = {
  __typename?: 'UserStrategyPerformance'
  duration?: Maybe<Scalars['Int']>
  initialValue?: Maybe<Scalars['String']>
  performances?: Maybe<Array<Maybe<StrategyPerformance>>>
}

export type UserStrategyProfit = {
  __typename?: 'UserStrategyProfit'
  sid?: Maybe<Scalars['String']>
  totalProfit?: Maybe<Array<Maybe<TotalProfits>>>
  type?: Maybe<StrategyType>
}

export type UserStrategyProfitFilterInput = {
  sid: Array<InputMaybe<Scalars['String']>>
  type?: InputMaybe<Array<InputMaybe<StrategyType>>>
}

export type UserStrategyRecord = {
  __typename?: 'UserStrategyRecord'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  rate?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
  type?: Maybe<RecordsType>
}

export type UserStrategyRecordsFilterInput = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  strategyID: Scalars['String']
  type?: InputMaybe<RecordsType>
}

export type UserToolInfo = UserStrategyInfo | UserUniswapPositionInfo

export type UserUniswapOnchainPositionInfo = {
  __typename?: 'UserUniswapOnchainPositionInfo'
  apy: Scalars['String']
  chain: EvmChain
  closedPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  totalInvestAmountUsdValue?: Maybe<Scalars['String']>
}

export type UserUniswapPositionInfo = {
  __typename?: 'UserUniswapPositionInfo'
  apy: Scalars['String']
  campaignId?: Maybe<Scalars['String']>
  chain: EvmChain
  closedAt?: Maybe<Scalars['String']>
  closedPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  createdAt: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  /** backend will cache the data for n minutes */
  onchainPositionInfo?: Maybe<UserUniswapOnchainPositionInfo>
  poolAddress: Scalars['String']
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  progresses?: Maybe<Array<UserUniswapv3PositionProgress>>
  publicToolInfo?: Maybe<UniswapToolInfo>
  reyieldTokens?: Maybe<Scalars['String']>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  status: UniswapToolPositionStatus
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  token0TotalFeeUsdValue: Scalars['String']
  /** api call from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  token1TotalFeeUsdValue: Scalars['String']
  toolDescription: Scalars['String']
  toolId: Scalars['String']
  toolIsOfficial: Scalars['Boolean']
  toolName: Scalars['String']
  toolPerformanceFeeRatio?: Maybe<Scalars['String']>
  /** if the user is the creator of the tool, then the toolSetting will be returned */
  toolSetting?: Maybe<UserUniswapToolSettingInfo>
  totalInvestAmountUsdValue: Scalars['String']
  updatedAt: Scalars['String']
  userAddress: Scalars['String']
}

export type UserUniswapPositionInfoOnchainPositionInfoArgs = {
  withoutCache?: InputMaybe<Scalars['Boolean']>
}

export type UserUniswapToolOutOfRangeInfo = {
  __typename?: 'UserUniswapToolOutOfRangeInfo'
  earnSpendRate: Scalars['String']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export type UserUniswapToolSettingInfo = {
  __typename?: 'UserUniswapToolSettingInfo'
  chain: EvmChain
  closedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  description: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  name: Scalars['String']
  outOfLowerRange: UserUniswapToolOutOfRangeInfo
  outOfUpperRange: UserUniswapToolOutOfRangeInfo
  performanceFeeRatio?: Maybe<Scalars['String']>
  poolAddress: Scalars['String']
  /** User will have only one position for each tool */
  position?: Maybe<UserUniswapPositionInfo>
  publicToolInfo?: Maybe<UniswapToolInfo>
  status: UniswapToolStatus
  tags?: Maybe<Array<UniswapToolTag>>
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  /** api call from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  totalUserUsed: Scalars['Int']
  updatedAt?: Maybe<Scalars['String']>
}

export type UserUniswapv3PositionProgress = {
  __typename?: 'UserUniswapv3PositionProgress'
  createdAt: Scalars['String']
  id: Scalars['String']
  index: Scalars['Int']
  status: UserUniswapv3PositionProgressStatus
  steps: Array<UserUniswapv3PositionProgressStep>
  type: UserUniswapv3PositionProgressType
  updatedAt: Scalars['String']
}

export enum UserUniswapv3PositionProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type UserUniswapv3PositionProgressStep = {
  __typename?: 'UserUniswapv3PositionProgressStep'
  status: UserUniswapv3PositionProgressStepStatus
  type: UserUniswapv3PositionProgressStepType
}

export enum UserUniswapv3PositionProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum UserUniswapv3PositionProgressStepType {
  UniswapV3ClosePositionWatching = 'uniswap_v3_close_position_watching',
  UniswapV3CreateListedToolPositionWatching = 'uniswap_v3_create_listed_tool_position_watching',
  UniswapV3CreatePositionWatching = 'uniswap_v3_create_position_watching',
  UniswapV3ListToolWatching = 'uniswap_v3_list_tool_watching',
  Unknown = 'unknown',
}

export enum UserUniswapv3PositionProgressType {
  UniswapV3ClosePosition = 'uniswap_v3_close_position',
  UniswapV3CreateListedToolPosition = 'uniswap_v3_create_listed_tool_position',
  UniswapV3CreatePosition = 'uniswap_v3_create_position',
  UniswapV3ListTool = 'uniswap_v3_list_tool',
  Unknown = 'unknown',
}

export type WalletBalances = {
  __typename?: 'WalletBalances'
  currencyBalances: Array<CurrencyBalances>
  wallet: Scalars['String']
}

export enum WalletType {
  BfxExchange = 'bfx_exchange',
  BfxFunding = 'bfx_funding',
  BfxMargin = 'bfx_margin',
  Spot = 'spot',
  Unknown = 'unknown',
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecords = {
  __typename?: 'aICompletionRecords'
  completedAt?: Maybe<Scalars['timestamptz']>
  content?: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  id: Scalars['String']
  meta?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  promptMutationInput?: Maybe<Scalars['jsonb']>
  promptMutationName?: Maybe<Scalars['String']>
  /** An array relationship */
  suggestions: Array<SuggestStrategyToolWithAllocations>
  /** An aggregate relationship */
  suggestions_aggregate: SuggestStrategyToolWithAllocations_Aggregate
  updatedAt: Scalars['timestamptz']
  userAddress?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsMetaArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsPromptMutationInputArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsSuggestionsArgs = {
  distinct_on?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsSuggestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations = {
  __typename?: 'suggestStrategyToolWithAllocations'
  aICompletionRecordId: Scalars['String']
  allocation: Scalars['String']
  createdAt: Scalars['timestamptz']
  description: Scalars['String']
  highestAnnualReturn: Scalars['String']
  id: Scalars['uuid']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  sid: Scalars['String']
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt: Scalars['timestamptz']
}

/** aggregated selection of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate = {
  __typename?: 'suggestStrategyToolWithAllocations_aggregate'
  aggregate?: Maybe<SuggestStrategyToolWithAllocations_Aggregate_Fields>
  nodes: Array<SuggestStrategyToolWithAllocations>
}

export type SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp = {
  count?: InputMaybe<SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count>
}

export type SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<SuggestStrategyToolWithAllocations_Max_Fields>
  min?: Maybe<SuggestStrategyToolWithAllocations_Min_Fields>
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<SuggestStrategyToolWithAllocations_Max_Order_By>
  min?: InputMaybe<SuggestStrategyToolWithAllocations_Min_Order_By>
}

/** Boolean expression to filter rows from the table "suggestStrategyToolWithAllocations". All fields are combined with a logical 'AND'. */
export type SuggestStrategyToolWithAllocations_Bool_Exp = {
  _and?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Bool_Exp>>
  _not?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
  _or?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Bool_Exp>>
  aICompletionRecordId?: InputMaybe<String_Comparison_Exp>
  allocation?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  highestAnnualReturn?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  maximumLoss?: InputMaybe<String_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  sid?: InputMaybe<String_Comparison_Exp>
  systemInvestAmountSuggestion?: InputMaybe<String_Comparison_Exp>
  systemInvestCurrencySuggestion?: InputMaybe<String_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** aggregate max on columns */
export type SuggestStrategyToolWithAllocations_Max_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_max_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Max_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type SuggestStrategyToolWithAllocations_Min_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_min_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Min_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** Ordering options when selecting data from "suggestStrategyToolWithAllocations". */
export type SuggestStrategyToolWithAllocations_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** select columns of table "suggestStrategyToolWithAllocations" */
export enum SuggestStrategyToolWithAllocations_Select_Column {
  /** column name */
  AICompletionRecordId = 'aICompletionRecordId',
  /** column name */
  Allocation = 'allocation',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  HighestAnnualReturn = 'highestAnnualReturn',
  /** column name */
  Id = 'id',
  /** column name */
  MaximumLoss = 'maximumLoss',
  /** column name */
  Name = 'name',
  /** column name */
  Sid = 'sid',
  /** column name */
  SystemInvestAmountSuggestion = 'systemInvestAmountSuggestion',
  /** column name */
  SystemInvestCurrencySuggestion = 'systemInvestCurrencySuggestion',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Streaming cursor of the table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input = {
  aICompletionRecordId?: InputMaybe<Scalars['String']>
  allocation?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  highestAnnualReturn?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  maximumLoss?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  sid?: InputMaybe<Scalars['String']>
  systemInvestAmountSuggestion?: InputMaybe<Scalars['String']>
  systemInvestCurrencySuggestion?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping of union types */
export type ResolversUnionTypes = {
  ToolInfo: StrategyInfo | UniswapToolInfo
  UserToolInfo: UserStrategyInfo | UserUniswapPositionInfo
}

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ApyHistory: ResolverTypeWrapper<ApyHistory>
  ApyHistoryPeriod: ApyHistoryPeriod
  Attributes: ResolverTypeWrapper<Attributes>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  Campaign: ResolverTypeWrapper<Campaign>
  CampaignAirdrop: ResolverTypeWrapper<CampaignAirdrop>
  CampaignAirdropExplore: ResolverTypeWrapper<CampaignAirdropExplore>
  CampaignAirdropTask: ResolverTypeWrapper<CampaignAirdropTask>
  CampaignType: CampaignType
  CefiDepositAddress: ResolverTypeWrapper<CefiDepositAddress>
  CefiDepositAddressInput: CefiDepositAddressInput
  CefiWallet: ResolverTypeWrapper<CefiWallet>
  CefiWalletFilterInput: CefiWalletFilterInput
  Chain: Chain
  CloseUniswapv3PositionInput: CloseUniswapv3PositionInput
  CoinPrice: ResolverTypeWrapper<CoinPrice>
  CreateAICompletionRecordInput: CreateAiCompletionRecordInput
  CreateAICompletionRecordItemInput: CreateAiCompletionRecordItemInput
  CreateUniswapv3PositionInput: CreateUniswapv3PositionInput
  CreateUniswapv3ToolInput: CreateUniswapv3ToolInput
  Creator: ResolverTypeWrapper<Creator>
  CurrencyAmount: ResolverTypeWrapper<CurrencyAmount>
  CurrencyBalance: ResolverTypeWrapper<CurrencyBalance>
  CurrencyBalances: ResolverTypeWrapper<CurrencyBalances>
  CurrencyInfo: ResolverTypeWrapper<CurrencyInfo>
  DefiTokensBalanceAmountInput: DefiTokensBalanceAmountInput
  DefiWallet: ResolverTypeWrapper<DefiWallet>
  DefiWalletFilterInput: DefiWalletFilterInput
  DefiWalletsFilterInput: DefiWalletsFilterInput
  DelistUserOwnedUniswapv3ToolInput: DelistUserOwnedUniswapv3ToolInput
  EvmChain: EvmChain
  Exchange: Exchange
  ExternalAPIKey: ResolverTypeWrapper<ExternalApiKey>
  ExternalAPIKeyCreateInput: ExternalApiKeyCreateInput
  ExternalAPIKeyDeleteInput: ExternalApiKeyDeleteInput
  ExternalAPIKeysFilter: ExternalApiKeysFilter
  Float: ResolverTypeWrapper<Scalars['Float']>
  FrontendVersion: ResolverTypeWrapper<FrontendVersion>
  GimCoolPayPaymentInfo: ResolverTypeWrapper<GimCoolPayPaymentInfo>
  GimCoolPayPaymentInfoInput: GimCoolPayPaymentInfoInput
  GimCoolPayPaymentSupportingBlockchain: GimCoolPayPaymentSupportingBlockchain
  GimCoolPayPaymentSupportingCoinType: GimCoolPayPaymentSupportingCoinType
  GimCoolPayPaymentSupportingFiatType: GimCoolPayPaymentSupportingFiatType
  GimCoolPayPriceResponse: ResolverTypeWrapper<GimCoolPayPriceResponse>
  GimCoolPayReadyToPurchasePrice: ResolverTypeWrapper<GimCoolPayReadyToPurchasePrice>
  ID: ResolverTypeWrapper<Scalars['ID']>
  Int: ResolverTypeWrapper<Scalars['Int']>
  Int_comparison_exp: Int_Comparison_Exp
  ListUserOwnedUniswapv3ToolInput: ListUserOwnedUniswapv3ToolInput
  Metric: ResolverTypeWrapper<Metric>
  MetricPosition: ResolverTypeWrapper<MetricPosition>
  Mutation: ResolverTypeWrapper<{}>
  NFTCollections: ResolverTypeWrapper<NftCollections>
  NFTMerkleTreeHexProof: ResolverTypeWrapper<NftMerkleTreeHexProof>
  NFTWhitelistInfoByPhrase: ResolverTypeWrapper<NftWhitelistInfoByPhrase>
  NFT_ROUND: Nft_Round
  OutOfRangeInfoInput: OutOfRangeInfoInput
  PaginationInput: PaginationInput
  PoolsWithArgumentsFlag: PoolsWithArgumentsFlag
  PositionManagerInfo: ResolverTypeWrapper<PositionManagerInfo>
  PromptVersion: PromptVersion
  PublicUniswapV3ToolStatus: PublicUniswapV3ToolStatus
  Query: ResolverTypeWrapper<{}>
  QuoteDataSource: QuoteDataSource
  RateHistorical: ResolverTypeWrapper<RateHistorical>
  RateHistoricalFilterInput: RateHistoricalFilterInput
  RecordsType: RecordsType
  RefreshUniswapv3ToolInput: RefreshUniswapv3ToolInput
  ReyieldLicense: ResolverTypeWrapper<ReyieldLicense>
  RiskLevel: RiskLevel
  RiskToleranceLevelName: RiskToleranceLevelName
  SmartContract: ResolverTypeWrapper<SmartContract>
  StrategyFilterInput: StrategyFilterInput
  StrategyInfo: ResolverTypeWrapper<StrategyInfo>
  StrategyInfoStage: StrategyInfoStage
  StrategyInfos: ResolverTypeWrapper<StrategyInfos>
  StrategyParameterType: StrategyParameterType
  StrategyParameters: ResolverTypeWrapper<StrategyParameters>
  StrategyPerformance: ResolverTypeWrapper<StrategyPerformance>
  StrategyProgress: ResolverTypeWrapper<StrategyProgress>
  StrategyProgressStatus: StrategyProgressStatus
  StrategyProgressStep: ResolverTypeWrapper<StrategyProgressStep>
  StrategyProgressStepStatus: StrategyProgressStepStatus
  StrategyProgressStepType: StrategyProgressStepType
  StrategyProgressType: StrategyProgressType
  StrategyStartInput: StrategyStartInput
  StrategyStartParamenters: StrategyStartParamenters
  StrategyStartResponse: ResolverTypeWrapper<StrategyStartResponse>
  StrategyStatus: StrategyStatus
  StrategyStopInput: StrategyStopInput
  StrategyStopResponse: ResolverTypeWrapper<StrategyStopResponse>
  StrategyType: StrategyType
  StrategyUpdateInput: StrategyUpdateInput
  StrategyUpdateParamenters: StrategyUpdateParamenters
  StrategyUpdateResponse: ResolverTypeWrapper<StrategyUpdateResponse>
  String: ResolverTypeWrapper<Scalars['String']>
  String_comparison_exp: String_Comparison_Exp
  TokenBalance: ResolverTypeWrapper<TokenBalance>
  TokenDisplayInfo: ResolverTypeWrapper<TokenDisplayInfo>
  ToolFilterInput: ToolFilterInput
  ToolInfo: ResolverTypeWrapper<ResolversUnionTypes['ToolInfo']>
  ToolsSupportingTokensInput: ToolsSupportingTokensInput
  TotalProfits: ResolverTypeWrapper<TotalProfits>
  UniswapOnchainPositionAmountInfo: ResolverTypeWrapper<UniswapOnchainPositionAmountInfo>
  UniswapPoolInfo: ResolverTypeWrapper<UniswapPoolInfo>
  UniswapPoolPosition: ResolverTypeWrapper<UniswapPoolPosition>
  UniswapPoolTick: ResolverTypeWrapper<UniswapPoolTick>
  UniswapQuote: ResolverTypeWrapper<UniswapQuote>
  UniswapQuoteInput: UniswapQuoteInput
  UniswapToolFeeTier: ResolverTypeWrapper<UniswapToolFeeTier>
  UniswapToolInfo: ResolverTypeWrapper<UniswapToolInfo>
  UniswapToolPositionStatus: UniswapToolPositionStatus
  UniswapToolStatus: UniswapToolStatus
  UniswapToolTag: ResolverTypeWrapper<UniswapToolTag>
  UniswapToolTagType: UniswapToolTagType
  UniswapToolToken: ResolverTypeWrapper<UniswapToolToken>
  UniswapToolTokenPriceChart: ResolverTypeWrapper<UniswapToolTokenPriceChart>
  UniswapToolTokenPriceChartPrice: ResolverTypeWrapper<UniswapToolTokenPriceChartPrice>
  UniswapV3BacktestPeriod: UniswapV3BacktestPeriod
  UniswapV3BacktestResult: ResolverTypeWrapper<UniswapV3BacktestResult>
  UpdateUserOwnedUniswapv3ToolsInfoInput: UpdateUserOwnedUniswapv3ToolsInfoInput
  UserAICompletionRecordsFilterInput: UserAiCompletionRecordsFilterInput
  UserCampaignAirdropPortfolio: ResolverTypeWrapper<UserCampaignAirdropPortfolio>
  UserInfo: ResolverTypeWrapper<
    Omit<UserInfo, 'tools'> & { tools?: Maybe<Array<ResolversTypes['UserToolInfo']>> }
  >
  UserStrategyFilterInput: UserStrategyFilterInput
  UserStrategyInfo: ResolverTypeWrapper<UserStrategyInfo>
  UserStrategyParameters: ResolverTypeWrapper<UserStrategyParameters>
  UserStrategyPerformance: ResolverTypeWrapper<UserStrategyPerformance>
  UserStrategyProfit: ResolverTypeWrapper<UserStrategyProfit>
  UserStrategyProfitFilterInput: UserStrategyProfitFilterInput
  UserStrategyRecord: ResolverTypeWrapper<UserStrategyRecord>
  UserStrategyRecordsFilterInput: UserStrategyRecordsFilterInput
  UserToolInfo: ResolverTypeWrapper<ResolversUnionTypes['UserToolInfo']>
  UserUniswapOnchainPositionInfo: ResolverTypeWrapper<UserUniswapOnchainPositionInfo>
  UserUniswapPositionInfo: ResolverTypeWrapper<UserUniswapPositionInfo>
  UserUniswapToolOutOfRangeInfo: ResolverTypeWrapper<UserUniswapToolOutOfRangeInfo>
  UserUniswapToolSettingInfo: ResolverTypeWrapper<UserUniswapToolSettingInfo>
  UserUniswapv3PositionProgress: ResolverTypeWrapper<UserUniswapv3PositionProgress>
  UserUniswapv3PositionProgressStatus: UserUniswapv3PositionProgressStatus
  UserUniswapv3PositionProgressStep: ResolverTypeWrapper<UserUniswapv3PositionProgressStep>
  UserUniswapv3PositionProgressStepStatus: UserUniswapv3PositionProgressStepStatus
  UserUniswapv3PositionProgressStepType: UserUniswapv3PositionProgressStepType
  UserUniswapv3PositionProgressType: UserUniswapv3PositionProgressType
  WalletBalances: ResolverTypeWrapper<WalletBalances>
  WalletType: WalletType
  aICompletionRecords: ResolverTypeWrapper<AICompletionRecords>
  cursor_ordering: Cursor_Ordering
  jsonb: ResolverTypeWrapper<Scalars['jsonb']>
  order_by: Order_By
  suggestStrategyToolWithAllocations: ResolverTypeWrapper<SuggestStrategyToolWithAllocations>
  suggestStrategyToolWithAllocations_aggregate: ResolverTypeWrapper<SuggestStrategyToolWithAllocations_Aggregate>
  suggestStrategyToolWithAllocations_aggregate_bool_exp: SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp
  suggestStrategyToolWithAllocations_aggregate_bool_exp_count: SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count
  suggestStrategyToolWithAllocations_aggregate_fields: ResolverTypeWrapper<SuggestStrategyToolWithAllocations_Aggregate_Fields>
  suggestStrategyToolWithAllocations_aggregate_order_by: SuggestStrategyToolWithAllocations_Aggregate_Order_By
  suggestStrategyToolWithAllocations_bool_exp: SuggestStrategyToolWithAllocations_Bool_Exp
  suggestStrategyToolWithAllocations_max_fields: ResolverTypeWrapper<SuggestStrategyToolWithAllocations_Max_Fields>
  suggestStrategyToolWithAllocations_max_order_by: SuggestStrategyToolWithAllocations_Max_Order_By
  suggestStrategyToolWithAllocations_min_fields: ResolverTypeWrapper<SuggestStrategyToolWithAllocations_Min_Fields>
  suggestStrategyToolWithAllocations_min_order_by: SuggestStrategyToolWithAllocations_Min_Order_By
  suggestStrategyToolWithAllocations_order_by: SuggestStrategyToolWithAllocations_Order_By
  suggestStrategyToolWithAllocations_select_column: SuggestStrategyToolWithAllocations_Select_Column
  suggestStrategyToolWithAllocations_stream_cursor_input: SuggestStrategyToolWithAllocations_Stream_Cursor_Input
  suggestStrategyToolWithAllocations_stream_cursor_value_input: SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']>
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp
  uuid: ResolverTypeWrapper<Scalars['uuid']>
  uuid_comparison_exp: Uuid_Comparison_Exp
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ApyHistory: ApyHistory
  Attributes: Attributes
  Boolean: Scalars['Boolean']
  Campaign: Campaign
  CampaignAirdrop: CampaignAirdrop
  CampaignAirdropExplore: CampaignAirdropExplore
  CampaignAirdropTask: CampaignAirdropTask
  CefiDepositAddress: CefiDepositAddress
  CefiDepositAddressInput: CefiDepositAddressInput
  CefiWallet: CefiWallet
  CefiWalletFilterInput: CefiWalletFilterInput
  CloseUniswapv3PositionInput: CloseUniswapv3PositionInput
  CoinPrice: CoinPrice
  CreateAICompletionRecordInput: CreateAiCompletionRecordInput
  CreateAICompletionRecordItemInput: CreateAiCompletionRecordItemInput
  CreateUniswapv3PositionInput: CreateUniswapv3PositionInput
  CreateUniswapv3ToolInput: CreateUniswapv3ToolInput
  Creator: Creator
  CurrencyAmount: CurrencyAmount
  CurrencyBalance: CurrencyBalance
  CurrencyBalances: CurrencyBalances
  CurrencyInfo: CurrencyInfo
  DefiTokensBalanceAmountInput: DefiTokensBalanceAmountInput
  DefiWallet: DefiWallet
  DefiWalletFilterInput: DefiWalletFilterInput
  DefiWalletsFilterInput: DefiWalletsFilterInput
  DelistUserOwnedUniswapv3ToolInput: DelistUserOwnedUniswapv3ToolInput
  ExternalAPIKey: ExternalApiKey
  ExternalAPIKeyCreateInput: ExternalApiKeyCreateInput
  ExternalAPIKeyDeleteInput: ExternalApiKeyDeleteInput
  ExternalAPIKeysFilter: ExternalApiKeysFilter
  Float: Scalars['Float']
  FrontendVersion: FrontendVersion
  GimCoolPayPaymentInfo: GimCoolPayPaymentInfo
  GimCoolPayPaymentInfoInput: GimCoolPayPaymentInfoInput
  GimCoolPayPriceResponse: GimCoolPayPriceResponse
  GimCoolPayReadyToPurchasePrice: GimCoolPayReadyToPurchasePrice
  ID: Scalars['ID']
  Int: Scalars['Int']
  Int_comparison_exp: Int_Comparison_Exp
  ListUserOwnedUniswapv3ToolInput: ListUserOwnedUniswapv3ToolInput
  Metric: Metric
  MetricPosition: MetricPosition
  Mutation: {}
  NFTCollections: NftCollections
  NFTMerkleTreeHexProof: NftMerkleTreeHexProof
  NFTWhitelistInfoByPhrase: NftWhitelistInfoByPhrase
  OutOfRangeInfoInput: OutOfRangeInfoInput
  PaginationInput: PaginationInput
  PositionManagerInfo: PositionManagerInfo
  Query: {}
  RateHistorical: RateHistorical
  RateHistoricalFilterInput: RateHistoricalFilterInput
  RefreshUniswapv3ToolInput: RefreshUniswapv3ToolInput
  ReyieldLicense: ReyieldLicense
  SmartContract: SmartContract
  StrategyFilterInput: StrategyFilterInput
  StrategyInfo: StrategyInfo
  StrategyInfos: StrategyInfos
  StrategyParameters: StrategyParameters
  StrategyPerformance: StrategyPerformance
  StrategyProgress: StrategyProgress
  StrategyProgressStep: StrategyProgressStep
  StrategyStartInput: StrategyStartInput
  StrategyStartParamenters: StrategyStartParamenters
  StrategyStartResponse: StrategyStartResponse
  StrategyStopInput: StrategyStopInput
  StrategyStopResponse: StrategyStopResponse
  StrategyUpdateInput: StrategyUpdateInput
  StrategyUpdateParamenters: StrategyUpdateParamenters
  StrategyUpdateResponse: StrategyUpdateResponse
  String: Scalars['String']
  String_comparison_exp: String_Comparison_Exp
  TokenBalance: TokenBalance
  TokenDisplayInfo: TokenDisplayInfo
  ToolFilterInput: ToolFilterInput
  ToolInfo: ResolversUnionTypes['ToolInfo']
  ToolsSupportingTokensInput: ToolsSupportingTokensInput
  TotalProfits: TotalProfits
  UniswapOnchainPositionAmountInfo: UniswapOnchainPositionAmountInfo
  UniswapPoolInfo: UniswapPoolInfo
  UniswapPoolPosition: UniswapPoolPosition
  UniswapPoolTick: UniswapPoolTick
  UniswapQuote: UniswapQuote
  UniswapQuoteInput: UniswapQuoteInput
  UniswapToolFeeTier: UniswapToolFeeTier
  UniswapToolInfo: UniswapToolInfo
  UniswapToolTag: UniswapToolTag
  UniswapToolToken: UniswapToolToken
  UniswapToolTokenPriceChart: UniswapToolTokenPriceChart
  UniswapToolTokenPriceChartPrice: UniswapToolTokenPriceChartPrice
  UniswapV3BacktestResult: UniswapV3BacktestResult
  UpdateUserOwnedUniswapv3ToolsInfoInput: UpdateUserOwnedUniswapv3ToolsInfoInput
  UserAICompletionRecordsFilterInput: UserAiCompletionRecordsFilterInput
  UserCampaignAirdropPortfolio: UserCampaignAirdropPortfolio
  UserInfo: Omit<UserInfo, 'tools'> & { tools?: Maybe<Array<ResolversParentTypes['UserToolInfo']>> }
  UserStrategyFilterInput: UserStrategyFilterInput
  UserStrategyInfo: UserStrategyInfo
  UserStrategyParameters: UserStrategyParameters
  UserStrategyPerformance: UserStrategyPerformance
  UserStrategyProfit: UserStrategyProfit
  UserStrategyProfitFilterInput: UserStrategyProfitFilterInput
  UserStrategyRecord: UserStrategyRecord
  UserStrategyRecordsFilterInput: UserStrategyRecordsFilterInput
  UserToolInfo: ResolversUnionTypes['UserToolInfo']
  UserUniswapOnchainPositionInfo: UserUniswapOnchainPositionInfo
  UserUniswapPositionInfo: UserUniswapPositionInfo
  UserUniswapToolOutOfRangeInfo: UserUniswapToolOutOfRangeInfo
  UserUniswapToolSettingInfo: UserUniswapToolSettingInfo
  UserUniswapv3PositionProgress: UserUniswapv3PositionProgress
  UserUniswapv3PositionProgressStep: UserUniswapv3PositionProgressStep
  WalletBalances: WalletBalances
  aICompletionRecords: AICompletionRecords
  jsonb: Scalars['jsonb']
  suggestStrategyToolWithAllocations: SuggestStrategyToolWithAllocations
  suggestStrategyToolWithAllocations_aggregate: SuggestStrategyToolWithAllocations_Aggregate
  suggestStrategyToolWithAllocations_aggregate_bool_exp: SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp
  suggestStrategyToolWithAllocations_aggregate_bool_exp_count: SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count
  suggestStrategyToolWithAllocations_aggregate_fields: SuggestStrategyToolWithAllocations_Aggregate_Fields
  suggestStrategyToolWithAllocations_aggregate_order_by: SuggestStrategyToolWithAllocations_Aggregate_Order_By
  suggestStrategyToolWithAllocations_bool_exp: SuggestStrategyToolWithAllocations_Bool_Exp
  suggestStrategyToolWithAllocations_max_fields: SuggestStrategyToolWithAllocations_Max_Fields
  suggestStrategyToolWithAllocations_max_order_by: SuggestStrategyToolWithAllocations_Max_Order_By
  suggestStrategyToolWithAllocations_min_fields: SuggestStrategyToolWithAllocations_Min_Fields
  suggestStrategyToolWithAllocations_min_order_by: SuggestStrategyToolWithAllocations_Min_Order_By
  suggestStrategyToolWithAllocations_order_by: SuggestStrategyToolWithAllocations_Order_By
  suggestStrategyToolWithAllocations_stream_cursor_input: SuggestStrategyToolWithAllocations_Stream_Cursor_Input
  suggestStrategyToolWithAllocations_stream_cursor_value_input: SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input
  timestamptz: Scalars['timestamptz']
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp
  uuid: Scalars['uuid']
  uuid_comparison_exp: Uuid_Comparison_Exp
}

export type ApyHistoryResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ApyHistory'] = ResolversParentTypes['ApyHistory']
> = {
  rate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  time?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AttributesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Attributes'] = ResolversParentTypes['Attributes']
> = {
  traitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CampaignResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Campaign'] = ResolversParentTypes['Campaign']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  endTtime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isEnable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['CampaignType']>, ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CampaignAirdropResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CampaignAirdrop'] = ResolversParentTypes['CampaignAirdrop']
> = {
  campaign?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  toolIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  totalAirdropToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tvl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CampaignAirdropExploreResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CampaignAirdropExplore'] = ResolversParentTypes['CampaignAirdropExplore']
> = {
  currentHourlyAirdropToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalAirdropToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  tvl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CampaignAirdropTaskResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CampaignAirdropTask'] = ResolversParentTypes['CampaignAirdropTask']
> = {
  endTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tweetLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userTaskFinishedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userTaskUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CefiDepositAddressResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CefiDepositAddress'] = ResolversParentTypes['CefiDepositAddress']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['Chain'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CefiWalletResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CefiWallet'] = ResolversParentTypes['CefiWallet']
> = {
  exchange?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  walletBalances?: Resolver<Array<ResolversTypes['WalletBalances']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CoinPriceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CoinPrice'] = ResolversParentTypes['CoinPrice']
> = {
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  usdtPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreatorResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Creator'] = ResolversParentTypes['Creator']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CurrencyAmountResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CurrencyAmount'] = ResolversParentTypes['CurrencyAmount']
> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CurrencyBalanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CurrencyBalance'] = ResolversParentTypes['CurrencyBalance']
> = {
  available?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CurrencyBalancesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CurrencyBalances'] = ResolversParentTypes['CurrencyBalances']
> = {
  balance?: Resolver<ResolversTypes['CurrencyBalance'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CurrencyInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CurrencyInfo'] = ResolversParentTypes['CurrencyInfo']
> = {
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DefiWalletResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['DefiWallet'] = ResolversParentTypes['DefiWallet']
> = {
  balance?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  balanceComputed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  balanceComputedWithUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  currencyInfo?: Resolver<ResolversTypes['CurrencyInfo'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExternalApiKeyResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ExternalAPIKey'] = ResolversParentTypes['ExternalAPIKey']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  exchange?: Resolver<ResolversTypes['Exchange'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isRevoked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['UserInfo']>, ParentType, ContextType>
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FrontendVersionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['FrontendVersion'] = ResolversParentTypes['FrontendVersion']
> = {
  commitHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  smartContracts?: Resolver<Array<ResolversTypes['SmartContract']>, ParentType, ContextType>
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GimCoolPayPaymentInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['GimCoolPayPaymentInfo'] = ResolversParentTypes['GimCoolPayPaymentInfo']
> = {
  blockchain?: Resolver<
    ResolversTypes['GimCoolPayPaymentSupportingBlockchain'],
    ParentType,
    ContextType
  >
  coinAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  coinType?: Resolver<
    ResolversTypes['GimCoolPayPaymentSupportingCoinType'],
    ParentType,
    ContextType
  >
  fiatAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fiatType?: Resolver<
    ResolversTypes['GimCoolPayPaymentSupportingFiatType'],
    ParentType,
    ContextType
  >
  paymentHref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price?: Resolver<Maybe<ResolversTypes['GimCoolPayPriceResponse']>, ParentType, ContextType>
  walletAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  walletAddressTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GimCoolPayPriceResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['GimCoolPayPriceResponse'] = ResolversParentTypes['GimCoolPayPriceResponse']
> = {
  prices?: Resolver<
    Maybe<Array<ResolversTypes['GimCoolPayReadyToPurchasePrice']>>,
    ParentType,
    ContextType
  >
  spotPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GimCoolPayReadyToPurchasePriceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['GimCoolPayReadyToPurchasePrice'] = ResolversParentTypes['GimCoolPayReadyToPurchasePrice']
> = {
  coinAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  coinCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fiatAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fiatCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  networkFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paymentMethodId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  spotPriceFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  spotPriceIncludingFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetricResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Metric'] = ResolversParentTypes['Metric']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionManagersInfo?: Resolver<
    Maybe<Array<ResolversTypes['PositionManagerInfo']>>,
    ParentType,
    ContextType
  >
  positions?: Resolver<Maybe<Array<ResolversTypes['MetricPosition']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetricPositionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['MetricPosition'] = ResolversParentTypes['MetricPosition']
> = {
  amountUsd?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeUsd?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  positionIdTotalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  positionIsRunning?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  positionManagerContractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionOwnerAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  strategyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  strategyProvider?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sumUsd?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  closeUniswapv3Position?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationCloseUniswapv3PositionArgs, 'input'>
  >
  createAICompletionRecord?: Resolver<
    ResolversTypes['aICompletionRecords'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateAiCompletionRecordArgs, 'input'>
  >
  createUniswapv3Position?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateUniswapv3PositionArgs, 'input'>
  >
  createUniswapv3Tool?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateUniswapv3ToolArgs, 'input'>
  >
  delistUserOwnedUniswapv3Tool?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationDelistUserOwnedUniswapv3ToolArgs, 'input'>
  >
  externalAPIKeyCreate?: Resolver<
    ResolversTypes['ExternalAPIKey'],
    ParentType,
    ContextType,
    RequireFields<MutationExternalApiKeyCreateArgs, 'input'>
  >
  externalAPIKeyDelete?: Resolver<
    ResolversTypes['ExternalAPIKey'],
    ParentType,
    ContextType,
    RequireFields<MutationExternalApiKeyDeleteArgs, 'input'>
  >
  generateMetrics?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  listUserOwnedUniswapv3Tool?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationListUserOwnedUniswapv3ToolArgs, 'input'>
  >
  refreshUserUniswapV3Tool?: Resolver<
    Maybe<ResolversTypes['UserUniswapPositionInfo']>,
    ParentType,
    ContextType,
    RequireFields<MutationRefreshUserUniswapV3ToolArgs, 'input'>
  >
  strategyStart?: Resolver<
    ResolversTypes['StrategyStartResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationStrategyStartArgs, 'input'>
  >
  strategyStop?: Resolver<
    ResolversTypes['StrategyStopResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationStrategyStopArgs, 'input'>
  >
  strategyUpdate?: Resolver<
    ResolversTypes['StrategyUpdateResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationStrategyUpdateArgs, 'input'>
  >
  updateUserOwnedUniswapv3ToolsInfo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserOwnedUniswapv3ToolsInfoArgs, 'input'>
  >
}

export type NftCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NFTCollections'] = ResolversParentTypes['NFTCollections']
> = {
  attributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Attributes']>>>, ParentType, ContextType>
  contract?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NftMerkleTreeHexProofResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NFTMerkleTreeHexProof'] = ResolversParentTypes['NFTMerkleTreeHexProof']
> = {
  hexProof?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NftWhitelistInfoByPhraseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NFTWhitelistInfoByPhrase'] = ResolversParentTypes['NFTWhitelistInfoByPhrase']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isWhitelisted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  phrase?: Resolver<ResolversTypes['NFT_ROUND'], ParentType, ContextType>
  userAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PositionManagerInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PositionManagerInfo'] = ResolversParentTypes['PositionManagerInfo']
> = {
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  campaignAirdrop?: Resolver<Maybe<ResolversTypes['CampaignAirdrop']>, ParentType, ContextType>
  campaignAirdropExplore?: Resolver<
    ResolversTypes['CampaignAirdropExplore'],
    ParentType,
    ContextType,
    RequireFields<QueryCampaignAirdropExploreArgs, 'campaignId'>
  >
  campaignAirdropTools?: Resolver<
    Maybe<Array<ResolversTypes['UniswapToolInfo']>>,
    ParentType,
    ContextType,
    Partial<QueryCampaignAirdropToolsArgs>
  >
  coinPrices?: Resolver<Maybe<Array<ResolversTypes['CoinPrice']>>, ParentType, ContextType>
  me?: Resolver<ResolversTypes['UserInfo'], ParentType, ContextType>
  metric?: Resolver<Maybe<Array<ResolversTypes['Metric']>>, ParentType, ContextType>
  poolsWithArguments?: Resolver<
    Maybe<Array<ResolversTypes['UniswapPoolInfo']>>,
    ParentType,
    ContextType,
    RequireFields<QueryPoolsWithArgumentsArgs, 'chain'>
  >
  rateHistorical?: Resolver<
    Maybe<Array<ResolversTypes['RateHistorical']>>,
    ParentType,
    ContextType,
    RequireFields<QueryRateHistoricalArgs, 'filter'>
  >
  strategies?: Resolver<
    Array<ResolversTypes['StrategyInfo']>,
    ParentType,
    ContextType,
    Partial<QueryStrategiesArgs>
  >
  tools?: Resolver<
    Array<ResolversTypes['ToolInfo']>,
    ParentType,
    ContextType,
    Partial<QueryToolsArgs>
  >
  toolsSupportingTokens?: Resolver<
    Maybe<Array<ResolversTypes['TokenDisplayInfo']>>,
    ParentType,
    ContextType,
    Partial<QueryToolsSupportingTokensArgs>
  >
  uniswapQuote?: Resolver<
    Maybe<ResolversTypes['UniswapQuote']>,
    ParentType,
    ContextType,
    RequireFields<QueryUniswapQuoteArgs, 'input'>
  >
  uniswapTokens?: Resolver<
    Maybe<Array<ResolversTypes['UniswapToolToken']>>,
    ParentType,
    ContextType,
    RequireFields<QueryUniswapTokensArgs, 'chain'>
  >
  uniswapV3Backtest?: Resolver<
    Maybe<ResolversTypes['UniswapV3BacktestResult']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryUniswapV3BacktestArgs,
      'compound' | 'days' | 'diff' | 'investmentAmount' | 'period' | 'poolID' | 'protocol'
    >
  >
  version?: Resolver<ResolversTypes['FrontendVersion'], ParentType, ContextType>
  wallet?: Resolver<
    Maybe<ResolversTypes['DefiWallet']>,
    ParentType,
    ContextType,
    Partial<QueryWalletArgs>
  >
}

export type RateHistoricalResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['RateHistorical'] = ResolversParentTypes['RateHistorical']
> = {
  rate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  time?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldLicenseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldLicense'] = ResolversParentTypes['ReyieldLicense']
> = {
  numberOfLicense?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  numberOfUsedLicense?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SmartContractResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SmartContract'] = ResolversParentTypes['SmartContract']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyInfo'] = ResolversParentTypes['StrategyInfo']
> = {
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  earningCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  exchanges?: Resolver<Array<ResolversTypes['Exchange']>, ParentType, ContextType>
  expectedAPY?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  maximumLoss?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  numOfApply?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  parameters?: Resolver<Array<ResolversTypes['StrategyParameters']>, ParentType, ContextType>
  riskLevel?: Resolver<ResolversTypes['RiskLevel'], ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  stage?: Resolver<ResolversTypes['StrategyInfoStage'], ParentType, ContextType>
  suggestionPeriod?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['StrategyType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyInfosResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyInfos'] = ResolversParentTypes['StrategyInfos']
> = {
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  earningCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  exchange?: Resolver<Maybe<ResolversTypes['Exchange']>, ParentType, ContextType>
  minEnableAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyParametersResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyParameters'] = ResolversParentTypes['StrategyParameters']
> = {
  defaultValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  maxValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  minValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['StrategyParameterType'], ParentType, ContextType>
  updatable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyPerformanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyPerformance'] = ResolversParentTypes['StrategyPerformance']
> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  apy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ror?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyProgressResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyProgress'] = ResolversParentTypes['StrategyProgress']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['StrategyProgressStatus'], ParentType, ContextType>
  steps?: Resolver<Array<Maybe<ResolversTypes['StrategyProgressStep']>>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['StrategyProgressType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyProgressStepResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyProgressStep'] = ResolversParentTypes['StrategyProgressStep']
> = {
  status?: Resolver<ResolversTypes['StrategyProgressStepStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['StrategyProgressStepType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyStartResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyStartResponse'] = ResolversParentTypes['StrategyStartResponse']
> = {
  strategy?: Resolver<ResolversTypes['UserStrategyInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyStopResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyStopResponse'] = ResolversParentTypes['StrategyStopResponse']
> = {
  strategy?: Resolver<ResolversTypes['UserStrategyInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyUpdateResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyUpdateResponse'] = ResolversParentTypes['StrategyUpdateResponse']
> = {
  strategy?: Resolver<ResolversTypes['UserStrategyInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TokenBalanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['TokenBalance'] = ResolversParentTypes['TokenBalance']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  amountReadable?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TokenDisplayInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['TokenDisplayInfo'] = ResolversParentTypes['TokenDisplayInfo']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  imageURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  usdtPrice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ToolInfo'] = ResolversParentTypes['ToolInfo']
> = {
  __resolveType: TypeResolveFn<'StrategyInfo' | 'UniswapToolInfo', ParentType, ContextType>
}

export type TotalProfitsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['TotalProfits'] = ResolversParentTypes['TotalProfits']
> = {
  amount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapOnchainPositionAmountInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapOnchainPositionAmountInfo'] = ResolversParentTypes['UniswapOnchainPositionAmountInfo']
> = {
  token0?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token0Amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0AmountUsdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token1Amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1AmountUsdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapPoolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapPoolInfo'] = ResolversParentTypes['UniswapPoolInfo']
> = {
  averageTradingVolumeUSD?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<UniswapPoolInfoAverageTradingVolumeUsdArgs, 'numberOfDays'>
  >
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  collectedFeesToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  collectedFeesToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  collectedFeesUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAtBlockNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAtTimestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthGlobal0X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthGlobal1X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feesUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPoolActiveForReyield?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  liquidity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  liquidityProviderCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  observationIndex?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positions?: Resolver<Maybe<Array<ResolversTypes['UniswapPoolPosition']>>, ParentType, ContextType>
  sqrtPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tick?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ticks?: Resolver<Maybe<Array<ResolversTypes['UniswapPoolTick']>>, ParentType, ContextType>
  token0?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token0Price?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token1Price?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedETH?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSDUntracked?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  txCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  untrackedVolumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapPoolPositionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapPoolPosition'] = ResolversParentTypes['UniswapPoolPosition']
> = {
  collectedFeesToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  collectedFeesToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  depositedToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  depositedToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthInside0LastX128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthInside1LastX128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  liquidity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerFeeGrowthOutside0X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerFeeGrowthOutside1X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerTickIdx?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperFeeGrowthOutside0X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperFeeGrowthOutside1X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperTickIdx?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactionTimestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapPoolTickResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapPoolTick'] = ResolversParentTypes['UniswapPoolTick']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  liquidityNet?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickIdx?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapQuoteResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapQuote'] = ResolversParentTypes['UniswapQuote']
> = {
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  fromToken?: Resolver<ResolversTypes['UniswapToolToken'], ParentType, ContextType>
  fromTokenAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  inputAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  inputReadableAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  outputAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  outputReadableAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toToken?: Resolver<ResolversTypes['UniswapToolToken'], ParentType, ContextType>
  toTokenAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolFeeTierResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolFeeTier'] = ResolversParentTypes['UniswapToolFeeTier']
> = {
  feeForContract?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeForDisplay?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolInfo'] = ResolversParentTypes['UniswapToolInfo']
> = {
  apyHighest7d?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  apyHighest24h?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  apyHighest30d?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  apyHighestCurrent?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  apyHistory?: Resolver<
    Maybe<Array<ResolversTypes['ApyHistory']>>,
    ParentType,
    ContextType,
    RequireFields<UniswapToolInfoApyHistoryArgs, 'period'>
  >
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  creator?: Resolver<Maybe<ResolversTypes['Creator']>, ParentType, ContextType>
  creatorAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  creatorDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  creatorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  creatorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  expectedAPY?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['UniswapToolFeeTier'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isOfficial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  maximumLoss?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  numOfApply?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  performanceFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  poolAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  poolInfo?: Resolver<Maybe<ResolversTypes['UniswapPoolInfo']>, ParentType, ContextType>
  riskLevel?: Resolver<ResolversTypes['RiskLevel'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['PublicUniswapV3ToolStatus'], ParentType, ContextType>
  tags?: Resolver<Maybe<Array<ResolversTypes['UniswapToolTag']>>, ParentType, ContextType>
  tickLowerDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerDiffForContract?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiffForContract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  tvlPool?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tvlTool?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolTagResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolTag'] = ResolversParentTypes['UniswapToolTag']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['UniswapToolTagType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolTokenResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolToken'] = ResolversParentTypes['UniswapToolToken']
> = {
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  decimals?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  derivedETH?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feesUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  poolCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  priceChart?: Resolver<
    Maybe<ResolversTypes['UniswapToolTokenPriceChart']>,
    ParentType,
    ContextType
  >
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalSupply?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLocked?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSDUntracked?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  txCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  untrackedVolumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volume?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolTokenPriceChartResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolTokenPriceChart'] = ResolversParentTypes['UniswapToolTokenPriceChart']
> = {
  currentPriceUSD?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  prices?: Resolver<
    Maybe<Array<ResolversTypes['UniswapToolTokenPriceChartPrice']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolTokenPriceChartPriceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolTokenPriceChartPrice'] = ResolversParentTypes['UniswapToolTokenPriceChartPrice']
> = {
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapV3BacktestResultResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapV3BacktestResult'] = ResolversParentTypes['UniswapV3BacktestResult']
> = {
  activeLiquidityPercent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  annualReturn?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  compound?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  dailyReturn?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  dataPointsCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  days?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  diff?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  fees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  impermanentLoss?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  investmentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  maxDrawdown?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  period?: Resolver<ResolversTypes['UniswapV3BacktestPeriod'], ParentType, ContextType>
  poolID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  protocol?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  totalReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  volatility?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCampaignAirdropPortfolioResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserCampaignAirdropPortfolio'] = ResolversParentTypes['UserCampaignAirdropPortfolio']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tasks?: Resolver<Maybe<Array<ResolversTypes['CampaignAirdropTask']>>, ParentType, ContextType>
  userTotalEarnedReyieldTokens?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userTotalInvestingAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userTotalProfit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserInfo'] = ResolversParentTypes['UserInfo']
> = {
  aICompletionRecords?: Resolver<
    Maybe<Array<ResolversTypes['aICompletionRecords']>>,
    ParentType,
    ContextType,
    Partial<UserInfoAiCompletionRecordsArgs>
  >
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  campaignAirdropPortfolio?: Resolver<
    Maybe<ResolversTypes['UserCampaignAirdropPortfolio']>,
    ParentType,
    ContextType,
    RequireFields<UserInfoCampaignAirdropPortfolioArgs, 'campaignId'>
  >
  campaignAirdropPositions?: Resolver<
    Maybe<Array<ResolversTypes['UserUniswapPositionInfo']>>,
    ParentType,
    ContextType,
    RequireFields<UserInfoCampaignAirdropPositionsArgs, 'campaignId'>
  >
  cefiDepositAddress?: Resolver<
    Maybe<ResolversTypes['CefiDepositAddress']>,
    ParentType,
    ContextType,
    Partial<UserInfoCefiDepositAddressArgs>
  >
  cefiWallets?: Resolver<
    Maybe<Array<ResolversTypes['CefiWallet']>>,
    ParentType,
    ContextType,
    Partial<UserInfoCefiWalletsArgs>
  >
  defiTokensBalanceAmount?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TokenBalance']>>>,
    ParentType,
    ContextType,
    RequireFields<UserInfoDefiTokensBalanceAmountArgs, 'input'>
  >
  defiWallets?: Resolver<
    Maybe<Array<ResolversTypes['DefiWallet']>>,
    ParentType,
    ContextType,
    Partial<UserInfoDefiWalletsArgs>
  >
  externalAPIKeys?: Resolver<
    Maybe<Array<ResolversTypes['ExternalAPIKey']>>,
    ParentType,
    ContextType,
    Partial<UserInfoExternalApiKeysArgs>
  >
  gimCoolPayPaymentInfo?: Resolver<
    Maybe<ResolversTypes['GimCoolPayPaymentInfo']>,
    ParentType,
    ContextType,
    Partial<UserInfoGimCoolPayPaymentInfoArgs>
  >
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nftCollections?: Resolver<Maybe<Array<ResolversTypes['NFTCollections']>>, ParentType, ContextType>
  nftMerkleTreeHexProof?: Resolver<
    Maybe<ResolversTypes['NFTMerkleTreeHexProof']>,
    ParentType,
    ContextType
  >
  nftWhitelistInfoByPhrases?: Resolver<
    Maybe<Array<ResolversTypes['NFTWhitelistInfoByPhrase']>>,
    ParentType,
    ContextType
  >
  numOfListedTool?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numOfListingLicense?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  ownedToolsSetting?: Resolver<
    Maybe<Array<ResolversTypes['UserUniswapToolSettingInfo']>>,
    ParentType,
    ContextType
  >
  privilege?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  reyieldLicense?: Resolver<Maybe<ResolversTypes['ReyieldLicense']>, ParentType, ContextType>
  strategies?: Resolver<
    Maybe<Array<ResolversTypes['UserStrategyInfo']>>,
    ParentType,
    ContextType,
    Partial<UserInfoStrategiesArgs>
  >
  strategyPerformance?: Resolver<
    Maybe<ResolversTypes['UserStrategyPerformance']>,
    ParentType,
    ContextType,
    RequireFields<UserInfoStrategyPerformanceArgs, 'strategyID'>
  >
  strategyProfit?: Resolver<
    Maybe<ResolversTypes['UserStrategyProfit']>,
    ParentType,
    ContextType,
    Partial<UserInfoStrategyProfitArgs>
  >
  strategyProgress?: Resolver<
    Maybe<ResolversTypes['StrategyProgress']>,
    ParentType,
    ContextType,
    RequireFields<UserInfoStrategyProgressArgs, 'strategyID'>
  >
  strategyRecords?: Resolver<
    Maybe<Array<ResolversTypes['UserStrategyRecord']>>,
    ParentType,
    ContextType,
    Partial<UserInfoStrategyRecordsArgs>
  >
  tools?: Resolver<Maybe<Array<ResolversTypes['UserToolInfo']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStrategyInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserStrategyInfo'] = ResolversParentTypes['UserStrategyInfo']
> = {
  ceFiStatus?: Resolver<ResolversTypes['StrategyStatus'], ParentType, ContextType>
  closedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  errMsg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  infos?: Resolver<Maybe<ResolversTypes['StrategyInfos']>, ParentType, ContextType>
  parameters?: Resolver<
    Maybe<Array<ResolversTypes['UserStrategyParameters']>>,
    ParentType,
    ContextType
  >
  performance?: Resolver<Maybe<ResolversTypes['UserStrategyPerformance']>, ParentType, ContextType>
  profits?: Resolver<Maybe<ResolversTypes['UserStrategyProfit']>, ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStrategyParametersResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserStrategyParameters'] = ResolversParentTypes['UserStrategyParameters']
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStrategyPerformanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserStrategyPerformance'] = ResolversParentTypes['UserStrategyPerformance']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  initialValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  performances?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['StrategyPerformance']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStrategyProfitResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserStrategyProfit'] = ResolversParentTypes['UserStrategyProfit']
> = {
  sid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totalProfit?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TotalProfits']>>>,
    ParentType,
    ContextType
  >
  type?: Resolver<Maybe<ResolversTypes['StrategyType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStrategyRecordResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserStrategyRecord'] = ResolversParentTypes['UserStrategyRecord']
> = {
  amount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  rate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['RecordsType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserToolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserToolInfo'] = ResolversParentTypes['UserToolInfo']
> = {
  __resolveType: TypeResolveFn<
    'UserStrategyInfo' | 'UserUniswapPositionInfo',
    ParentType,
    ContextType
  >
}

export type UserUniswapOnchainPositionInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapOnchainPositionInfo'] = ResolversParentTypes['UserUniswapOnchainPositionInfo']
> = {
  apy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  closedPositionAmount?: Resolver<
    Maybe<ResolversTypes['UniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  positionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionIdFromSmartContractPositionManager?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  roi?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  runningPositionAmount?: Resolver<
    Maybe<ResolversTypes['UniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  totalInvestAmountUsdValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserUniswapPositionInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapPositionInfo'] = ResolversParentTypes['UserUniswapPositionInfo']
> = {
  apy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  campaignId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  closedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  closedPositionAmount?: Resolver<
    Maybe<ResolversTypes['UniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['UniswapToolFeeTier'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  onchainPositionInfo?: Resolver<
    Maybe<ResolversTypes['UserUniswapOnchainPositionInfo']>,
    ParentType,
    ContextType,
    Partial<UserUniswapPositionInfoOnchainPositionInfoArgs>
  >
  poolAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionIdFromSmartContractPositionManager?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  progresses?: Resolver<
    Maybe<Array<ResolversTypes['UserUniswapv3PositionProgress']>>,
    ParentType,
    ContextType
  >
  publicToolInfo?: Resolver<Maybe<ResolversTypes['UniswapToolInfo']>, ParentType, ContextType>
  reyieldTokens?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  roi?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  runningPositionAmount?: Resolver<
    Maybe<ResolversTypes['UniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['UniswapToolPositionStatus'], ParentType, ContextType>
  tickLowerDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  token0TotalFeeUsdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  token1TotalFeeUsdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toolDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toolId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toolIsOfficial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  toolName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toolPerformanceFeeRatio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  toolSetting?: Resolver<
    Maybe<ResolversTypes['UserUniswapToolSettingInfo']>,
    ParentType,
    ContextType
  >
  totalInvestAmountUsdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserUniswapToolOutOfRangeInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapToolOutOfRangeInfo'] = ResolversParentTypes['UserUniswapToolOutOfRangeInfo']
> = {
  earnSpendRate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  maxRebalanceTimes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  standbyInterval?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  standbyMaxInterval?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserUniswapToolSettingInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapToolSettingInfo'] = ResolversParentTypes['UserUniswapToolSettingInfo']
> = {
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  closedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['UniswapToolFeeTier'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isOfficial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  outOfLowerRange?: Resolver<
    ResolversTypes['UserUniswapToolOutOfRangeInfo'],
    ParentType,
    ContextType
  >
  outOfUpperRange?: Resolver<
    ResolversTypes['UserUniswapToolOutOfRangeInfo'],
    ParentType,
    ContextType
  >
  performanceFeeRatio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  poolAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['UserUniswapPositionInfo']>, ParentType, ContextType>
  publicToolInfo?: Resolver<Maybe<ResolversTypes['UniswapToolInfo']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['UniswapToolStatus'], ParentType, ContextType>
  tags?: Resolver<Maybe<Array<ResolversTypes['UniswapToolTag']>>, ParentType, ContextType>
  tickLowerDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  token1?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  totalUserUsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserUniswapv3PositionProgressResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapv3PositionProgress'] = ResolversParentTypes['UserUniswapv3PositionProgress']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['UserUniswapv3PositionProgressStatus'], ParentType, ContextType>
  steps?: Resolver<
    Array<ResolversTypes['UserUniswapv3PositionProgressStep']>,
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['UserUniswapv3PositionProgressType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserUniswapv3PositionProgressStepResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapv3PositionProgressStep'] = ResolversParentTypes['UserUniswapv3PositionProgressStep']
> = {
  status?: Resolver<
    ResolversTypes['UserUniswapv3PositionProgressStepStatus'],
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['UserUniswapv3PositionProgressStepType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletBalancesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletBalances'] = ResolversParentTypes['WalletBalances']
> = {
  currencyBalances?: Resolver<Array<ResolversTypes['CurrencyBalances']>, ParentType, ContextType>
  wallet?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AICompletionRecordsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['aICompletionRecords'] = ResolversParentTypes['aICompletionRecords']
> = {
  completedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  meta?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<AICompletionRecordsMetaArgs>
  >
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  promptMutationInput?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<AICompletionRecordsPromptMutationInputArgs>
  >
  promptMutationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  suggestions?: Resolver<
    Array<ResolversTypes['suggestStrategyToolWithAllocations']>,
    ParentType,
    ContextType,
    Partial<AICompletionRecordsSuggestionsArgs>
  >
  suggestions_aggregate?: Resolver<
    ResolversTypes['suggestStrategyToolWithAllocations_aggregate'],
    ParentType,
    ContextType,
    Partial<AICompletionRecordsSuggestions_AggregateArgs>
  >
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  userAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface JsonbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb'
}

export type SuggestStrategyToolWithAllocationsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['suggestStrategyToolWithAllocations'] = ResolversParentTypes['suggestStrategyToolWithAllocations']
> = {
  aICompletionRecordId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  allocation?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  highestAnnualReturn?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>
  maximumLoss?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  systemInvestAmountSuggestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestCurrencySuggestion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuggestStrategyToolWithAllocations_AggregateResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['suggestStrategyToolWithAllocations_aggregate'] = ResolversParentTypes['suggestStrategyToolWithAllocations_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['suggestStrategyToolWithAllocations_aggregate_fields']>,
    ParentType,
    ContextType
  >
  nodes?: Resolver<
    Array<ResolversTypes['suggestStrategyToolWithAllocations']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuggestStrategyToolWithAllocations_Aggregate_FieldsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['suggestStrategyToolWithAllocations_aggregate_fields'] = ResolversParentTypes['suggestStrategyToolWithAllocations_aggregate_fields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<SuggestStrategyToolWithAllocations_Aggregate_FieldsCountArgs>
  >
  max?: Resolver<
    Maybe<ResolversTypes['suggestStrategyToolWithAllocations_max_fields']>,
    ParentType,
    ContextType
  >
  min?: Resolver<
    Maybe<ResolversTypes['suggestStrategyToolWithAllocations_min_fields']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuggestStrategyToolWithAllocations_Max_FieldsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['suggestStrategyToolWithAllocations_max_fields'] = ResolversParentTypes['suggestStrategyToolWithAllocations_max_fields']
> = {
  aICompletionRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  allocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  highestAnnualReturn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>
  maximumLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestAmountSuggestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestCurrencySuggestion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuggestStrategyToolWithAllocations_Min_FieldsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['suggestStrategyToolWithAllocations_min_fields'] = ResolversParentTypes['suggestStrategyToolWithAllocations_min_fields']
> = {
  aICompletionRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  allocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  highestAnnualReturn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>
  maximumLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestAmountSuggestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestCurrencySuggestion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface TimestamptzScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz'
}

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid'
}

export type Resolvers<ContextType = GraphqlContext> = {
  ApyHistory?: ApyHistoryResolvers<ContextType>
  Attributes?: AttributesResolvers<ContextType>
  Campaign?: CampaignResolvers<ContextType>
  CampaignAirdrop?: CampaignAirdropResolvers<ContextType>
  CampaignAirdropExplore?: CampaignAirdropExploreResolvers<ContextType>
  CampaignAirdropTask?: CampaignAirdropTaskResolvers<ContextType>
  CefiDepositAddress?: CefiDepositAddressResolvers<ContextType>
  CefiWallet?: CefiWalletResolvers<ContextType>
  CoinPrice?: CoinPriceResolvers<ContextType>
  Creator?: CreatorResolvers<ContextType>
  CurrencyAmount?: CurrencyAmountResolvers<ContextType>
  CurrencyBalance?: CurrencyBalanceResolvers<ContextType>
  CurrencyBalances?: CurrencyBalancesResolvers<ContextType>
  CurrencyInfo?: CurrencyInfoResolvers<ContextType>
  DefiWallet?: DefiWalletResolvers<ContextType>
  ExternalAPIKey?: ExternalApiKeyResolvers<ContextType>
  FrontendVersion?: FrontendVersionResolvers<ContextType>
  GimCoolPayPaymentInfo?: GimCoolPayPaymentInfoResolvers<ContextType>
  GimCoolPayPriceResponse?: GimCoolPayPriceResponseResolvers<ContextType>
  GimCoolPayReadyToPurchasePrice?: GimCoolPayReadyToPurchasePriceResolvers<ContextType>
  Metric?: MetricResolvers<ContextType>
  MetricPosition?: MetricPositionResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  NFTCollections?: NftCollectionsResolvers<ContextType>
  NFTMerkleTreeHexProof?: NftMerkleTreeHexProofResolvers<ContextType>
  NFTWhitelistInfoByPhrase?: NftWhitelistInfoByPhraseResolvers<ContextType>
  PositionManagerInfo?: PositionManagerInfoResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  RateHistorical?: RateHistoricalResolvers<ContextType>
  ReyieldLicense?: ReyieldLicenseResolvers<ContextType>
  SmartContract?: SmartContractResolvers<ContextType>
  StrategyInfo?: StrategyInfoResolvers<ContextType>
  StrategyInfos?: StrategyInfosResolvers<ContextType>
  StrategyParameters?: StrategyParametersResolvers<ContextType>
  StrategyPerformance?: StrategyPerformanceResolvers<ContextType>
  StrategyProgress?: StrategyProgressResolvers<ContextType>
  StrategyProgressStep?: StrategyProgressStepResolvers<ContextType>
  StrategyStartResponse?: StrategyStartResponseResolvers<ContextType>
  StrategyStopResponse?: StrategyStopResponseResolvers<ContextType>
  StrategyUpdateResponse?: StrategyUpdateResponseResolvers<ContextType>
  TokenBalance?: TokenBalanceResolvers<ContextType>
  TokenDisplayInfo?: TokenDisplayInfoResolvers<ContextType>
  ToolInfo?: ToolInfoResolvers<ContextType>
  TotalProfits?: TotalProfitsResolvers<ContextType>
  UniswapOnchainPositionAmountInfo?: UniswapOnchainPositionAmountInfoResolvers<ContextType>
  UniswapPoolInfo?: UniswapPoolInfoResolvers<ContextType>
  UniswapPoolPosition?: UniswapPoolPositionResolvers<ContextType>
  UniswapPoolTick?: UniswapPoolTickResolvers<ContextType>
  UniswapQuote?: UniswapQuoteResolvers<ContextType>
  UniswapToolFeeTier?: UniswapToolFeeTierResolvers<ContextType>
  UniswapToolInfo?: UniswapToolInfoResolvers<ContextType>
  UniswapToolTag?: UniswapToolTagResolvers<ContextType>
  UniswapToolToken?: UniswapToolTokenResolvers<ContextType>
  UniswapToolTokenPriceChart?: UniswapToolTokenPriceChartResolvers<ContextType>
  UniswapToolTokenPriceChartPrice?: UniswapToolTokenPriceChartPriceResolvers<ContextType>
  UniswapV3BacktestResult?: UniswapV3BacktestResultResolvers<ContextType>
  UserCampaignAirdropPortfolio?: UserCampaignAirdropPortfolioResolvers<ContextType>
  UserInfo?: UserInfoResolvers<ContextType>
  UserStrategyInfo?: UserStrategyInfoResolvers<ContextType>
  UserStrategyParameters?: UserStrategyParametersResolvers<ContextType>
  UserStrategyPerformance?: UserStrategyPerformanceResolvers<ContextType>
  UserStrategyProfit?: UserStrategyProfitResolvers<ContextType>
  UserStrategyRecord?: UserStrategyRecordResolvers<ContextType>
  UserToolInfo?: UserToolInfoResolvers<ContextType>
  UserUniswapOnchainPositionInfo?: UserUniswapOnchainPositionInfoResolvers<ContextType>
  UserUniswapPositionInfo?: UserUniswapPositionInfoResolvers<ContextType>
  UserUniswapToolOutOfRangeInfo?: UserUniswapToolOutOfRangeInfoResolvers<ContextType>
  UserUniswapToolSettingInfo?: UserUniswapToolSettingInfoResolvers<ContextType>
  UserUniswapv3PositionProgress?: UserUniswapv3PositionProgressResolvers<ContextType>
  UserUniswapv3PositionProgressStep?: UserUniswapv3PositionProgressStepResolvers<ContextType>
  WalletBalances?: WalletBalancesResolvers<ContextType>
  aICompletionRecords?: AICompletionRecordsResolvers<ContextType>
  jsonb?: GraphQLScalarType
  suggestStrategyToolWithAllocations?: SuggestStrategyToolWithAllocationsResolvers<ContextType>
  suggestStrategyToolWithAllocations_aggregate?: SuggestStrategyToolWithAllocations_AggregateResolvers<ContextType>
  suggestStrategyToolWithAllocations_aggregate_fields?: SuggestStrategyToolWithAllocations_Aggregate_FieldsResolvers<ContextType>
  suggestStrategyToolWithAllocations_max_fields?: SuggestStrategyToolWithAllocations_Max_FieldsResolvers<ContextType>
  suggestStrategyToolWithAllocations_min_fields?: SuggestStrategyToolWithAllocations_Min_FieldsResolvers<ContextType>
  timestamptz?: GraphQLScalarType
  uuid?: GraphQLScalarType
}
