import { enUS, jaJP, Localization, zhCN } from '@mui/material/locale'

// icon download from https://www.flaticon.com/free-icon/united-states_330459?term=usa&page=1&position=4&page=1&position=4&related_id=330459&origin=search

const previewOnlyLang = {
  label: 'Key',
  value: 'key',
  systemValue: 'key' as Localization,
  icon: 'line-md:github-loop',
}

export const langs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'emojione:flag-for-united-states',
  },
  {
    label: '繁體中文',
    value: 'cn',
    systemValue: zhCN,
    icon: 'emojione:flag-for-taiwan',
  },
  {
    label: '日本語',
    value: 'jp',
    systemValue: jaJP,
    icon: 'emojione:flag-for-japan',
  },
]

export const allLangs =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? langs : [...langs, previewOnlyLang]

export const defaultLang = allLangs[0] // English
