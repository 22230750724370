import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { defaultLang } from './config'
import cnLocales from './langs/cn'
import enLocales from './langs/en'
import jpLocales from './langs/jp'
import keyLocales from './langs/key'

let lng = defaultLang.value

if (typeof window !== 'undefined') {
  lng = localStorage.getItem('i18nextLng') || defaultLang.value
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      jp: { translations: jpLocales },
      cn: { translations: cnLocales },
      key: { translations: keyLocales },
    },
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
