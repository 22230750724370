import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'

export interface ColorInterface {
  lighter: string
  light: string
  main: string
  dark: string
  darker: string
  contrastText: string
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string
  }
  interface SimplePaletteColorOptions {
    lighter: string
    darker: string
  }
  interface PaletteColor {
    lighter: string
    darker: string
  }
}

// SETUP COLORS

export const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  1000: '#AEAEAE',
}

const PRIMARY: ColorInterface = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#FAE55A',
  dark: '#007B55',
  darker: '#005249',
  contrastText: '#fff',
}

const SECONDARY: ColorInterface = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
}

const INFO: ColorInterface = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#fff',
}

const SUCCESS: ColorInterface = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#fff',
}

const WARNING: ColorInterface = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
}

const ERROR: ColorInterface = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#fff',
}

const REYIELD_PRIMARY = {
  lighter: '#D3DDE1',
  light: '#ACB2B5',
  main: '#182226',
  dark: '#0E191E',
  darker: '#081216',
  contrastText: '#FFFFFF',
}

const REYIELD_SECONDARY = {
  lighter:
    'linear-gradient(90.47deg, rgba(229, 241, 88, 0.4) 9.78%, rgba(97, 181, 160, 0.4) 100.45%)',
  light: '#73E3BB',
  main: '#CDFB6B',
  dark: 'linear-gradient(90.47deg, #AACD7E 9.78%, #61B5A0 100.45%)',
  darker:
    'linear-gradient(306.02deg, rgba(219, 217, 217, 0.2) 39.91%, rgba(11, 11, 11, 0.13) 103.26%), #081216',
}

const REYIELD_WARNING = {
  main: '#FF7C44',
}

const REYIELD_ERROR = {
  main: '#EB535C',
}

const REYIELD_STROKE = {
  light: '#FFFFFF',
  main: '#FFFFFF4D',
}

export const REYIELD_COLORS = {
  primary: REYIELD_PRIMARY,
  secondary: REYIELD_SECONDARY,
  warning: REYIELD_WARNING,
  error: REYIELD_ERROR,
  stroke: REYIELD_STROKE,
}

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  } as const

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#fff',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: COMMON.common.black,
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  } as const

  return themeMode === 'light' ? light : dark
}

export const textLinearGradientStyle = (color: string | undefined) => ({
  background: color,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
})
