import { Theme } from '@mui/material/styles'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    p1: true
    p2: true
    p3: true
  }
}

export const variantsConfig = [
  { propsVariant: 'h1', fontSizeUp: '60px', fontSizeDown: '48px', lineHeight: '140%' },
  { propsVariant: 'h2', fontSizeUp: '48px', fontSizeDown: '40px', lineHeight: '140%' },
  { propsVariant: 'h3', fontSizeUp: '40px', fontSizeDown: '32px', lineHeight: '140%' },
  { propsVariant: 'h4', fontSizeUp: '32px', fontSizeDown: '28px', lineHeight: '140%' },
  { propsVariant: 'h5', fontSizeUp: '28px', fontSizeDown: '24px', lineHeight: '140%' },
  { propsVariant: 'h6', fontSizeUp: '24px', fontSizeDown: '20px', lineHeight: '140%' },
  { propsVariant: 'p1', fontSizeUp: '20px', fontSizeDown: '16px', lineHeight: '120%' },
  { propsVariant: 'p2', fontSizeUp: '16px', fontSizeDown: '14px', lineHeight: '120%' },
  { propsVariant: 'p3', fontSizeUp: '14px', fontSizeDown: '12px', lineHeight: '120%' },
  { propsVariant: 'caption1', fontSizeUp: '12px', fontSizeDown: '12px', lineHeight: '120%' },
  { propsVariant: 'caption2', fontSizeUp: '12px', fontSizeDown: '12px', lineHeight: '120%' },
]

export const fontWeightConfig = [
  { propsFontWeight: 'light', fontWeight: '300' },
  { propsFontWeight: 'regular', fontWeight: '400' },
  { propsFontWeight: 'medium', fontWeight: '500' },
  { propsFontWeight: 'bold', fontWeight: '700' },
]

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      variants: [
        ...fontWeightConfig.map((config) => ({
          props: { fontWeight: config.propsFontWeight },
          style: {
            fontWeight: `${config.fontWeight}`,
          },
        })),
        ...variantsConfig.map((config) => ({
          props: { variant: config.propsVariant },
          style: {
            lineHeight: `${config.lineHeight}`,
            [theme.breakpoints.up('lg')]: {
              fontSize: `${config.fontSizeUp}`,
            },
            [theme.breakpoints.down('lg')]: {
              fontSize: `${config.fontSizeDown}`,
            },
          },
        })),
      ],
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  }
}
