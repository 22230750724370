import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Slider(theme: Theme) {
  return {
    MuiSlider: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
          color: theme.reyieldPalette.secondary.main,
        },
        rail: {
          opacity: 0.32,
        },
        markLabel: {
          fontSize: 13,
          color: theme.palette.text.disabled,
        },
        valueLabelCircle: {
          [theme.breakpoints.down('sm')]: {
            whiteSpace: 'unset',
          },
        },
        valueLabel: {
          borderRadius: 8,
          backdropFilter: 'blur(7.5px)',
          background: theme.reyieldPalette.secondary.main,
          color: theme.reyieldPalette.primary.main,
          [theme.breakpoints.down('sm')]: {
            '&::before': {
              display: 'none',
            },
            whiteSpace: 'unset',
            display: 'block',
          },
        },
      },
    },
  }
}
