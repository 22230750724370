// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_APP = '/app'

const ROOTS_APP_EXPLORE = '/app/explore'
const ROOTS_APP_CREATOR = '/app/creator'
const ROOTS_APP_STAKE_VEST = '/app/stake-vest'

const ROOTS_AUTH = '/auth'

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
}

export const PATH_FOOTER = {
  twitter: 'https://twitter.com/REYIELDLabs',
  discord: 'https://discord.com/invite/fpMDhK4P9V',
  telegram: 'https://t.me/REYIELDfinance',
  whitepaper: 'https://doc.reyield.fi/',
  medium: 'https://reyield.medium.com/',
  linktree: 'https://linktr.ee/reyield.fi',
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  privacyAndPolicy: '#',
  termsAndConditions: '#',
}

export const PATH_NFT = {
  landing: '/reyield-nft',
  whitelistChecker: '/whitelist-checker',
  mint: '/mint',
  myCollection: '/my-collection',
  faq: '/reyield-nft-faq',
}

export const PATH_AIRDROP = {
  landing: '/reyield-airdrop',
  explore: '/airdrop/explore',
  task: '/airdrop/task',
}

export const PATH_DASHBOARD = {
  root: ROOTS_APP,
  explore: {
    root: ROOTS_APP_EXPLORE,
    investmentTools: path(ROOTS_APP_EXPLORE, '/investment-tools'),
    reyieldGPTLanding: path(ROOTS_APP_EXPLORE, '/reyield-gpt-landing'),
  },
  creator: {
    root: ROOTS_APP_CREATOR,
    createTools: path(ROOTS_APP_CREATOR, '/create-tools'),
    listTools: path(ROOTS_APP_CREATOR, '/list-tools'),
  },
  myPortfolio: path(ROOTS_APP, '/my-portfolio'),
  myWallet: path(ROOTS_APP, '/my-wallet'),
  rewards: path(ROOTS_APP, '/rewards'),
  stakeVest: {
    root: ROOTS_APP_STAKE_VEST,
    stake: path(ROOTS_APP_STAKE_VEST, '/stake'),
    vest: path(ROOTS_APP_STAKE_VEST, '/vest'),
  },
  voteAndBribe: path(ROOTS_APP, '/vote-bribe'),
  setting: path(ROOTS_APP, '/setting'),
  reyieldGPT: path(ROOTS_APP, '/reyield-gpt'),
}

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
}

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/'

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/'

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/'

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/OBEorYicjdbIT6P1YQTTK7/%5BPreview%5D-Minimal-Web.15.10.22?node-id=0%3A1'
