import { TabProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Tabs(theme: Theme) {
  return {
    MuiTabs: {
      defaultProps: {
        textColor: 'inherit',
        allowScrollButtonsMobile: true,
        variant: 'scrollable',
      },
      styleOverrides: {
        root: {
          minHeight: 37,
        },
        scrollButtons: {
          width: 48,
          borderRadius: '50%',
        },
        indicator: {
          backgroundColor: 'transparent',
        },
        flexContainer: {
          gap: 2,
          height: 37,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        iconPosition: 'start',
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: TabProps }) => ({
          opacity: 1,
          minWidth: 48,
          fontWeight: theme.typography.fontWeightMedium,
          // '&:not(:last-of-type)': {
          //   marginRight: theme.spacing(3),
          //   [theme.breakpoints.up('sm')]: {
          //     marginRight: theme.spacing(5),
          //   },
          // },
          '&:Mui-selected': {
            color: '#fff',
          },
          // '&:not(.Mui-selected)': {
          //   color: theme.palette.text.secondary,
          // },
          // ...((ownerState.iconPosition === 'start' || ownerState.iconPosition === 'end') && {
          //   minHeight: 48,
          // }),
        }),
      },
    },
  }
}
