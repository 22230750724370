import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
  WithSnackbarProps,
} from 'notistack'
import React from 'react'

let useSnackbarRef: WithSnackbarProps
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar()
  return null
}

export default {
  enqueueSnackbar(message: SnackbarMessage, options?: OptionsObject) {
    useSnackbarRef.enqueueSnackbar(message, options)
  },
  closeSnackbar(key: SnackbarKey | undefined) {
    useSnackbarRef.closeSnackbar(key)
  },
}
