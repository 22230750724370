import { GlobalStyles as MUIGlobalStyles } from '@mui/material'

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#__next': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  )

  return inputGlobalStyles
}

export const layout = {
  elementGap: {
    mobile: 15,
    desktop: 30,
  },
  innerGap: {
    mobile: 25,
    desktop: 40,
  },
  gap: {
    mobile: 30,
    desktop: 45,
  },
  appBar: {
    mobile: 64,
    desktop: 88,
  },
  footerHeight: {
    mobile: 700,
    desktop: 290,
  },
  drawerWidth: {
    collapse: 100,
    mobile: 250,
    desktop: 250,
  },
  iconFontSize: {
    share: 20,
  },
  maxWidth: {
    desktop: 1200,
    mobile: 'calc(100% - 32px)',
  },
  borderRadius: {
    desktop: 16,
    mobile: 16,
  },
  sectionGap: {
    desktop: 150,
    mobile: 55,
  },
}
