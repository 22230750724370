import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  jsonb: any
  timestamptz: any
  uuid: any
}

export type ApyHistory = {
  __typename?: 'ApyHistory'
  rate: Scalars['String']
  time: Scalars['String']
}

export enum ApyHistoryPeriod {
  D7 = 'd7',
  D30 = 'd30',
  H24 = 'h24',
}

export type Attributes = {
  __typename?: 'Attributes'
  traitType?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type Campaign = {
  __typename?: 'Campaign'
  createdAt?: Maybe<Scalars['String']>
  endTtime?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  type?: Maybe<CampaignType>
  updatedAt?: Maybe<Scalars['String']>
}

export type CampaignAirdrop = {
  __typename?: 'CampaignAirdrop'
  campaign: Campaign
  id?: Maybe<Scalars['String']>
  toolIds: Array<Scalars['String']>
  totalAirdropToken: Scalars['String']
  tvl: Scalars['String']
}

export type CampaignAirdropExplore = {
  __typename?: 'CampaignAirdropExplore'
  currentHourlyAirdropToken?: Maybe<Scalars['String']>
  id: Scalars['String']
  totalAirdropToken?: Maybe<Scalars['String']>
  tvl?: Maybe<Scalars['String']>
}

export type CampaignAirdropTask = {
  __typename?: 'CampaignAirdropTask'
  endTime: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
  startTime: Scalars['String']
  tweetLink: Scalars['String']
  userTaskFinishedAt?: Maybe<Scalars['String']>
  userTaskUserId?: Maybe<Scalars['String']>
}

export enum CampaignType {
  Airdrop = 'airdrop',
  Unknown = 'unknown',
}

export type CefiDepositAddress = {
  __typename?: 'CefiDepositAddress'
  address: Scalars['String']
  chain: Chain
}

export type CefiDepositAddressInput = {
  chain: Chain
  currency: Scalars['String']
  exchange: Exchange
  wallet?: InputMaybe<WalletType>
}

export type CefiWallet = {
  __typename?: 'CefiWallet'
  exchange: Scalars['String']
  walletBalances: Array<WalletBalances>
}

export type CefiWalletFilterInput = {
  exchange: Exchange
  hideTinyUSDValue?: InputMaybe<Scalars['Int']>
}

export enum Chain {
  Bitcoin = 'Bitcoin',
  Ethereum = 'Ethereum',
  Polygon = 'Polygon',
  Tron = 'Tron',
}

export type CloseUniswapv3PositionInput = {
  positionId: Scalars['String']
  txHash: Scalars['String']
}

export type CoinPrice = {
  __typename?: 'CoinPrice'
  symbol: Scalars['String']
  usdtPrice: Scalars['String']
}

export type CreateAiCompletionRecordInput = {
  currentItems?: InputMaybe<Array<CreateAiCompletionRecordItemInput>>
  expectedReturn: Scalars['String']
  goal: Scalars['String']
  investmentAmount: Scalars['String']
  investmentPeriod: Scalars['String']
  items: Array<CreateAiCompletionRecordItemInput>
  language?: InputMaybe<Scalars['String']>
  otherPreferenceDescription?: InputMaybe<Scalars['String']>
  promptVersion?: InputMaybe<PromptVersion>
  riskToleranceLevel: RiskToleranceLevelName
}

export type CreateAiCompletionRecordItemInput = {
  desc: Scalars['String']
  highestAnnualReturn: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  performanceFee?: InputMaybe<Scalars['String']>
  sid: Scalars['String']
}

export type CreateUniswapv3PositionInput = {
  toolId: Scalars['String']
  txHash: Scalars['String']
}

export type CreateUniswapv3ToolInput = {
  chain: EvmChain
  description?: InputMaybe<Scalars['String']>
  feeTier: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  outOfLowerRange: OutOfRangeInfoInput
  outOfUpperRange: OutOfRangeInfoInput
  tickLowerDiff: Scalars['Float']
  tickUpperDiff: Scalars['Float']
  token0Address: Scalars['String']
  token1Address: Scalars['String']
}

export type Creator = {
  __typename?: 'Creator'
  address: Scalars['String']
  id: Scalars['String']
}

export type CurrencyAmount = {
  __typename?: 'CurrencyAmount'
  amount: Scalars['String']
  currency: Scalars['String']
}

export type CurrencyBalance = {
  __typename?: 'CurrencyBalance'
  available: Scalars['String']
  total: Scalars['String']
}

export type CurrencyBalances = {
  __typename?: 'CurrencyBalances'
  balance: CurrencyBalance
  currency: Scalars['String']
}

export type CurrencyInfo = {
  __typename?: 'CurrencyInfo'
  decimals: Scalars['Int']
  name: Scalars['String']
  symbol: Scalars['String']
}

export type DefiTokensBalanceAmountInput = {
  chain: EvmChain
  chainId: Scalars['Int']
  tokenAddresses: Array<Scalars['String']>
}

export type DefiWallet = {
  __typename?: 'DefiWallet'
  balance: Scalars['String']
  balanceComputed?: Maybe<Scalars['String']>
  balanceComputedWithUnit?: Maybe<Scalars['String']>
  chain: EvmChain
  currencyInfo: CurrencyInfo
  iconifySrc: Scalars['String']
  id: Scalars['String']
}

export type DefiWalletFilterInput = {
  address: Scalars['String']
}

export type DefiWalletsFilterInput = {
  chains?: InputMaybe<Array<EvmChain>>
}

export type DelistUserOwnedUniswapv3ToolInput = {
  toolId: Scalars['String']
}

export enum EvmChain {
  Arbitrum = 'Arbitrum',
  Ethereum = 'Ethereum',
  Goerli = 'Goerli',
  Optimism = 'Optimism',
  OptimismGoerli = 'OptimismGoerli',
  Polygon = 'Polygon',
  Unknown = 'Unknown',
}

export enum Exchange {
  Bitfinex = 'Bitfinex',
  Tradingview = 'Tradingview',
  Uniswap = 'Uniswap',
  Unknown = 'Unknown',
}

export type ExternalApiKey = {
  __typename?: 'ExternalAPIKey'
  createdAt?: Maybe<Scalars['String']>
  exchange: Exchange
  id: Scalars['ID']
  isRevoked: Scalars['Boolean']
  key: Scalars['String']
  name: Scalars['String']
  user?: Maybe<UserInfo>
  userId?: Maybe<Scalars['String']>
}

export type ExternalApiKeyCreateInput = {
  exchange: Exchange
  key: Scalars['String']
  name: Scalars['String']
  secret: Scalars['String']
}

export type ExternalApiKeyDeleteInput = {
  id: Scalars['String']
}

export type ExternalApiKeysFilter = {
  exchange?: InputMaybe<Exchange>
  isRevoked?: InputMaybe<Scalars['Boolean']>
}

export type FrontendVersion = {
  __typename?: 'FrontendVersion'
  commitHash: Scalars['String']
  smartContracts: Array<SmartContract>
  version: Scalars['String']
}

export type GasFeeWallet = {
  __typename?: 'GasFeeWallet'
  address: Scalars['String']
  amount: Scalars['String']
  amountSymbol: Scalars['String']
  amountUsd?: Maybe<Scalars['String']>
  chain: EvmChain
  id: Scalars['String']
  isLoading: Scalars['Boolean']
  status: GasFeeWalletStatus
  updatedAt: Scalars['String']
}

export enum GasFeeWalletStatus {
  Insufficient = 'insufficient',
  Low = 'low',
  Normal = 'normal',
}

export type GimCoolPayPaymentInfo = {
  __typename?: 'GimCoolPayPaymentInfo'
  blockchain: GimCoolPayPaymentSupportingBlockchain
  coinAmount?: Maybe<Scalars['String']>
  coinType: GimCoolPayPaymentSupportingCoinType
  fiatAmount?: Maybe<Scalars['String']>
  fiatType: GimCoolPayPaymentSupportingFiatType
  paymentHref: Scalars['String']
  price?: Maybe<GimCoolPayPriceResponse>
  walletAddress: Scalars['String']
  walletAddressTag?: Maybe<Scalars['String']>
}

export type GimCoolPayPaymentInfoInput = {
  blockchain: GimCoolPayPaymentSupportingBlockchain
  coinAmount: Scalars['String']
  coinType: GimCoolPayPaymentSupportingCoinType
  fiatType: GimCoolPayPaymentSupportingFiatType
  isSandBox?: InputMaybe<Scalars['Boolean']>
}

export enum GimCoolPayPaymentSupportingBlockchain {
  Eth = 'ETH',
  Unknown = 'Unknown',
}

export enum GimCoolPayPaymentSupportingCoinType {
  Eth = 'ETH',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Unknown = 'Unknown',
}

export enum GimCoolPayPaymentSupportingFiatType {
  Usd = 'USD',
  Unknown = 'Unknown',
}

export type GimCoolPayPriceResponse = {
  __typename?: 'GimCoolPayPriceResponse'
  prices?: Maybe<Array<GimCoolPayReadyToPurchasePrice>>
  spotPrice: Scalars['String']
}

export type GimCoolPayReadyToPurchasePrice = {
  __typename?: 'GimCoolPayReadyToPurchasePrice'
  coinAmount: Scalars['String']
  coinCode: Scalars['String']
  feeAmount: Scalars['String']
  fiatAmount: Scalars['String']
  fiatCode: Scalars['String']
  networkFee: Scalars['String']
  paymentMethodId: Scalars['String']
  spotPriceFee: Scalars['String']
  spotPriceIncludingFee: Scalars['String']
  type: Scalars['String']
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>
  _gt?: InputMaybe<Scalars['Int']>
  _gte?: InputMaybe<Scalars['Int']>
  _in?: InputMaybe<Array<Scalars['Int']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Int']>
  _lte?: InputMaybe<Scalars['Int']>
  _neq?: InputMaybe<Scalars['Int']>
  _nin?: InputMaybe<Array<Scalars['Int']>>
}

export type ListUserOwnedUniswapv3ToolInput = {
  toolId: Scalars['String']
  txHash: Scalars['String']
}

export type Metric = {
  __typename?: 'Metric'
  createdAt: Scalars['String']
  id: Scalars['String']
  positionManagersInfo?: Maybe<Array<PositionManagerInfo>>
  positions?: Maybe<Array<MetricPosition>>
}

export type MetricPosition = {
  __typename?: 'MetricPosition'
  amountUsd: Scalars['String']
  chain: EvmChain
  createdAt: Scalars['String']
  feeUsd: Scalars['String']
  id: Scalars['String']
  positionId: Scalars['Int']
  positionIdTotalCount: Scalars['Int']
  positionIsRunning: Scalars['Boolean']
  positionManagerContractAddress: Scalars['String']
  positionOwnerAddress: Scalars['String']
  strategyId: Scalars['String']
  strategyProvider: Scalars['String']
  sumUsd: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  closeUniswapv3Position?: Maybe<Scalars['String']>
  createAICompletionRecord: AICompletionRecords
  /** Reyield Backend will monitor this position with the txHash (either pending or confirmed) */
  createUniswapv3Position?: Maybe<Scalars['String']>
  createUniswapv3Tool?: Maybe<Scalars['String']>
  delistUserOwnedUniswapv3Tool?: Maybe<Scalars['String']>
  externalAPIKeyCreate: ExternalApiKey
  externalAPIKeyDelete: ExternalApiKey
  generateMetrics: Scalars['Boolean']
  listUserOwnedUniswapv3Tool?: Maybe<Scalars['String']>
  refreshUserUniswapV3Tool?: Maybe<UserUniswapPositionInfo>
  strategyStart: StrategyStartResponse
  strategyStop: StrategyStopResponse
  strategyUpdate: StrategyUpdateResponse
  updateUserOwnedUniswapv3ToolsInfo?: Maybe<Scalars['String']>
}

export type MutationCloseUniswapv3PositionArgs = {
  input: CloseUniswapv3PositionInput
}

export type MutationCreateAiCompletionRecordArgs = {
  input: CreateAiCompletionRecordInput
}

export type MutationCreateUniswapv3PositionArgs = {
  input: CreateUniswapv3PositionInput
}

export type MutationCreateUniswapv3ToolArgs = {
  input: CreateUniswapv3ToolInput
}

export type MutationDelistUserOwnedUniswapv3ToolArgs = {
  input: DelistUserOwnedUniswapv3ToolInput
}

export type MutationExternalApiKeyCreateArgs = {
  input: ExternalApiKeyCreateInput
}

export type MutationExternalApiKeyDeleteArgs = {
  input: ExternalApiKeyDeleteInput
}

export type MutationListUserOwnedUniswapv3ToolArgs = {
  input: ListUserOwnedUniswapv3ToolInput
}

export type MutationRefreshUserUniswapV3ToolArgs = {
  input: RefreshUniswapv3ToolInput
}

export type MutationStrategyStartArgs = {
  input: StrategyStartInput
}

export type MutationStrategyStopArgs = {
  input: StrategyStopInput
}

export type MutationStrategyUpdateArgs = {
  input: StrategyUpdateInput
}

export type MutationUpdateUserOwnedUniswapv3ToolsInfoArgs = {
  input: UpdateUserOwnedUniswapv3ToolsInfoInput
}

export type NftCollections = {
  __typename?: 'NFTCollections'
  attributes?: Maybe<Array<Maybe<Attributes>>>
  contract: Scalars['String']
  description?: Maybe<Scalars['String']>
  imageUrl: Scalars['String']
  name?: Maybe<Scalars['String']>
  tokenId: Scalars['String']
}

export type NftMerkleTreeHexProof = {
  __typename?: 'NFTMerkleTreeHexProof'
  hexProof?: Maybe<Array<Scalars['String']>>
}

export type NftWhitelistInfoByPhrase = {
  __typename?: 'NFTWhitelistInfoByPhrase'
  id: Scalars['String']
  isWhitelisted: Scalars['Boolean']
  phrase: Nft_Round
  userAddress: Scalars['String']
}

export enum Nft_Round {
  Airdrop = 'AIRDROP',
  AllowWhitelistMint = 'ALLOW_WHITELIST_MINT',
  GuaranteeWhitelistMint = 'GUARANTEE_WHITELIST_MINT',
  SoldOut = 'SOLD_OUT',
  WhitelistCheck = 'WHITELIST_CHECK',
}

export type OutOfRangeInfoInput = {
  earnSpendRate: Scalars['Int']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export type PaginationInput = {
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export enum PoolsWithArgumentsFlag {
  /** This will use default value to filter */
  Default = 'Default',
  /** This will include selected Tokens (Ex: BTC, ETH ...) */
  Generic = 'Generic',
  /** This will include stable tokens (Ex: USDT, USDC ...) */
  Stables = 'Stables',
}

export type PositionManagerInfo = {
  __typename?: 'PositionManagerInfo'
  chain: EvmChain
  count: Scalars['Int']
  id: Scalars['String']
}

export enum PromptVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
  V4 = 'V4',
}

export enum PublicUniswapV3ToolStatus {
  Deprecated = 'deprecated',
  Listed = 'listed',
  Unknown = 'unknown',
}

export type Query = {
  __typename?: 'Query'
  campaignAirdrop?: Maybe<CampaignAirdrop>
  campaignAirdropExplore: CampaignAirdropExplore
  campaignAirdropTools?: Maybe<Array<UniswapToolInfo>>
  coinPrices?: Maybe<Array<CoinPrice>>
  me: UserInfo
  metric?: Maybe<Array<Metric>>
  poolsWithArguments?: Maybe<Array<UniswapPoolInfo>>
  rateHistorical?: Maybe<Array<RateHistorical>>
  strategies: Array<StrategyInfo>
  tools: Array<ToolInfo>
  toolsSupportingTokens?: Maybe<Array<TokenDisplayInfo>>
  uniswapQuote?: Maybe<UniswapQuote>
  uniswapTokens?: Maybe<Array<UniswapToolToken>>
  uniswapV3Backtest?: Maybe<UniswapV3BacktestResult>
  version: FrontendVersion
  wallet?: Maybe<DefiWallet>
}

export type QueryCampaignAirdropExploreArgs = {
  campaignId: Scalars['String']
}

export type QueryCampaignAirdropToolsArgs = {
  filter?: InputMaybe<ToolFilterInput>
}

export type QueryPoolsWithArgumentsArgs = {
  chain: EvmChain
  first?: InputMaybe<Scalars['Int']>
  flag?: InputMaybe<PoolsWithArgumentsFlag>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: InputMaybe<Scalars['String']>
  poolIds?: InputMaybe<Array<Scalars['String']>>
  skip?: InputMaybe<Scalars['Int']>
  token0s?: InputMaybe<Array<Scalars['String']>>
  token1s?: InputMaybe<Array<Scalars['String']>>
}

export type QueryRateHistoricalArgs = {
  filter: RateHistoricalFilterInput
}

export type QueryStrategiesArgs = {
  filter?: InputMaybe<StrategyFilterInput>
}

export type QueryToolsArgs = {
  filter?: InputMaybe<ToolFilterInput>
}

export type QueryToolsSupportingTokensArgs = {
  input?: InputMaybe<ToolsSupportingTokensInput>
}

export type QueryUniswapQuoteArgs = {
  input: UniswapQuoteInput
  source?: InputMaybe<QuoteDataSource>
}

export type QueryUniswapTokensArgs = {
  chain: EvmChain
}

export type QueryUniswapV3BacktestArgs = {
  compound: Scalars['Boolean']
  days: Scalars['Int']
  diff: Scalars['Float']
  investmentAmount: Scalars['Float']
  period: UniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: EvmChain
}

export type QueryWalletArgs = {
  input?: InputMaybe<DefiWalletFilterInput>
}

export enum QuoteDataSource {
  Blockchain = 'blockchain',
  Thegraph = 'thegraph',
}

export type RateHistorical = {
  __typename?: 'RateHistorical'
  rate: Scalars['String']
  time: Scalars['String']
}

export type RateHistoricalFilterInput = {
  period: Scalars['String']
  sid?: InputMaybe<Scalars['String']>
}

export enum RecordsType {
  Revenue = 'revenue',
  Unknown = 'unknown',
}

export type RefreshUniswapv3ToolInput = {
  positionId: Scalars['String']
}

export type ReyieldLicense = {
  __typename?: 'ReyieldLicense'
  numberOfLicense: Scalars['Float']
  numberOfUsedLicense: Scalars['Float']
}

export enum RiskLevel {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  MediumHigh = 'medium_high',
  MediumLow = 'medium_low',
  Unknown = 'unknown',
}

export enum RiskToleranceLevelName {
  Aggressive = 'Aggressive',
  Conservative = 'Conservative',
  ExtremelyAggressive = 'ExtremelyAggressive',
  ExtremelyConservative = 'ExtremelyConservative',
  Moderate = 'Moderate',
  VeryConservative = 'VeryConservative',
}

export type SmartContract = {
  __typename?: 'SmartContract'
  address: Scalars['String']
  chain: EvmChain
  name: Scalars['String']
}

export type StrategyFilterInput = {
  riskLevel?: InputMaybe<RiskLevel>
  sid?: InputMaybe<Scalars['String']>
  type?: InputMaybe<StrategyType>
}

export type StrategyInfo = {
  __typename?: 'StrategyInfo'
  currency: Scalars['String']
  description: Scalars['String']
  earningCurrency: Scalars['String']
  exchanges: Array<Exchange>
  expectedAPY: Scalars['String']
  id: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  parameters: Array<StrategyParameters>
  riskLevel: RiskLevel
  sid: Scalars['String']
  stage: StrategyInfoStage
  suggestionPeriod: Scalars['String']
  target: Scalars['String']
  type: StrategyType
}

export enum StrategyInfoStage {
  Active = 'Active',
  Beta = 'Beta',
  Mock = 'Mock',
}

export type StrategyInfos = {
  __typename?: 'StrategyInfos'
  currency: Scalars['String']
  earningCurrency: Scalars['String']
  exchange?: Maybe<Exchange>
  minEnableAmount: Scalars['String']
  name: Scalars['String']
}

export enum StrategyParameterType {
  Bool = 'bool',
  Num = 'num',
  Str = 'str',
  Unknown = 'unknown',
}

export type StrategyParameters = {
  __typename?: 'StrategyParameters'
  defaultValue?: Maybe<Scalars['String']>
  description: Scalars['String']
  key: Scalars['String']
  maxValue?: Maybe<Scalars['String']>
  minValue?: Maybe<Scalars['String']>
  name: Scalars['String']
  type: StrategyParameterType
  updatable: Scalars['Boolean']
}

export type StrategyPerformance = {
  __typename?: 'StrategyPerformance'
  amount: Scalars['String']
  apy: Scalars['String']
  currency: Scalars['String']
  ror: Scalars['String']
}

export type StrategyProgress = {
  __typename?: 'StrategyProgress'
  createdAt: Scalars['String']
  id: Scalars['ID']
  index: Scalars['Int']
  status: StrategyProgressStatus
  steps: Array<Maybe<StrategyProgressStep>>
  type: StrategyProgressType
  updatedAt: Scalars['String']
}

export enum StrategyProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type StrategyProgressStep = {
  __typename?: 'StrategyProgressStep'
  status: StrategyProgressStepStatus
  type: StrategyProgressStepType
}

export enum StrategyProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum StrategyProgressStepType {
  CexDepositCheck = 'cex_deposit_check',
  CexSwap = 'cex_swap',
  CexWalletsTransfer = 'cex_wallets_transfer',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export enum StrategyProgressType {
  StrategyFinalize = 'strategy_finalize',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export type StrategyStartInput = {
  parameters: Array<StrategyStartParamenters>
  sid: Scalars['String']
}

export type StrategyStartParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type StrategyStartResponse = {
  __typename?: 'StrategyStartResponse'
  strategy: UserStrategyInfo
}

export enum StrategyStatus {
  Error = 'error',
  Preparing = 'preparing',
  Running = 'running',
  Stopped = 'stopped',
  Stopping = 'stopping',
  Unknown = 'unknown',
  WaitForUserAction = 'wait_for_user_action',
}

export type StrategyStopInput = {
  isForce?: InputMaybe<Scalars['Boolean']>
  strategyID: Scalars['String']
}

export type StrategyStopResponse = {
  __typename?: 'StrategyStopResponse'
  strategy: UserStrategyInfo
}

export enum StrategyType {
  Arbitrage = 'arbitrage',
  Defi = 'defi',
  Hedging = 'hedging',
  Lending = 'lending',
  Unknown = 'unknown',
}

export type StrategyUpdateInput = {
  parameters: Array<StrategyUpdateParamenters>
  strategyID: Scalars['String']
}

export type StrategyUpdateParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type StrategyUpdateResponse = {
  __typename?: 'StrategyUpdateResponse'
  strategy: UserStrategyInfo
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

export type TokenBalance = {
  __typename?: 'TokenBalance'
  address: Scalars['String']
  amount: Scalars['String']
  amountReadable: Scalars['String']
  chain: EvmChain
  decimals: Scalars['Int']
  id: Scalars['String']
  symbol: Scalars['String']
}

export type TokenDisplayInfo = {
  __typename?: 'TokenDisplayInfo'
  address: Scalars['String']
  chain: EvmChain
  id: Scalars['String']
  imageURL: Scalars['String']
  name: Scalars['String']
  symbol: Scalars['String']
  usdtPrice?: Maybe<Scalars['String']>
}

export type ToolFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']>>
}

export type ToolInfo = StrategyInfo | UniswapToolInfo

export type ToolsSupportingTokensInput = {
  chains?: InputMaybe<Array<EvmChain>>
}

export type TotalProfits = {
  __typename?: 'TotalProfits'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
}

export type UniswapOnchainPositionAmountInfo = {
  __typename?: 'UniswapOnchainPositionAmountInfo'
  /** this data is from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Amount: Scalars['String']
  token0AmountUsdValue: Scalars['String']
  /** this data is from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Amount: Scalars['String']
  token1AmountUsdValue: Scalars['String']
}

export type UniswapPoolInfo = {
  __typename?: 'UniswapPoolInfo'
  averageTradingVolumeUSD?: Maybe<Scalars['String']>
  chain: EvmChain
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  collectedFeesUSD: Scalars['String']
  createdAtBlockNumber: Scalars['String']
  createdAtTimestamp: Scalars['String']
  feeGrowthGlobal0X128: Scalars['String']
  feeGrowthGlobal1X128: Scalars['String']
  feeTier: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  /** this part is from smart contract developed by reyield */
  isPoolActiveForReyield?: Maybe<Scalars['Boolean']>
  liquidity: Scalars['String']
  liquidityProviderCount: Scalars['String']
  observationIndex: Scalars['String']
  positions?: Maybe<Array<UniswapPoolPosition>>
  sqrtPrice: Scalars['String']
  tick?: Maybe<Scalars['String']>
  ticks?: Maybe<Array<UniswapPoolTick>>
  token0?: Maybe<UniswapToolToken>
  token0Price: Scalars['String']
  token1?: Maybe<UniswapToolToken>
  token1Price: Scalars['String']
  totalValueLockedETH: Scalars['String']
  totalValueLockedToken0: Scalars['String']
  totalValueLockedToken1: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volumeToken0: Scalars['String']
  volumeToken1: Scalars['String']
  volumeUSD: Scalars['String']
}

export type UniswapPoolInfoAverageTradingVolumeUsdArgs = {
  numberOfDays: Scalars['Int']
}

export type UniswapPoolPosition = {
  __typename?: 'UniswapPoolPosition'
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  depositedToken0: Scalars['String']
  depositedToken1: Scalars['String']
  feeGrowthInside0LastX128: Scalars['String']
  feeGrowthInside1LastX128: Scalars['String']
  id: Scalars['ID']
  liquidity: Scalars['String']
  tickLowerFeeGrowthOutside0X128: Scalars['String']
  tickLowerFeeGrowthOutside1X128: Scalars['String']
  tickLowerTickIdx: Scalars['String']
  tickUpperFeeGrowthOutside0X128: Scalars['String']
  tickUpperFeeGrowthOutside1X128: Scalars['String']
  tickUpperTickIdx: Scalars['String']
  transactionTimestamp: Scalars['String']
}

export type UniswapPoolTick = {
  __typename?: 'UniswapPoolTick'
  id: Scalars['String']
  liquidityNet: Scalars['String']
  price0: Scalars['String']
  price1: Scalars['String']
  tickIdx: Scalars['String']
}

export type UniswapQuote = {
  __typename?: 'UniswapQuote'
  chain: EvmChain
  fromToken: UniswapToolToken
  fromTokenAddress: Scalars['String']
  id: Scalars['String']
  inputAmount: Scalars['String']
  inputReadableAmount: Scalars['String']
  outputAmount: Scalars['String']
  outputReadableAmount: Scalars['String']
  toToken: UniswapToolToken
  toTokenAddress: Scalars['String']
}

export type UniswapQuoteInput = {
  chain: EvmChain
  fromTokenAddress: Scalars['String']
  inputReadableAmount: Scalars['String']
  toTokenAddress: Scalars['String']
}

export type UniswapToolFeeTier = {
  __typename?: 'UniswapToolFeeTier'
  feeForContract: Scalars['String']
  feeForDisplay: Scalars['String']
  id: Scalars['String']
}

export type UniswapToolInfo = {
  __typename?: 'UniswapToolInfo'
  apyEstimationBaseOnInputUsdValue?: Maybe<Scalars['String']>
  apyHighest7d: Scalars['String']
  apyHighest24h: Scalars['String']
  apyHighest30d: Scalars['String']
  apyHighestCurrent: Scalars['String']
  apyHistory?: Maybe<Array<ApyHistory>>
  chain: EvmChain
  createdAt: Scalars['String']
  creator?: Maybe<Creator>
  creatorAddress: Scalars['String']
  creatorDescription?: Maybe<Scalars['String']>
  creatorId: Scalars['String']
  creatorName?: Maybe<Scalars['String']>
  description: Scalars['String']
  expectedAPY: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  performanceFee: Scalars['String']
  poolAddress: Scalars['String']
  /** this data is from thegraph */
  poolInfo?: Maybe<UniswapPoolInfo>
  riskLevel: RiskLevel
  status: PublicUniswapV3ToolStatus
  tags?: Maybe<Array<UniswapToolTag>>
  tickLowerDiff: Scalars['String']
  tickLowerDiffForContract: Scalars['String']
  tickUpperDiff: Scalars['String']
  tickUpperDiffForContract?: Maybe<Scalars['String']>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  tvlPool: Scalars['String']
  tvlTool: Scalars['String']
}

export type UniswapToolInfoApyHistoryArgs = {
  period: ApyHistoryPeriod
}

export enum UniswapToolPositionStatus {
  Closed = 'CLOSED',
  Closing = 'CLOSING',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Unknown = 'UNKNOWN',
}

export enum UniswapToolStatus {
  Created = 'CREATED',
  Deprecated = 'DEPRECATED',
  Listed = 'LISTED',
  Listing = 'LISTING',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
}

export type UniswapToolTag = {
  __typename?: 'UniswapToolTag'
  id: Scalars['String']
  name: Scalars['String']
  type: UniswapToolTagType
}

export enum UniswapToolTagType {
  Chain = 'chain',
  Custom = 'custom',
  IsOfficial = 'isOfficial',
  Uniswapv3 = 'uniswapv3',
  Unknown = 'unknown',
}

export type UniswapToolToken = {
  __typename?: 'UniswapToolToken'
  chain: EvmChain
  decimals: Scalars['String']
  derivedETH: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  poolCount: Scalars['String']
  priceChart?: Maybe<UniswapToolTokenPriceChart>
  symbol: Scalars['String']
  totalSupply: Scalars['String']
  totalValueLocked: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volume: Scalars['String']
  volumeUSD: Scalars['String']
}

export type UniswapToolTokenPriceChart = {
  __typename?: 'UniswapToolTokenPriceChart'
  currentPriceUSD?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  prices?: Maybe<Array<UniswapToolTokenPriceChartPrice>>
}

export type UniswapToolTokenPriceChartPrice = {
  __typename?: 'UniswapToolTokenPriceChartPrice'
  timestamp: Scalars['String']
  value: Scalars['String']
}

export enum UniswapV3BacktestPeriod {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type UniswapV3BacktestResult = {
  __typename?: 'UniswapV3BacktestResult'
  activeLiquidityPercent: Scalars['Float']
  annualReturn: Scalars['Float']
  compound: Scalars['Boolean']
  createdAt: Scalars['String']
  dailyReturn: Scalars['Float']
  dataPointsCount: Scalars['String']
  days: Scalars['Int']
  diff: Scalars['Float']
  fees?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  impermanentLoss: Scalars['Float']
  investmentAmount: Scalars['Float']
  maxDrawdown?: Maybe<Scalars['Float']>
  period: UniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: EvmChain
  totalReturn?: Maybe<Scalars['Float']>
  volatility?: Maybe<Scalars['Float']>
}

export type UpdateUserOwnedUniswapv3ToolsInfoInput = {
  description: Scalars['String']
  name: Scalars['String']
  toolId: Scalars['String']
}

export type UserAiCompletionRecordsFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserCampaignAirdropPortfolio = {
  __typename?: 'UserCampaignAirdropPortfolio'
  id: Scalars['String']
  tasks?: Maybe<Array<CampaignAirdropTask>>
  userTotalEarnedReyieldTokens?: Maybe<Scalars['String']>
  userTotalInvestingAmount?: Maybe<Scalars['String']>
  userTotalProfit?: Maybe<Scalars['String']>
}

export type UserInfo = {
  __typename?: 'UserInfo'
  aICompletionRecords?: Maybe<Array<AICompletionRecords>>
  avatar?: Maybe<Scalars['String']>
  campaignAirdropPortfolio?: Maybe<UserCampaignAirdropPortfolio>
  campaignAirdropPositions?: Maybe<Array<UserUniswapPositionInfo>>
  cefiDepositAddress?: Maybe<CefiDepositAddress>
  cefiWallets?: Maybe<Array<CefiWallet>>
  defiTokensBalanceAmount?: Maybe<Array<Maybe<TokenBalance>>>
  defiWallets?: Maybe<Array<DefiWallet>>
  externalAPIKeys?: Maybe<Array<ExternalApiKey>>
  gasFeeWallets?: Maybe<Array<GasFeeWallet>>
  gimCoolPayPaymentInfo?: Maybe<GimCoolPayPaymentInfo>
  id: Scalars['String']
  isBitfinexAPIKeyBind: Scalars['Boolean']
  nftCollections?: Maybe<Array<NftCollections>>
  nftMerkleTreeHexProof?: Maybe<NftMerkleTreeHexProof>
  nftWhitelistInfoByPhrases?: Maybe<Array<NftWhitelistInfoByPhrase>>
  numOfListedTool?: Maybe<Scalars['Int']>
  numOfListingLicense?: Maybe<Scalars['Int']>
  ownedToolsSetting?: Maybe<Array<UserUniswapToolSettingInfo>>
  privilege?: Maybe<Scalars['Boolean']>
  reyieldLicense?: Maybe<ReyieldLicense>
  strategies?: Maybe<Array<UserStrategyInfo>>
  strategyPerformance?: Maybe<UserStrategyPerformance>
  strategyProfit?: Maybe<UserStrategyProfit>
  strategyProgress?: Maybe<StrategyProgress>
  strategyRecords?: Maybe<Array<UserStrategyRecord>>
  tools?: Maybe<Array<UserToolInfo>>
}

export type UserInfoAiCompletionRecordsArgs = {
  filter?: InputMaybe<UserAiCompletionRecordsFilterInput>
}

export type UserInfoCampaignAirdropPortfolioArgs = {
  campaignId: Scalars['String']
}

export type UserInfoCampaignAirdropPositionsArgs = {
  campaignId: Scalars['String']
}

export type UserInfoCefiDepositAddressArgs = {
  filter?: InputMaybe<CefiDepositAddressInput>
}

export type UserInfoCefiWalletsArgs = {
  filter?: InputMaybe<CefiWalletFilterInput>
}

export type UserInfoDefiTokensBalanceAmountArgs = {
  input: DefiTokensBalanceAmountInput
}

export type UserInfoDefiWalletsArgs = {
  filter?: InputMaybe<DefiWalletsFilterInput>
}

export type UserInfoExternalApiKeysArgs = {
  filter?: InputMaybe<ExternalApiKeysFilter>
}

export type UserInfoGimCoolPayPaymentInfoArgs = {
  input?: InputMaybe<GimCoolPayPaymentInfoInput>
}

export type UserInfoStrategiesArgs = {
  filter?: InputMaybe<UserStrategyFilterInput>
}

export type UserInfoStrategyPerformanceArgs = {
  strategyID: Scalars['String']
}

export type UserInfoStrategyProfitArgs = {
  filter?: InputMaybe<UserStrategyProfitFilterInput>
}

export type UserInfoStrategyProgressArgs = {
  strategyID: Scalars['String']
}

export type UserInfoStrategyRecordsArgs = {
  filter?: InputMaybe<UserStrategyRecordsFilterInput>
}

export type UserStrategyFilterInput = {
  sid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status?: InputMaybe<Array<InputMaybe<StrategyStatus>>>
  strategyID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UserStrategyInfo = {
  __typename?: 'UserStrategyInfo'
  ceFiStatus: StrategyStatus
  closedAt?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  errMsg?: Maybe<Scalars['String']>
  id: Scalars['String']
  infos?: Maybe<StrategyInfos>
  parameters?: Maybe<Array<UserStrategyParameters>>
  performance?: Maybe<UserStrategyPerformance>
  profits?: Maybe<UserStrategyProfit>
  sid: Scalars['String']
  updatedAt: Scalars['String']
}

export type UserStrategyParameters = {
  __typename?: 'UserStrategyParameters'
  key: Scalars['String']
  value: Scalars['String']
}

export type UserStrategyPerformance = {
  __typename?: 'UserStrategyPerformance'
  duration?: Maybe<Scalars['Int']>
  initialValue?: Maybe<Scalars['String']>
  performances?: Maybe<Array<Maybe<StrategyPerformance>>>
}

export type UserStrategyProfit = {
  __typename?: 'UserStrategyProfit'
  sid?: Maybe<Scalars['String']>
  totalProfit?: Maybe<Array<Maybe<TotalProfits>>>
  type?: Maybe<StrategyType>
}

export type UserStrategyProfitFilterInput = {
  sid: Array<InputMaybe<Scalars['String']>>
  type?: InputMaybe<Array<InputMaybe<StrategyType>>>
}

export type UserStrategyRecord = {
  __typename?: 'UserStrategyRecord'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  rate?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
  type?: Maybe<RecordsType>
}

export type UserStrategyRecordsFilterInput = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  strategyID: Scalars['String']
  type?: InputMaybe<RecordsType>
}

export type UserToolInfo = UserStrategyInfo | UserUniswapPositionInfo

export type UserUniswapOnchainPositionInfo = {
  __typename?: 'UserUniswapOnchainPositionInfo'
  apy: Scalars['String']
  chain: EvmChain
  closedPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  totalInvestAmountUsdValue?: Maybe<Scalars['String']>
}

export type UserUniswapPositionInfo = {
  __typename?: 'UserUniswapPositionInfo'
  apy: Scalars['String']
  campaignId?: Maybe<Scalars['String']>
  chain: EvmChain
  closedAt?: Maybe<Scalars['String']>
  closedPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  createdAt: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  /** backend will cache the data for n minutes */
  onchainPositionInfo?: Maybe<UserUniswapOnchainPositionInfo>
  performanceFeeChargeUsdValue?: Maybe<Scalars['String']>
  poolAddress: Scalars['String']
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  progresses?: Maybe<Array<UserUniswapv3PositionProgress>>
  publicToolInfo?: Maybe<UniswapToolInfo>
  reyieldTokens?: Maybe<Scalars['String']>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  status: UniswapToolPositionStatus
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  token0TotalFeeUsdValue: Scalars['String']
  /** api call from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  token1TotalFeeUsdValue: Scalars['String']
  tokenPriceFluctuationsUsd?: Maybe<Scalars['String']>
  toolDescription: Scalars['String']
  toolId: Scalars['String']
  toolIsOfficial: Scalars['Boolean']
  toolName: Scalars['String']
  toolPerformanceFeeRatio?: Maybe<Scalars['String']>
  /** if the user is the creator of the tool, then the toolSetting will be returned */
  toolSetting?: Maybe<UserUniswapToolSettingInfo>
  totalInvestAmountUsdValue: Scalars['String']
  uniswapIncomeUsd?: Maybe<Scalars['String']>
  unrealizedPnLUsd?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
  userAddress: Scalars['String']
}

export type UserUniswapPositionInfoOnchainPositionInfoArgs = {
  withoutCache?: InputMaybe<Scalars['Boolean']>
}

export type UserUniswapToolOutOfRangeInfo = {
  __typename?: 'UserUniswapToolOutOfRangeInfo'
  earnSpendRate: Scalars['String']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export type UserUniswapToolSettingInfo = {
  __typename?: 'UserUniswapToolSettingInfo'
  chain: EvmChain
  closedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  description: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  name: Scalars['String']
  outOfLowerRange: UserUniswapToolOutOfRangeInfo
  outOfUpperRange: UserUniswapToolOutOfRangeInfo
  performanceFeeRatio?: Maybe<Scalars['String']>
  poolAddress: Scalars['String']
  /** User will have only one position for each tool */
  position?: Maybe<UserUniswapPositionInfo>
  publicToolInfo?: Maybe<UniswapToolInfo>
  status: UniswapToolStatus
  tags?: Maybe<Array<UniswapToolTag>>
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  /** api call from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  totalUserUsed: Scalars['Int']
  updatedAt?: Maybe<Scalars['String']>
}

export type UserUniswapv3PositionProgress = {
  __typename?: 'UserUniswapv3PositionProgress'
  createdAt: Scalars['String']
  id: Scalars['String']
  index: Scalars['Int']
  status: UserUniswapv3PositionProgressStatus
  steps: Array<UserUniswapv3PositionProgressStep>
  type: UserUniswapv3PositionProgressType
  updatedAt: Scalars['String']
}

export enum UserUniswapv3PositionProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type UserUniswapv3PositionProgressStep = {
  __typename?: 'UserUniswapv3PositionProgressStep'
  status: UserUniswapv3PositionProgressStepStatus
  type: UserUniswapv3PositionProgressStepType
}

export enum UserUniswapv3PositionProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum UserUniswapv3PositionProgressStepType {
  UniswapV3ClosePositionWatching = 'uniswap_v3_close_position_watching',
  UniswapV3CreateListedToolPositionWatching = 'uniswap_v3_create_listed_tool_position_watching',
  UniswapV3CreatePositionWatching = 'uniswap_v3_create_position_watching',
  UniswapV3ListToolWatching = 'uniswap_v3_list_tool_watching',
  Unknown = 'unknown',
}

export enum UserUniswapv3PositionProgressType {
  UniswapV3ClosePosition = 'uniswap_v3_close_position',
  UniswapV3CreateListedToolPosition = 'uniswap_v3_create_listed_tool_position',
  UniswapV3CreatePosition = 'uniswap_v3_create_position',
  UniswapV3ListTool = 'uniswap_v3_list_tool',
  Unknown = 'unknown',
}

export type WalletBalances = {
  __typename?: 'WalletBalances'
  currencyBalances: Array<CurrencyBalances>
  wallet: Scalars['String']
}

export enum WalletType {
  BfxExchange = 'bfx_exchange',
  BfxFunding = 'bfx_funding',
  BfxMargin = 'bfx_margin',
  Spot = 'spot',
  Unknown = 'unknown',
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecords = {
  __typename?: 'aICompletionRecords'
  completedAt?: Maybe<Scalars['timestamptz']>
  content?: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  id: Scalars['String']
  meta?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  promptMutationInput?: Maybe<Scalars['jsonb']>
  promptMutationName?: Maybe<Scalars['String']>
  /** An array relationship */
  suggestions: Array<SuggestStrategyToolWithAllocations>
  /** An aggregate relationship */
  suggestions_aggregate: SuggestStrategyToolWithAllocations_Aggregate
  updatedAt: Scalars['timestamptz']
  userAddress?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsMetaArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsPromptMutationInputArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsSuggestionsArgs = {
  distinct_on?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsSuggestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations = {
  __typename?: 'suggestStrategyToolWithAllocations'
  aICompletionRecordId: Scalars['String']
  allocation: Scalars['String']
  createdAt: Scalars['timestamptz']
  description: Scalars['String']
  highestAnnualReturn: Scalars['String']
  id: Scalars['uuid']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  sid: Scalars['String']
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt: Scalars['timestamptz']
}

/** aggregated selection of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate = {
  __typename?: 'suggestStrategyToolWithAllocations_aggregate'
  aggregate?: Maybe<SuggestStrategyToolWithAllocations_Aggregate_Fields>
  nodes: Array<SuggestStrategyToolWithAllocations>
}

export type SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp = {
  count?: InputMaybe<SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count>
}

export type SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<SuggestStrategyToolWithAllocations_Max_Fields>
  min?: Maybe<SuggestStrategyToolWithAllocations_Min_Fields>
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<SuggestStrategyToolWithAllocations_Max_Order_By>
  min?: InputMaybe<SuggestStrategyToolWithAllocations_Min_Order_By>
}

/** Boolean expression to filter rows from the table "suggestStrategyToolWithAllocations". All fields are combined with a logical 'AND'. */
export type SuggestStrategyToolWithAllocations_Bool_Exp = {
  _and?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Bool_Exp>>
  _not?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
  _or?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Bool_Exp>>
  aICompletionRecordId?: InputMaybe<String_Comparison_Exp>
  allocation?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  highestAnnualReturn?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  maximumLoss?: InputMaybe<String_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  sid?: InputMaybe<String_Comparison_Exp>
  systemInvestAmountSuggestion?: InputMaybe<String_Comparison_Exp>
  systemInvestCurrencySuggestion?: InputMaybe<String_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** aggregate max on columns */
export type SuggestStrategyToolWithAllocations_Max_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_max_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Max_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type SuggestStrategyToolWithAllocations_Min_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_min_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Min_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** Ordering options when selecting data from "suggestStrategyToolWithAllocations". */
export type SuggestStrategyToolWithAllocations_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** select columns of table "suggestStrategyToolWithAllocations" */
export enum SuggestStrategyToolWithAllocations_Select_Column {
  /** column name */
  AICompletionRecordId = 'aICompletionRecordId',
  /** column name */
  Allocation = 'allocation',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  HighestAnnualReturn = 'highestAnnualReturn',
  /** column name */
  Id = 'id',
  /** column name */
  MaximumLoss = 'maximumLoss',
  /** column name */
  Name = 'name',
  /** column name */
  Sid = 'sid',
  /** column name */
  SystemInvestAmountSuggestion = 'systemInvestAmountSuggestion',
  /** column name */
  SystemInvestCurrencySuggestion = 'systemInvestCurrencySuggestion',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Streaming cursor of the table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input = {
  aICompletionRecordId?: InputMaybe<Scalars['String']>
  allocation?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  highestAnnualReturn?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  maximumLoss?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  sid?: InputMaybe<Scalars['String']>
  systemInvestAmountSuggestion?: InputMaybe<Scalars['String']>
  systemInvestCurrencySuggestion?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

export type CampaignAirdropTaskFragment = {
  __typename?: 'CampaignAirdropTask'
  id: string
  name: string
  startTime: string
  endTime: string
  tweetLink: string
  userTaskFinishedAt?: string | null
  userTaskUserId?: string | null
}

export type GasFeeWalletFragment = {
  __typename?: 'GasFeeWallet'
  id: string
  chain: EvmChain
  address: string
  amount: string
  amountSymbol: string
  amountUsd?: string | null
  status: GasFeeWalletStatus
  isLoading: boolean
  updatedAt: string
}

export type UniswapToolTagFragment = {
  __typename?: 'UniswapToolTag'
  id: string
  name: string
  type: UniswapToolTagType
}

export type TokenDisplayInfoFragment = {
  __typename?: 'TokenDisplayInfo'
  id: string
  chain: EvmChain
  name: string
  address: string
  imageURL: string
  symbol: string
  usdtPrice?: string | null
}

export type UserUniswapv3PositionProgressFragment = {
  __typename?: 'UserUniswapv3PositionProgress'
  id: string
  createdAt: string
  updatedAt: string
  index: number
  status: UserUniswapv3PositionProgressStatus
  type: UserUniswapv3PositionProgressType
  steps: Array<{
    __typename?: 'UserUniswapv3PositionProgressStep'
    status: UserUniswapv3PositionProgressStepStatus
    type: UserUniswapv3PositionProgressStepType
  }>
}

export type UserUniswapOnchainPositionInfoFragment = {
  __typename?: 'UserUniswapOnchainPositionInfo'
  apy: string
  roi: string
  totalInvestAmountUsdValue?: string | null
  token0Address: string
  token1Address: string
  positionIdFromSmartContractPositionManager?: number | null
  positionId: string
  runningPositionAmount?: {
    __typename?: 'UniswapOnchainPositionAmountInfo'
    token0Amount: string
    token1Amount: string
    token0AmountUsdValue: string
    token1AmountUsdValue: string
  } | null
  closedPositionAmount?: {
    __typename?: 'UniswapOnchainPositionAmountInfo'
    token0Amount: string
    token1Amount: string
    token0AmountUsdValue: string
    token1AmountUsdValue: string
  } | null
  token0DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
    usdtPrice?: string | null
  } | null
  token1DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
    usdtPrice?: string | null
  } | null
}

export type UniswapOnchainPositionAmountInfoFragment = {
  __typename?: 'UniswapOnchainPositionAmountInfo'
  token0Amount: string
  token1Amount: string
  token0AmountUsdValue: string
  token1AmountUsdValue: string
}

export type UserUniswapPositionInfoFragment = {
  __typename?: 'UserUniswapPositionInfo'
  id: string
  userAddress: string
  chain: EvmChain
  positionIdFromSmartContractPositionManager?: number | null
  toolId: string
  toolName: string
  toolDescription: string
  toolIsOfficial: boolean
  toolPerformanceFeeRatio?: string | null
  token0Address: string
  token1Address: string
  poolAddress: string
  tickLowerDiff: string
  tickUpperDiff: string
  createdAt: string
  closedAt?: string | null
  updatedAt: string
  apy: string
  roi: string
  totalInvestAmountUsdValue: string
  status: UniswapToolPositionStatus
  token0TotalFeeUsdValue: string
  token1TotalFeeUsdValue: string
  unrealizedPnLUsd?: string | null
  uniswapIncomeUsd?: string | null
  performanceFeeChargeUsdValue?: string | null
  tokenPriceFluctuationsUsd?: string | null
  campaignId?: string | null
  reyieldTokens?: string | null
  token0DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
    usdtPrice?: string | null
  } | null
  token1DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
    usdtPrice?: string | null
  } | null
  publicToolInfo?: {
    __typename?: 'UniswapToolInfo'
    id: string
    description: string
    status: PublicUniswapV3ToolStatus
    chain: EvmChain
    createdAt: string
    name: string
    numOfApply?: number | null
    expectedAPY: string
    isOfficial: boolean
    riskLevel: RiskLevel
    tickLowerDiff: string
    tickLowerDiffForContract: string
    tickUpperDiff: string
    tickUpperDiffForContract?: string | null
    performanceFee: string
    token0Address: string
    creatorName?: string | null
    creatorDescription?: string | null
    token1Address: string
    creatorId: string
    creatorAddress: string
    maximumLoss: string
    poolAddress: string
    apyEstimationBaseOnInputUsdValue?: string | null
    apyHighestCurrent: string
    apyHighest24h: string
    apyHighest7d: string
    apyHighest30d: string
    tvlTool: string
    tvlPool: string
    token0DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
    token1DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
    tags?: Array<{
      __typename?: 'UniswapToolTag'
      id: string
      name: string
      type: UniswapToolTagType
    }> | null
    feeTier: {
      __typename?: 'UniswapToolFeeTier'
      id: string
      feeForContract: string
      feeForDisplay: string
    }
    poolInfo?: {
      __typename?: 'UniswapPoolInfo'
      id: string
      untrackedVolumeUSD: string
      txCount: string
      totalValueLockedUSDUntracked: string
      totalValueLockedUSD: string
      totalValueLockedToken1: string
      totalValueLockedToken0: string
      totalValueLockedETH: string
      observationIndex: string
      feesUSD: string
      createdAtBlockNumber: string
      createdAtTimestamp: string
      liquidityProviderCount: string
      liquidity: string
      sqrtPrice: string
      feeGrowthGlobal0X128: string
      feeGrowthGlobal1X128: string
      tick?: string | null
      volumeUSD: string
      token0Price: string
      token1Price: string
      averageTradingVolumeUSD1D?: string | null
      averageTradingVolumeUSD7D?: string | null
      token0?: {
        __typename?: 'UniswapToolToken'
        id: string
        symbol: string
        name: string
        volumeUSD: string
        chain: EvmChain
        decimals: string
        volume: string
        untrackedVolumeUSD: string
        feesUSD: string
        txCount: string
        poolCount: string
        totalValueLocked: string
        totalValueLockedUSD: string
        totalValueLockedUSDUntracked: string
        derivedETH: string
        totalSupply: string
      } | null
      token1?: {
        __typename?: 'UniswapToolToken'
        id: string
        symbol: string
        name: string
        volumeUSD: string
        chain: EvmChain
        decimals: string
        volume: string
        untrackedVolumeUSD: string
        feesUSD: string
        txCount: string
        poolCount: string
        totalValueLocked: string
        totalValueLockedUSD: string
        totalValueLockedUSDUntracked: string
        derivedETH: string
        totalSupply: string
      } | null
    } | null
  } | null
  feeTier: {
    __typename?: 'UniswapToolFeeTier'
    id: string
    feeForDisplay: string
    feeForContract: string
  }
  runningPositionAmount?: {
    __typename?: 'UniswapOnchainPositionAmountInfo'
    token0Amount: string
    token1Amount: string
    token0AmountUsdValue: string
    token1AmountUsdValue: string
  } | null
  closedPositionAmount?: {
    __typename?: 'UniswapOnchainPositionAmountInfo'
    token0Amount: string
    token1Amount: string
    token0AmountUsdValue: string
    token1AmountUsdValue: string
  } | null
  onchainPositionInfo?: {
    __typename?: 'UserUniswapOnchainPositionInfo'
    apy: string
    roi: string
    totalInvestAmountUsdValue?: string | null
    token0Address: string
    token1Address: string
    positionIdFromSmartContractPositionManager?: number | null
    positionId: string
    runningPositionAmount?: {
      __typename?: 'UniswapOnchainPositionAmountInfo'
      token0Amount: string
      token1Amount: string
      token0AmountUsdValue: string
      token1AmountUsdValue: string
    } | null
    closedPositionAmount?: {
      __typename?: 'UniswapOnchainPositionAmountInfo'
      token0Amount: string
      token1Amount: string
      token0AmountUsdValue: string
      token1AmountUsdValue: string
    } | null
    token0DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
    token1DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
  } | null
  progresses?: Array<{
    __typename?: 'UserUniswapv3PositionProgress'
    id: string
    createdAt: string
    updatedAt: string
    index: number
    status: UserUniswapv3PositionProgressStatus
    type: UserUniswapv3PositionProgressType
    steps: Array<{
      __typename?: 'UserUniswapv3PositionProgressStep'
      status: UserUniswapv3PositionProgressStepStatus
      type: UserUniswapv3PositionProgressStepType
    }>
  }> | null
}

export type UserUniswapToolSettingInfoFragment = {
  __typename?: 'UserUniswapToolSettingInfo'
  id: string
  chain: EvmChain
  name: string
  description: string
  createdAt?: string | null
  closedAt?: string | null
  updatedAt?: string | null
  poolAddress: string
  token0Address: string
  token1Address: string
  status: UniswapToolStatus
  tickLowerDiff: string
  tickUpperDiff: string
  isOfficial: boolean
  totalUserUsed: number
  performanceFeeRatio?: string | null
  tags?: Array<{
    __typename?: 'UniswapToolTag'
    id: string
    name: string
    type: UniswapToolTagType
  }> | null
  token0DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
    usdtPrice?: string | null
  } | null
  token1DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
    usdtPrice?: string | null
  } | null
  feeTier: {
    __typename?: 'UniswapToolFeeTier'
    id: string
    feeForDisplay: string
    feeForContract: string
  }
  publicToolInfo?: {
    __typename?: 'UniswapToolInfo'
    id: string
    description: string
    status: PublicUniswapV3ToolStatus
    chain: EvmChain
    createdAt: string
    name: string
    numOfApply?: number | null
    expectedAPY: string
    isOfficial: boolean
    riskLevel: RiskLevel
    tickLowerDiff: string
    tickLowerDiffForContract: string
    tickUpperDiff: string
    tickUpperDiffForContract?: string | null
    performanceFee: string
    token0Address: string
    creatorName?: string | null
    creatorDescription?: string | null
    token1Address: string
    creatorId: string
    creatorAddress: string
    maximumLoss: string
    poolAddress: string
    apyEstimationBaseOnInputUsdValue?: string | null
    apyHighestCurrent: string
    apyHighest24h: string
    apyHighest7d: string
    apyHighest30d: string
    tvlTool: string
    tvlPool: string
    token0DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
    token1DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
    tags?: Array<{
      __typename?: 'UniswapToolTag'
      id: string
      name: string
      type: UniswapToolTagType
    }> | null
    feeTier: {
      __typename?: 'UniswapToolFeeTier'
      id: string
      feeForContract: string
      feeForDisplay: string
    }
    poolInfo?: {
      __typename?: 'UniswapPoolInfo'
      id: string
      untrackedVolumeUSD: string
      txCount: string
      totalValueLockedUSDUntracked: string
      totalValueLockedUSD: string
      totalValueLockedToken1: string
      totalValueLockedToken0: string
      totalValueLockedETH: string
      observationIndex: string
      feesUSD: string
      createdAtBlockNumber: string
      createdAtTimestamp: string
      liquidityProviderCount: string
      liquidity: string
      sqrtPrice: string
      feeGrowthGlobal0X128: string
      feeGrowthGlobal1X128: string
      tick?: string | null
      volumeUSD: string
      token0Price: string
      token1Price: string
      averageTradingVolumeUSD1D?: string | null
      averageTradingVolumeUSD7D?: string | null
      token0?: {
        __typename?: 'UniswapToolToken'
        id: string
        symbol: string
        name: string
        volumeUSD: string
        chain: EvmChain
        decimals: string
        volume: string
        untrackedVolumeUSD: string
        feesUSD: string
        txCount: string
        poolCount: string
        totalValueLocked: string
        totalValueLockedUSD: string
        totalValueLockedUSDUntracked: string
        derivedETH: string
        totalSupply: string
      } | null
      token1?: {
        __typename?: 'UniswapToolToken'
        id: string
        symbol: string
        name: string
        volumeUSD: string
        chain: EvmChain
        decimals: string
        volume: string
        untrackedVolumeUSD: string
        feesUSD: string
        txCount: string
        poolCount: string
        totalValueLocked: string
        totalValueLockedUSD: string
        totalValueLockedUSDUntracked: string
        derivedETH: string
        totalSupply: string
      } | null
    } | null
  } | null
  position?: {
    __typename?: 'UserUniswapPositionInfo'
    id: string
    userAddress: string
    chain: EvmChain
    positionIdFromSmartContractPositionManager?: number | null
    toolId: string
    toolName: string
    toolDescription: string
    toolIsOfficial: boolean
    toolPerformanceFeeRatio?: string | null
    token0Address: string
    token1Address: string
    poolAddress: string
    tickLowerDiff: string
    tickUpperDiff: string
    createdAt: string
    closedAt?: string | null
    updatedAt: string
    apy: string
    roi: string
    totalInvestAmountUsdValue: string
    status: UniswapToolPositionStatus
    token0TotalFeeUsdValue: string
    token1TotalFeeUsdValue: string
    unrealizedPnLUsd?: string | null
    uniswapIncomeUsd?: string | null
    performanceFeeChargeUsdValue?: string | null
    tokenPriceFluctuationsUsd?: string | null
    campaignId?: string | null
    reyieldTokens?: string | null
    token0DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
    token1DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
    publicToolInfo?: {
      __typename?: 'UniswapToolInfo'
      id: string
      description: string
      status: PublicUniswapV3ToolStatus
      chain: EvmChain
      createdAt: string
      name: string
      numOfApply?: number | null
      expectedAPY: string
      isOfficial: boolean
      riskLevel: RiskLevel
      tickLowerDiff: string
      tickLowerDiffForContract: string
      tickUpperDiff: string
      tickUpperDiffForContract?: string | null
      performanceFee: string
      token0Address: string
      creatorName?: string | null
      creatorDescription?: string | null
      token1Address: string
      creatorId: string
      creatorAddress: string
      maximumLoss: string
      poolAddress: string
      apyEstimationBaseOnInputUsdValue?: string | null
      apyHighestCurrent: string
      apyHighest24h: string
      apyHighest7d: string
      apyHighest30d: string
      tvlTool: string
      tvlPool: string
      token0DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
      token1DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
      tags?: Array<{
        __typename?: 'UniswapToolTag'
        id: string
        name: string
        type: UniswapToolTagType
      }> | null
      feeTier: {
        __typename?: 'UniswapToolFeeTier'
        id: string
        feeForContract: string
        feeForDisplay: string
      }
      poolInfo?: {
        __typename?: 'UniswapPoolInfo'
        id: string
        untrackedVolumeUSD: string
        txCount: string
        totalValueLockedUSDUntracked: string
        totalValueLockedUSD: string
        totalValueLockedToken1: string
        totalValueLockedToken0: string
        totalValueLockedETH: string
        observationIndex: string
        feesUSD: string
        createdAtBlockNumber: string
        createdAtTimestamp: string
        liquidityProviderCount: string
        liquidity: string
        sqrtPrice: string
        feeGrowthGlobal0X128: string
        feeGrowthGlobal1X128: string
        tick?: string | null
        volumeUSD: string
        token0Price: string
        token1Price: string
        averageTradingVolumeUSD1D?: string | null
        averageTradingVolumeUSD7D?: string | null
        token0?: {
          __typename?: 'UniswapToolToken'
          id: string
          symbol: string
          name: string
          volumeUSD: string
          chain: EvmChain
          decimals: string
          volume: string
          untrackedVolumeUSD: string
          feesUSD: string
          txCount: string
          poolCount: string
          totalValueLocked: string
          totalValueLockedUSD: string
          totalValueLockedUSDUntracked: string
          derivedETH: string
          totalSupply: string
        } | null
        token1?: {
          __typename?: 'UniswapToolToken'
          id: string
          symbol: string
          name: string
          volumeUSD: string
          chain: EvmChain
          decimals: string
          volume: string
          untrackedVolumeUSD: string
          feesUSD: string
          txCount: string
          poolCount: string
          totalValueLocked: string
          totalValueLockedUSD: string
          totalValueLockedUSDUntracked: string
          derivedETH: string
          totalSupply: string
        } | null
      } | null
    } | null
    feeTier: {
      __typename?: 'UniswapToolFeeTier'
      id: string
      feeForDisplay: string
      feeForContract: string
    }
    runningPositionAmount?: {
      __typename?: 'UniswapOnchainPositionAmountInfo'
      token0Amount: string
      token1Amount: string
      token0AmountUsdValue: string
      token1AmountUsdValue: string
    } | null
    closedPositionAmount?: {
      __typename?: 'UniswapOnchainPositionAmountInfo'
      token0Amount: string
      token1Amount: string
      token0AmountUsdValue: string
      token1AmountUsdValue: string
    } | null
    onchainPositionInfo?: {
      __typename?: 'UserUniswapOnchainPositionInfo'
      apy: string
      roi: string
      totalInvestAmountUsdValue?: string | null
      token0Address: string
      token1Address: string
      positionIdFromSmartContractPositionManager?: number | null
      positionId: string
      runningPositionAmount?: {
        __typename?: 'UniswapOnchainPositionAmountInfo'
        token0Amount: string
        token1Amount: string
        token0AmountUsdValue: string
        token1AmountUsdValue: string
      } | null
      closedPositionAmount?: {
        __typename?: 'UniswapOnchainPositionAmountInfo'
        token0Amount: string
        token1Amount: string
        token0AmountUsdValue: string
        token1AmountUsdValue: string
      } | null
      token0DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
      token1DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
    } | null
    progresses?: Array<{
      __typename?: 'UserUniswapv3PositionProgress'
      id: string
      createdAt: string
      updatedAt: string
      index: number
      status: UserUniswapv3PositionProgressStatus
      type: UserUniswapv3PositionProgressType
      steps: Array<{
        __typename?: 'UserUniswapv3PositionProgressStep'
        status: UserUniswapv3PositionProgressStepStatus
        type: UserUniswapv3PositionProgressStepType
      }>
    }> | null
  } | null
  outOfLowerRange: {
    __typename?: 'UserUniswapToolOutOfRangeInfo'
    maxRebalanceTimes: number
    standbyInterval: number
    standbyMaxInterval: number
    earnSpendRate: string
  }
  outOfUpperRange: {
    __typename?: 'UserUniswapToolOutOfRangeInfo'
    maxRebalanceTimes: number
    standbyInterval: number
    standbyMaxInterval: number
    earnSpendRate: string
  }
}

export type UniswapToolFeeTierFragment = {
  __typename?: 'UniswapToolFeeTier'
  id: string
  feeForDisplay: string
  feeForContract: string
}

export type UserUniswapToolOutOfRangeInfoFragment = {
  __typename?: 'UserUniswapToolOutOfRangeInfo'
  maxRebalanceTimes: number
  standbyInterval: number
  standbyMaxInterval: number
  earnSpendRate: string
}

export type UniswapToolInfoFragment = {
  __typename?: 'UniswapToolInfo'
  id: string
  description: string
  status: PublicUniswapV3ToolStatus
  chain: EvmChain
  createdAt: string
  name: string
  numOfApply?: number | null
  expectedAPY: string
  isOfficial: boolean
  riskLevel: RiskLevel
  tickLowerDiff: string
  tickLowerDiffForContract: string
  tickUpperDiff: string
  tickUpperDiffForContract?: string | null
  performanceFee: string
  token0Address: string
  creatorName?: string | null
  creatorDescription?: string | null
  token1Address: string
  creatorId: string
  creatorAddress: string
  maximumLoss: string
  poolAddress: string
  apyEstimationBaseOnInputUsdValue?: string | null
  apyHighestCurrent: string
  apyHighest24h: string
  apyHighest7d: string
  apyHighest30d: string
  tvlTool: string
  tvlPool: string
  token0DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
    usdtPrice?: string | null
  } | null
  token1DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
    usdtPrice?: string | null
  } | null
  tags?: Array<{
    __typename?: 'UniswapToolTag'
    id: string
    name: string
    type: UniswapToolTagType
  }> | null
  feeTier: {
    __typename?: 'UniswapToolFeeTier'
    id: string
    feeForContract: string
    feeForDisplay: string
  }
  poolInfo?: {
    __typename?: 'UniswapPoolInfo'
    id: string
    untrackedVolumeUSD: string
    txCount: string
    totalValueLockedUSDUntracked: string
    totalValueLockedUSD: string
    totalValueLockedToken1: string
    totalValueLockedToken0: string
    totalValueLockedETH: string
    observationIndex: string
    feesUSD: string
    createdAtBlockNumber: string
    createdAtTimestamp: string
    liquidityProviderCount: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    tick?: string | null
    volumeUSD: string
    token0Price: string
    token1Price: string
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD7D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      volumeUSD: string
      chain: EvmChain
      decimals: string
      volume: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      totalSupply: string
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      volumeUSD: string
      chain: EvmChain
      decimals: string
      volume: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      totalSupply: string
    } | null
  } | null
}

export type ExploreToolCardTokenFragment = {
  __typename?: 'UniswapToolToken'
  id: string
  symbol: string
  name: string
  volumeUSD: string
  chain: EvmChain
  decimals: string
  volume: string
  untrackedVolumeUSD: string
  feesUSD: string
  txCount: string
  poolCount: string
  totalValueLocked: string
  totalValueLockedUSD: string
  totalValueLockedUSDUntracked: string
  derivedETH: string
  totalSupply: string
}

export type UniswapPoolTokenFragment = {
  __typename?: 'UniswapToolToken'
  id: string
  symbol: string
  name: string
  decimals: string
  totalSupply: string
  volume: string
  volumeUSD: string
  untrackedVolumeUSD: string
  feesUSD: string
  txCount: string
  poolCount: string
  totalValueLocked: string
  totalValueLockedUSD: string
  totalValueLockedUSDUntracked: string
  derivedETH: string
}

export type PoolWithReyieldPoolValidationFragment = {
  __typename?: 'UniswapPoolInfo'
  isPoolActiveForReyield?: boolean | null
  id: string
  createdAtTimestamp: string
  createdAtBlockNumber: string
  feeTier: string
  liquidity: string
  sqrtPrice: string
  feeGrowthGlobal0X128: string
  feeGrowthGlobal1X128: string
  token0Price: string
  token1Price: string
  tick?: string | null
  observationIndex: string
  volumeToken0: string
  volumeToken1: string
  volumeUSD: string
  untrackedVolumeUSD: string
  feesUSD: string
  txCount: string
  collectedFeesToken0: string
  collectedFeesToken1: string
  collectedFeesUSD: string
  totalValueLockedToken0: string
  totalValueLockedToken1: string
  totalValueLockedETH: string
  totalValueLockedUSD: string
  totalValueLockedUSDUntracked: string
  liquidityProviderCount: string
  chain: EvmChain
  averageTradingVolumeUSD1D?: string | null
  averageTradingVolumeUSD30D?: string | null
  averageTradingVolumeUSD365D?: string | null
  token0?: {
    __typename?: 'UniswapToolToken'
    id: string
    symbol: string
    name: string
    decimals: string
    totalSupply: string
    volume: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    poolCount: string
    totalValueLocked: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    derivedETH: string
    priceChart?: {
      __typename?: 'UniswapToolTokenPriceChart'
      id?: string | null
      currentPriceUSD?: string | null
      prices?: Array<{
        __typename?: 'UniswapToolTokenPriceChartPrice'
        timestamp: string
        value: string
      }> | null
    } | null
  } | null
  token1?: {
    __typename?: 'UniswapToolToken'
    id: string
    symbol: string
    name: string
    decimals: string
    totalSupply: string
    volume: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    poolCount: string
    totalValueLocked: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    derivedETH: string
    priceChart?: {
      __typename?: 'UniswapToolTokenPriceChart'
      id?: string | null
      currentPriceUSD?: string | null
      prices?: Array<{
        __typename?: 'UniswapToolTokenPriceChartPrice'
        timestamp: string
        value: string
      }> | null
    } | null
  } | null
}

export type UniswapToolTokenPriceChartFragment = {
  __typename?: 'UniswapToolTokenPriceChart'
  id?: string | null
  currentPriceUSD?: string | null
  prices?: Array<{
    __typename?: 'UniswapToolTokenPriceChartPrice'
    timestamp: string
    value: string
  }> | null
}

export type PoolFragment = {
  __typename?: 'UniswapPoolInfo'
  id: string
  createdAtTimestamp: string
  createdAtBlockNumber: string
  feeTier: string
  liquidity: string
  sqrtPrice: string
  feeGrowthGlobal0X128: string
  feeGrowthGlobal1X128: string
  token0Price: string
  token1Price: string
  tick?: string | null
  observationIndex: string
  volumeToken0: string
  volumeToken1: string
  volumeUSD: string
  untrackedVolumeUSD: string
  feesUSD: string
  txCount: string
  collectedFeesToken0: string
  collectedFeesToken1: string
  collectedFeesUSD: string
  totalValueLockedToken0: string
  totalValueLockedToken1: string
  totalValueLockedETH: string
  totalValueLockedUSD: string
  totalValueLockedUSDUntracked: string
  liquidityProviderCount: string
  chain: EvmChain
  averageTradingVolumeUSD1D?: string | null
  averageTradingVolumeUSD30D?: string | null
  averageTradingVolumeUSD365D?: string | null
  token0?: {
    __typename?: 'UniswapToolToken'
    id: string
    symbol: string
    name: string
    decimals: string
    totalSupply: string
    volume: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    poolCount: string
    totalValueLocked: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    derivedETH: string
    priceChart?: {
      __typename?: 'UniswapToolTokenPriceChart'
      id?: string | null
      currentPriceUSD?: string | null
      prices?: Array<{
        __typename?: 'UniswapToolTokenPriceChartPrice'
        timestamp: string
        value: string
      }> | null
    } | null
  } | null
  token1?: {
    __typename?: 'UniswapToolToken'
    id: string
    symbol: string
    name: string
    decimals: string
    totalSupply: string
    volume: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    poolCount: string
    totalValueLocked: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    derivedETH: string
    priceChart?: {
      __typename?: 'UniswapToolTokenPriceChart'
      id?: string | null
      currentPriceUSD?: string | null
      prices?: Array<{
        __typename?: 'UniswapToolTokenPriceChartPrice'
        timestamp: string
        value: string
      }> | null
    } | null
  } | null
}

export type DefiWalletFragment = {
  __typename?: 'DefiWallet'
  id: string
  chain: EvmChain
  balance: string
  balanceComputed?: string | null
  balanceComputedWithUnit?: string | null
  iconifySrc: string
  currencyInfo: { __typename?: 'CurrencyInfo'; name: string; symbol: string; decimals: number }
}

export type CurrencyInfoFragment = {
  __typename?: 'CurrencyInfo'
  name: string
  symbol: string
  decimals: number
}

export type ExternalApiKeyFragment = {
  __typename?: 'ExternalAPIKey'
  id: string
  exchange: Exchange
  key: string
  name: string
  isRevoked: boolean
}

export type PublicStrategyParameterFragment = {
  __typename?: 'StrategyParameters'
  defaultValue?: string | null
  description: string
  key: string
  maxValue?: string | null
  minValue?: string | null
  name: string
  type: StrategyParameterType
  updatable: boolean
}

export type PublicStrategyInfoFragment = {
  __typename?: 'StrategyInfo'
  id: string
  sid: string
  currency: string
  description: string
  earningCurrency: string
  exchanges: Array<Exchange>
  expectedAPY: string
  name: string
  suggestionPeriod: string
  target: string
  type: StrategyType
  riskLevel: RiskLevel
  numOfApply?: number | null
  maximumLoss: string
  stage: StrategyInfoStage
  parameters: Array<{
    __typename?: 'StrategyParameters'
    defaultValue?: string | null
    description: string
    key: string
    maxValue?: string | null
    minValue?: string | null
    name: string
    type: StrategyParameterType
    updatable: boolean
  }>
}

export type UserStrategyParametersFragment = {
  __typename?: 'UserStrategyParameters'
  key: string
  value: string
}

export type UserStrategiesFragment = {
  __typename?: 'UserStrategyInfo'
  id: string
  sid: string
  createdAt: string
  closedAt?: string | null
  updatedAt: string
  ceFiStatus: StrategyStatus
  errMsg?: string | null
  infos?: {
    __typename?: 'StrategyInfos'
    name: string
    currency: string
    earningCurrency: string
    exchange?: Exchange | null
    minEnableAmount: string
  } | null
  parameters?: Array<{ __typename?: 'UserStrategyParameters'; key: string; value: string }> | null
  performance?: {
    __typename?: 'UserStrategyPerformance'
    initialValue?: string | null
    duration?: number | null
    performances?: Array<{
      __typename?: 'StrategyPerformance'
      currency: string
      amount: string
      ror: string
      apy: string
    } | null> | null
  } | null
  profits?: {
    __typename?: 'UserStrategyProfit'
    sid?: string | null
    type?: StrategyType | null
    totalProfit?: Array<{
      __typename?: 'TotalProfits'
      currency?: string | null
      amount?: string | null
    } | null> | null
  } | null
}

export type UserStrategyInfoFragment = {
  __typename?: 'UserStrategyInfo'
  id: string
  sid: string
  createdAt: string
  closedAt?: string | null
  updatedAt: string
  ceFiStatus: StrategyStatus
  errMsg?: string | null
  infos?: {
    __typename?: 'StrategyInfos'
    name: string
    currency: string
    earningCurrency: string
    exchange?: Exchange | null
    minEnableAmount: string
  } | null
  parameters?: Array<{ __typename?: 'UserStrategyParameters'; key: string; value: string }> | null
  performance?: {
    __typename?: 'UserStrategyPerformance'
    initialValue?: string | null
    duration?: number | null
    performances?: Array<{
      __typename?: 'StrategyPerformance'
      currency: string
      amount: string
      ror: string
      apy: string
    } | null> | null
  } | null
  profits?: {
    __typename?: 'UserStrategyProfit'
    sid?: string | null
    type?: StrategyType | null
    totalProfit?: Array<{
      __typename?: 'TotalProfits'
      currency?: string | null
      amount?: string | null
    } | null> | null
  } | null
}

export type CefiWalletFragment = {
  __typename?: 'CefiWallet'
  exchange: string
  walletBalances: Array<{
    __typename?: 'WalletBalances'
    wallet: string
    currencyBalances: Array<{
      __typename?: 'CurrencyBalances'
      currency: string
      balance: { __typename?: 'CurrencyBalance'; available: string; total: string }
    }>
  }>
}

export type WalletBalancesFragment = {
  __typename?: 'WalletBalances'
  wallet: string
  currencyBalances: Array<{
    __typename?: 'CurrencyBalances'
    currency: string
    balance: { __typename?: 'CurrencyBalance'; available: string; total: string }
  }>
}

export type CurrencyBalancesFragment = {
  __typename?: 'CurrencyBalances'
  currency: string
  balance: { __typename?: 'CurrencyBalance'; available: string; total: string }
}

export type StrategyProgressStepFragment = {
  __typename?: 'StrategyProgressStep'
  status: StrategyProgressStepStatus
  type: StrategyProgressStepType
}

export type StrategyProgressFragment = {
  __typename?: 'StrategyProgress'
  id: string
  createdAt: string
  updatedAt: string
  index: number
  status: StrategyProgressStatus
  type: StrategyProgressType
  steps: Array<{
    __typename?: 'StrategyProgressStep'
    status: StrategyProgressStepStatus
    type: StrategyProgressStepType
  } | null>
}

export type StrategyPerformanceFragment = {
  __typename?: 'StrategyPerformance'
  currency: string
  amount: string
  ror: string
  apy: string
}

export type UserStrategyProfitFragment = {
  __typename?: 'UserStrategyProfit'
  sid?: string | null
  type?: StrategyType | null
  totalProfit?: Array<{
    __typename?: 'TotalProfits'
    currency?: string | null
    amount?: string | null
  } | null> | null
}

export type TotalProfitsFragment = {
  __typename?: 'TotalProfits'
  currency?: string | null
  amount?: string | null
}

export type CoinPriceFragment = { __typename?: 'CoinPrice'; symbol: string; usdtPrice: string }

export type RateHistoricalFragment = { __typename?: 'RateHistorical'; time: string; rate: string }

export type UserStrategyRecordFragment = {
  __typename?: 'UserStrategyRecord'
  id?: string | null
  amount?: string | null
  currency?: string | null
  rate?: string | null
  time?: string | null
  type?: RecordsType | null
}

export type AiCompletionRecordFragment = {
  __typename?: 'aICompletionRecords'
  id: string
  promptMutationName?: string | null
  promptMutationInput?: any | null
  platform?: string | null
  meta?: any | null
  createdAt: any
  content?: string | null
  completedAt?: any | null
  updatedAt: any
  userAddress?: string | null
  userId?: string | null
}

export type SingleCompletionRecordForDialogFragment = {
  __typename?: 'aICompletionRecords'
  id: string
  promptMutationName?: string | null
  promptMutationInput?: any | null
  platform?: string | null
  meta?: any | null
  createdAt: any
  content?: string | null
  completedAt?: any | null
  updatedAt: any
  userAddress?: string | null
  userId?: string | null
  suggestions: Array<{
    __typename?: 'suggestStrategyToolWithAllocations'
    id: any
    maximumLoss: string
    name: string
    sid: string
    updatedAt: any
    highestAnnualReturn: string
    description: string
    createdAt: any
    allocation: string
    systemInvestAmountSuggestion?: string | null
    systemInvestCurrencySuggestion?: string | null
  }>
}

export type SuggestStrategyToolWithAllocationFragment = {
  __typename?: 'suggestStrategyToolWithAllocations'
  id: any
  maximumLoss: string
  name: string
  sid: string
  updatedAt: any
  highestAnnualReturn: string
  description: string
  createdAt: any
  allocation: string
  systemInvestAmountSuggestion?: string | null
  systemInvestCurrencySuggestion?: string | null
}

export type NftCollectionFragment = {
  __typename?: 'NFTCollections'
  name?: string | null
  description?: string | null
  tokenId: string
  contract: string
  imageUrl: string
  attributes?: Array<{
    __typename?: 'Attributes'
    traitType?: string | null
    value?: string | null
  } | null> | null
}

export type PositionFragment = {
  __typename?: 'UniswapPoolPosition'
  id: string
  tickLowerTickIdx: string
  tickLowerFeeGrowthOutside0X128: string
  tickLowerFeeGrowthOutside1X128: string
  tickUpperTickIdx: string
  tickUpperFeeGrowthOutside0X128: string
  tickUpperFeeGrowthOutside1X128: string
  depositedToken0: string
  depositedToken1: string
  liquidity: string
  transactionTimestamp: string
  collectedFeesToken0: string
  collectedFeesToken1: string
  feeGrowthInside0LastX128: string
  feeGrowthInside1LastX128: string
}

export type UserExternalApiKeyCreateMutationVariables = Exact<{
  input: ExternalApiKeyCreateInput
}>

export type UserExternalApiKeyCreateMutation = {
  __typename?: 'Mutation'
  externalAPIKeyCreate: {
    __typename?: 'ExternalAPIKey'
    id: string
    exchange: Exchange
    key: string
    name: string
    isRevoked: boolean
    user?: {
      __typename?: 'UserInfo'
      id: string
      externalAPIKeys?: Array<{
        __typename?: 'ExternalAPIKey'
        id: string
        exchange: Exchange
        key: string
        name: string
        isRevoked: boolean
      }> | null
    } | null
  }
}

export type UserExternalApiKeyDeleteMutationVariables = Exact<{
  input: ExternalApiKeyDeleteInput
}>

export type UserExternalApiKeyDeleteMutation = {
  __typename?: 'Mutation'
  externalAPIKeyDelete: {
    __typename?: 'ExternalAPIKey'
    id: string
    exchange: Exchange
    key: string
    name: string
    isRevoked: boolean
    user?: {
      __typename?: 'UserInfo'
      id: string
      externalAPIKeys?: Array<{
        __typename?: 'ExternalAPIKey'
        id: string
        exchange: Exchange
        key: string
        name: string
        isRevoked: boolean
      }> | null
    } | null
  }
}

export type UserStrategyStartMutationVariables = Exact<{
  input: StrategyStartInput
}>

export type UserStrategyStartMutation = {
  __typename?: 'Mutation'
  strategyStart: {
    __typename?: 'StrategyStartResponse'
    strategy: {
      __typename?: 'UserStrategyInfo'
      id: string
      sid: string
      createdAt: string
      closedAt?: string | null
      updatedAt: string
      ceFiStatus: StrategyStatus
      errMsg?: string | null
      infos?: {
        __typename?: 'StrategyInfos'
        name: string
        currency: string
        earningCurrency: string
        exchange?: Exchange | null
        minEnableAmount: string
      } | null
      parameters?: Array<{
        __typename?: 'UserStrategyParameters'
        key: string
        value: string
      }> | null
      performance?: {
        __typename?: 'UserStrategyPerformance'
        initialValue?: string | null
        duration?: number | null
        performances?: Array<{
          __typename?: 'StrategyPerformance'
          currency: string
          amount: string
          ror: string
          apy: string
        } | null> | null
      } | null
      profits?: {
        __typename?: 'UserStrategyProfit'
        sid?: string | null
        type?: StrategyType | null
        totalProfit?: Array<{
          __typename?: 'TotalProfits'
          currency?: string | null
          amount?: string | null
        } | null> | null
      } | null
    }
  }
}

export type UserStrategyStopMutationVariables = Exact<{
  input: StrategyStopInput
}>

export type UserStrategyStopMutation = {
  __typename?: 'Mutation'
  strategyStop: {
    __typename?: 'StrategyStopResponse'
    strategy: {
      __typename?: 'UserStrategyInfo'
      id: string
      sid: string
      createdAt: string
      closedAt?: string | null
      updatedAt: string
      ceFiStatus: StrategyStatus
      errMsg?: string | null
      infos?: {
        __typename?: 'StrategyInfos'
        name: string
        currency: string
        earningCurrency: string
        exchange?: Exchange | null
        minEnableAmount: string
      } | null
      parameters?: Array<{
        __typename?: 'UserStrategyParameters'
        key: string
        value: string
      }> | null
      performance?: {
        __typename?: 'UserStrategyPerformance'
        initialValue?: string | null
        duration?: number | null
        performances?: Array<{
          __typename?: 'StrategyPerformance'
          currency: string
          amount: string
          ror: string
          apy: string
        } | null> | null
      } | null
      profits?: {
        __typename?: 'UserStrategyProfit'
        sid?: string | null
        type?: StrategyType | null
        totalProfit?: Array<{
          __typename?: 'TotalProfits'
          currency?: string | null
          amount?: string | null
        } | null> | null
      } | null
    }
  }
}

export type UserStrategyUpdateMutationVariables = Exact<{
  input: StrategyUpdateInput
}>

export type UserStrategyUpdateMutation = {
  __typename?: 'Mutation'
  strategyUpdate: {
    __typename?: 'StrategyUpdateResponse'
    strategy: {
      __typename?: 'UserStrategyInfo'
      id: string
      sid: string
      createdAt: string
      closedAt?: string | null
      updatedAt: string
      ceFiStatus: StrategyStatus
      errMsg?: string | null
      infos?: {
        __typename?: 'StrategyInfos'
        name: string
        currency: string
        earningCurrency: string
        exchange?: Exchange | null
        minEnableAmount: string
      } | null
      parameters?: Array<{
        __typename?: 'UserStrategyParameters'
        key: string
        value: string
      }> | null
      performance?: {
        __typename?: 'UserStrategyPerformance'
        initialValue?: string | null
        duration?: number | null
        performances?: Array<{
          __typename?: 'StrategyPerformance'
          currency: string
          amount: string
          ror: string
          apy: string
        } | null> | null
      } | null
      profits?: {
        __typename?: 'UserStrategyProfit'
        sid?: string | null
        type?: StrategyType | null
        totalProfit?: Array<{
          __typename?: 'TotalProfits'
          currency?: string | null
          amount?: string | null
        } | null> | null
      } | null
    }
  }
}

export type CreateAiCompletionRecordMutationVariables = Exact<{
  input: CreateAiCompletionRecordInput
}>

export type CreateAiCompletionRecordMutation = {
  __typename?: 'Mutation'
  createAICompletionRecord: {
    __typename?: 'aICompletionRecords'
    id: string
    promptMutationName?: string | null
    promptMutationInput?: any | null
    platform?: string | null
    meta?: any | null
    createdAt: any
    content?: string | null
    completedAt?: any | null
    updatedAt: any
    userAddress?: string | null
    userId?: string | null
  }
}

export type CreateUniswapv3ToolMutationVariables = Exact<{
  input: CreateUniswapv3ToolInput
}>

export type CreateUniswapv3ToolMutation = {
  __typename?: 'Mutation'
  createUniswapv3Tool?: string | null
}

export type CreateUniswapv3PositionMutationVariables = Exact<{
  input: CreateUniswapv3PositionInput
}>

export type CreateUniswapv3PositionMutation = {
  __typename?: 'Mutation'
  createUniswapv3Position?: string | null
}

export type CloseUniswapv3PositionMutationVariables = Exact<{
  input: CloseUniswapv3PositionInput
}>

export type CloseUniswapv3PositionMutation = {
  __typename?: 'Mutation'
  closeUniswapv3Position?: string | null
}

export type UpdateUserOwnedUniswapv3ToolsInfoMutationVariables = Exact<{
  input: UpdateUserOwnedUniswapv3ToolsInfoInput
}>

export type UpdateUserOwnedUniswapv3ToolsInfoMutation = {
  __typename?: 'Mutation'
  updateUserOwnedUniswapv3ToolsInfo?: string | null
}

export type ListUserOwnedUniswapv3ToolMutationVariables = Exact<{
  input: ListUserOwnedUniswapv3ToolInput
}>

export type ListUserOwnedUniswapv3ToolMutation = {
  __typename?: 'Mutation'
  listUserOwnedUniswapv3Tool?: string | null
}

export type DelistUserOwnedUniswapv3ToolMutationVariables = Exact<{
  input: DelistUserOwnedUniswapv3ToolInput
}>

export type DelistUserOwnedUniswapv3ToolMutation = {
  __typename?: 'Mutation'
  delistUserOwnedUniswapv3Tool?: string | null
}

export type RefreshUserUniswapV3ToolMutationVariables = Exact<{
  input: RefreshUniswapv3ToolInput
}>

export type RefreshUserUniswapV3ToolMutation = {
  __typename?: 'Mutation'
  refreshUserUniswapV3Tool?: {
    __typename?: 'UserUniswapPositionInfo'
    id: string
    userAddress: string
    chain: EvmChain
    positionIdFromSmartContractPositionManager?: number | null
    toolId: string
    toolName: string
    toolDescription: string
    toolIsOfficial: boolean
    toolPerformanceFeeRatio?: string | null
    token0Address: string
    token1Address: string
    poolAddress: string
    tickLowerDiff: string
    tickUpperDiff: string
    createdAt: string
    closedAt?: string | null
    updatedAt: string
    apy: string
    roi: string
    totalInvestAmountUsdValue: string
    status: UniswapToolPositionStatus
    token0TotalFeeUsdValue: string
    token1TotalFeeUsdValue: string
    unrealizedPnLUsd?: string | null
    uniswapIncomeUsd?: string | null
    performanceFeeChargeUsdValue?: string | null
    tokenPriceFluctuationsUsd?: string | null
    campaignId?: string | null
    reyieldTokens?: string | null
    token0DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
    token1DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
    publicToolInfo?: {
      __typename?: 'UniswapToolInfo'
      id: string
      description: string
      status: PublicUniswapV3ToolStatus
      chain: EvmChain
      createdAt: string
      name: string
      numOfApply?: number | null
      expectedAPY: string
      isOfficial: boolean
      riskLevel: RiskLevel
      tickLowerDiff: string
      tickLowerDiffForContract: string
      tickUpperDiff: string
      tickUpperDiffForContract?: string | null
      performanceFee: string
      token0Address: string
      creatorName?: string | null
      creatorDescription?: string | null
      token1Address: string
      creatorId: string
      creatorAddress: string
      maximumLoss: string
      poolAddress: string
      apyEstimationBaseOnInputUsdValue?: string | null
      apyHighestCurrent: string
      apyHighest24h: string
      apyHighest7d: string
      apyHighest30d: string
      tvlTool: string
      tvlPool: string
      token0DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
      token1DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
      tags?: Array<{
        __typename?: 'UniswapToolTag'
        id: string
        name: string
        type: UniswapToolTagType
      }> | null
      feeTier: {
        __typename?: 'UniswapToolFeeTier'
        id: string
        feeForContract: string
        feeForDisplay: string
      }
      poolInfo?: {
        __typename?: 'UniswapPoolInfo'
        id: string
        untrackedVolumeUSD: string
        txCount: string
        totalValueLockedUSDUntracked: string
        totalValueLockedUSD: string
        totalValueLockedToken1: string
        totalValueLockedToken0: string
        totalValueLockedETH: string
        observationIndex: string
        feesUSD: string
        createdAtBlockNumber: string
        createdAtTimestamp: string
        liquidityProviderCount: string
        liquidity: string
        sqrtPrice: string
        feeGrowthGlobal0X128: string
        feeGrowthGlobal1X128: string
        tick?: string | null
        volumeUSD: string
        token0Price: string
        token1Price: string
        averageTradingVolumeUSD1D?: string | null
        averageTradingVolumeUSD7D?: string | null
        token0?: {
          __typename?: 'UniswapToolToken'
          id: string
          symbol: string
          name: string
          volumeUSD: string
          chain: EvmChain
          decimals: string
          volume: string
          untrackedVolumeUSD: string
          feesUSD: string
          txCount: string
          poolCount: string
          totalValueLocked: string
          totalValueLockedUSD: string
          totalValueLockedUSDUntracked: string
          derivedETH: string
          totalSupply: string
        } | null
        token1?: {
          __typename?: 'UniswapToolToken'
          id: string
          symbol: string
          name: string
          volumeUSD: string
          chain: EvmChain
          decimals: string
          volume: string
          untrackedVolumeUSD: string
          feesUSD: string
          txCount: string
          poolCount: string
          totalValueLocked: string
          totalValueLockedUSD: string
          totalValueLockedUSDUntracked: string
          derivedETH: string
          totalSupply: string
        } | null
      } | null
    } | null
    feeTier: {
      __typename?: 'UniswapToolFeeTier'
      id: string
      feeForDisplay: string
      feeForContract: string
    }
    runningPositionAmount?: {
      __typename?: 'UniswapOnchainPositionAmountInfo'
      token0Amount: string
      token1Amount: string
      token0AmountUsdValue: string
      token1AmountUsdValue: string
    } | null
    closedPositionAmount?: {
      __typename?: 'UniswapOnchainPositionAmountInfo'
      token0Amount: string
      token1Amount: string
      token0AmountUsdValue: string
      token1AmountUsdValue: string
    } | null
    onchainPositionInfo?: {
      __typename?: 'UserUniswapOnchainPositionInfo'
      apy: string
      roi: string
      totalInvestAmountUsdValue?: string | null
      token0Address: string
      token1Address: string
      positionIdFromSmartContractPositionManager?: number | null
      positionId: string
      runningPositionAmount?: {
        __typename?: 'UniswapOnchainPositionAmountInfo'
        token0Amount: string
        token1Amount: string
        token0AmountUsdValue: string
        token1AmountUsdValue: string
      } | null
      closedPositionAmount?: {
        __typename?: 'UniswapOnchainPositionAmountInfo'
        token0Amount: string
        token1Amount: string
        token0AmountUsdValue: string
        token1AmountUsdValue: string
      } | null
      token0DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
      token1DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
    } | null
    progresses?: Array<{
      __typename?: 'UserUniswapv3PositionProgress'
      id: string
      createdAt: string
      updatedAt: string
      index: number
      status: UserUniswapv3PositionProgressStatus
      type: UserUniswapv3PositionProgressType
      steps: Array<{
        __typename?: 'UserUniswapv3PositionProgressStep'
        status: UserUniswapv3PositionProgressStepStatus
        type: UserUniswapv3PositionProgressStepType
      }>
    }> | null
  } | null
}

export type FetchMeQueryVariables = Exact<{
  filter?: InputMaybe<DefiWalletsFilterInput>
}>

export type FetchMeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    defiWallets?: Array<{
      __typename?: 'DefiWallet'
      id: string
      chain: EvmChain
      balance: string
      balanceComputed?: string | null
      balanceComputedWithUnit?: string | null
      iconifySrc: string
      currencyInfo: { __typename?: 'CurrencyInfo'; name: string; symbol: string; decimals: number }
    }> | null
  }
}

export type FetchMequeryDefiTokensBalanceAmountQueryVariables = Exact<{
  input: DefiTokensBalanceAmountInput
}>

export type FetchMequeryDefiTokensBalanceAmountQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    defiTokensBalanceAmount?: Array<{
      __typename?: 'TokenBalance'
      id: string
      chain: EvmChain
      address: string
      symbol: string
      decimals: number
      amount: string
      amountReadable: string
    } | null> | null
  }
}

export type FetchPublicCoinPricesQueryVariables = Exact<{ [key: string]: never }>

export type FetchPublicCoinPricesQuery = {
  __typename?: 'Query'
  coinPrices?: Array<{ __typename?: 'CoinPrice'; symbol: string; usdtPrice: string }> | null
}

export type FetchMeGimCoolPayPaymentInfoQueryVariables = Exact<{
  input: GimCoolPayPaymentInfoInput
}>

export type FetchMeGimCoolPayPaymentInfoQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    gimCoolPayPaymentInfo?: {
      __typename?: 'GimCoolPayPaymentInfo'
      coinType: GimCoolPayPaymentSupportingCoinType
      fiatType: GimCoolPayPaymentSupportingFiatType
      coinAmount?: string | null
      fiatAmount?: string | null
      blockchain: GimCoolPayPaymentSupportingBlockchain
      walletAddress: string
      walletAddressTag?: string | null
      paymentHref: string
      price?: {
        __typename?: 'GimCoolPayPriceResponse'
        spotPrice: string
        prices?: Array<{
          __typename?: 'GimCoolPayReadyToPurchasePrice'
          paymentMethodId: string
          type: string
          spotPriceFee: string
          spotPriceIncludingFee: string
          coinAmount: string
          coinCode: string
          fiatAmount: string
          fiatCode: string
          feeAmount: string
          networkFee: string
        }> | null
      } | null
    } | null
  }
}

export type FetchMeSettingPageQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeSettingPageQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    isBitfinexAPIKeyBind: boolean
    externalAPIKeys?: Array<{
      __typename?: 'ExternalAPIKey'
      id: string
      exchange: Exchange
      key: string
      name: string
      isRevoked: boolean
    }> | null
  }
}

export type FetchPublicStrategyListQueryVariables = Exact<{
  filter?: InputMaybe<StrategyFilterInput>
}>

export type FetchPublicStrategyListQuery = {
  __typename?: 'Query'
  strategies: Array<{
    __typename?: 'StrategyInfo'
    id: string
    sid: string
    currency: string
    description: string
    earningCurrency: string
    exchanges: Array<Exchange>
    expectedAPY: string
    name: string
    suggestionPeriod: string
    target: string
    type: StrategyType
    riskLevel: RiskLevel
    numOfApply?: number | null
    maximumLoss: string
    stage: StrategyInfoStage
    parameters: Array<{
      __typename?: 'StrategyParameters'
      defaultValue?: string | null
      description: string
      key: string
      maxValue?: string | null
      minValue?: string | null
      name: string
      type: StrategyParameterType
      updatable: boolean
    }>
  }>
}

export type FetchPublicRateHistoricalQueryVariables = Exact<{
  filter: RateHistoricalFilterInput
}>

export type FetchPublicRateHistoricalQuery = {
  __typename?: 'Query'
  rateHistorical?: Array<{ __typename?: 'RateHistorical'; time: string; rate: string }> | null
}

export type FetchMeStrategyListQueryVariables = Exact<{
  filter?: InputMaybe<UserStrategyFilterInput>
}>

export type FetchMeStrategyListQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    strategies?: Array<{
      __typename?: 'UserStrategyInfo'
      id: string
      sid: string
      createdAt: string
      closedAt?: string | null
      updatedAt: string
      ceFiStatus: StrategyStatus
      errMsg?: string | null
      infos?: {
        __typename?: 'StrategyInfos'
        name: string
        currency: string
        earningCurrency: string
        exchange?: Exchange | null
        minEnableAmount: string
      } | null
      parameters?: Array<{
        __typename?: 'UserStrategyParameters'
        key: string
        value: string
      }> | null
      performance?: {
        __typename?: 'UserStrategyPerformance'
        initialValue?: string | null
        duration?: number | null
        performances?: Array<{
          __typename?: 'StrategyPerformance'
          currency: string
          amount: string
          ror: string
          apy: string
        } | null> | null
      } | null
      profits?: {
        __typename?: 'UserStrategyProfit'
        sid?: string | null
        type?: StrategyType | null
        totalProfit?: Array<{
          __typename?: 'TotalProfits'
          currency?: string | null
          amount?: string | null
        } | null> | null
      } | null
    }> | null
  }
}

export type FetchMeAiRecordsQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeAiRecordsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    aICompletionRecords?: Array<{
      __typename?: 'aICompletionRecords'
      id: string
      promptMutationName?: string | null
      promptMutationInput?: any | null
      platform?: string | null
      meta?: any | null
      createdAt: any
      content?: string | null
      completedAt?: any | null
      updatedAt: any
      userAddress?: string | null
      userId?: string | null
      suggestions: Array<{
        __typename?: 'suggestStrategyToolWithAllocations'
        id: any
        maximumLoss: string
        name: string
        sid: string
        updatedAt: any
        highestAnnualReturn: string
        description: string
        createdAt: any
        allocation: string
        systemInvestAmountSuggestion?: string | null
        systemInvestCurrencySuggestion?: string | null
      }>
    }> | null
  }
}

export type FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables = Exact<{
  filter: UserAiCompletionRecordsFilterInput
}>

export type FetchMeAiRecordDataByIdForPortfolioDialogQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    aICompletionRecords?: Array<{
      __typename?: 'aICompletionRecords'
      id: string
      promptMutationName?: string | null
      promptMutationInput?: any | null
      platform?: string | null
      meta?: any | null
      createdAt: any
      content?: string | null
      completedAt?: any | null
      updatedAt: any
      userAddress?: string | null
      userId?: string | null
      suggestions: Array<{
        __typename?: 'suggestStrategyToolWithAllocations'
        id: any
        maximumLoss: string
        name: string
        sid: string
        updatedAt: any
        highestAnnualReturn: string
        description: string
        createdAt: any
        allocation: string
        systemInvestAmountSuggestion?: string | null
        systemInvestCurrencySuggestion?: string | null
      }>
    }> | null
  }
}

export type FetchMeStrategyListForSidQueryVariables = Exact<{
  filter?: InputMaybe<UserStrategyFilterInput>
}>

export type FetchMeStrategyListForSidQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    strategies?: Array<{
      __typename?: 'UserStrategyInfo'
      id: string
      sid: string
      ceFiStatus: StrategyStatus
    }> | null
  }
}

export type FetchMeCexWalletBalancesQueryVariables = Exact<{
  filter?: InputMaybe<CefiWalletFilterInput>
}>

export type FetchMeCexWalletBalancesQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    cefiWallets?: Array<{
      __typename?: 'CefiWallet'
      exchange: string
      walletBalances: Array<{
        __typename?: 'WalletBalances'
        wallet: string
        currencyBalances: Array<{
          __typename?: 'CurrencyBalances'
          currency: string
          balance: { __typename?: 'CurrencyBalance'; available: string; total: string }
        }>
      }>
    }> | null
  }
}

export type FetchMeCexDepositAddressQueryVariables = Exact<{
  filter?: InputMaybe<CefiDepositAddressInput>
}>

export type FetchMeCexDepositAddressQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    cefiDepositAddress?: { __typename?: 'CefiDepositAddress'; address: string; chain: Chain } | null
  }
}

export type FetchMeStrategyProgressQueryVariables = Exact<{
  strategyID: Scalars['String']
}>

export type FetchMeStrategyProgressQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    strategyProgress?: {
      __typename?: 'StrategyProgress'
      id: string
      createdAt: string
      updatedAt: string
      index: number
      status: StrategyProgressStatus
      type: StrategyProgressType
      steps: Array<{
        __typename?: 'StrategyProgressStep'
        status: StrategyProgressStepStatus
        type: StrategyProgressStepType
      } | null>
    } | null
  }
}

export type FetchMeStrategyTotalProfitQueryVariables = Exact<{
  filter?: InputMaybe<UserStrategyProfitFilterInput>
}>

export type FetchMeStrategyTotalProfitQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    strategyProfit?: {
      __typename?: 'UserStrategyProfit'
      sid?: string | null
      type?: StrategyType | null
      totalProfit?: Array<{
        __typename?: 'TotalProfits'
        currency?: string | null
        amount?: string | null
      } | null> | null
    } | null
  }
}

export type FetchMeStrategyRecordsQueryVariables = Exact<{
  filter?: InputMaybe<UserStrategyRecordsFilterInput>
}>

export type FetchMeStrategyRecordsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    strategyRecords?: Array<{
      __typename?: 'UserStrategyRecord'
      id?: string | null
      amount?: string | null
      currency?: string | null
      rate?: string | null
      time?: string | null
      type?: RecordsType | null
    }> | null
  }
}

export type FetchMeNftCollectionsQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeNftCollectionsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    nftCollections?: Array<{
      __typename?: 'NFTCollections'
      name?: string | null
      description?: string | null
      tokenId: string
      contract: string
      imageUrl: string
      attributes?: Array<{
        __typename?: 'Attributes'
        traitType?: string | null
        value?: string | null
      } | null> | null
    }> | null
  }
}

export type FetchMeNftWhitelistInfoByPhrasesQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeNftWhitelistInfoByPhrasesQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    nftWhitelistInfoByPhrases?: Array<{
      __typename?: 'NFTWhitelistInfoByPhrase'
      id: string
      phrase: Nft_Round
      userAddress: string
      isWhitelisted: boolean
    }> | null
  }
}

export type FetchMeNftMerkleTreeHexProofQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeNftMerkleTreeHexProofQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    nftMerkleTreeHexProof?: {
      __typename?: 'NFTMerkleTreeHexProof'
      hexProof?: Array<string> | null
    } | null
  }
}

export type UniswapV3BacktestQueryVariables = Exact<{
  poolID?: InputMaybe<Scalars['String']>
  investmentAmount?: InputMaybe<Scalars['Float']>
  days?: InputMaybe<Scalars['Int']>
  compound?: InputMaybe<Scalars['Boolean']>
  protocol?: InputMaybe<EvmChain>
  diff?: InputMaybe<Scalars['Float']>
}>

export type UniswapV3BacktestQuery = {
  __typename?: 'Query'
  uniswapV3Backtest?: {
    __typename?: 'UniswapV3BacktestResult'
    id: string
    days: number
    activeLiquidityPercent: number
    dailyReturn: number
    annualReturn: number
    impermanentLoss: number
    compound: boolean
    dataPointsCount: string
  } | null
}

export type FetchToolsQueryVariables = Exact<{ [key: string]: never }>

export type FetchToolsQuery = {
  __typename?: 'Query'
  tools: Array<
    | {
        __typename?: 'StrategyInfo'
        id: string
        sid: string
        currency: string
        description: string
        earningCurrency: string
        exchanges: Array<Exchange>
        expectedAPY: string
        name: string
        suggestionPeriod: string
        target: string
        type: StrategyType
        riskLevel: RiskLevel
        numOfApply?: number | null
        maximumLoss: string
        stage: StrategyInfoStage
        parameters: Array<{
          __typename?: 'StrategyParameters'
          defaultValue?: string | null
          description: string
          key: string
          maxValue?: string | null
          minValue?: string | null
          name: string
          type: StrategyParameterType
          updatable: boolean
        }>
      }
    | {
        __typename?: 'UniswapToolInfo'
        id: string
        description: string
        status: PublicUniswapV3ToolStatus
        chain: EvmChain
        createdAt: string
        name: string
        numOfApply?: number | null
        expectedAPY: string
        isOfficial: boolean
        riskLevel: RiskLevel
        tickLowerDiff: string
        tickLowerDiffForContract: string
        tickUpperDiff: string
        tickUpperDiffForContract?: string | null
        performanceFee: string
        token0Address: string
        creatorName?: string | null
        creatorDescription?: string | null
        token1Address: string
        creatorId: string
        creatorAddress: string
        maximumLoss: string
        poolAddress: string
        apyEstimationBaseOnInputUsdValue?: string | null
        apyHighestCurrent: string
        apyHighest24h: string
        apyHighest7d: string
        apyHighest30d: string
        tvlTool: string
        tvlPool: string
        token0DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
        token1DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
        tags?: Array<{
          __typename?: 'UniswapToolTag'
          id: string
          name: string
          type: UniswapToolTagType
        }> | null
        feeTier: {
          __typename?: 'UniswapToolFeeTier'
          id: string
          feeForContract: string
          feeForDisplay: string
        }
        poolInfo?: {
          __typename?: 'UniswapPoolInfo'
          id: string
          untrackedVolumeUSD: string
          txCount: string
          totalValueLockedUSDUntracked: string
          totalValueLockedUSD: string
          totalValueLockedToken1: string
          totalValueLockedToken0: string
          totalValueLockedETH: string
          observationIndex: string
          feesUSD: string
          createdAtBlockNumber: string
          createdAtTimestamp: string
          liquidityProviderCount: string
          liquidity: string
          sqrtPrice: string
          feeGrowthGlobal0X128: string
          feeGrowthGlobal1X128: string
          tick?: string | null
          volumeUSD: string
          token0Price: string
          token1Price: string
          averageTradingVolumeUSD1D?: string | null
          averageTradingVolumeUSD7D?: string | null
          token0?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
          token1?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
        } | null
      }
  >
}

export type FetchSingleToolQueryVariables = Exact<{
  filter: ToolFilterInput
  period: ApyHistoryPeriod
}>

export type FetchSingleToolQuery = {
  __typename?: 'Query'
  tools: Array<
    | {
        __typename?: 'StrategyInfo'
        id: string
        sid: string
        currency: string
        description: string
        earningCurrency: string
        exchanges: Array<Exchange>
        expectedAPY: string
        name: string
        suggestionPeriod: string
        target: string
        type: StrategyType
        riskLevel: RiskLevel
        numOfApply?: number | null
        maximumLoss: string
        stage: StrategyInfoStage
        parameters: Array<{
          __typename?: 'StrategyParameters'
          defaultValue?: string | null
          description: string
          key: string
          maxValue?: string | null
          minValue?: string | null
          name: string
          type: StrategyParameterType
          updatable: boolean
        }>
      }
    | {
        __typename?: 'UniswapToolInfo'
        id: string
        description: string
        status: PublicUniswapV3ToolStatus
        chain: EvmChain
        createdAt: string
        name: string
        numOfApply?: number | null
        expectedAPY: string
        isOfficial: boolean
        riskLevel: RiskLevel
        tickLowerDiff: string
        tickLowerDiffForContract: string
        tickUpperDiff: string
        tickUpperDiffForContract?: string | null
        performanceFee: string
        token0Address: string
        creatorName?: string | null
        creatorDescription?: string | null
        token1Address: string
        creatorId: string
        creatorAddress: string
        maximumLoss: string
        poolAddress: string
        apyEstimationBaseOnInputUsdValue?: string | null
        apyHighestCurrent: string
        apyHighest24h: string
        apyHighest7d: string
        apyHighest30d: string
        tvlTool: string
        tvlPool: string
        apyHistory?: Array<{ __typename?: 'ApyHistory'; time: string; rate: string }> | null
        token0DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
        token1DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
        tags?: Array<{
          __typename?: 'UniswapToolTag'
          id: string
          name: string
          type: UniswapToolTagType
        }> | null
        feeTier: {
          __typename?: 'UniswapToolFeeTier'
          id: string
          feeForContract: string
          feeForDisplay: string
        }
        poolInfo?: {
          __typename?: 'UniswapPoolInfo'
          id: string
          untrackedVolumeUSD: string
          txCount: string
          totalValueLockedUSDUntracked: string
          totalValueLockedUSD: string
          totalValueLockedToken1: string
          totalValueLockedToken0: string
          totalValueLockedETH: string
          observationIndex: string
          feesUSD: string
          createdAtBlockNumber: string
          createdAtTimestamp: string
          liquidityProviderCount: string
          liquidity: string
          sqrtPrice: string
          feeGrowthGlobal0X128: string
          feeGrowthGlobal1X128: string
          tick?: string | null
          volumeUSD: string
          token0Price: string
          token1Price: string
          averageTradingVolumeUSD1D?: string | null
          averageTradingVolumeUSD7D?: string | null
          token0?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
          token1?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
        } | null
      }
  >
}

export type FetchMeToolsQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeToolsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    tools?: Array<
      | {
          __typename?: 'UserStrategyInfo'
          id: string
          sid: string
          createdAt: string
          closedAt?: string | null
          updatedAt: string
          ceFiStatus: StrategyStatus
          errMsg?: string | null
          infos?: {
            __typename?: 'StrategyInfos'
            name: string
            currency: string
            earningCurrency: string
            exchange?: Exchange | null
            minEnableAmount: string
          } | null
          parameters?: Array<{
            __typename?: 'UserStrategyParameters'
            key: string
            value: string
          }> | null
          performance?: {
            __typename?: 'UserStrategyPerformance'
            initialValue?: string | null
            duration?: number | null
            performances?: Array<{
              __typename?: 'StrategyPerformance'
              currency: string
              amount: string
              ror: string
              apy: string
            } | null> | null
          } | null
          profits?: {
            __typename?: 'UserStrategyProfit'
            sid?: string | null
            type?: StrategyType | null
            totalProfit?: Array<{
              __typename?: 'TotalProfits'
              currency?: string | null
              amount?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename?: 'UserUniswapPositionInfo'
          id: string
          userAddress: string
          chain: EvmChain
          positionIdFromSmartContractPositionManager?: number | null
          toolId: string
          toolName: string
          toolDescription: string
          toolIsOfficial: boolean
          toolPerformanceFeeRatio?: string | null
          token0Address: string
          token1Address: string
          poolAddress: string
          tickLowerDiff: string
          tickUpperDiff: string
          createdAt: string
          closedAt?: string | null
          updatedAt: string
          apy: string
          roi: string
          totalInvestAmountUsdValue: string
          status: UniswapToolPositionStatus
          token0TotalFeeUsdValue: string
          token1TotalFeeUsdValue: string
          unrealizedPnLUsd?: string | null
          uniswapIncomeUsd?: string | null
          performanceFeeChargeUsdValue?: string | null
          tokenPriceFluctuationsUsd?: string | null
          campaignId?: string | null
          reyieldTokens?: string | null
          token0DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
            usdtPrice?: string | null
          } | null
          token1DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
            usdtPrice?: string | null
          } | null
          publicToolInfo?: {
            __typename?: 'UniswapToolInfo'
            id: string
            description: string
            status: PublicUniswapV3ToolStatus
            chain: EvmChain
            createdAt: string
            name: string
            numOfApply?: number | null
            expectedAPY: string
            isOfficial: boolean
            riskLevel: RiskLevel
            tickLowerDiff: string
            tickLowerDiffForContract: string
            tickUpperDiff: string
            tickUpperDiffForContract?: string | null
            performanceFee: string
            token0Address: string
            creatorName?: string | null
            creatorDescription?: string | null
            token1Address: string
            creatorId: string
            creatorAddress: string
            maximumLoss: string
            poolAddress: string
            apyEstimationBaseOnInputUsdValue?: string | null
            apyHighestCurrent: string
            apyHighest24h: string
            apyHighest7d: string
            apyHighest30d: string
            tvlTool: string
            tvlPool: string
            token0DisplayInfo?: {
              __typename?: 'TokenDisplayInfo'
              id: string
              chain: EvmChain
              name: string
              address: string
              imageURL: string
              symbol: string
              usdtPrice?: string | null
            } | null
            token1DisplayInfo?: {
              __typename?: 'TokenDisplayInfo'
              id: string
              chain: EvmChain
              name: string
              address: string
              imageURL: string
              symbol: string
              usdtPrice?: string | null
            } | null
            tags?: Array<{
              __typename?: 'UniswapToolTag'
              id: string
              name: string
              type: UniswapToolTagType
            }> | null
            feeTier: {
              __typename?: 'UniswapToolFeeTier'
              id: string
              feeForContract: string
              feeForDisplay: string
            }
            poolInfo?: {
              __typename?: 'UniswapPoolInfo'
              id: string
              untrackedVolumeUSD: string
              txCount: string
              totalValueLockedUSDUntracked: string
              totalValueLockedUSD: string
              totalValueLockedToken1: string
              totalValueLockedToken0: string
              totalValueLockedETH: string
              observationIndex: string
              feesUSD: string
              createdAtBlockNumber: string
              createdAtTimestamp: string
              liquidityProviderCount: string
              liquidity: string
              sqrtPrice: string
              feeGrowthGlobal0X128: string
              feeGrowthGlobal1X128: string
              tick?: string | null
              volumeUSD: string
              token0Price: string
              token1Price: string
              averageTradingVolumeUSD1D?: string | null
              averageTradingVolumeUSD7D?: string | null
              token0?: {
                __typename?: 'UniswapToolToken'
                id: string
                symbol: string
                name: string
                volumeUSD: string
                chain: EvmChain
                decimals: string
                volume: string
                untrackedVolumeUSD: string
                feesUSD: string
                txCount: string
                poolCount: string
                totalValueLocked: string
                totalValueLockedUSD: string
                totalValueLockedUSDUntracked: string
                derivedETH: string
                totalSupply: string
              } | null
              token1?: {
                __typename?: 'UniswapToolToken'
                id: string
                symbol: string
                name: string
                volumeUSD: string
                chain: EvmChain
                decimals: string
                volume: string
                untrackedVolumeUSD: string
                feesUSD: string
                txCount: string
                poolCount: string
                totalValueLocked: string
                totalValueLockedUSD: string
                totalValueLockedUSDUntracked: string
                derivedETH: string
                totalSupply: string
              } | null
            } | null
          } | null
          feeTier: {
            __typename?: 'UniswapToolFeeTier'
            id: string
            feeForDisplay: string
            feeForContract: string
          }
          runningPositionAmount?: {
            __typename?: 'UniswapOnchainPositionAmountInfo'
            token0Amount: string
            token1Amount: string
            token0AmountUsdValue: string
            token1AmountUsdValue: string
          } | null
          closedPositionAmount?: {
            __typename?: 'UniswapOnchainPositionAmountInfo'
            token0Amount: string
            token1Amount: string
            token0AmountUsdValue: string
            token1AmountUsdValue: string
          } | null
          onchainPositionInfo?: {
            __typename?: 'UserUniswapOnchainPositionInfo'
            apy: string
            roi: string
            totalInvestAmountUsdValue?: string | null
            token0Address: string
            token1Address: string
            positionIdFromSmartContractPositionManager?: number | null
            positionId: string
            runningPositionAmount?: {
              __typename?: 'UniswapOnchainPositionAmountInfo'
              token0Amount: string
              token1Amount: string
              token0AmountUsdValue: string
              token1AmountUsdValue: string
            } | null
            closedPositionAmount?: {
              __typename?: 'UniswapOnchainPositionAmountInfo'
              token0Amount: string
              token1Amount: string
              token0AmountUsdValue: string
              token1AmountUsdValue: string
            } | null
            token0DisplayInfo?: {
              __typename?: 'TokenDisplayInfo'
              id: string
              chain: EvmChain
              name: string
              address: string
              imageURL: string
              symbol: string
              usdtPrice?: string | null
            } | null
            token1DisplayInfo?: {
              __typename?: 'TokenDisplayInfo'
              id: string
              chain: EvmChain
              name: string
              address: string
              imageURL: string
              symbol: string
              usdtPrice?: string | null
            } | null
          } | null
          progresses?: Array<{
            __typename?: 'UserUniswapv3PositionProgress'
            id: string
            createdAt: string
            updatedAt: string
            index: number
            status: UserUniswapv3PositionProgressStatus
            type: UserUniswapv3PositionProgressType
            steps: Array<{
              __typename?: 'UserUniswapv3PositionProgressStep'
              status: UserUniswapv3PositionProgressStepStatus
              type: UserUniswapv3PositionProgressStepType
            }>
          }> | null
        }
    > | null
    ownedToolsSetting?: Array<{
      __typename?: 'UserUniswapToolSettingInfo'
      id: string
      chain: EvmChain
      name: string
      description: string
      createdAt?: string | null
      closedAt?: string | null
      updatedAt?: string | null
      poolAddress: string
      token0Address: string
      token1Address: string
      status: UniswapToolStatus
      tickLowerDiff: string
      tickUpperDiff: string
      isOfficial: boolean
      totalUserUsed: number
      performanceFeeRatio?: string | null
      tags?: Array<{
        __typename?: 'UniswapToolTag'
        id: string
        name: string
        type: UniswapToolTagType
      }> | null
      token0DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
      token1DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
      feeTier: {
        __typename?: 'UniswapToolFeeTier'
        id: string
        feeForDisplay: string
        feeForContract: string
      }
      publicToolInfo?: {
        __typename?: 'UniswapToolInfo'
        id: string
        description: string
        status: PublicUniswapV3ToolStatus
        chain: EvmChain
        createdAt: string
        name: string
        numOfApply?: number | null
        expectedAPY: string
        isOfficial: boolean
        riskLevel: RiskLevel
        tickLowerDiff: string
        tickLowerDiffForContract: string
        tickUpperDiff: string
        tickUpperDiffForContract?: string | null
        performanceFee: string
        token0Address: string
        creatorName?: string | null
        creatorDescription?: string | null
        token1Address: string
        creatorId: string
        creatorAddress: string
        maximumLoss: string
        poolAddress: string
        apyEstimationBaseOnInputUsdValue?: string | null
        apyHighestCurrent: string
        apyHighest24h: string
        apyHighest7d: string
        apyHighest30d: string
        tvlTool: string
        tvlPool: string
        token0DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
        token1DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
        tags?: Array<{
          __typename?: 'UniswapToolTag'
          id: string
          name: string
          type: UniswapToolTagType
        }> | null
        feeTier: {
          __typename?: 'UniswapToolFeeTier'
          id: string
          feeForContract: string
          feeForDisplay: string
        }
        poolInfo?: {
          __typename?: 'UniswapPoolInfo'
          id: string
          untrackedVolumeUSD: string
          txCount: string
          totalValueLockedUSDUntracked: string
          totalValueLockedUSD: string
          totalValueLockedToken1: string
          totalValueLockedToken0: string
          totalValueLockedETH: string
          observationIndex: string
          feesUSD: string
          createdAtBlockNumber: string
          createdAtTimestamp: string
          liquidityProviderCount: string
          liquidity: string
          sqrtPrice: string
          feeGrowthGlobal0X128: string
          feeGrowthGlobal1X128: string
          tick?: string | null
          volumeUSD: string
          token0Price: string
          token1Price: string
          averageTradingVolumeUSD1D?: string | null
          averageTradingVolumeUSD7D?: string | null
          token0?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
          token1?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
        } | null
      } | null
      position?: {
        __typename?: 'UserUniswapPositionInfo'
        id: string
        userAddress: string
        chain: EvmChain
        positionIdFromSmartContractPositionManager?: number | null
        toolId: string
        toolName: string
        toolDescription: string
        toolIsOfficial: boolean
        toolPerformanceFeeRatio?: string | null
        token0Address: string
        token1Address: string
        poolAddress: string
        tickLowerDiff: string
        tickUpperDiff: string
        createdAt: string
        closedAt?: string | null
        updatedAt: string
        apy: string
        roi: string
        totalInvestAmountUsdValue: string
        status: UniswapToolPositionStatus
        token0TotalFeeUsdValue: string
        token1TotalFeeUsdValue: string
        unrealizedPnLUsd?: string | null
        uniswapIncomeUsd?: string | null
        performanceFeeChargeUsdValue?: string | null
        tokenPriceFluctuationsUsd?: string | null
        campaignId?: string | null
        reyieldTokens?: string | null
        token0DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
        token1DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
        publicToolInfo?: {
          __typename?: 'UniswapToolInfo'
          id: string
          description: string
          status: PublicUniswapV3ToolStatus
          chain: EvmChain
          createdAt: string
          name: string
          numOfApply?: number | null
          expectedAPY: string
          isOfficial: boolean
          riskLevel: RiskLevel
          tickLowerDiff: string
          tickLowerDiffForContract: string
          tickUpperDiff: string
          tickUpperDiffForContract?: string | null
          performanceFee: string
          token0Address: string
          creatorName?: string | null
          creatorDescription?: string | null
          token1Address: string
          creatorId: string
          creatorAddress: string
          maximumLoss: string
          poolAddress: string
          apyEstimationBaseOnInputUsdValue?: string | null
          apyHighestCurrent: string
          apyHighest24h: string
          apyHighest7d: string
          apyHighest30d: string
          tvlTool: string
          tvlPool: string
          token0DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
            usdtPrice?: string | null
          } | null
          token1DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
            usdtPrice?: string | null
          } | null
          tags?: Array<{
            __typename?: 'UniswapToolTag'
            id: string
            name: string
            type: UniswapToolTagType
          }> | null
          feeTier: {
            __typename?: 'UniswapToolFeeTier'
            id: string
            feeForContract: string
            feeForDisplay: string
          }
          poolInfo?: {
            __typename?: 'UniswapPoolInfo'
            id: string
            untrackedVolumeUSD: string
            txCount: string
            totalValueLockedUSDUntracked: string
            totalValueLockedUSD: string
            totalValueLockedToken1: string
            totalValueLockedToken0: string
            totalValueLockedETH: string
            observationIndex: string
            feesUSD: string
            createdAtBlockNumber: string
            createdAtTimestamp: string
            liquidityProviderCount: string
            liquidity: string
            sqrtPrice: string
            feeGrowthGlobal0X128: string
            feeGrowthGlobal1X128: string
            tick?: string | null
            volumeUSD: string
            token0Price: string
            token1Price: string
            averageTradingVolumeUSD1D?: string | null
            averageTradingVolumeUSD7D?: string | null
            token0?: {
              __typename?: 'UniswapToolToken'
              id: string
              symbol: string
              name: string
              volumeUSD: string
              chain: EvmChain
              decimals: string
              volume: string
              untrackedVolumeUSD: string
              feesUSD: string
              txCount: string
              poolCount: string
              totalValueLocked: string
              totalValueLockedUSD: string
              totalValueLockedUSDUntracked: string
              derivedETH: string
              totalSupply: string
            } | null
            token1?: {
              __typename?: 'UniswapToolToken'
              id: string
              symbol: string
              name: string
              volumeUSD: string
              chain: EvmChain
              decimals: string
              volume: string
              untrackedVolumeUSD: string
              feesUSD: string
              txCount: string
              poolCount: string
              totalValueLocked: string
              totalValueLockedUSD: string
              totalValueLockedUSDUntracked: string
              derivedETH: string
              totalSupply: string
            } | null
          } | null
        } | null
        feeTier: {
          __typename?: 'UniswapToolFeeTier'
          id: string
          feeForDisplay: string
          feeForContract: string
        }
        runningPositionAmount?: {
          __typename?: 'UniswapOnchainPositionAmountInfo'
          token0Amount: string
          token1Amount: string
          token0AmountUsdValue: string
          token1AmountUsdValue: string
        } | null
        closedPositionAmount?: {
          __typename?: 'UniswapOnchainPositionAmountInfo'
          token0Amount: string
          token1Amount: string
          token0AmountUsdValue: string
          token1AmountUsdValue: string
        } | null
        onchainPositionInfo?: {
          __typename?: 'UserUniswapOnchainPositionInfo'
          apy: string
          roi: string
          totalInvestAmountUsdValue?: string | null
          token0Address: string
          token1Address: string
          positionIdFromSmartContractPositionManager?: number | null
          positionId: string
          runningPositionAmount?: {
            __typename?: 'UniswapOnchainPositionAmountInfo'
            token0Amount: string
            token1Amount: string
            token0AmountUsdValue: string
            token1AmountUsdValue: string
          } | null
          closedPositionAmount?: {
            __typename?: 'UniswapOnchainPositionAmountInfo'
            token0Amount: string
            token1Amount: string
            token0AmountUsdValue: string
            token1AmountUsdValue: string
          } | null
          token0DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
            usdtPrice?: string | null
          } | null
          token1DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
            usdtPrice?: string | null
          } | null
        } | null
        progresses?: Array<{
          __typename?: 'UserUniswapv3PositionProgress'
          id: string
          createdAt: string
          updatedAt: string
          index: number
          status: UserUniswapv3PositionProgressStatus
          type: UserUniswapv3PositionProgressType
          steps: Array<{
            __typename?: 'UserUniswapv3PositionProgressStep'
            status: UserUniswapv3PositionProgressStepStatus
            type: UserUniswapv3PositionProgressStepType
          }>
        }> | null
      } | null
      outOfLowerRange: {
        __typename?: 'UserUniswapToolOutOfRangeInfo'
        maxRebalanceTimes: number
        standbyInterval: number
        standbyMaxInterval: number
        earnSpendRate: string
      }
      outOfUpperRange: {
        __typename?: 'UserUniswapToolOutOfRangeInfo'
        maxRebalanceTimes: number
        standbyInterval: number
        standbyMaxInterval: number
        earnSpendRate: string
      }
    }> | null
  }
}

export type FetchPoolsWithArgumentsQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  orderDirection?: InputMaybe<Scalars['String']>
}>

export type FetchPoolsWithArgumentsQuery = {
  __typename?: 'Query'
  genericPoolsEthereum?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
  genericPoolsPolygon?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
  stablesPoolsEthereum?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
  stablesPoolsPolygon?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
  poolsEthereum?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
  poolsPolygon?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
}

export type FetchPoolsQueryVariables = Exact<{
  token0s?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  token1s?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  chain: EvmChain
}>

export type FetchPoolsQuery = {
  __typename?: 'Query'
  poolsWithArguments?: Array<{
    __typename?: 'UniswapPoolInfo'
    isPoolActiveForReyield?: boolean | null
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
}

export type FetchPoolsTicksByPoolIdsQueryVariables = Exact<{
  poolIds: Array<Scalars['String']> | Scalars['String']
  chain: EvmChain
}>

export type FetchPoolsTicksByPoolIdsQuery = {
  __typename?: 'Query'
  poolsWithArguments?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    ticks?: Array<{
      __typename?: 'UniswapPoolTick'
      id: string
      tickIdx: string
      liquidityNet: string
      price0: string
      price1: string
    }> | null
  }> | null
}

export type FetchSupportingTokensQueryVariables = Exact<{
  chain: EvmChain
}>

export type FetchSupportingTokensQuery = {
  __typename?: 'Query'
  uniswapTokens?: Array<{
    __typename?: 'UniswapToolToken'
    id: string
    symbol: string
    name: string
    decimals: string
    totalSupply: string
    volume: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    poolCount: string
    totalValueLocked: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    derivedETH: string
  }> | null
}

export type FetchMetricQueryVariables = Exact<{ [key: string]: never }>

export type FetchMetricQuery = {
  __typename?: 'Query'
  metric?: Array<{
    __typename?: 'Metric'
    id: string
    createdAt: string
    positions?: Array<{
      __typename?: 'MetricPosition'
      id: string
      positionManagerContractAddress: string
      positionOwnerAddress: string
      positionId: number
      positionIdTotalCount: number
      positionIsRunning: boolean
      amountUsd: string
      sumUsd: string
      feeUsd: string
      strategyId: string
      strategyProvider: string
      chain: EvmChain
      createdAt: string
    }> | null
    positionManagersInfo?: Array<{
      __typename?: 'PositionManagerInfo'
      id: string
      chain: EvmChain
      count: number
    }> | null
  }> | null
}

export type FetchToolSupportingTokensQueryVariables = Exact<{ [key: string]: never }>

export type FetchToolSupportingTokensQuery = {
  __typename?: 'Query'
  toolsSupportingTokens?: Array<{
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
    usdtPrice?: string | null
  }> | null
}

export type FetchMeReyieldLicenseQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeReyieldLicenseQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    reyieldLicense?: {
      __typename?: 'ReyieldLicense'
      numberOfLicense: number
      numberOfUsedLicense: number
    } | null
  }
}

export type FetchMePrivilegeQueryVariables = Exact<{ [key: string]: never }>

export type FetchMePrivilegeQuery = {
  __typename?: 'Query'
  me: { __typename?: 'UserInfo'; id: string; privilege?: boolean | null }
}

export type FetchMeGasFeeWalletsQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeGasFeeWalletsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    gasFeeWallets?: Array<{
      __typename?: 'GasFeeWallet'
      id: string
      chain: EvmChain
      address: string
      amount: string
      amountSymbol: string
      amountUsd?: string | null
      status: GasFeeWalletStatus
      isLoading: boolean
      updatedAt: string
    }> | null
  }
}

export type FetchCampaignAirdropLandingQueryVariables = Exact<{ [key: string]: never }>

export type FetchCampaignAirdropLandingQuery = {
  __typename?: 'Query'
  campaignAirdrop?: {
    __typename?: 'CampaignAirdrop'
    id?: string | null
    tvl: string
    totalAirdropToken: string
    toolIds: Array<string>
    campaign: {
      __typename?: 'Campaign'
      createdAt?: string | null
      endTtime?: string | null
      id?: string | null
      isEnable?: boolean | null
      name?: string | null
      startTime?: string | null
      type?: CampaignType | null
      updatedAt?: string | null
    }
  } | null
}

export type FetchCampaignAirdropToolsQueryVariables = Exact<{
  filter?: InputMaybe<ToolFilterInput>
}>

export type FetchCampaignAirdropToolsQuery = {
  __typename?: 'Query'
  campaignAirdropTools?: Array<{
    __typename?: 'UniswapToolInfo'
    id: string
    description: string
    status: PublicUniswapV3ToolStatus
    chain: EvmChain
    createdAt: string
    name: string
    numOfApply?: number | null
    expectedAPY: string
    isOfficial: boolean
    riskLevel: RiskLevel
    tickLowerDiff: string
    tickLowerDiffForContract: string
    tickUpperDiff: string
    tickUpperDiffForContract?: string | null
    performanceFee: string
    token0Address: string
    creatorName?: string | null
    creatorDescription?: string | null
    token1Address: string
    creatorId: string
    creatorAddress: string
    maximumLoss: string
    poolAddress: string
    apyEstimationBaseOnInputUsdValue?: string | null
    apyHighestCurrent: string
    apyHighest24h: string
    apyHighest7d: string
    apyHighest30d: string
    tvlTool: string
    tvlPool: string
    token0DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
    token1DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
      usdtPrice?: string | null
    } | null
    tags?: Array<{
      __typename?: 'UniswapToolTag'
      id: string
      name: string
      type: UniswapToolTagType
    }> | null
    feeTier: {
      __typename?: 'UniswapToolFeeTier'
      id: string
      feeForContract: string
      feeForDisplay: string
    }
    poolInfo?: {
      __typename?: 'UniswapPoolInfo'
      id: string
      untrackedVolumeUSD: string
      txCount: string
      totalValueLockedUSDUntracked: string
      totalValueLockedUSD: string
      totalValueLockedToken1: string
      totalValueLockedToken0: string
      totalValueLockedETH: string
      observationIndex: string
      feesUSD: string
      createdAtBlockNumber: string
      createdAtTimestamp: string
      liquidityProviderCount: string
      liquidity: string
      sqrtPrice: string
      feeGrowthGlobal0X128: string
      feeGrowthGlobal1X128: string
      tick?: string | null
      volumeUSD: string
      token0Price: string
      token1Price: string
      averageTradingVolumeUSD1D?: string | null
      averageTradingVolumeUSD7D?: string | null
      token0?: {
        __typename?: 'UniswapToolToken'
        id: string
        symbol: string
        name: string
        volumeUSD: string
        chain: EvmChain
        decimals: string
        volume: string
        untrackedVolumeUSD: string
        feesUSD: string
        txCount: string
        poolCount: string
        totalValueLocked: string
        totalValueLockedUSD: string
        totalValueLockedUSDUntracked: string
        derivedETH: string
        totalSupply: string
      } | null
      token1?: {
        __typename?: 'UniswapToolToken'
        id: string
        symbol: string
        name: string
        volumeUSD: string
        chain: EvmChain
        decimals: string
        volume: string
        untrackedVolumeUSD: string
        feesUSD: string
        txCount: string
        poolCount: string
        totalValueLocked: string
        totalValueLockedUSD: string
        totalValueLockedUSDUntracked: string
        derivedETH: string
        totalSupply: string
      } | null
    } | null
  }> | null
}

export type FetchCampaignAirdropExploreQueryVariables = Exact<{
  campaignId: Scalars['String']
}>

export type FetchCampaignAirdropExploreQuery = {
  __typename?: 'Query'
  campaignAirdropExplore: {
    __typename?: 'CampaignAirdropExplore'
    id: string
    tvl?: string | null
    totalAirdropToken?: string | null
    currentHourlyAirdropToken?: string | null
  }
}

export type FetchUserCampaignAirdropPortfolioQueryVariables = Exact<{
  campaignId: Scalars['String']
}>

export type FetchUserCampaignAirdropPortfolioQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    campaignAirdropPortfolio?: {
      __typename?: 'UserCampaignAirdropPortfolio'
      id: string
      userTotalEarnedReyieldTokens?: string | null
      userTotalInvestingAmount?: string | null
      userTotalProfit?: string | null
      tasks?: Array<{
        __typename?: 'CampaignAirdropTask'
        id: string
        name: string
        startTime: string
        endTime: string
        tweetLink: string
        userTaskFinishedAt?: string | null
        userTaskUserId?: string | null
      }> | null
    } | null
  }
}

export type FetchUserCampaignAirdropToolsQueryVariables = Exact<{
  campaignId: Scalars['String']
}>

export type FetchUserCampaignAirdropToolsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    campaignAirdropPositions?: Array<{
      __typename?: 'UserUniswapPositionInfo'
      id: string
      userAddress: string
      chain: EvmChain
      positionIdFromSmartContractPositionManager?: number | null
      toolId: string
      toolName: string
      toolDescription: string
      toolIsOfficial: boolean
      toolPerformanceFeeRatio?: string | null
      token0Address: string
      token1Address: string
      poolAddress: string
      tickLowerDiff: string
      tickUpperDiff: string
      createdAt: string
      closedAt?: string | null
      updatedAt: string
      apy: string
      roi: string
      totalInvestAmountUsdValue: string
      status: UniswapToolPositionStatus
      token0TotalFeeUsdValue: string
      token1TotalFeeUsdValue: string
      unrealizedPnLUsd?: string | null
      uniswapIncomeUsd?: string | null
      performanceFeeChargeUsdValue?: string | null
      tokenPriceFluctuationsUsd?: string | null
      campaignId?: string | null
      reyieldTokens?: string | null
      token0DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
      token1DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
        usdtPrice?: string | null
      } | null
      publicToolInfo?: {
        __typename?: 'UniswapToolInfo'
        id: string
        description: string
        status: PublicUniswapV3ToolStatus
        chain: EvmChain
        createdAt: string
        name: string
        numOfApply?: number | null
        expectedAPY: string
        isOfficial: boolean
        riskLevel: RiskLevel
        tickLowerDiff: string
        tickLowerDiffForContract: string
        tickUpperDiff: string
        tickUpperDiffForContract?: string | null
        performanceFee: string
        token0Address: string
        creatorName?: string | null
        creatorDescription?: string | null
        token1Address: string
        creatorId: string
        creatorAddress: string
        maximumLoss: string
        poolAddress: string
        apyEstimationBaseOnInputUsdValue?: string | null
        apyHighestCurrent: string
        apyHighest24h: string
        apyHighest7d: string
        apyHighest30d: string
        tvlTool: string
        tvlPool: string
        token0DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
        token1DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
        tags?: Array<{
          __typename?: 'UniswapToolTag'
          id: string
          name: string
          type: UniswapToolTagType
        }> | null
        feeTier: {
          __typename?: 'UniswapToolFeeTier'
          id: string
          feeForContract: string
          feeForDisplay: string
        }
        poolInfo?: {
          __typename?: 'UniswapPoolInfo'
          id: string
          untrackedVolumeUSD: string
          txCount: string
          totalValueLockedUSDUntracked: string
          totalValueLockedUSD: string
          totalValueLockedToken1: string
          totalValueLockedToken0: string
          totalValueLockedETH: string
          observationIndex: string
          feesUSD: string
          createdAtBlockNumber: string
          createdAtTimestamp: string
          liquidityProviderCount: string
          liquidity: string
          sqrtPrice: string
          feeGrowthGlobal0X128: string
          feeGrowthGlobal1X128: string
          tick?: string | null
          volumeUSD: string
          token0Price: string
          token1Price: string
          averageTradingVolumeUSD1D?: string | null
          averageTradingVolumeUSD7D?: string | null
          token0?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
          token1?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
        } | null
      } | null
      feeTier: {
        __typename?: 'UniswapToolFeeTier'
        id: string
        feeForDisplay: string
        feeForContract: string
      }
      runningPositionAmount?: {
        __typename?: 'UniswapOnchainPositionAmountInfo'
        token0Amount: string
        token1Amount: string
        token0AmountUsdValue: string
        token1AmountUsdValue: string
      } | null
      closedPositionAmount?: {
        __typename?: 'UniswapOnchainPositionAmountInfo'
        token0Amount: string
        token1Amount: string
        token0AmountUsdValue: string
        token1AmountUsdValue: string
      } | null
      onchainPositionInfo?: {
        __typename?: 'UserUniswapOnchainPositionInfo'
        apy: string
        roi: string
        totalInvestAmountUsdValue?: string | null
        token0Address: string
        token1Address: string
        positionIdFromSmartContractPositionManager?: number | null
        positionId: string
        runningPositionAmount?: {
          __typename?: 'UniswapOnchainPositionAmountInfo'
          token0Amount: string
          token1Amount: string
          token0AmountUsdValue: string
          token1AmountUsdValue: string
        } | null
        closedPositionAmount?: {
          __typename?: 'UniswapOnchainPositionAmountInfo'
          token0Amount: string
          token1Amount: string
          token0AmountUsdValue: string
          token1AmountUsdValue: string
        } | null
        token0DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
        token1DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
          usdtPrice?: string | null
        } | null
      } | null
      progresses?: Array<{
        __typename?: 'UserUniswapv3PositionProgress'
        id: string
        createdAt: string
        updatedAt: string
        index: number
        status: UserUniswapv3PositionProgressStatus
        type: UserUniswapv3PositionProgressType
        steps: Array<{
          __typename?: 'UserUniswapv3PositionProgressStep'
          status: UserUniswapv3PositionProgressStepStatus
          type: UserUniswapv3PositionProgressStepType
        }>
      }> | null
    }> | null
  }
}

export const CampaignAirdropTaskFragmentDoc = gql`
  fragment CampaignAirdropTask on CampaignAirdropTask {
    id
    name
    startTime
    endTime
    tweetLink
    userTaskFinishedAt
    userTaskUserId
  }
`
export const GasFeeWalletFragmentDoc = gql`
  fragment GasFeeWallet on GasFeeWallet {
    id @client
    chain @client
    address @client
    amount @client
    amountSymbol @client
    amountUsd @client
    status @client
    isLoading @client
    updatedAt @client
  }
`
export const UniswapToolTagFragmentDoc = gql`
  fragment UniswapToolTag on UniswapToolTag {
    id
    name
    type
  }
`
export const TokenDisplayInfoFragmentDoc = gql`
  fragment TokenDisplayInfo on TokenDisplayInfo {
    id
    chain
    name
    address
    imageURL
    symbol
    usdtPrice
  }
`
export const UniswapToolFeeTierFragmentDoc = gql`
  fragment UniswapToolFeeTier on UniswapToolFeeTier {
    id
    feeForDisplay
    feeForContract
  }
`
export const ExploreToolCardTokenFragmentDoc = gql`
  fragment ExploreToolCardToken on UniswapToolToken {
    id
    symbol
    name
    volumeUSD
    chain
    decimals
    volume
    volumeUSD
    untrackedVolumeUSD
    feesUSD
    txCount
    poolCount
    totalValueLocked
    totalValueLockedUSD
    totalValueLockedUSDUntracked
    derivedETH
    totalSupply
  }
`
export const UniswapToolInfoFragmentDoc = gql`
  fragment UniswapToolInfo on UniswapToolInfo {
    id
    description
    status
    chain
    createdAt
    name
    numOfApply
    expectedAPY
    isOfficial
    riskLevel
    tickLowerDiff
    tickLowerDiffForContract
    tickUpperDiff
    tickUpperDiffForContract
    performanceFee
    token0Address
    creatorName
    creatorDescription
    token0DisplayInfo {
      ...TokenDisplayInfo
    }
    token1Address
    token1DisplayInfo {
      ...TokenDisplayInfo
    }
    creatorId
    creatorAddress
    maximumLoss
    poolAddress
    tags {
      ...UniswapToolTag
    }
    feeTier {
      id
      feeForContract
      feeForDisplay
    }
    apyEstimationBaseOnInputUsdValue @client
    apyHighestCurrent
    apyHighest24h
    apyHighest7d
    apyHighest30d
    tvlTool
    tvlPool
    poolInfo {
      id
      averageTradingVolumeUSD1D: averageTradingVolumeUSD(numberOfDays: 1)
      averageTradingVolumeUSD7D: averageTradingVolumeUSD(numberOfDays: 7)
      untrackedVolumeUSD
      txCount
      totalValueLockedUSDUntracked
      totalValueLockedUSD
      totalValueLockedToken1
      totalValueLockedToken0
      totalValueLockedETH
      observationIndex
      feesUSD
      createdAtBlockNumber
      createdAtTimestamp
      liquidityProviderCount
      liquidity
      sqrtPrice
      feeGrowthGlobal0X128
      feeGrowthGlobal1X128
      tick
      volumeUSD
      token0Price
      token1Price
      token0 {
        ...ExploreToolCardToken
      }
      token1 {
        ...ExploreToolCardToken
      }
    }
  }
  ${TokenDisplayInfoFragmentDoc}
  ${UniswapToolTagFragmentDoc}
  ${ExploreToolCardTokenFragmentDoc}
`
export const UniswapOnchainPositionAmountInfoFragmentDoc = gql`
  fragment UniswapOnchainPositionAmountInfo on UniswapOnchainPositionAmountInfo {
    token0Amount
    token1Amount
    token0AmountUsdValue
    token1AmountUsdValue
  }
`
export const UserUniswapOnchainPositionInfoFragmentDoc = gql`
  fragment UserUniswapOnchainPositionInfo on UserUniswapOnchainPositionInfo {
    apy
    roi
    totalInvestAmountUsdValue
    runningPositionAmount {
      ...UniswapOnchainPositionAmountInfo
    }
    closedPositionAmount {
      ...UniswapOnchainPositionAmountInfo
    }
    token0Address
    token1Address
    token0DisplayInfo {
      ...TokenDisplayInfo
    }
    token1DisplayInfo {
      ...TokenDisplayInfo
    }
    positionIdFromSmartContractPositionManager
    positionId
  }
  ${UniswapOnchainPositionAmountInfoFragmentDoc}
  ${TokenDisplayInfoFragmentDoc}
`
export const UserUniswapv3PositionProgressFragmentDoc = gql`
  fragment UserUniswapv3PositionProgress on UserUniswapv3PositionProgress {
    id
    createdAt
    updatedAt
    index
    status
    steps {
      status
      type
    }
    type
  }
`
export const UserUniswapPositionInfoFragmentDoc = gql`
  fragment UserUniswapPositionInfo on UserUniswapPositionInfo {
    id
    userAddress
    chain
    positionIdFromSmartContractPositionManager
    toolId
    toolName
    toolDescription
    toolIsOfficial
    toolPerformanceFeeRatio
    token0Address
    token0DisplayInfo {
      ...TokenDisplayInfo
    }
    token1Address
    token1DisplayInfo {
      ...TokenDisplayInfo
    }
    poolAddress
    publicToolInfo {
      ...UniswapToolInfo
    }
    feeTier {
      ...UniswapToolFeeTier
    }
    tickLowerDiff
    tickUpperDiff
    createdAt
    closedAt
    updatedAt
    apy
    roi
    totalInvestAmountUsdValue
    runningPositionAmount {
      ...UniswapOnchainPositionAmountInfo
    }
    closedPositionAmount {
      ...UniswapOnchainPositionAmountInfo
    }
    onchainPositionInfo {
      ...UserUniswapOnchainPositionInfo
    }
    status
    progresses {
      ...UserUniswapv3PositionProgress
    }
    token0TotalFeeUsdValue
    token1TotalFeeUsdValue
    unrealizedPnLUsd @client
    uniswapIncomeUsd @client
    performanceFeeChargeUsdValue @client
    tokenPriceFluctuationsUsd @client
    campaignId
    reyieldTokens
  }
  ${TokenDisplayInfoFragmentDoc}
  ${UniswapToolInfoFragmentDoc}
  ${UniswapToolFeeTierFragmentDoc}
  ${UniswapOnchainPositionAmountInfoFragmentDoc}
  ${UserUniswapOnchainPositionInfoFragmentDoc}
  ${UserUniswapv3PositionProgressFragmentDoc}
`
export const UserUniswapToolOutOfRangeInfoFragmentDoc = gql`
  fragment UserUniswapToolOutOfRangeInfo on UserUniswapToolOutOfRangeInfo {
    maxRebalanceTimes
    standbyInterval
    standbyMaxInterval
    earnSpendRate
  }
`
export const UserUniswapToolSettingInfoFragmentDoc = gql`
  fragment UserUniswapToolSettingInfo on UserUniswapToolSettingInfo {
    id
    chain
    name
    description
    tags {
      ...UniswapToolTag
    }
    createdAt
    closedAt
    updatedAt
    poolAddress
    token0Address
    token0DisplayInfo {
      ...TokenDisplayInfo
    }
    token1Address
    token1DisplayInfo {
      ...TokenDisplayInfo
    }
    feeTier {
      ...UniswapToolFeeTier
    }
    status
    tickLowerDiff
    tickUpperDiff
    isOfficial
    totalUserUsed
    performanceFeeRatio
    publicToolInfo {
      ...UniswapToolInfo
    }
    position {
      ...UserUniswapPositionInfo
    }
    outOfLowerRange {
      ...UserUniswapToolOutOfRangeInfo
    }
    outOfUpperRange {
      ...UserUniswapToolOutOfRangeInfo
    }
  }
  ${UniswapToolTagFragmentDoc}
  ${TokenDisplayInfoFragmentDoc}
  ${UniswapToolFeeTierFragmentDoc}
  ${UniswapToolInfoFragmentDoc}
  ${UserUniswapPositionInfoFragmentDoc}
  ${UserUniswapToolOutOfRangeInfoFragmentDoc}
`
export const UniswapPoolTokenFragmentDoc = gql`
  fragment UniswapPoolToken on UniswapToolToken {
    id
    symbol
    name
    decimals
    totalSupply
    volume
    volumeUSD
    untrackedVolumeUSD
    feesUSD
    txCount
    poolCount
    totalValueLocked
    totalValueLockedUSD
    totalValueLockedUSDUntracked
    derivedETH
  }
`
export const UniswapToolTokenPriceChartFragmentDoc = gql`
  fragment UniswapToolTokenPriceChart on UniswapToolTokenPriceChart {
    id
    currentPriceUSD
    prices {
      timestamp
      value
    }
  }
`
export const PoolFragmentDoc = gql`
  fragment Pool on UniswapPoolInfo {
    id
    createdAtTimestamp
    createdAtBlockNumber
    token0 {
      ...UniswapPoolToken
      priceChart {
        ...UniswapToolTokenPriceChart
      }
    }
    token1 {
      ...UniswapPoolToken
      priceChart {
        ...UniswapToolTokenPriceChart
      }
    }
    feeTier
    liquidity
    sqrtPrice
    feeGrowthGlobal0X128
    feeGrowthGlobal1X128
    token0Price
    token1Price
    tick
    observationIndex
    volumeToken0
    volumeToken1
    volumeUSD
    untrackedVolumeUSD
    feesUSD
    txCount
    collectedFeesToken0
    collectedFeesToken1
    collectedFeesUSD
    totalValueLockedToken0
    totalValueLockedToken1
    totalValueLockedETH
    totalValueLockedUSD
    totalValueLockedUSDUntracked
    liquidityProviderCount
    chain
    averageTradingVolumeUSD1D: averageTradingVolumeUSD(numberOfDays: 1)
    averageTradingVolumeUSD30D: averageTradingVolumeUSD(numberOfDays: 30)
    averageTradingVolumeUSD365D: averageTradingVolumeUSD(numberOfDays: 365)
  }
  ${UniswapPoolTokenFragmentDoc}
  ${UniswapToolTokenPriceChartFragmentDoc}
`
export const PoolWithReyieldPoolValidationFragmentDoc = gql`
  fragment PoolWithReyieldPoolValidation on UniswapPoolInfo {
    ...Pool
    isPoolActiveForReyield
  }
  ${PoolFragmentDoc}
`
export const CurrencyInfoFragmentDoc = gql`
  fragment CurrencyInfo on CurrencyInfo {
    name
    symbol
    decimals
  }
`
export const DefiWalletFragmentDoc = gql`
  fragment DefiWallet on DefiWallet {
    id
    chain
    balance
    balanceComputed
    balanceComputedWithUnit
    currencyInfo {
      ...CurrencyInfo
    }
    iconifySrc @client
  }
  ${CurrencyInfoFragmentDoc}
`
export const ExternalApiKeyFragmentDoc = gql`
  fragment ExternalAPIKey on ExternalAPIKey {
    id
    exchange
    key
    name
    isRevoked
  }
`
export const PublicStrategyParameterFragmentDoc = gql`
  fragment PublicStrategyParameter on StrategyParameters {
    defaultValue
    description
    key
    maxValue
    minValue
    name
    type
    updatable
  }
`
export const PublicStrategyInfoFragmentDoc = gql`
  fragment PublicStrategyInfo on StrategyInfo {
    id
    sid
    currency
    description
    earningCurrency
    exchanges
    expectedAPY
    name
    parameters {
      ...PublicStrategyParameter
    }
    suggestionPeriod
    target
    type
    riskLevel
    numOfApply
    maximumLoss
    stage
  }
  ${PublicStrategyParameterFragmentDoc}
`
export const UserStrategyParametersFragmentDoc = gql`
  fragment UserStrategyParameters on UserStrategyParameters {
    key
    value
  }
`
export const StrategyPerformanceFragmentDoc = gql`
  fragment StrategyPerformance on StrategyPerformance {
    currency
    amount
    ror
    apy
  }
`
export const TotalProfitsFragmentDoc = gql`
  fragment TotalProfits on TotalProfits {
    currency
    amount
  }
`
export const UserStrategyProfitFragmentDoc = gql`
  fragment UserStrategyProfit on UserStrategyProfit {
    sid
    type
    totalProfit {
      ...TotalProfits
    }
  }
  ${TotalProfitsFragmentDoc}
`
export const UserStrategiesFragmentDoc = gql`
  fragment UserStrategies on UserStrategyInfo {
    id
    sid
    createdAt
    closedAt
    updatedAt
    infos {
      name
      currency
      earningCurrency
      exchange
      minEnableAmount
    }
    parameters {
      ...UserStrategyParameters
    }
    performance {
      initialValue
      duration
      performances {
        ...StrategyPerformance
      }
    }
    ceFiStatus
    errMsg
    profits {
      ...UserStrategyProfit
    }
  }
  ${UserStrategyParametersFragmentDoc}
  ${StrategyPerformanceFragmentDoc}
  ${UserStrategyProfitFragmentDoc}
`
export const UserStrategyInfoFragmentDoc = gql`
  fragment UserStrategyInfo on UserStrategyInfo {
    id
    sid
    createdAt
    closedAt
    updatedAt
    createdAt
    closedAt
    updatedAt
    sid
    updatedAt
    infos {
      name
      currency
      earningCurrency
      exchange
      minEnableAmount
    }
    parameters {
      ...UserStrategyParameters
    }
    performance {
      initialValue
      duration
      performances {
        ...StrategyPerformance
      }
    }
    ceFiStatus
    errMsg
    profits {
      ...UserStrategyProfit
    }
  }
  ${UserStrategyParametersFragmentDoc}
  ${StrategyPerformanceFragmentDoc}
  ${UserStrategyProfitFragmentDoc}
`
export const CurrencyBalancesFragmentDoc = gql`
  fragment CurrencyBalances on CurrencyBalances {
    currency
    balance {
      available
      total
    }
  }
`
export const WalletBalancesFragmentDoc = gql`
  fragment WalletBalances on WalletBalances {
    currencyBalances {
      ...CurrencyBalances
    }
    wallet
  }
  ${CurrencyBalancesFragmentDoc}
`
export const CefiWalletFragmentDoc = gql`
  fragment CefiWallet on CefiWallet {
    exchange
    walletBalances {
      ...WalletBalances
    }
  }
  ${WalletBalancesFragmentDoc}
`
export const StrategyProgressStepFragmentDoc = gql`
  fragment StrategyProgressStep on StrategyProgressStep {
    status
    type
  }
`
export const StrategyProgressFragmentDoc = gql`
  fragment StrategyProgress on StrategyProgress {
    id
    createdAt
    updatedAt
    index
    status
    steps {
      ...StrategyProgressStep
    }
    type
  }
  ${StrategyProgressStepFragmentDoc}
`
export const CoinPriceFragmentDoc = gql`
  fragment CoinPrice on CoinPrice {
    symbol
    usdtPrice
  }
`
export const RateHistoricalFragmentDoc = gql`
  fragment RateHistorical on RateHistorical {
    time
    rate
  }
`
export const UserStrategyRecordFragmentDoc = gql`
  fragment UserStrategyRecord on UserStrategyRecord {
    id
    amount
    currency
    rate
    time
    type
  }
`
export const AiCompletionRecordFragmentDoc = gql`
  fragment AICompletionRecord on aICompletionRecords {
    id
    promptMutationName
    promptMutationInput
    platform
    meta
    createdAt
    content
    completedAt
    updatedAt
    userAddress
    userId
  }
`
export const SuggestStrategyToolWithAllocationFragmentDoc = gql`
  fragment SuggestStrategyToolWithAllocation on suggestStrategyToolWithAllocations {
    id
    maximumLoss
    name
    sid
    updatedAt
    highestAnnualReturn
    description
    createdAt
    allocation
    systemInvestAmountSuggestion
    systemInvestCurrencySuggestion
  }
`
export const SingleCompletionRecordForDialogFragmentDoc = gql`
  fragment SingleCompletionRecordForDialog on aICompletionRecords {
    id
    promptMutationName
    promptMutationInput
    platform
    meta
    createdAt
    content
    completedAt
    updatedAt
    userAddress
    userId
    suggestions {
      ...SuggestStrategyToolWithAllocation
    }
  }
  ${SuggestStrategyToolWithAllocationFragmentDoc}
`
export const NftCollectionFragmentDoc = gql`
  fragment NFTCollection on NFTCollections {
    name
    description
    tokenId
    contract
    imageUrl
    attributes {
      traitType
      value
    }
  }
`
export const PositionFragmentDoc = gql`
  fragment Position on UniswapPoolPosition {
    id
    tickLowerTickIdx
    tickLowerFeeGrowthOutside0X128
    tickLowerFeeGrowthOutside1X128
    tickUpperTickIdx
    tickUpperFeeGrowthOutside0X128
    tickUpperFeeGrowthOutside1X128
    depositedToken0
    depositedToken1
    liquidity
    transactionTimestamp
    collectedFeesToken0
    collectedFeesToken1
    feeGrowthInside0LastX128
    feeGrowthInside1LastX128
  }
`
export const UserExternalApiKeyCreateDocument = gql`
  mutation userExternalAPIKeyCreate($input: ExternalAPIKeyCreateInput!) {
    externalAPIKeyCreate(input: $input) {
      ...ExternalAPIKey
      user {
        id
        externalAPIKeys(filter: { isRevoked: false }) {
          ...ExternalAPIKey
        }
      }
    }
  }
  ${ExternalApiKeyFragmentDoc}
`
export type UserExternalApiKeyCreateMutationFn = Apollo.MutationFunction<
  UserExternalApiKeyCreateMutation,
  UserExternalApiKeyCreateMutationVariables
>

/**
 * __useUserExternalApiKeyCreateMutation__
 *
 * To run a mutation, you first call `useUserExternalApiKeyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserExternalApiKeyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userExternalApiKeyCreateMutation, { data, loading, error }] = useUserExternalApiKeyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserExternalApiKeyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserExternalApiKeyCreateMutation,
    UserExternalApiKeyCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UserExternalApiKeyCreateMutation,
    UserExternalApiKeyCreateMutationVariables
  >(UserExternalApiKeyCreateDocument, options)
}
export type UserExternalApiKeyCreateMutationHookResult = ReturnType<
  typeof useUserExternalApiKeyCreateMutation
>
export type UserExternalApiKeyCreateMutationResult =
  Apollo.MutationResult<UserExternalApiKeyCreateMutation>
export type UserExternalApiKeyCreateMutationOptions = Apollo.BaseMutationOptions<
  UserExternalApiKeyCreateMutation,
  UserExternalApiKeyCreateMutationVariables
>
export const UserExternalApiKeyDeleteDocument = gql`
  mutation userExternalAPIKeyDelete($input: ExternalAPIKeyDeleteInput!) {
    externalAPIKeyDelete(input: $input) {
      ...ExternalAPIKey
      user {
        id
        externalAPIKeys(filter: { isRevoked: false }) {
          ...ExternalAPIKey
        }
      }
    }
  }
  ${ExternalApiKeyFragmentDoc}
`
export type UserExternalApiKeyDeleteMutationFn = Apollo.MutationFunction<
  UserExternalApiKeyDeleteMutation,
  UserExternalApiKeyDeleteMutationVariables
>

/**
 * __useUserExternalApiKeyDeleteMutation__
 *
 * To run a mutation, you first call `useUserExternalApiKeyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserExternalApiKeyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userExternalApiKeyDeleteMutation, { data, loading, error }] = useUserExternalApiKeyDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserExternalApiKeyDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserExternalApiKeyDeleteMutation,
    UserExternalApiKeyDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UserExternalApiKeyDeleteMutation,
    UserExternalApiKeyDeleteMutationVariables
  >(UserExternalApiKeyDeleteDocument, options)
}
export type UserExternalApiKeyDeleteMutationHookResult = ReturnType<
  typeof useUserExternalApiKeyDeleteMutation
>
export type UserExternalApiKeyDeleteMutationResult =
  Apollo.MutationResult<UserExternalApiKeyDeleteMutation>
export type UserExternalApiKeyDeleteMutationOptions = Apollo.BaseMutationOptions<
  UserExternalApiKeyDeleteMutation,
  UserExternalApiKeyDeleteMutationVariables
>
export const UserStrategyStartDocument = gql`
  mutation userStrategyStart($input: StrategyStartInput!) {
    strategyStart(input: $input) {
      strategy {
        ...UserStrategies
      }
    }
  }
  ${UserStrategiesFragmentDoc}
`
export type UserStrategyStartMutationFn = Apollo.MutationFunction<
  UserStrategyStartMutation,
  UserStrategyStartMutationVariables
>

/**
 * __useUserStrategyStartMutation__
 *
 * To run a mutation, you first call `useUserStrategyStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStrategyStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStrategyStartMutation, { data, loading, error }] = useUserStrategyStartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserStrategyStartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserStrategyStartMutation,
    UserStrategyStartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserStrategyStartMutation, UserStrategyStartMutationVariables>(
    UserStrategyStartDocument,
    options
  )
}
export type UserStrategyStartMutationHookResult = ReturnType<typeof useUserStrategyStartMutation>
export type UserStrategyStartMutationResult = Apollo.MutationResult<UserStrategyStartMutation>
export type UserStrategyStartMutationOptions = Apollo.BaseMutationOptions<
  UserStrategyStartMutation,
  UserStrategyStartMutationVariables
>
export const UserStrategyStopDocument = gql`
  mutation userStrategyStop($input: StrategyStopInput!) {
    strategyStop(input: $input) {
      strategy {
        ...UserStrategies
      }
    }
  }
  ${UserStrategiesFragmentDoc}
`
export type UserStrategyStopMutationFn = Apollo.MutationFunction<
  UserStrategyStopMutation,
  UserStrategyStopMutationVariables
>

/**
 * __useUserStrategyStopMutation__
 *
 * To run a mutation, you first call `useUserStrategyStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStrategyStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStrategyStopMutation, { data, loading, error }] = useUserStrategyStopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserStrategyStopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserStrategyStopMutation,
    UserStrategyStopMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserStrategyStopMutation, UserStrategyStopMutationVariables>(
    UserStrategyStopDocument,
    options
  )
}
export type UserStrategyStopMutationHookResult = ReturnType<typeof useUserStrategyStopMutation>
export type UserStrategyStopMutationResult = Apollo.MutationResult<UserStrategyStopMutation>
export type UserStrategyStopMutationOptions = Apollo.BaseMutationOptions<
  UserStrategyStopMutation,
  UserStrategyStopMutationVariables
>
export const UserStrategyUpdateDocument = gql`
  mutation userStrategyUpdate($input: StrategyUpdateInput!) {
    strategyUpdate(input: $input) {
      strategy {
        ...UserStrategies
      }
    }
  }
  ${UserStrategiesFragmentDoc}
`
export type UserStrategyUpdateMutationFn = Apollo.MutationFunction<
  UserStrategyUpdateMutation,
  UserStrategyUpdateMutationVariables
>

/**
 * __useUserStrategyUpdateMutation__
 *
 * To run a mutation, you first call `useUserStrategyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStrategyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStrategyUpdateMutation, { data, loading, error }] = useUserStrategyUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserStrategyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserStrategyUpdateMutation,
    UserStrategyUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserStrategyUpdateMutation, UserStrategyUpdateMutationVariables>(
    UserStrategyUpdateDocument,
    options
  )
}
export type UserStrategyUpdateMutationHookResult = ReturnType<typeof useUserStrategyUpdateMutation>
export type UserStrategyUpdateMutationResult = Apollo.MutationResult<UserStrategyUpdateMutation>
export type UserStrategyUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserStrategyUpdateMutation,
  UserStrategyUpdateMutationVariables
>
export const CreateAiCompletionRecordDocument = gql`
  mutation createAICompletionRecord($input: CreateAICompletionRecordInput!) {
    createAICompletionRecord(input: $input) {
      ...AICompletionRecord
    }
  }
  ${AiCompletionRecordFragmentDoc}
`
export type CreateAiCompletionRecordMutationFn = Apollo.MutationFunction<
  CreateAiCompletionRecordMutation,
  CreateAiCompletionRecordMutationVariables
>

/**
 * __useCreateAiCompletionRecordMutation__
 *
 * To run a mutation, you first call `useCreateAiCompletionRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiCompletionRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiCompletionRecordMutation, { data, loading, error }] = useCreateAiCompletionRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAiCompletionRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAiCompletionRecordMutation,
    CreateAiCompletionRecordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAiCompletionRecordMutation,
    CreateAiCompletionRecordMutationVariables
  >(CreateAiCompletionRecordDocument, options)
}
export type CreateAiCompletionRecordMutationHookResult = ReturnType<
  typeof useCreateAiCompletionRecordMutation
>
export type CreateAiCompletionRecordMutationResult =
  Apollo.MutationResult<CreateAiCompletionRecordMutation>
export type CreateAiCompletionRecordMutationOptions = Apollo.BaseMutationOptions<
  CreateAiCompletionRecordMutation,
  CreateAiCompletionRecordMutationVariables
>
export const CreateUniswapv3ToolDocument = gql`
  mutation createUniswapv3Tool($input: CreateUniswapv3ToolInput!) {
    createUniswapv3Tool(input: $input)
  }
`
export type CreateUniswapv3ToolMutationFn = Apollo.MutationFunction<
  CreateUniswapv3ToolMutation,
  CreateUniswapv3ToolMutationVariables
>

/**
 * __useCreateUniswapv3ToolMutation__
 *
 * To run a mutation, you first call `useCreateUniswapv3ToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUniswapv3ToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUniswapv3ToolMutation, { data, loading, error }] = useCreateUniswapv3ToolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUniswapv3ToolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUniswapv3ToolMutation,
    CreateUniswapv3ToolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUniswapv3ToolMutation, CreateUniswapv3ToolMutationVariables>(
    CreateUniswapv3ToolDocument,
    options
  )
}
export type CreateUniswapv3ToolMutationHookResult = ReturnType<
  typeof useCreateUniswapv3ToolMutation
>
export type CreateUniswapv3ToolMutationResult = Apollo.MutationResult<CreateUniswapv3ToolMutation>
export type CreateUniswapv3ToolMutationOptions = Apollo.BaseMutationOptions<
  CreateUniswapv3ToolMutation,
  CreateUniswapv3ToolMutationVariables
>
export const CreateUniswapv3PositionDocument = gql`
  mutation createUniswapv3Position($input: CreateUniswapv3PositionInput!) {
    createUniswapv3Position(input: $input)
  }
`
export type CreateUniswapv3PositionMutationFn = Apollo.MutationFunction<
  CreateUniswapv3PositionMutation,
  CreateUniswapv3PositionMutationVariables
>

/**
 * __useCreateUniswapv3PositionMutation__
 *
 * To run a mutation, you first call `useCreateUniswapv3PositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUniswapv3PositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUniswapv3PositionMutation, { data, loading, error }] = useCreateUniswapv3PositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUniswapv3PositionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUniswapv3PositionMutation,
    CreateUniswapv3PositionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUniswapv3PositionMutation,
    CreateUniswapv3PositionMutationVariables
  >(CreateUniswapv3PositionDocument, options)
}
export type CreateUniswapv3PositionMutationHookResult = ReturnType<
  typeof useCreateUniswapv3PositionMutation
>
export type CreateUniswapv3PositionMutationResult =
  Apollo.MutationResult<CreateUniswapv3PositionMutation>
export type CreateUniswapv3PositionMutationOptions = Apollo.BaseMutationOptions<
  CreateUniswapv3PositionMutation,
  CreateUniswapv3PositionMutationVariables
>
export const CloseUniswapv3PositionDocument = gql`
  mutation closeUniswapv3Position($input: CloseUniswapv3PositionInput!) {
    closeUniswapv3Position(input: $input)
  }
`
export type CloseUniswapv3PositionMutationFn = Apollo.MutationFunction<
  CloseUniswapv3PositionMutation,
  CloseUniswapv3PositionMutationVariables
>

/**
 * __useCloseUniswapv3PositionMutation__
 *
 * To run a mutation, you first call `useCloseUniswapv3PositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseUniswapv3PositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeUniswapv3PositionMutation, { data, loading, error }] = useCloseUniswapv3PositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseUniswapv3PositionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseUniswapv3PositionMutation,
    CloseUniswapv3PositionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CloseUniswapv3PositionMutation,
    CloseUniswapv3PositionMutationVariables
  >(CloseUniswapv3PositionDocument, options)
}
export type CloseUniswapv3PositionMutationHookResult = ReturnType<
  typeof useCloseUniswapv3PositionMutation
>
export type CloseUniswapv3PositionMutationResult =
  Apollo.MutationResult<CloseUniswapv3PositionMutation>
export type CloseUniswapv3PositionMutationOptions = Apollo.BaseMutationOptions<
  CloseUniswapv3PositionMutation,
  CloseUniswapv3PositionMutationVariables
>
export const UpdateUserOwnedUniswapv3ToolsInfoDocument = gql`
  mutation updateUserOwnedUniswapv3ToolsInfo($input: UpdateUserOwnedUniswapv3ToolsInfoInput!) {
    updateUserOwnedUniswapv3ToolsInfo(input: $input)
  }
`
export type UpdateUserOwnedUniswapv3ToolsInfoMutationFn = Apollo.MutationFunction<
  UpdateUserOwnedUniswapv3ToolsInfoMutation,
  UpdateUserOwnedUniswapv3ToolsInfoMutationVariables
>

/**
 * __useUpdateUserOwnedUniswapv3ToolsInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserOwnedUniswapv3ToolsInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOwnedUniswapv3ToolsInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOwnedUniswapv3ToolsInfoMutation, { data, loading, error }] = useUpdateUserOwnedUniswapv3ToolsInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserOwnedUniswapv3ToolsInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserOwnedUniswapv3ToolsInfoMutation,
    UpdateUserOwnedUniswapv3ToolsInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserOwnedUniswapv3ToolsInfoMutation,
    UpdateUserOwnedUniswapv3ToolsInfoMutationVariables
  >(UpdateUserOwnedUniswapv3ToolsInfoDocument, options)
}
export type UpdateUserOwnedUniswapv3ToolsInfoMutationHookResult = ReturnType<
  typeof useUpdateUserOwnedUniswapv3ToolsInfoMutation
>
export type UpdateUserOwnedUniswapv3ToolsInfoMutationResult =
  Apollo.MutationResult<UpdateUserOwnedUniswapv3ToolsInfoMutation>
export type UpdateUserOwnedUniswapv3ToolsInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserOwnedUniswapv3ToolsInfoMutation,
  UpdateUserOwnedUniswapv3ToolsInfoMutationVariables
>
export const ListUserOwnedUniswapv3ToolDocument = gql`
  mutation listUserOwnedUniswapv3Tool($input: ListUserOwnedUniswapv3ToolInput!) {
    listUserOwnedUniswapv3Tool(input: $input)
  }
`
export type ListUserOwnedUniswapv3ToolMutationFn = Apollo.MutationFunction<
  ListUserOwnedUniswapv3ToolMutation,
  ListUserOwnedUniswapv3ToolMutationVariables
>

/**
 * __useListUserOwnedUniswapv3ToolMutation__
 *
 * To run a mutation, you first call `useListUserOwnedUniswapv3ToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListUserOwnedUniswapv3ToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listUserOwnedUniswapv3ToolMutation, { data, loading, error }] = useListUserOwnedUniswapv3ToolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListUserOwnedUniswapv3ToolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ListUserOwnedUniswapv3ToolMutation,
    ListUserOwnedUniswapv3ToolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ListUserOwnedUniswapv3ToolMutation,
    ListUserOwnedUniswapv3ToolMutationVariables
  >(ListUserOwnedUniswapv3ToolDocument, options)
}
export type ListUserOwnedUniswapv3ToolMutationHookResult = ReturnType<
  typeof useListUserOwnedUniswapv3ToolMutation
>
export type ListUserOwnedUniswapv3ToolMutationResult =
  Apollo.MutationResult<ListUserOwnedUniswapv3ToolMutation>
export type ListUserOwnedUniswapv3ToolMutationOptions = Apollo.BaseMutationOptions<
  ListUserOwnedUniswapv3ToolMutation,
  ListUserOwnedUniswapv3ToolMutationVariables
>
export const DelistUserOwnedUniswapv3ToolDocument = gql`
  mutation delistUserOwnedUniswapv3Tool($input: DelistUserOwnedUniswapv3ToolInput!) {
    delistUserOwnedUniswapv3Tool(input: $input)
  }
`
export type DelistUserOwnedUniswapv3ToolMutationFn = Apollo.MutationFunction<
  DelistUserOwnedUniswapv3ToolMutation,
  DelistUserOwnedUniswapv3ToolMutationVariables
>

/**
 * __useDelistUserOwnedUniswapv3ToolMutation__
 *
 * To run a mutation, you first call `useDelistUserOwnedUniswapv3ToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelistUserOwnedUniswapv3ToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [delistUserOwnedUniswapv3ToolMutation, { data, loading, error }] = useDelistUserOwnedUniswapv3ToolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelistUserOwnedUniswapv3ToolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DelistUserOwnedUniswapv3ToolMutation,
    DelistUserOwnedUniswapv3ToolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DelistUserOwnedUniswapv3ToolMutation,
    DelistUserOwnedUniswapv3ToolMutationVariables
  >(DelistUserOwnedUniswapv3ToolDocument, options)
}
export type DelistUserOwnedUniswapv3ToolMutationHookResult = ReturnType<
  typeof useDelistUserOwnedUniswapv3ToolMutation
>
export type DelistUserOwnedUniswapv3ToolMutationResult =
  Apollo.MutationResult<DelistUserOwnedUniswapv3ToolMutation>
export type DelistUserOwnedUniswapv3ToolMutationOptions = Apollo.BaseMutationOptions<
  DelistUserOwnedUniswapv3ToolMutation,
  DelistUserOwnedUniswapv3ToolMutationVariables
>
export const RefreshUserUniswapV3ToolDocument = gql`
  mutation refreshUserUniswapV3Tool($input: RefreshUniswapv3ToolInput!) {
    refreshUserUniswapV3Tool(input: $input) {
      ...UserUniswapPositionInfo
    }
  }
  ${UserUniswapPositionInfoFragmentDoc}
`
export type RefreshUserUniswapV3ToolMutationFn = Apollo.MutationFunction<
  RefreshUserUniswapV3ToolMutation,
  RefreshUserUniswapV3ToolMutationVariables
>

/**
 * __useRefreshUserUniswapV3ToolMutation__
 *
 * To run a mutation, you first call `useRefreshUserUniswapV3ToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshUserUniswapV3ToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshUserUniswapV3ToolMutation, { data, loading, error }] = useRefreshUserUniswapV3ToolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshUserUniswapV3ToolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshUserUniswapV3ToolMutation,
    RefreshUserUniswapV3ToolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RefreshUserUniswapV3ToolMutation,
    RefreshUserUniswapV3ToolMutationVariables
  >(RefreshUserUniswapV3ToolDocument, options)
}
export type RefreshUserUniswapV3ToolMutationHookResult = ReturnType<
  typeof useRefreshUserUniswapV3ToolMutation
>
export type RefreshUserUniswapV3ToolMutationResult =
  Apollo.MutationResult<RefreshUserUniswapV3ToolMutation>
export type RefreshUserUniswapV3ToolMutationOptions = Apollo.BaseMutationOptions<
  RefreshUserUniswapV3ToolMutation,
  RefreshUserUniswapV3ToolMutationVariables
>
export const FetchMeDocument = gql`
  query fetchMe($filter: DefiWalletsFilterInput) {
    me {
      id
      defiWallets(filter: $filter) {
        ...DefiWallet
      }
    }
  }
  ${DefiWalletFragmentDoc}
`

/**
 * __useFetchMeQuery__
 *
 * To run a query within a React component, call `useFetchMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMeQuery, FetchMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeQuery, FetchMeQueryVariables>(FetchMeDocument, options)
}
export function useFetchMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMeQuery, FetchMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeQuery, FetchMeQueryVariables>(FetchMeDocument, options)
}
export type FetchMeQueryHookResult = ReturnType<typeof useFetchMeQuery>
export type FetchMeLazyQueryHookResult = ReturnType<typeof useFetchMeLazyQuery>
export type FetchMeQueryResult = Apollo.QueryResult<FetchMeQuery, FetchMeQueryVariables>
export function refetchFetchMeQuery(variables?: FetchMeQueryVariables) {
  return { query: FetchMeDocument, variables: variables }
}
export const FetchMequeryDefiTokensBalanceAmountDocument = gql`
  query fetchMequeryDefiTokensBalanceAmount($input: DefiTokensBalanceAmountInput!) {
    me {
      id
      defiTokensBalanceAmount(input: $input) {
        id
        chain
        address
        symbol
        decimals
        amount
        amountReadable
      }
    }
  }
`

/**
 * __useFetchMequeryDefiTokensBalanceAmountQuery__
 *
 * To run a query within a React component, call `useFetchMequeryDefiTokensBalanceAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMequeryDefiTokensBalanceAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMequeryDefiTokensBalanceAmountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchMequeryDefiTokensBalanceAmountQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMequeryDefiTokensBalanceAmountQuery,
    FetchMequeryDefiTokensBalanceAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchMequeryDefiTokensBalanceAmountQuery,
    FetchMequeryDefiTokensBalanceAmountQueryVariables
  >(FetchMequeryDefiTokensBalanceAmountDocument, options)
}
export function useFetchMequeryDefiTokensBalanceAmountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMequeryDefiTokensBalanceAmountQuery,
    FetchMequeryDefiTokensBalanceAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMequeryDefiTokensBalanceAmountQuery,
    FetchMequeryDefiTokensBalanceAmountQueryVariables
  >(FetchMequeryDefiTokensBalanceAmountDocument, options)
}
export type FetchMequeryDefiTokensBalanceAmountQueryHookResult = ReturnType<
  typeof useFetchMequeryDefiTokensBalanceAmountQuery
>
export type FetchMequeryDefiTokensBalanceAmountLazyQueryHookResult = ReturnType<
  typeof useFetchMequeryDefiTokensBalanceAmountLazyQuery
>
export type FetchMequeryDefiTokensBalanceAmountQueryResult = Apollo.QueryResult<
  FetchMequeryDefiTokensBalanceAmountQuery,
  FetchMequeryDefiTokensBalanceAmountQueryVariables
>
export function refetchFetchMequeryDefiTokensBalanceAmountQuery(
  variables: FetchMequeryDefiTokensBalanceAmountQueryVariables
) {
  return { query: FetchMequeryDefiTokensBalanceAmountDocument, variables: variables }
}
export const FetchPublicCoinPricesDocument = gql`
  query fetchPublicCoinPrices {
    coinPrices {
      ...CoinPrice
    }
  }
  ${CoinPriceFragmentDoc}
`

/**
 * __useFetchPublicCoinPricesQuery__
 *
 * To run a query within a React component, call `useFetchPublicCoinPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPublicCoinPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPublicCoinPricesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPublicCoinPricesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchPublicCoinPricesQuery,
    FetchPublicCoinPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPublicCoinPricesQuery, FetchPublicCoinPricesQueryVariables>(
    FetchPublicCoinPricesDocument,
    options
  )
}
export function useFetchPublicCoinPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPublicCoinPricesQuery,
    FetchPublicCoinPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPublicCoinPricesQuery, FetchPublicCoinPricesQueryVariables>(
    FetchPublicCoinPricesDocument,
    options
  )
}
export type FetchPublicCoinPricesQueryHookResult = ReturnType<typeof useFetchPublicCoinPricesQuery>
export type FetchPublicCoinPricesLazyQueryHookResult = ReturnType<
  typeof useFetchPublicCoinPricesLazyQuery
>
export type FetchPublicCoinPricesQueryResult = Apollo.QueryResult<
  FetchPublicCoinPricesQuery,
  FetchPublicCoinPricesQueryVariables
>
export function refetchFetchPublicCoinPricesQuery(variables?: FetchPublicCoinPricesQueryVariables) {
  return { query: FetchPublicCoinPricesDocument, variables: variables }
}
export const FetchMeGimCoolPayPaymentInfoDocument = gql`
  query fetchMeGimCoolPayPaymentInfo($input: GimCoolPayPaymentInfoInput!) {
    me {
      id
      gimCoolPayPaymentInfo(input: $input) {
        coinType
        fiatType
        coinAmount
        fiatAmount
        blockchain
        walletAddress
        walletAddressTag
        paymentHref
        price {
          spotPrice
          prices {
            paymentMethodId
            type
            spotPriceFee
            spotPriceIncludingFee
            coinAmount
            coinCode
            fiatAmount
            fiatCode
            feeAmount
            networkFee
          }
        }
      }
    }
  }
`

/**
 * __useFetchMeGimCoolPayPaymentInfoQuery__
 *
 * To run a query within a React component, call `useFetchMeGimCoolPayPaymentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeGimCoolPayPaymentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeGimCoolPayPaymentInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchMeGimCoolPayPaymentInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMeGimCoolPayPaymentInfoQuery,
    FetchMeGimCoolPayPaymentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchMeGimCoolPayPaymentInfoQuery,
    FetchMeGimCoolPayPaymentInfoQueryVariables
  >(FetchMeGimCoolPayPaymentInfoDocument, options)
}
export function useFetchMeGimCoolPayPaymentInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeGimCoolPayPaymentInfoQuery,
    FetchMeGimCoolPayPaymentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeGimCoolPayPaymentInfoQuery,
    FetchMeGimCoolPayPaymentInfoQueryVariables
  >(FetchMeGimCoolPayPaymentInfoDocument, options)
}
export type FetchMeGimCoolPayPaymentInfoQueryHookResult = ReturnType<
  typeof useFetchMeGimCoolPayPaymentInfoQuery
>
export type FetchMeGimCoolPayPaymentInfoLazyQueryHookResult = ReturnType<
  typeof useFetchMeGimCoolPayPaymentInfoLazyQuery
>
export type FetchMeGimCoolPayPaymentInfoQueryResult = Apollo.QueryResult<
  FetchMeGimCoolPayPaymentInfoQuery,
  FetchMeGimCoolPayPaymentInfoQueryVariables
>
export function refetchFetchMeGimCoolPayPaymentInfoQuery(
  variables: FetchMeGimCoolPayPaymentInfoQueryVariables
) {
  return { query: FetchMeGimCoolPayPaymentInfoDocument, variables: variables }
}
export const FetchMeSettingPageDocument = gql`
  query fetchMeSettingPage {
    me {
      id
      externalAPIKeys(filter: { isRevoked: false }) {
        ...ExternalAPIKey
      }
      isBitfinexAPIKeyBind @client
    }
  }
  ${ExternalApiKeyFragmentDoc}
`

/**
 * __useFetchMeSettingPageQuery__
 *
 * To run a query within a React component, call `useFetchMeSettingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeSettingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeSettingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeSettingPageQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMeSettingPageQuery, FetchMeSettingPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeSettingPageQuery, FetchMeSettingPageQueryVariables>(
    FetchMeSettingPageDocument,
    options
  )
}
export function useFetchMeSettingPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeSettingPageQuery,
    FetchMeSettingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeSettingPageQuery, FetchMeSettingPageQueryVariables>(
    FetchMeSettingPageDocument,
    options
  )
}
export type FetchMeSettingPageQueryHookResult = ReturnType<typeof useFetchMeSettingPageQuery>
export type FetchMeSettingPageLazyQueryHookResult = ReturnType<
  typeof useFetchMeSettingPageLazyQuery
>
export type FetchMeSettingPageQueryResult = Apollo.QueryResult<
  FetchMeSettingPageQuery,
  FetchMeSettingPageQueryVariables
>
export function refetchFetchMeSettingPageQuery(variables?: FetchMeSettingPageQueryVariables) {
  return { query: FetchMeSettingPageDocument, variables: variables }
}
export const FetchPublicStrategyListDocument = gql`
  query fetchPublicStrategyList($filter: StrategyFilterInput) {
    strategies(filter: $filter) {
      ...PublicStrategyInfo
    }
  }
  ${PublicStrategyInfoFragmentDoc}
`

/**
 * __useFetchPublicStrategyListQuery__
 *
 * To run a query within a React component, call `useFetchPublicStrategyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPublicStrategyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPublicStrategyListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchPublicStrategyListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchPublicStrategyListQuery,
    FetchPublicStrategyListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPublicStrategyListQuery, FetchPublicStrategyListQueryVariables>(
    FetchPublicStrategyListDocument,
    options
  )
}
export function useFetchPublicStrategyListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPublicStrategyListQuery,
    FetchPublicStrategyListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPublicStrategyListQuery, FetchPublicStrategyListQueryVariables>(
    FetchPublicStrategyListDocument,
    options
  )
}
export type FetchPublicStrategyListQueryHookResult = ReturnType<
  typeof useFetchPublicStrategyListQuery
>
export type FetchPublicStrategyListLazyQueryHookResult = ReturnType<
  typeof useFetchPublicStrategyListLazyQuery
>
export type FetchPublicStrategyListQueryResult = Apollo.QueryResult<
  FetchPublicStrategyListQuery,
  FetchPublicStrategyListQueryVariables
>
export function refetchFetchPublicStrategyListQuery(
  variables?: FetchPublicStrategyListQueryVariables
) {
  return { query: FetchPublicStrategyListDocument, variables: variables }
}
export const FetchPublicRateHistoricalDocument = gql`
  query fetchPublicRateHistorical($filter: RateHistoricalFilterInput!) {
    rateHistorical(filter: $filter) {
      ...RateHistorical
    }
  }
  ${RateHistoricalFragmentDoc}
`

/**
 * __useFetchPublicRateHistoricalQuery__
 *
 * To run a query within a React component, call `useFetchPublicRateHistoricalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPublicRateHistoricalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPublicRateHistoricalQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchPublicRateHistoricalQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchPublicRateHistoricalQuery,
    FetchPublicRateHistoricalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPublicRateHistoricalQuery, FetchPublicRateHistoricalQueryVariables>(
    FetchPublicRateHistoricalDocument,
    options
  )
}
export function useFetchPublicRateHistoricalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPublicRateHistoricalQuery,
    FetchPublicRateHistoricalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchPublicRateHistoricalQuery,
    FetchPublicRateHistoricalQueryVariables
  >(FetchPublicRateHistoricalDocument, options)
}
export type FetchPublicRateHistoricalQueryHookResult = ReturnType<
  typeof useFetchPublicRateHistoricalQuery
>
export type FetchPublicRateHistoricalLazyQueryHookResult = ReturnType<
  typeof useFetchPublicRateHistoricalLazyQuery
>
export type FetchPublicRateHistoricalQueryResult = Apollo.QueryResult<
  FetchPublicRateHistoricalQuery,
  FetchPublicRateHistoricalQueryVariables
>
export function refetchFetchPublicRateHistoricalQuery(
  variables: FetchPublicRateHistoricalQueryVariables
) {
  return { query: FetchPublicRateHistoricalDocument, variables: variables }
}
export const FetchMeStrategyListDocument = gql`
  query fetchMeStrategyList($filter: UserStrategyFilterInput) {
    me {
      id
      strategies(filter: $filter) {
        ...UserStrategies
      }
    }
  }
  ${UserStrategiesFragmentDoc}
`

/**
 * __useFetchMeStrategyListQuery__
 *
 * To run a query within a React component, call `useFetchMeStrategyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeStrategyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeStrategyListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeStrategyListQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMeStrategyListQuery, FetchMeStrategyListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeStrategyListQuery, FetchMeStrategyListQueryVariables>(
    FetchMeStrategyListDocument,
    options
  )
}
export function useFetchMeStrategyListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeStrategyListQuery,
    FetchMeStrategyListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeStrategyListQuery, FetchMeStrategyListQueryVariables>(
    FetchMeStrategyListDocument,
    options
  )
}
export type FetchMeStrategyListQueryHookResult = ReturnType<typeof useFetchMeStrategyListQuery>
export type FetchMeStrategyListLazyQueryHookResult = ReturnType<
  typeof useFetchMeStrategyListLazyQuery
>
export type FetchMeStrategyListQueryResult = Apollo.QueryResult<
  FetchMeStrategyListQuery,
  FetchMeStrategyListQueryVariables
>
export function refetchFetchMeStrategyListQuery(variables?: FetchMeStrategyListQueryVariables) {
  return { query: FetchMeStrategyListDocument, variables: variables }
}
export const FetchMeAiRecordsDocument = gql`
  query fetchMeAiRecords {
    me {
      id
      aICompletionRecords {
        ...AICompletionRecord
        suggestions {
          ...SuggestStrategyToolWithAllocation
        }
      }
    }
  }
  ${AiCompletionRecordFragmentDoc}
  ${SuggestStrategyToolWithAllocationFragmentDoc}
`

/**
 * __useFetchMeAiRecordsQuery__
 *
 * To run a query within a React component, call `useFetchMeAiRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeAiRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeAiRecordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeAiRecordsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMeAiRecordsQuery, FetchMeAiRecordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeAiRecordsQuery, FetchMeAiRecordsQueryVariables>(
    FetchMeAiRecordsDocument,
    options
  )
}
export function useFetchMeAiRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMeAiRecordsQuery, FetchMeAiRecordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeAiRecordsQuery, FetchMeAiRecordsQueryVariables>(
    FetchMeAiRecordsDocument,
    options
  )
}
export type FetchMeAiRecordsQueryHookResult = ReturnType<typeof useFetchMeAiRecordsQuery>
export type FetchMeAiRecordsLazyQueryHookResult = ReturnType<typeof useFetchMeAiRecordsLazyQuery>
export type FetchMeAiRecordsQueryResult = Apollo.QueryResult<
  FetchMeAiRecordsQuery,
  FetchMeAiRecordsQueryVariables
>
export function refetchFetchMeAiRecordsQuery(variables?: FetchMeAiRecordsQueryVariables) {
  return { query: FetchMeAiRecordsDocument, variables: variables }
}
export const FetchMeAiRecordDataByIdForPortfolioDialogDocument = gql`
  query fetchMeAiRecordDataByIdForPortfolioDialog($filter: UserAICompletionRecordsFilterInput!) {
    me {
      id
      aICompletionRecords(filter: $filter) {
        ...SingleCompletionRecordForDialog
      }
    }
  }
  ${SingleCompletionRecordForDialogFragmentDoc}
`

/**
 * __useFetchMeAiRecordDataByIdForPortfolioDialogQuery__
 *
 * To run a query within a React component, call `useFetchMeAiRecordDataByIdForPortfolioDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeAiRecordDataByIdForPortfolioDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeAiRecordDataByIdForPortfolioDialogQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeAiRecordDataByIdForPortfolioDialogQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMeAiRecordDataByIdForPortfolioDialogQuery,
    FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchMeAiRecordDataByIdForPortfolioDialogQuery,
    FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
  >(FetchMeAiRecordDataByIdForPortfolioDialogDocument, options)
}
export function useFetchMeAiRecordDataByIdForPortfolioDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeAiRecordDataByIdForPortfolioDialogQuery,
    FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeAiRecordDataByIdForPortfolioDialogQuery,
    FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
  >(FetchMeAiRecordDataByIdForPortfolioDialogDocument, options)
}
export type FetchMeAiRecordDataByIdForPortfolioDialogQueryHookResult = ReturnType<
  typeof useFetchMeAiRecordDataByIdForPortfolioDialogQuery
>
export type FetchMeAiRecordDataByIdForPortfolioDialogLazyQueryHookResult = ReturnType<
  typeof useFetchMeAiRecordDataByIdForPortfolioDialogLazyQuery
>
export type FetchMeAiRecordDataByIdForPortfolioDialogQueryResult = Apollo.QueryResult<
  FetchMeAiRecordDataByIdForPortfolioDialogQuery,
  FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
>
export function refetchFetchMeAiRecordDataByIdForPortfolioDialogQuery(
  variables: FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
) {
  return { query: FetchMeAiRecordDataByIdForPortfolioDialogDocument, variables: variables }
}
export const FetchMeStrategyListForSidDocument = gql`
  query fetchMeStrategyListForSid($filter: UserStrategyFilterInput) {
    me {
      id
      strategies(filter: $filter) {
        id
        sid
        ceFiStatus
      }
    }
  }
`

/**
 * __useFetchMeStrategyListForSidQuery__
 *
 * To run a query within a React component, call `useFetchMeStrategyListForSidQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeStrategyListForSidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeStrategyListForSidQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeStrategyListForSidQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeStrategyListForSidQuery,
    FetchMeStrategyListForSidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeStrategyListForSidQuery, FetchMeStrategyListForSidQueryVariables>(
    FetchMeStrategyListForSidDocument,
    options
  )
}
export function useFetchMeStrategyListForSidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeStrategyListForSidQuery,
    FetchMeStrategyListForSidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeStrategyListForSidQuery,
    FetchMeStrategyListForSidQueryVariables
  >(FetchMeStrategyListForSidDocument, options)
}
export type FetchMeStrategyListForSidQueryHookResult = ReturnType<
  typeof useFetchMeStrategyListForSidQuery
>
export type FetchMeStrategyListForSidLazyQueryHookResult = ReturnType<
  typeof useFetchMeStrategyListForSidLazyQuery
>
export type FetchMeStrategyListForSidQueryResult = Apollo.QueryResult<
  FetchMeStrategyListForSidQuery,
  FetchMeStrategyListForSidQueryVariables
>
export function refetchFetchMeStrategyListForSidQuery(
  variables?: FetchMeStrategyListForSidQueryVariables
) {
  return { query: FetchMeStrategyListForSidDocument, variables: variables }
}
export const FetchMeCexWalletBalancesDocument = gql`
  query fetchMeCexWalletBalances($filter: CefiWalletFilterInput) {
    me {
      id
      cefiWallets(filter: $filter) {
        ...CefiWallet
      }
    }
  }
  ${CefiWalletFragmentDoc}
`

/**
 * __useFetchMeCexWalletBalancesQuery__
 *
 * To run a query within a React component, call `useFetchMeCexWalletBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeCexWalletBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeCexWalletBalancesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeCexWalletBalancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeCexWalletBalancesQuery,
    FetchMeCexWalletBalancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeCexWalletBalancesQuery, FetchMeCexWalletBalancesQueryVariables>(
    FetchMeCexWalletBalancesDocument,
    options
  )
}
export function useFetchMeCexWalletBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeCexWalletBalancesQuery,
    FetchMeCexWalletBalancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeCexWalletBalancesQuery, FetchMeCexWalletBalancesQueryVariables>(
    FetchMeCexWalletBalancesDocument,
    options
  )
}
export type FetchMeCexWalletBalancesQueryHookResult = ReturnType<
  typeof useFetchMeCexWalletBalancesQuery
>
export type FetchMeCexWalletBalancesLazyQueryHookResult = ReturnType<
  typeof useFetchMeCexWalletBalancesLazyQuery
>
export type FetchMeCexWalletBalancesQueryResult = Apollo.QueryResult<
  FetchMeCexWalletBalancesQuery,
  FetchMeCexWalletBalancesQueryVariables
>
export function refetchFetchMeCexWalletBalancesQuery(
  variables?: FetchMeCexWalletBalancesQueryVariables
) {
  return { query: FetchMeCexWalletBalancesDocument, variables: variables }
}
export const FetchMeCexDepositAddressDocument = gql`
  query fetchMeCexDepositAddress($filter: CefiDepositAddressInput) {
    me {
      id
      cefiDepositAddress(filter: $filter) {
        address
        chain
      }
    }
  }
`

/**
 * __useFetchMeCexDepositAddressQuery__
 *
 * To run a query within a React component, call `useFetchMeCexDepositAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeCexDepositAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeCexDepositAddressQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeCexDepositAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeCexDepositAddressQuery,
    FetchMeCexDepositAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeCexDepositAddressQuery, FetchMeCexDepositAddressQueryVariables>(
    FetchMeCexDepositAddressDocument,
    options
  )
}
export function useFetchMeCexDepositAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeCexDepositAddressQuery,
    FetchMeCexDepositAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeCexDepositAddressQuery, FetchMeCexDepositAddressQueryVariables>(
    FetchMeCexDepositAddressDocument,
    options
  )
}
export type FetchMeCexDepositAddressQueryHookResult = ReturnType<
  typeof useFetchMeCexDepositAddressQuery
>
export type FetchMeCexDepositAddressLazyQueryHookResult = ReturnType<
  typeof useFetchMeCexDepositAddressLazyQuery
>
export type FetchMeCexDepositAddressQueryResult = Apollo.QueryResult<
  FetchMeCexDepositAddressQuery,
  FetchMeCexDepositAddressQueryVariables
>
export function refetchFetchMeCexDepositAddressQuery(
  variables?: FetchMeCexDepositAddressQueryVariables
) {
  return { query: FetchMeCexDepositAddressDocument, variables: variables }
}
export const FetchMeStrategyProgressDocument = gql`
  query fetchMeStrategyProgress($strategyID: String!) {
    me {
      id
      strategyProgress(strategyID: $strategyID) {
        ...StrategyProgress
      }
    }
  }
  ${StrategyProgressFragmentDoc}
`

/**
 * __useFetchMeStrategyProgressQuery__
 *
 * To run a query within a React component, call `useFetchMeStrategyProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeStrategyProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeStrategyProgressQuery({
 *   variables: {
 *      strategyID: // value for 'strategyID'
 *   },
 * });
 */
export function useFetchMeStrategyProgressQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMeStrategyProgressQuery,
    FetchMeStrategyProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeStrategyProgressQuery, FetchMeStrategyProgressQueryVariables>(
    FetchMeStrategyProgressDocument,
    options
  )
}
export function useFetchMeStrategyProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeStrategyProgressQuery,
    FetchMeStrategyProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeStrategyProgressQuery, FetchMeStrategyProgressQueryVariables>(
    FetchMeStrategyProgressDocument,
    options
  )
}
export type FetchMeStrategyProgressQueryHookResult = ReturnType<
  typeof useFetchMeStrategyProgressQuery
>
export type FetchMeStrategyProgressLazyQueryHookResult = ReturnType<
  typeof useFetchMeStrategyProgressLazyQuery
>
export type FetchMeStrategyProgressQueryResult = Apollo.QueryResult<
  FetchMeStrategyProgressQuery,
  FetchMeStrategyProgressQueryVariables
>
export function refetchFetchMeStrategyProgressQuery(
  variables: FetchMeStrategyProgressQueryVariables
) {
  return { query: FetchMeStrategyProgressDocument, variables: variables }
}
export const FetchMeStrategyTotalProfitDocument = gql`
  query fetchMeStrategyTotalProfit($filter: UserStrategyProfitFilterInput) {
    me {
      id
      strategyProfit(filter: $filter) {
        ...UserStrategyProfit
      }
    }
  }
  ${UserStrategyProfitFragmentDoc}
`

/**
 * __useFetchMeStrategyTotalProfitQuery__
 *
 * To run a query within a React component, call `useFetchMeStrategyTotalProfitQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeStrategyTotalProfitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeStrategyTotalProfitQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeStrategyTotalProfitQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeStrategyTotalProfitQuery,
    FetchMeStrategyTotalProfitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeStrategyTotalProfitQuery, FetchMeStrategyTotalProfitQueryVariables>(
    FetchMeStrategyTotalProfitDocument,
    options
  )
}
export function useFetchMeStrategyTotalProfitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeStrategyTotalProfitQuery,
    FetchMeStrategyTotalProfitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeStrategyTotalProfitQuery,
    FetchMeStrategyTotalProfitQueryVariables
  >(FetchMeStrategyTotalProfitDocument, options)
}
export type FetchMeStrategyTotalProfitQueryHookResult = ReturnType<
  typeof useFetchMeStrategyTotalProfitQuery
>
export type FetchMeStrategyTotalProfitLazyQueryHookResult = ReturnType<
  typeof useFetchMeStrategyTotalProfitLazyQuery
>
export type FetchMeStrategyTotalProfitQueryResult = Apollo.QueryResult<
  FetchMeStrategyTotalProfitQuery,
  FetchMeStrategyTotalProfitQueryVariables
>
export function refetchFetchMeStrategyTotalProfitQuery(
  variables?: FetchMeStrategyTotalProfitQueryVariables
) {
  return { query: FetchMeStrategyTotalProfitDocument, variables: variables }
}
export const FetchMeStrategyRecordsDocument = gql`
  query fetchMeStrategyRecords($filter: UserStrategyRecordsFilterInput) {
    me {
      id
      strategyRecords(filter: $filter) {
        ...UserStrategyRecord
      }
    }
  }
  ${UserStrategyRecordFragmentDoc}
`

/**
 * __useFetchMeStrategyRecordsQuery__
 *
 * To run a query within a React component, call `useFetchMeStrategyRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeStrategyRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeStrategyRecordsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeStrategyRecordsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeStrategyRecordsQuery,
    FetchMeStrategyRecordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeStrategyRecordsQuery, FetchMeStrategyRecordsQueryVariables>(
    FetchMeStrategyRecordsDocument,
    options
  )
}
export function useFetchMeStrategyRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeStrategyRecordsQuery,
    FetchMeStrategyRecordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeStrategyRecordsQuery, FetchMeStrategyRecordsQueryVariables>(
    FetchMeStrategyRecordsDocument,
    options
  )
}
export type FetchMeStrategyRecordsQueryHookResult = ReturnType<
  typeof useFetchMeStrategyRecordsQuery
>
export type FetchMeStrategyRecordsLazyQueryHookResult = ReturnType<
  typeof useFetchMeStrategyRecordsLazyQuery
>
export type FetchMeStrategyRecordsQueryResult = Apollo.QueryResult<
  FetchMeStrategyRecordsQuery,
  FetchMeStrategyRecordsQueryVariables
>
export function refetchFetchMeStrategyRecordsQuery(
  variables?: FetchMeStrategyRecordsQueryVariables
) {
  return { query: FetchMeStrategyRecordsDocument, variables: variables }
}
export const FetchMeNftCollectionsDocument = gql`
  query fetchMeNftCollections {
    me {
      id
      nftCollections {
        ...NFTCollection
      }
    }
  }
  ${NftCollectionFragmentDoc}
`

/**
 * __useFetchMeNftCollectionsQuery__
 *
 * To run a query within a React component, call `useFetchMeNftCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeNftCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeNftCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeNftCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeNftCollectionsQuery,
    FetchMeNftCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeNftCollectionsQuery, FetchMeNftCollectionsQueryVariables>(
    FetchMeNftCollectionsDocument,
    options
  )
}
export function useFetchMeNftCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeNftCollectionsQuery,
    FetchMeNftCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeNftCollectionsQuery, FetchMeNftCollectionsQueryVariables>(
    FetchMeNftCollectionsDocument,
    options
  )
}
export type FetchMeNftCollectionsQueryHookResult = ReturnType<typeof useFetchMeNftCollectionsQuery>
export type FetchMeNftCollectionsLazyQueryHookResult = ReturnType<
  typeof useFetchMeNftCollectionsLazyQuery
>
export type FetchMeNftCollectionsQueryResult = Apollo.QueryResult<
  FetchMeNftCollectionsQuery,
  FetchMeNftCollectionsQueryVariables
>
export function refetchFetchMeNftCollectionsQuery(variables?: FetchMeNftCollectionsQueryVariables) {
  return { query: FetchMeNftCollectionsDocument, variables: variables }
}
export const FetchMeNftWhitelistInfoByPhrasesDocument = gql`
  query fetchMeNftWhitelistInfoByPhrases {
    me {
      id
      nftWhitelistInfoByPhrases {
        id
        phrase
        userAddress
        isWhitelisted
      }
    }
  }
`

/**
 * __useFetchMeNftWhitelistInfoByPhrasesQuery__
 *
 * To run a query within a React component, call `useFetchMeNftWhitelistInfoByPhrasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeNftWhitelistInfoByPhrasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeNftWhitelistInfoByPhrasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeNftWhitelistInfoByPhrasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeNftWhitelistInfoByPhrasesQuery,
    FetchMeNftWhitelistInfoByPhrasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchMeNftWhitelistInfoByPhrasesQuery,
    FetchMeNftWhitelistInfoByPhrasesQueryVariables
  >(FetchMeNftWhitelistInfoByPhrasesDocument, options)
}
export function useFetchMeNftWhitelistInfoByPhrasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeNftWhitelistInfoByPhrasesQuery,
    FetchMeNftWhitelistInfoByPhrasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeNftWhitelistInfoByPhrasesQuery,
    FetchMeNftWhitelistInfoByPhrasesQueryVariables
  >(FetchMeNftWhitelistInfoByPhrasesDocument, options)
}
export type FetchMeNftWhitelistInfoByPhrasesQueryHookResult = ReturnType<
  typeof useFetchMeNftWhitelistInfoByPhrasesQuery
>
export type FetchMeNftWhitelistInfoByPhrasesLazyQueryHookResult = ReturnType<
  typeof useFetchMeNftWhitelistInfoByPhrasesLazyQuery
>
export type FetchMeNftWhitelistInfoByPhrasesQueryResult = Apollo.QueryResult<
  FetchMeNftWhitelistInfoByPhrasesQuery,
  FetchMeNftWhitelistInfoByPhrasesQueryVariables
>
export function refetchFetchMeNftWhitelistInfoByPhrasesQuery(
  variables?: FetchMeNftWhitelistInfoByPhrasesQueryVariables
) {
  return { query: FetchMeNftWhitelistInfoByPhrasesDocument, variables: variables }
}
export const FetchMeNftMerkleTreeHexProofDocument = gql`
  query fetchMeNftMerkleTreeHexProof {
    me {
      id
      nftMerkleTreeHexProof {
        hexProof
      }
    }
  }
`

/**
 * __useFetchMeNftMerkleTreeHexProofQuery__
 *
 * To run a query within a React component, call `useFetchMeNftMerkleTreeHexProofQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeNftMerkleTreeHexProofQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeNftMerkleTreeHexProofQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeNftMerkleTreeHexProofQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeNftMerkleTreeHexProofQuery,
    FetchMeNftMerkleTreeHexProofQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchMeNftMerkleTreeHexProofQuery,
    FetchMeNftMerkleTreeHexProofQueryVariables
  >(FetchMeNftMerkleTreeHexProofDocument, options)
}
export function useFetchMeNftMerkleTreeHexProofLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeNftMerkleTreeHexProofQuery,
    FetchMeNftMerkleTreeHexProofQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeNftMerkleTreeHexProofQuery,
    FetchMeNftMerkleTreeHexProofQueryVariables
  >(FetchMeNftMerkleTreeHexProofDocument, options)
}
export type FetchMeNftMerkleTreeHexProofQueryHookResult = ReturnType<
  typeof useFetchMeNftMerkleTreeHexProofQuery
>
export type FetchMeNftMerkleTreeHexProofLazyQueryHookResult = ReturnType<
  typeof useFetchMeNftMerkleTreeHexProofLazyQuery
>
export type FetchMeNftMerkleTreeHexProofQueryResult = Apollo.QueryResult<
  FetchMeNftMerkleTreeHexProofQuery,
  FetchMeNftMerkleTreeHexProofQueryVariables
>
export function refetchFetchMeNftMerkleTreeHexProofQuery(
  variables?: FetchMeNftMerkleTreeHexProofQueryVariables
) {
  return { query: FetchMeNftMerkleTreeHexProofDocument, variables: variables }
}
export const UniswapV3BacktestDocument = gql`
  query uniswapV3Backtest(
    $poolID: String = "0xdac8a8e6dbf8c690ec6815e0ff03491b2770255d"
    $investmentAmount: Float = 100000
    $days: Int = 365
    $compound: Boolean = true
    $protocol: EvmChain = Polygon
    $diff: Float = 0.0001
  ) {
    uniswapV3Backtest(
      poolID: $poolID
      investmentAmount: $investmentAmount
      days: $days
      period: DAILY
      protocol: $protocol
      compound: $compound
      diff: $diff
    ) {
      id
      days
      activeLiquidityPercent
      dailyReturn
      annualReturn
      impermanentLoss
      compound
      dataPointsCount
    }
  }
`

/**
 * __useUniswapV3BacktestQuery__
 *
 * To run a query within a React component, call `useUniswapV3BacktestQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniswapV3BacktestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniswapV3BacktestQuery({
 *   variables: {
 *      poolID: // value for 'poolID'
 *      investmentAmount: // value for 'investmentAmount'
 *      days: // value for 'days'
 *      compound: // value for 'compound'
 *      protocol: // value for 'protocol'
 *      diff: // value for 'diff'
 *   },
 * });
 */
export function useUniswapV3BacktestQuery(
  baseOptions?: Apollo.QueryHookOptions<UniswapV3BacktestQuery, UniswapV3BacktestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UniswapV3BacktestQuery, UniswapV3BacktestQueryVariables>(
    UniswapV3BacktestDocument,
    options
  )
}
export function useUniswapV3BacktestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UniswapV3BacktestQuery, UniswapV3BacktestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UniswapV3BacktestQuery, UniswapV3BacktestQueryVariables>(
    UniswapV3BacktestDocument,
    options
  )
}
export type UniswapV3BacktestQueryHookResult = ReturnType<typeof useUniswapV3BacktestQuery>
export type UniswapV3BacktestLazyQueryHookResult = ReturnType<typeof useUniswapV3BacktestLazyQuery>
export type UniswapV3BacktestQueryResult = Apollo.QueryResult<
  UniswapV3BacktestQuery,
  UniswapV3BacktestQueryVariables
>
export function refetchUniswapV3BacktestQuery(variables?: UniswapV3BacktestQueryVariables) {
  return { query: UniswapV3BacktestDocument, variables: variables }
}
export const FetchToolsDocument = gql`
  query fetchTools {
    tools {
      ... on StrategyInfo {
        ...PublicStrategyInfo
      }
      ... on UniswapToolInfo {
        ...UniswapToolInfo
      }
    }
  }
  ${PublicStrategyInfoFragmentDoc}
  ${UniswapToolInfoFragmentDoc}
`

/**
 * __useFetchToolsQuery__
 *
 * To run a query within a React component, call `useFetchToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchToolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchToolsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchToolsQuery, FetchToolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchToolsQuery, FetchToolsQueryVariables>(FetchToolsDocument, options)
}
export function useFetchToolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchToolsQuery, FetchToolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchToolsQuery, FetchToolsQueryVariables>(FetchToolsDocument, options)
}
export type FetchToolsQueryHookResult = ReturnType<typeof useFetchToolsQuery>
export type FetchToolsLazyQueryHookResult = ReturnType<typeof useFetchToolsLazyQuery>
export type FetchToolsQueryResult = Apollo.QueryResult<FetchToolsQuery, FetchToolsQueryVariables>
export function refetchFetchToolsQuery(variables?: FetchToolsQueryVariables) {
  return { query: FetchToolsDocument, variables: variables }
}
export const FetchSingleToolDocument = gql`
  query fetchSingleTool($filter: ToolFilterInput!, $period: ApyHistoryPeriod!) {
    tools(filter: $filter) {
      ... on StrategyInfo {
        ...PublicStrategyInfo
      }
      ... on UniswapToolInfo {
        ...UniswapToolInfo
        apyHistory(period: $period) {
          time
          rate
        }
      }
    }
  }
  ${PublicStrategyInfoFragmentDoc}
  ${UniswapToolInfoFragmentDoc}
`

/**
 * __useFetchSingleToolQuery__
 *
 * To run a query within a React component, call `useFetchSingleToolQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSingleToolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSingleToolQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useFetchSingleToolQuery(
  baseOptions: Apollo.QueryHookOptions<FetchSingleToolQuery, FetchSingleToolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSingleToolQuery, FetchSingleToolQueryVariables>(
    FetchSingleToolDocument,
    options
  )
}
export function useFetchSingleToolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchSingleToolQuery, FetchSingleToolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSingleToolQuery, FetchSingleToolQueryVariables>(
    FetchSingleToolDocument,
    options
  )
}
export type FetchSingleToolQueryHookResult = ReturnType<typeof useFetchSingleToolQuery>
export type FetchSingleToolLazyQueryHookResult = ReturnType<typeof useFetchSingleToolLazyQuery>
export type FetchSingleToolQueryResult = Apollo.QueryResult<
  FetchSingleToolQuery,
  FetchSingleToolQueryVariables
>
export function refetchFetchSingleToolQuery(variables: FetchSingleToolQueryVariables) {
  return { query: FetchSingleToolDocument, variables: variables }
}
export const FetchMeToolsDocument = gql`
  query fetchMeTools {
    me {
      id
      tools {
        ... on UserStrategyInfo {
          ...UserStrategyInfo
        }
        ... on UserUniswapPositionInfo {
          ...UserUniswapPositionInfo
        }
      }
      ownedToolsSetting {
        ...UserUniswapToolSettingInfo
      }
    }
  }
  ${UserStrategyInfoFragmentDoc}
  ${UserUniswapPositionInfoFragmentDoc}
  ${UserUniswapToolSettingInfoFragmentDoc}
`

/**
 * __useFetchMeToolsQuery__
 *
 * To run a query within a React component, call `useFetchMeToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeToolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeToolsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMeToolsQuery, FetchMeToolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeToolsQuery, FetchMeToolsQueryVariables>(
    FetchMeToolsDocument,
    options
  )
}
export function useFetchMeToolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMeToolsQuery, FetchMeToolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeToolsQuery, FetchMeToolsQueryVariables>(
    FetchMeToolsDocument,
    options
  )
}
export type FetchMeToolsQueryHookResult = ReturnType<typeof useFetchMeToolsQuery>
export type FetchMeToolsLazyQueryHookResult = ReturnType<typeof useFetchMeToolsLazyQuery>
export type FetchMeToolsQueryResult = Apollo.QueryResult<
  FetchMeToolsQuery,
  FetchMeToolsQueryVariables
>
export function refetchFetchMeToolsQuery(variables?: FetchMeToolsQueryVariables) {
  return { query: FetchMeToolsDocument, variables: variables }
}
export const FetchPoolsWithArgumentsDocument = gql`
  query fetchPoolsWithArguments(
    $orderBy: String
    $first: Int = 10
    $skip: Int = 0
    $orderDirection: String
  ) {
    genericPoolsEthereum: poolsWithArguments(
      chain: Ethereum
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
      flag: Generic
    ) {
      ...Pool
    }
    genericPoolsPolygon: poolsWithArguments(
      chain: Polygon
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
      flag: Generic
    ) {
      ...Pool
    }
    stablesPoolsEthereum: poolsWithArguments(
      chain: Ethereum
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
      flag: Stables
    ) {
      ...Pool
    }
    stablesPoolsPolygon: poolsWithArguments(
      chain: Polygon
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
      flag: Stables
    ) {
      ...Pool
    }
    poolsEthereum: poolsWithArguments(
      chain: Ethereum
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
    ) {
      ...Pool
    }
    poolsPolygon: poolsWithArguments(
      chain: Polygon
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
    ) {
      ...Pool
    }
  }
  ${PoolFragmentDoc}
`

/**
 * __useFetchPoolsWithArgumentsQuery__
 *
 * To run a query within a React component, call `useFetchPoolsWithArgumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPoolsWithArgumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPoolsWithArgumentsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useFetchPoolsWithArgumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchPoolsWithArgumentsQuery,
    FetchPoolsWithArgumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPoolsWithArgumentsQuery, FetchPoolsWithArgumentsQueryVariables>(
    FetchPoolsWithArgumentsDocument,
    options
  )
}
export function useFetchPoolsWithArgumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPoolsWithArgumentsQuery,
    FetchPoolsWithArgumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPoolsWithArgumentsQuery, FetchPoolsWithArgumentsQueryVariables>(
    FetchPoolsWithArgumentsDocument,
    options
  )
}
export type FetchPoolsWithArgumentsQueryHookResult = ReturnType<
  typeof useFetchPoolsWithArgumentsQuery
>
export type FetchPoolsWithArgumentsLazyQueryHookResult = ReturnType<
  typeof useFetchPoolsWithArgumentsLazyQuery
>
export type FetchPoolsWithArgumentsQueryResult = Apollo.QueryResult<
  FetchPoolsWithArgumentsQuery,
  FetchPoolsWithArgumentsQueryVariables
>
export function refetchFetchPoolsWithArgumentsQuery(
  variables?: FetchPoolsWithArgumentsQueryVariables
) {
  return { query: FetchPoolsWithArgumentsDocument, variables: variables }
}
export const FetchPoolsDocument = gql`
  query fetchPools($token0s: [String!], $token1s: [String!], $chain: EvmChain!) {
    poolsWithArguments(chain: $chain, token0s: $token0s, token1s: $token1s) {
      ...PoolWithReyieldPoolValidation
    }
  }
  ${PoolWithReyieldPoolValidationFragmentDoc}
`

/**
 * __useFetchPoolsQuery__
 *
 * To run a query within a React component, call `useFetchPoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPoolsQuery({
 *   variables: {
 *      token0s: // value for 'token0s'
 *      token1s: // value for 'token1s'
 *      chain: // value for 'chain'
 *   },
 * });
 */
export function useFetchPoolsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchPoolsQuery, FetchPoolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPoolsQuery, FetchPoolsQueryVariables>(FetchPoolsDocument, options)
}
export function useFetchPoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchPoolsQuery, FetchPoolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPoolsQuery, FetchPoolsQueryVariables>(FetchPoolsDocument, options)
}
export type FetchPoolsQueryHookResult = ReturnType<typeof useFetchPoolsQuery>
export type FetchPoolsLazyQueryHookResult = ReturnType<typeof useFetchPoolsLazyQuery>
export type FetchPoolsQueryResult = Apollo.QueryResult<FetchPoolsQuery, FetchPoolsQueryVariables>
export function refetchFetchPoolsQuery(variables: FetchPoolsQueryVariables) {
  return { query: FetchPoolsDocument, variables: variables }
}
export const FetchPoolsTicksByPoolIdsDocument = gql`
  query fetchPoolsTicksByPoolIds($poolIds: [String!]!, $chain: EvmChain!) {
    poolsWithArguments(chain: $chain, poolIds: $poolIds) {
      id
      ticks {
        id
        tickIdx
        liquidityNet
        price0
        price1
      }
    }
  }
`

/**
 * __useFetchPoolsTicksByPoolIdsQuery__
 *
 * To run a query within a React component, call `useFetchPoolsTicksByPoolIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPoolsTicksByPoolIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPoolsTicksByPoolIdsQuery({
 *   variables: {
 *      poolIds: // value for 'poolIds'
 *      chain: // value for 'chain'
 *   },
 * });
 */
export function useFetchPoolsTicksByPoolIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchPoolsTicksByPoolIdsQuery,
    FetchPoolsTicksByPoolIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPoolsTicksByPoolIdsQuery, FetchPoolsTicksByPoolIdsQueryVariables>(
    FetchPoolsTicksByPoolIdsDocument,
    options
  )
}
export function useFetchPoolsTicksByPoolIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPoolsTicksByPoolIdsQuery,
    FetchPoolsTicksByPoolIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPoolsTicksByPoolIdsQuery, FetchPoolsTicksByPoolIdsQueryVariables>(
    FetchPoolsTicksByPoolIdsDocument,
    options
  )
}
export type FetchPoolsTicksByPoolIdsQueryHookResult = ReturnType<
  typeof useFetchPoolsTicksByPoolIdsQuery
>
export type FetchPoolsTicksByPoolIdsLazyQueryHookResult = ReturnType<
  typeof useFetchPoolsTicksByPoolIdsLazyQuery
>
export type FetchPoolsTicksByPoolIdsQueryResult = Apollo.QueryResult<
  FetchPoolsTicksByPoolIdsQuery,
  FetchPoolsTicksByPoolIdsQueryVariables
>
export function refetchFetchPoolsTicksByPoolIdsQuery(
  variables: FetchPoolsTicksByPoolIdsQueryVariables
) {
  return { query: FetchPoolsTicksByPoolIdsDocument, variables: variables }
}
export const FetchSupportingTokensDocument = gql`
  query fetchSupportingTokens($chain: EvmChain!) {
    uniswapTokens(chain: $chain) {
      ...UniswapPoolToken
    }
  }
  ${UniswapPoolTokenFragmentDoc}
`

/**
 * __useFetchSupportingTokensQuery__
 *
 * To run a query within a React component, call `useFetchSupportingTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSupportingTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSupportingTokensQuery({
 *   variables: {
 *      chain: // value for 'chain'
 *   },
 * });
 */
export function useFetchSupportingTokensQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSupportingTokensQuery,
    FetchSupportingTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSupportingTokensQuery, FetchSupportingTokensQueryVariables>(
    FetchSupportingTokensDocument,
    options
  )
}
export function useFetchSupportingTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSupportingTokensQuery,
    FetchSupportingTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSupportingTokensQuery, FetchSupportingTokensQueryVariables>(
    FetchSupportingTokensDocument,
    options
  )
}
export type FetchSupportingTokensQueryHookResult = ReturnType<typeof useFetchSupportingTokensQuery>
export type FetchSupportingTokensLazyQueryHookResult = ReturnType<
  typeof useFetchSupportingTokensLazyQuery
>
export type FetchSupportingTokensQueryResult = Apollo.QueryResult<
  FetchSupportingTokensQuery,
  FetchSupportingTokensQueryVariables
>
export function refetchFetchSupportingTokensQuery(variables: FetchSupportingTokensQueryVariables) {
  return { query: FetchSupportingTokensDocument, variables: variables }
}
export const FetchMetricDocument = gql`
  query fetchMetric {
    metric {
      id
      positions {
        id
        positionManagerContractAddress
        positionOwnerAddress
        positionId
        positionIdTotalCount
        positionIsRunning
        amountUsd
        sumUsd
        feeUsd
        strategyId
        strategyProvider
        chain
        createdAt
      }
      positionManagersInfo {
        id
        chain
        count
      }
      createdAt
    }
  }
`

/**
 * __useFetchMetricQuery__
 *
 * To run a query within a React component, call `useFetchMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMetricQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMetricQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMetricQuery, FetchMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMetricQuery, FetchMetricQueryVariables>(FetchMetricDocument, options)
}
export function useFetchMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMetricQuery, FetchMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMetricQuery, FetchMetricQueryVariables>(
    FetchMetricDocument,
    options
  )
}
export type FetchMetricQueryHookResult = ReturnType<typeof useFetchMetricQuery>
export type FetchMetricLazyQueryHookResult = ReturnType<typeof useFetchMetricLazyQuery>
export type FetchMetricQueryResult = Apollo.QueryResult<FetchMetricQuery, FetchMetricQueryVariables>
export function refetchFetchMetricQuery(variables?: FetchMetricQueryVariables) {
  return { query: FetchMetricDocument, variables: variables }
}
export const FetchToolSupportingTokensDocument = gql`
  query fetchToolSupportingTokens {
    toolsSupportingTokens {
      ...TokenDisplayInfo
    }
  }
  ${TokenDisplayInfoFragmentDoc}
`

/**
 * __useFetchToolSupportingTokensQuery__
 *
 * To run a query within a React component, call `useFetchToolSupportingTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchToolSupportingTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchToolSupportingTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchToolSupportingTokensQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchToolSupportingTokensQuery,
    FetchToolSupportingTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchToolSupportingTokensQuery, FetchToolSupportingTokensQueryVariables>(
    FetchToolSupportingTokensDocument,
    options
  )
}
export function useFetchToolSupportingTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchToolSupportingTokensQuery,
    FetchToolSupportingTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchToolSupportingTokensQuery,
    FetchToolSupportingTokensQueryVariables
  >(FetchToolSupportingTokensDocument, options)
}
export type FetchToolSupportingTokensQueryHookResult = ReturnType<
  typeof useFetchToolSupportingTokensQuery
>
export type FetchToolSupportingTokensLazyQueryHookResult = ReturnType<
  typeof useFetchToolSupportingTokensLazyQuery
>
export type FetchToolSupportingTokensQueryResult = Apollo.QueryResult<
  FetchToolSupportingTokensQuery,
  FetchToolSupportingTokensQueryVariables
>
export function refetchFetchToolSupportingTokensQuery(
  variables?: FetchToolSupportingTokensQueryVariables
) {
  return { query: FetchToolSupportingTokensDocument, variables: variables }
}
export const FetchMeReyieldLicenseDocument = gql`
  query fetchMeReyieldLicense {
    me {
      id
      reyieldLicense {
        numberOfLicense
        numberOfUsedLicense
      }
    }
  }
`

/**
 * __useFetchMeReyieldLicenseQuery__
 *
 * To run a query within a React component, call `useFetchMeReyieldLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeReyieldLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeReyieldLicenseQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeReyieldLicenseQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeReyieldLicenseQuery,
    FetchMeReyieldLicenseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeReyieldLicenseQuery, FetchMeReyieldLicenseQueryVariables>(
    FetchMeReyieldLicenseDocument,
    options
  )
}
export function useFetchMeReyieldLicenseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeReyieldLicenseQuery,
    FetchMeReyieldLicenseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeReyieldLicenseQuery, FetchMeReyieldLicenseQueryVariables>(
    FetchMeReyieldLicenseDocument,
    options
  )
}
export type FetchMeReyieldLicenseQueryHookResult = ReturnType<typeof useFetchMeReyieldLicenseQuery>
export type FetchMeReyieldLicenseLazyQueryHookResult = ReturnType<
  typeof useFetchMeReyieldLicenseLazyQuery
>
export type FetchMeReyieldLicenseQueryResult = Apollo.QueryResult<
  FetchMeReyieldLicenseQuery,
  FetchMeReyieldLicenseQueryVariables
>
export function refetchFetchMeReyieldLicenseQuery(variables?: FetchMeReyieldLicenseQueryVariables) {
  return { query: FetchMeReyieldLicenseDocument, variables: variables }
}
export const FetchMePrivilegeDocument = gql`
  query fetchMePrivilege {
    me {
      id
      privilege
    }
  }
`

/**
 * __useFetchMePrivilegeQuery__
 *
 * To run a query within a React component, call `useFetchMePrivilegeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMePrivilegeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMePrivilegeQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMePrivilegeQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMePrivilegeQuery, FetchMePrivilegeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMePrivilegeQuery, FetchMePrivilegeQueryVariables>(
    FetchMePrivilegeDocument,
    options
  )
}
export function useFetchMePrivilegeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMePrivilegeQuery, FetchMePrivilegeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMePrivilegeQuery, FetchMePrivilegeQueryVariables>(
    FetchMePrivilegeDocument,
    options
  )
}
export type FetchMePrivilegeQueryHookResult = ReturnType<typeof useFetchMePrivilegeQuery>
export type FetchMePrivilegeLazyQueryHookResult = ReturnType<typeof useFetchMePrivilegeLazyQuery>
export type FetchMePrivilegeQueryResult = Apollo.QueryResult<
  FetchMePrivilegeQuery,
  FetchMePrivilegeQueryVariables
>
export function refetchFetchMePrivilegeQuery(variables?: FetchMePrivilegeQueryVariables) {
  return { query: FetchMePrivilegeDocument, variables: variables }
}
export const FetchMeGasFeeWalletsDocument = gql`
  query fetchMeGasFeeWallets {
    me {
      id
      gasFeeWallets @client {
        ...GasFeeWallet
      }
    }
  }
  ${GasFeeWalletFragmentDoc}
`

/**
 * __useFetchMeGasFeeWalletsQuery__
 *
 * To run a query within a React component, call `useFetchMeGasFeeWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeGasFeeWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeGasFeeWalletsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeGasFeeWalletsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeGasFeeWalletsQuery,
    FetchMeGasFeeWalletsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeGasFeeWalletsQuery, FetchMeGasFeeWalletsQueryVariables>(
    FetchMeGasFeeWalletsDocument,
    options
  )
}
export function useFetchMeGasFeeWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeGasFeeWalletsQuery,
    FetchMeGasFeeWalletsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeGasFeeWalletsQuery, FetchMeGasFeeWalletsQueryVariables>(
    FetchMeGasFeeWalletsDocument,
    options
  )
}
export type FetchMeGasFeeWalletsQueryHookResult = ReturnType<typeof useFetchMeGasFeeWalletsQuery>
export type FetchMeGasFeeWalletsLazyQueryHookResult = ReturnType<
  typeof useFetchMeGasFeeWalletsLazyQuery
>
export type FetchMeGasFeeWalletsQueryResult = Apollo.QueryResult<
  FetchMeGasFeeWalletsQuery,
  FetchMeGasFeeWalletsQueryVariables
>
export function refetchFetchMeGasFeeWalletsQuery(variables?: FetchMeGasFeeWalletsQueryVariables) {
  return { query: FetchMeGasFeeWalletsDocument, variables: variables }
}
export const FetchCampaignAirdropLandingDocument = gql`
  query fetchCampaignAirdropLanding {
    campaignAirdrop {
      id
      campaign {
        createdAt
        endTtime
        id
        isEnable
        name
        startTime
        type
        updatedAt
      }
      tvl
      totalAirdropToken
      toolIds
    }
  }
`

/**
 * __useFetchCampaignAirdropLandingQuery__
 *
 * To run a query within a React component, call `useFetchCampaignAirdropLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCampaignAirdropLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCampaignAirdropLandingQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCampaignAirdropLandingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchCampaignAirdropLandingQuery,
    FetchCampaignAirdropLandingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchCampaignAirdropLandingQuery,
    FetchCampaignAirdropLandingQueryVariables
  >(FetchCampaignAirdropLandingDocument, options)
}
export function useFetchCampaignAirdropLandingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCampaignAirdropLandingQuery,
    FetchCampaignAirdropLandingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchCampaignAirdropLandingQuery,
    FetchCampaignAirdropLandingQueryVariables
  >(FetchCampaignAirdropLandingDocument, options)
}
export type FetchCampaignAirdropLandingQueryHookResult = ReturnType<
  typeof useFetchCampaignAirdropLandingQuery
>
export type FetchCampaignAirdropLandingLazyQueryHookResult = ReturnType<
  typeof useFetchCampaignAirdropLandingLazyQuery
>
export type FetchCampaignAirdropLandingQueryResult = Apollo.QueryResult<
  FetchCampaignAirdropLandingQuery,
  FetchCampaignAirdropLandingQueryVariables
>
export function refetchFetchCampaignAirdropLandingQuery(
  variables?: FetchCampaignAirdropLandingQueryVariables
) {
  return { query: FetchCampaignAirdropLandingDocument, variables: variables }
}
export const FetchCampaignAirdropToolsDocument = gql`
  query fetchCampaignAirdropTools($filter: ToolFilterInput) {
    campaignAirdropTools(filter: $filter) {
      ...UniswapToolInfo
    }
  }
  ${UniswapToolInfoFragmentDoc}
`

/**
 * __useFetchCampaignAirdropToolsQuery__
 *
 * To run a query within a React component, call `useFetchCampaignAirdropToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCampaignAirdropToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCampaignAirdropToolsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchCampaignAirdropToolsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchCampaignAirdropToolsQuery,
    FetchCampaignAirdropToolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchCampaignAirdropToolsQuery, FetchCampaignAirdropToolsQueryVariables>(
    FetchCampaignAirdropToolsDocument,
    options
  )
}
export function useFetchCampaignAirdropToolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCampaignAirdropToolsQuery,
    FetchCampaignAirdropToolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchCampaignAirdropToolsQuery,
    FetchCampaignAirdropToolsQueryVariables
  >(FetchCampaignAirdropToolsDocument, options)
}
export type FetchCampaignAirdropToolsQueryHookResult = ReturnType<
  typeof useFetchCampaignAirdropToolsQuery
>
export type FetchCampaignAirdropToolsLazyQueryHookResult = ReturnType<
  typeof useFetchCampaignAirdropToolsLazyQuery
>
export type FetchCampaignAirdropToolsQueryResult = Apollo.QueryResult<
  FetchCampaignAirdropToolsQuery,
  FetchCampaignAirdropToolsQueryVariables
>
export function refetchFetchCampaignAirdropToolsQuery(
  variables?: FetchCampaignAirdropToolsQueryVariables
) {
  return { query: FetchCampaignAirdropToolsDocument, variables: variables }
}
export const FetchCampaignAirdropExploreDocument = gql`
  query fetchCampaignAirdropExplore($campaignId: String!) {
    campaignAirdropExplore(campaignId: $campaignId) {
      id
      tvl
      totalAirdropToken
      currentHourlyAirdropToken
    }
  }
`

/**
 * __useFetchCampaignAirdropExploreQuery__
 *
 * To run a query within a React component, call `useFetchCampaignAirdropExploreQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCampaignAirdropExploreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCampaignAirdropExploreQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useFetchCampaignAirdropExploreQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchCampaignAirdropExploreQuery,
    FetchCampaignAirdropExploreQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchCampaignAirdropExploreQuery,
    FetchCampaignAirdropExploreQueryVariables
  >(FetchCampaignAirdropExploreDocument, options)
}
export function useFetchCampaignAirdropExploreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCampaignAirdropExploreQuery,
    FetchCampaignAirdropExploreQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchCampaignAirdropExploreQuery,
    FetchCampaignAirdropExploreQueryVariables
  >(FetchCampaignAirdropExploreDocument, options)
}
export type FetchCampaignAirdropExploreQueryHookResult = ReturnType<
  typeof useFetchCampaignAirdropExploreQuery
>
export type FetchCampaignAirdropExploreLazyQueryHookResult = ReturnType<
  typeof useFetchCampaignAirdropExploreLazyQuery
>
export type FetchCampaignAirdropExploreQueryResult = Apollo.QueryResult<
  FetchCampaignAirdropExploreQuery,
  FetchCampaignAirdropExploreQueryVariables
>
export function refetchFetchCampaignAirdropExploreQuery(
  variables: FetchCampaignAirdropExploreQueryVariables
) {
  return { query: FetchCampaignAirdropExploreDocument, variables: variables }
}
export const FetchUserCampaignAirdropPortfolioDocument = gql`
  query fetchUserCampaignAirdropPortfolio($campaignId: String!) {
    me {
      id
      campaignAirdropPortfolio(campaignId: $campaignId) {
        id
        userTotalEarnedReyieldTokens
        userTotalInvestingAmount
        userTotalProfit
        tasks {
          ...CampaignAirdropTask
        }
      }
    }
  }
  ${CampaignAirdropTaskFragmentDoc}
`

/**
 * __useFetchUserCampaignAirdropPortfolioQuery__
 *
 * To run a query within a React component, call `useFetchUserCampaignAirdropPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserCampaignAirdropPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserCampaignAirdropPortfolioQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useFetchUserCampaignAirdropPortfolioQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchUserCampaignAirdropPortfolioQuery,
    FetchUserCampaignAirdropPortfolioQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchUserCampaignAirdropPortfolioQuery,
    FetchUserCampaignAirdropPortfolioQueryVariables
  >(FetchUserCampaignAirdropPortfolioDocument, options)
}
export function useFetchUserCampaignAirdropPortfolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUserCampaignAirdropPortfolioQuery,
    FetchUserCampaignAirdropPortfolioQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchUserCampaignAirdropPortfolioQuery,
    FetchUserCampaignAirdropPortfolioQueryVariables
  >(FetchUserCampaignAirdropPortfolioDocument, options)
}
export type FetchUserCampaignAirdropPortfolioQueryHookResult = ReturnType<
  typeof useFetchUserCampaignAirdropPortfolioQuery
>
export type FetchUserCampaignAirdropPortfolioLazyQueryHookResult = ReturnType<
  typeof useFetchUserCampaignAirdropPortfolioLazyQuery
>
export type FetchUserCampaignAirdropPortfolioQueryResult = Apollo.QueryResult<
  FetchUserCampaignAirdropPortfolioQuery,
  FetchUserCampaignAirdropPortfolioQueryVariables
>
export function refetchFetchUserCampaignAirdropPortfolioQuery(
  variables: FetchUserCampaignAirdropPortfolioQueryVariables
) {
  return { query: FetchUserCampaignAirdropPortfolioDocument, variables: variables }
}
export const FetchUserCampaignAirdropToolsDocument = gql`
  query fetchUserCampaignAirdropTools($campaignId: String!) {
    me {
      id
      campaignAirdropPositions(campaignId: $campaignId) {
        ... on UserUniswapPositionInfo {
          ...UserUniswapPositionInfo
        }
      }
    }
  }
  ${UserUniswapPositionInfoFragmentDoc}
`

/**
 * __useFetchUserCampaignAirdropToolsQuery__
 *
 * To run a query within a React component, call `useFetchUserCampaignAirdropToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserCampaignAirdropToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserCampaignAirdropToolsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useFetchUserCampaignAirdropToolsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchUserCampaignAirdropToolsQuery,
    FetchUserCampaignAirdropToolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchUserCampaignAirdropToolsQuery,
    FetchUserCampaignAirdropToolsQueryVariables
  >(FetchUserCampaignAirdropToolsDocument, options)
}
export function useFetchUserCampaignAirdropToolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUserCampaignAirdropToolsQuery,
    FetchUserCampaignAirdropToolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchUserCampaignAirdropToolsQuery,
    FetchUserCampaignAirdropToolsQueryVariables
  >(FetchUserCampaignAirdropToolsDocument, options)
}
export type FetchUserCampaignAirdropToolsQueryHookResult = ReturnType<
  typeof useFetchUserCampaignAirdropToolsQuery
>
export type FetchUserCampaignAirdropToolsLazyQueryHookResult = ReturnType<
  typeof useFetchUserCampaignAirdropToolsLazyQuery
>
export type FetchUserCampaignAirdropToolsQueryResult = Apollo.QueryResult<
  FetchUserCampaignAirdropToolsQuery,
  FetchUserCampaignAirdropToolsQueryVariables
>
export function refetchFetchUserCampaignAirdropToolsQuery(
  variables: FetchUserCampaignAirdropToolsQueryVariables
) {
  return { query: FetchUserCampaignAirdropToolsDocument, variables: variables }
}
export type ApyHistoryKeySpecifier = ('rate' | 'time' | ApyHistoryKeySpecifier)[]
export type ApyHistoryFieldPolicy = {
  rate?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AttributesKeySpecifier = ('traitType' | 'value' | AttributesKeySpecifier)[]
export type AttributesFieldPolicy = {
  traitType?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CampaignKeySpecifier = (
  | 'createdAt'
  | 'endTtime'
  | 'id'
  | 'isEnable'
  | 'name'
  | 'startTime'
  | 'type'
  | 'updatedAt'
  | CampaignKeySpecifier
)[]
export type CampaignFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  endTtime?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isEnable?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  startTime?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CampaignAirdropKeySpecifier = (
  | 'campaign'
  | 'id'
  | 'toolIds'
  | 'totalAirdropToken'
  | 'tvl'
  | CampaignAirdropKeySpecifier
)[]
export type CampaignAirdropFieldPolicy = {
  campaign?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  toolIds?: FieldPolicy<any> | FieldReadFunction<any>
  totalAirdropToken?: FieldPolicy<any> | FieldReadFunction<any>
  tvl?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CampaignAirdropExploreKeySpecifier = (
  | 'currentHourlyAirdropToken'
  | 'id'
  | 'totalAirdropToken'
  | 'tvl'
  | CampaignAirdropExploreKeySpecifier
)[]
export type CampaignAirdropExploreFieldPolicy = {
  currentHourlyAirdropToken?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  totalAirdropToken?: FieldPolicy<any> | FieldReadFunction<any>
  tvl?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CampaignAirdropTaskKeySpecifier = (
  | 'endTime'
  | 'id'
  | 'name'
  | 'startTime'
  | 'tweetLink'
  | 'userTaskFinishedAt'
  | 'userTaskUserId'
  | CampaignAirdropTaskKeySpecifier
)[]
export type CampaignAirdropTaskFieldPolicy = {
  endTime?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  startTime?: FieldPolicy<any> | FieldReadFunction<any>
  tweetLink?: FieldPolicy<any> | FieldReadFunction<any>
  userTaskFinishedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userTaskUserId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CefiDepositAddressKeySpecifier = (
  | 'address'
  | 'chain'
  | CefiDepositAddressKeySpecifier
)[]
export type CefiDepositAddressFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CefiWalletKeySpecifier = ('exchange' | 'walletBalances' | CefiWalletKeySpecifier)[]
export type CefiWalletFieldPolicy = {
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  walletBalances?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CoinPriceKeySpecifier = ('symbol' | 'usdtPrice' | CoinPriceKeySpecifier)[]
export type CoinPriceFieldPolicy = {
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
  usdtPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CreatorKeySpecifier = ('address' | 'id' | CreatorKeySpecifier)[]
export type CreatorFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CurrencyAmountKeySpecifier = ('amount' | 'currency' | CurrencyAmountKeySpecifier)[]
export type CurrencyAmountFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CurrencyBalanceKeySpecifier = ('available' | 'total' | CurrencyBalanceKeySpecifier)[]
export type CurrencyBalanceFieldPolicy = {
  available?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CurrencyBalancesKeySpecifier = ('balance' | 'currency' | CurrencyBalancesKeySpecifier)[]
export type CurrencyBalancesFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CurrencyInfoKeySpecifier = ('decimals' | 'name' | 'symbol' | CurrencyInfoKeySpecifier)[]
export type CurrencyInfoFieldPolicy = {
  decimals?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DefiWalletKeySpecifier = (
  | 'balance'
  | 'balanceComputed'
  | 'balanceComputedWithUnit'
  | 'chain'
  | 'currencyInfo'
  | 'iconifySrc'
  | 'id'
  | DefiWalletKeySpecifier
)[]
export type DefiWalletFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>
  balanceComputed?: FieldPolicy<any> | FieldReadFunction<any>
  balanceComputedWithUnit?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  currencyInfo?: FieldPolicy<any> | FieldReadFunction<any>
  iconifySrc?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalAPIKeyKeySpecifier = (
  | 'createdAt'
  | 'exchange'
  | 'id'
  | 'isRevoked'
  | 'key'
  | 'name'
  | 'user'
  | 'userId'
  | ExternalAPIKeyKeySpecifier
)[]
export type ExternalAPIKeyFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isRevoked?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FrontendVersionKeySpecifier = (
  | 'commitHash'
  | 'smartContracts'
  | 'version'
  | FrontendVersionKeySpecifier
)[]
export type FrontendVersionFieldPolicy = {
  commitHash?: FieldPolicy<any> | FieldReadFunction<any>
  smartContracts?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GasFeeWalletKeySpecifier = (
  | 'address'
  | 'amount'
  | 'amountSymbol'
  | 'amountUsd'
  | 'chain'
  | 'id'
  | 'isLoading'
  | 'status'
  | 'updatedAt'
  | GasFeeWalletKeySpecifier
)[]
export type GasFeeWalletFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  amountSymbol?: FieldPolicy<any> | FieldReadFunction<any>
  amountUsd?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isLoading?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GimCoolPayPaymentInfoKeySpecifier = (
  | 'blockchain'
  | 'coinAmount'
  | 'coinType'
  | 'fiatAmount'
  | 'fiatType'
  | 'paymentHref'
  | 'price'
  | 'walletAddress'
  | 'walletAddressTag'
  | GimCoolPayPaymentInfoKeySpecifier
)[]
export type GimCoolPayPaymentInfoFieldPolicy = {
  blockchain?: FieldPolicy<any> | FieldReadFunction<any>
  coinAmount?: FieldPolicy<any> | FieldReadFunction<any>
  coinType?: FieldPolicy<any> | FieldReadFunction<any>
  fiatAmount?: FieldPolicy<any> | FieldReadFunction<any>
  fiatType?: FieldPolicy<any> | FieldReadFunction<any>
  paymentHref?: FieldPolicy<any> | FieldReadFunction<any>
  price?: FieldPolicy<any> | FieldReadFunction<any>
  walletAddress?: FieldPolicy<any> | FieldReadFunction<any>
  walletAddressTag?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GimCoolPayPriceResponseKeySpecifier = (
  | 'prices'
  | 'spotPrice'
  | GimCoolPayPriceResponseKeySpecifier
)[]
export type GimCoolPayPriceResponseFieldPolicy = {
  prices?: FieldPolicy<any> | FieldReadFunction<any>
  spotPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GimCoolPayReadyToPurchasePriceKeySpecifier = (
  | 'coinAmount'
  | 'coinCode'
  | 'feeAmount'
  | 'fiatAmount'
  | 'fiatCode'
  | 'networkFee'
  | 'paymentMethodId'
  | 'spotPriceFee'
  | 'spotPriceIncludingFee'
  | 'type'
  | GimCoolPayReadyToPurchasePriceKeySpecifier
)[]
export type GimCoolPayReadyToPurchasePriceFieldPolicy = {
  coinAmount?: FieldPolicy<any> | FieldReadFunction<any>
  coinCode?: FieldPolicy<any> | FieldReadFunction<any>
  feeAmount?: FieldPolicy<any> | FieldReadFunction<any>
  fiatAmount?: FieldPolicy<any> | FieldReadFunction<any>
  fiatCode?: FieldPolicy<any> | FieldReadFunction<any>
  networkFee?: FieldPolicy<any> | FieldReadFunction<any>
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>
  spotPriceFee?: FieldPolicy<any> | FieldReadFunction<any>
  spotPriceIncludingFee?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MetricKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'positionManagersInfo'
  | 'positions'
  | MetricKeySpecifier
)[]
export type MetricFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  positionManagersInfo?: FieldPolicy<any> | FieldReadFunction<any>
  positions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MetricPositionKeySpecifier = (
  | 'amountUsd'
  | 'chain'
  | 'createdAt'
  | 'feeUsd'
  | 'id'
  | 'positionId'
  | 'positionIdTotalCount'
  | 'positionIsRunning'
  | 'positionManagerContractAddress'
  | 'positionOwnerAddress'
  | 'strategyId'
  | 'strategyProvider'
  | 'sumUsd'
  | MetricPositionKeySpecifier
)[]
export type MetricPositionFieldPolicy = {
  amountUsd?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  feeUsd?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  positionId?: FieldPolicy<any> | FieldReadFunction<any>
  positionIdTotalCount?: FieldPolicy<any> | FieldReadFunction<any>
  positionIsRunning?: FieldPolicy<any> | FieldReadFunction<any>
  positionManagerContractAddress?: FieldPolicy<any> | FieldReadFunction<any>
  positionOwnerAddress?: FieldPolicy<any> | FieldReadFunction<any>
  strategyId?: FieldPolicy<any> | FieldReadFunction<any>
  strategyProvider?: FieldPolicy<any> | FieldReadFunction<any>
  sumUsd?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MutationKeySpecifier = (
  | 'closeUniswapv3Position'
  | 'createAICompletionRecord'
  | 'createUniswapv3Position'
  | 'createUniswapv3Tool'
  | 'delistUserOwnedUniswapv3Tool'
  | 'externalAPIKeyCreate'
  | 'externalAPIKeyDelete'
  | 'generateMetrics'
  | 'listUserOwnedUniswapv3Tool'
  | 'refreshUserUniswapV3Tool'
  | 'strategyStart'
  | 'strategyStop'
  | 'strategyUpdate'
  | 'updateUserOwnedUniswapv3ToolsInfo'
  | MutationKeySpecifier
)[]
export type MutationFieldPolicy = {
  closeUniswapv3Position?: FieldPolicy<any> | FieldReadFunction<any>
  createAICompletionRecord?: FieldPolicy<any> | FieldReadFunction<any>
  createUniswapv3Position?: FieldPolicy<any> | FieldReadFunction<any>
  createUniswapv3Tool?: FieldPolicy<any> | FieldReadFunction<any>
  delistUserOwnedUniswapv3Tool?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeyCreate?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeyDelete?: FieldPolicy<any> | FieldReadFunction<any>
  generateMetrics?: FieldPolicy<any> | FieldReadFunction<any>
  listUserOwnedUniswapv3Tool?: FieldPolicy<any> | FieldReadFunction<any>
  refreshUserUniswapV3Tool?: FieldPolicy<any> | FieldReadFunction<any>
  strategyStart?: FieldPolicy<any> | FieldReadFunction<any>
  strategyStop?: FieldPolicy<any> | FieldReadFunction<any>
  strategyUpdate?: FieldPolicy<any> | FieldReadFunction<any>
  updateUserOwnedUniswapv3ToolsInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NFTCollectionsKeySpecifier = (
  | 'attributes'
  | 'contract'
  | 'description'
  | 'imageUrl'
  | 'name'
  | 'tokenId'
  | NFTCollectionsKeySpecifier
)[]
export type NFTCollectionsFieldPolicy = {
  attributes?: FieldPolicy<any> | FieldReadFunction<any>
  contract?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NFTMerkleTreeHexProofKeySpecifier = ('hexProof' | NFTMerkleTreeHexProofKeySpecifier)[]
export type NFTMerkleTreeHexProofFieldPolicy = {
  hexProof?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NFTWhitelistInfoByPhraseKeySpecifier = (
  | 'id'
  | 'isWhitelisted'
  | 'phrase'
  | 'userAddress'
  | NFTWhitelistInfoByPhraseKeySpecifier
)[]
export type NFTWhitelistInfoByPhraseFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isWhitelisted?: FieldPolicy<any> | FieldReadFunction<any>
  phrase?: FieldPolicy<any> | FieldReadFunction<any>
  userAddress?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PositionManagerInfoKeySpecifier = (
  | 'chain'
  | 'count'
  | 'id'
  | PositionManagerInfoKeySpecifier
)[]
export type PositionManagerInfoFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  count?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QueryKeySpecifier = (
  | 'campaignAirdrop'
  | 'campaignAirdropExplore'
  | 'campaignAirdropTools'
  | 'coinPrices'
  | 'me'
  | 'metric'
  | 'poolsWithArguments'
  | 'rateHistorical'
  | 'strategies'
  | 'tools'
  | 'toolsSupportingTokens'
  | 'uniswapQuote'
  | 'uniswapTokens'
  | 'uniswapV3Backtest'
  | 'version'
  | 'wallet'
  | QueryKeySpecifier
)[]
export type QueryFieldPolicy = {
  campaignAirdrop?: FieldPolicy<any> | FieldReadFunction<any>
  campaignAirdropExplore?: FieldPolicy<any> | FieldReadFunction<any>
  campaignAirdropTools?: FieldPolicy<any> | FieldReadFunction<any>
  coinPrices?: FieldPolicy<any> | FieldReadFunction<any>
  me?: FieldPolicy<any> | FieldReadFunction<any>
  metric?: FieldPolicy<any> | FieldReadFunction<any>
  poolsWithArguments?: FieldPolicy<any> | FieldReadFunction<any>
  rateHistorical?: FieldPolicy<any> | FieldReadFunction<any>
  strategies?: FieldPolicy<any> | FieldReadFunction<any>
  tools?: FieldPolicy<any> | FieldReadFunction<any>
  toolsSupportingTokens?: FieldPolicy<any> | FieldReadFunction<any>
  uniswapQuote?: FieldPolicy<any> | FieldReadFunction<any>
  uniswapTokens?: FieldPolicy<any> | FieldReadFunction<any>
  uniswapV3Backtest?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
  wallet?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RateHistoricalKeySpecifier = ('rate' | 'time' | RateHistoricalKeySpecifier)[]
export type RateHistoricalFieldPolicy = {
  rate?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldLicenseKeySpecifier = (
  | 'numberOfLicense'
  | 'numberOfUsedLicense'
  | ReyieldLicenseKeySpecifier
)[]
export type ReyieldLicenseFieldPolicy = {
  numberOfLicense?: FieldPolicy<any> | FieldReadFunction<any>
  numberOfUsedLicense?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractKeySpecifier = ('address' | 'chain' | 'name' | SmartContractKeySpecifier)[]
export type SmartContractFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyInfoKeySpecifier = (
  | 'currency'
  | 'description'
  | 'earningCurrency'
  | 'exchanges'
  | 'expectedAPY'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'numOfApply'
  | 'parameters'
  | 'riskLevel'
  | 'sid'
  | 'stage'
  | 'suggestionPeriod'
  | 'target'
  | 'type'
  | StrategyInfoKeySpecifier
)[]
export type StrategyInfoFieldPolicy = {
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  earningCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  exchanges?: FieldPolicy<any> | FieldReadFunction<any>
  expectedAPY?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  numOfApply?: FieldPolicy<any> | FieldReadFunction<any>
  parameters?: FieldPolicy<any> | FieldReadFunction<any>
  riskLevel?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  stage?: FieldPolicy<any> | FieldReadFunction<any>
  suggestionPeriod?: FieldPolicy<any> | FieldReadFunction<any>
  target?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyInfosKeySpecifier = (
  | 'currency'
  | 'earningCurrency'
  | 'exchange'
  | 'minEnableAmount'
  | 'name'
  | StrategyInfosKeySpecifier
)[]
export type StrategyInfosFieldPolicy = {
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  earningCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  minEnableAmount?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyParametersKeySpecifier = (
  | 'defaultValue'
  | 'description'
  | 'key'
  | 'maxValue'
  | 'minValue'
  | 'name'
  | 'type'
  | 'updatable'
  | StrategyParametersKeySpecifier
)[]
export type StrategyParametersFieldPolicy = {
  defaultValue?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  maxValue?: FieldPolicy<any> | FieldReadFunction<any>
  minValue?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatable?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyPerformanceKeySpecifier = (
  | 'amount'
  | 'apy'
  | 'currency'
  | 'ror'
  | StrategyPerformanceKeySpecifier
)[]
export type StrategyPerformanceFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  apy?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  ror?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyProgressKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'index'
  | 'status'
  | 'steps'
  | 'type'
  | 'updatedAt'
  | StrategyProgressKeySpecifier
)[]
export type StrategyProgressFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  index?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  steps?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyProgressStepKeySpecifier = (
  | 'status'
  | 'type'
  | StrategyProgressStepKeySpecifier
)[]
export type StrategyProgressStepFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyStartResponseKeySpecifier = ('strategy' | StrategyStartResponseKeySpecifier)[]
export type StrategyStartResponseFieldPolicy = {
  strategy?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyStopResponseKeySpecifier = ('strategy' | StrategyStopResponseKeySpecifier)[]
export type StrategyStopResponseFieldPolicy = {
  strategy?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyUpdateResponseKeySpecifier = ('strategy' | StrategyUpdateResponseKeySpecifier)[]
export type StrategyUpdateResponseFieldPolicy = {
  strategy?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TokenBalanceKeySpecifier = (
  | 'address'
  | 'amount'
  | 'amountReadable'
  | 'chain'
  | 'decimals'
  | 'id'
  | 'symbol'
  | TokenBalanceKeySpecifier
)[]
export type TokenBalanceFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  amountReadable?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  decimals?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TokenDisplayInfoKeySpecifier = (
  | 'address'
  | 'chain'
  | 'id'
  | 'imageURL'
  | 'name'
  | 'symbol'
  | 'usdtPrice'
  | TokenDisplayInfoKeySpecifier
)[]
export type TokenDisplayInfoFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  imageURL?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
  usdtPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TotalProfitsKeySpecifier = ('amount' | 'currency' | TotalProfitsKeySpecifier)[]
export type TotalProfitsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapOnchainPositionAmountInfoKeySpecifier = (
  | 'token0'
  | 'token0Amount'
  | 'token0AmountUsdValue'
  | 'token1'
  | 'token1Amount'
  | 'token1AmountUsdValue'
  | UniswapOnchainPositionAmountInfoKeySpecifier
)[]
export type UniswapOnchainPositionAmountInfoFieldPolicy = {
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Amount?: FieldPolicy<any> | FieldReadFunction<any>
  token0AmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Amount?: FieldPolicy<any> | FieldReadFunction<any>
  token1AmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapPoolInfoKeySpecifier = (
  | 'averageTradingVolumeUSD'
  | 'chain'
  | 'collectedFeesToken0'
  | 'collectedFeesToken1'
  | 'collectedFeesUSD'
  | 'createdAtBlockNumber'
  | 'createdAtTimestamp'
  | 'feeGrowthGlobal0X128'
  | 'feeGrowthGlobal1X128'
  | 'feeTier'
  | 'feesUSD'
  | 'id'
  | 'isPoolActiveForReyield'
  | 'liquidity'
  | 'liquidityProviderCount'
  | 'observationIndex'
  | 'positions'
  | 'sqrtPrice'
  | 'tick'
  | 'ticks'
  | 'token0'
  | 'token0Price'
  | 'token1'
  | 'token1Price'
  | 'totalValueLockedETH'
  | 'totalValueLockedToken0'
  | 'totalValueLockedToken1'
  | 'totalValueLockedUSD'
  | 'totalValueLockedUSDUntracked'
  | 'txCount'
  | 'untrackedVolumeUSD'
  | 'volumeToken0'
  | 'volumeToken1'
  | 'volumeUSD'
  | UniswapPoolInfoKeySpecifier
)[]
export type UniswapPoolInfoFieldPolicy = {
  averageTradingVolumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesToken0?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesToken1?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesUSD?: FieldPolicy<any> | FieldReadFunction<any>
  createdAtBlockNumber?: FieldPolicy<any> | FieldReadFunction<any>
  createdAtTimestamp?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthGlobal0X128?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthGlobal1X128?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  feesUSD?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isPoolActiveForReyield?: FieldPolicy<any> | FieldReadFunction<any>
  liquidity?: FieldPolicy<any> | FieldReadFunction<any>
  liquidityProviderCount?: FieldPolicy<any> | FieldReadFunction<any>
  observationIndex?: FieldPolicy<any> | FieldReadFunction<any>
  positions?: FieldPolicy<any> | FieldReadFunction<any>
  sqrtPrice?: FieldPolicy<any> | FieldReadFunction<any>
  tick?: FieldPolicy<any> | FieldReadFunction<any>
  ticks?: FieldPolicy<any> | FieldReadFunction<any>
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Price?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Price?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedETH?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedToken0?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedToken1?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSD?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSDUntracked?: FieldPolicy<any> | FieldReadFunction<any>
  txCount?: FieldPolicy<any> | FieldReadFunction<any>
  untrackedVolumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
  volumeToken0?: FieldPolicy<any> | FieldReadFunction<any>
  volumeToken1?: FieldPolicy<any> | FieldReadFunction<any>
  volumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapPoolPositionKeySpecifier = (
  | 'collectedFeesToken0'
  | 'collectedFeesToken1'
  | 'depositedToken0'
  | 'depositedToken1'
  | 'feeGrowthInside0LastX128'
  | 'feeGrowthInside1LastX128'
  | 'id'
  | 'liquidity'
  | 'tickLowerFeeGrowthOutside0X128'
  | 'tickLowerFeeGrowthOutside1X128'
  | 'tickLowerTickIdx'
  | 'tickUpperFeeGrowthOutside0X128'
  | 'tickUpperFeeGrowthOutside1X128'
  | 'tickUpperTickIdx'
  | 'transactionTimestamp'
  | UniswapPoolPositionKeySpecifier
)[]
export type UniswapPoolPositionFieldPolicy = {
  collectedFeesToken0?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesToken1?: FieldPolicy<any> | FieldReadFunction<any>
  depositedToken0?: FieldPolicy<any> | FieldReadFunction<any>
  depositedToken1?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthInside0LastX128?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthInside1LastX128?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  liquidity?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerFeeGrowthOutside0X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerFeeGrowthOutside1X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerTickIdx?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperFeeGrowthOutside0X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperFeeGrowthOutside1X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperTickIdx?: FieldPolicy<any> | FieldReadFunction<any>
  transactionTimestamp?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapPoolTickKeySpecifier = (
  | 'id'
  | 'liquidityNet'
  | 'price0'
  | 'price1'
  | 'tickIdx'
  | UniswapPoolTickKeySpecifier
)[]
export type UniswapPoolTickFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  liquidityNet?: FieldPolicy<any> | FieldReadFunction<any>
  price0?: FieldPolicy<any> | FieldReadFunction<any>
  price1?: FieldPolicy<any> | FieldReadFunction<any>
  tickIdx?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapQuoteKeySpecifier = (
  | 'chain'
  | 'fromToken'
  | 'fromTokenAddress'
  | 'id'
  | 'inputAmount'
  | 'inputReadableAmount'
  | 'outputAmount'
  | 'outputReadableAmount'
  | 'toToken'
  | 'toTokenAddress'
  | UniswapQuoteKeySpecifier
)[]
export type UniswapQuoteFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  fromToken?: FieldPolicy<any> | FieldReadFunction<any>
  fromTokenAddress?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  inputAmount?: FieldPolicy<any> | FieldReadFunction<any>
  inputReadableAmount?: FieldPolicy<any> | FieldReadFunction<any>
  outputAmount?: FieldPolicy<any> | FieldReadFunction<any>
  outputReadableAmount?: FieldPolicy<any> | FieldReadFunction<any>
  toToken?: FieldPolicy<any> | FieldReadFunction<any>
  toTokenAddress?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolFeeTierKeySpecifier = (
  | 'feeForContract'
  | 'feeForDisplay'
  | 'id'
  | UniswapToolFeeTierKeySpecifier
)[]
export type UniswapToolFeeTierFieldPolicy = {
  feeForContract?: FieldPolicy<any> | FieldReadFunction<any>
  feeForDisplay?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolInfoKeySpecifier = (
  | 'apyEstimationBaseOnInputUsdValue'
  | 'apyHighest7d'
  | 'apyHighest24h'
  | 'apyHighest30d'
  | 'apyHighestCurrent'
  | 'apyHistory'
  | 'chain'
  | 'createdAt'
  | 'creator'
  | 'creatorAddress'
  | 'creatorDescription'
  | 'creatorId'
  | 'creatorName'
  | 'description'
  | 'expectedAPY'
  | 'feeTier'
  | 'id'
  | 'isOfficial'
  | 'maximumLoss'
  | 'name'
  | 'numOfApply'
  | 'performanceFee'
  | 'poolAddress'
  | 'poolInfo'
  | 'riskLevel'
  | 'status'
  | 'tags'
  | 'tickLowerDiff'
  | 'tickLowerDiffForContract'
  | 'tickUpperDiff'
  | 'tickUpperDiffForContract'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token1Address'
  | 'token1DisplayInfo'
  | 'tvlPool'
  | 'tvlTool'
  | UniswapToolInfoKeySpecifier
)[]
export type UniswapToolInfoFieldPolicy = {
  apyEstimationBaseOnInputUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
  apyHighest7d?: FieldPolicy<any> | FieldReadFunction<any>
  apyHighest24h?: FieldPolicy<any> | FieldReadFunction<any>
  apyHighest30d?: FieldPolicy<any> | FieldReadFunction<any>
  apyHighestCurrent?: FieldPolicy<any> | FieldReadFunction<any>
  apyHistory?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  creator?: FieldPolicy<any> | FieldReadFunction<any>
  creatorAddress?: FieldPolicy<any> | FieldReadFunction<any>
  creatorDescription?: FieldPolicy<any> | FieldReadFunction<any>
  creatorId?: FieldPolicy<any> | FieldReadFunction<any>
  creatorName?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  expectedAPY?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isOfficial?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  numOfApply?: FieldPolicy<any> | FieldReadFunction<any>
  performanceFee?: FieldPolicy<any> | FieldReadFunction<any>
  poolAddress?: FieldPolicy<any> | FieldReadFunction<any>
  poolInfo?: FieldPolicy<any> | FieldReadFunction<any>
  riskLevel?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  tags?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiffForContract?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiffForContract?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  tvlPool?: FieldPolicy<any> | FieldReadFunction<any>
  tvlTool?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolTagKeySpecifier = ('id' | 'name' | 'type' | UniswapToolTagKeySpecifier)[]
export type UniswapToolTagFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolTokenKeySpecifier = (
  | 'chain'
  | 'decimals'
  | 'derivedETH'
  | 'feesUSD'
  | 'id'
  | 'name'
  | 'poolCount'
  | 'priceChart'
  | 'symbol'
  | 'totalSupply'
  | 'totalValueLocked'
  | 'totalValueLockedUSD'
  | 'totalValueLockedUSDUntracked'
  | 'txCount'
  | 'untrackedVolumeUSD'
  | 'volume'
  | 'volumeUSD'
  | UniswapToolTokenKeySpecifier
)[]
export type UniswapToolTokenFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  decimals?: FieldPolicy<any> | FieldReadFunction<any>
  derivedETH?: FieldPolicy<any> | FieldReadFunction<any>
  feesUSD?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  poolCount?: FieldPolicy<any> | FieldReadFunction<any>
  priceChart?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
  totalSupply?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLocked?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSD?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSDUntracked?: FieldPolicy<any> | FieldReadFunction<any>
  txCount?: FieldPolicy<any> | FieldReadFunction<any>
  untrackedVolumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
  volume?: FieldPolicy<any> | FieldReadFunction<any>
  volumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolTokenPriceChartKeySpecifier = (
  | 'currentPriceUSD'
  | 'id'
  | 'prices'
  | UniswapToolTokenPriceChartKeySpecifier
)[]
export type UniswapToolTokenPriceChartFieldPolicy = {
  currentPriceUSD?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  prices?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolTokenPriceChartPriceKeySpecifier = (
  | 'timestamp'
  | 'value'
  | UniswapToolTokenPriceChartPriceKeySpecifier
)[]
export type UniswapToolTokenPriceChartPriceFieldPolicy = {
  timestamp?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapV3BacktestResultKeySpecifier = (
  | 'activeLiquidityPercent'
  | 'annualReturn'
  | 'compound'
  | 'createdAt'
  | 'dailyReturn'
  | 'dataPointsCount'
  | 'days'
  | 'diff'
  | 'fees'
  | 'id'
  | 'impermanentLoss'
  | 'investmentAmount'
  | 'maxDrawdown'
  | 'period'
  | 'poolID'
  | 'protocol'
  | 'totalReturn'
  | 'volatility'
  | UniswapV3BacktestResultKeySpecifier
)[]
export type UniswapV3BacktestResultFieldPolicy = {
  activeLiquidityPercent?: FieldPolicy<any> | FieldReadFunction<any>
  annualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  compound?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  dailyReturn?: FieldPolicy<any> | FieldReadFunction<any>
  dataPointsCount?: FieldPolicy<any> | FieldReadFunction<any>
  days?: FieldPolicy<any> | FieldReadFunction<any>
  diff?: FieldPolicy<any> | FieldReadFunction<any>
  fees?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  impermanentLoss?: FieldPolicy<any> | FieldReadFunction<any>
  investmentAmount?: FieldPolicy<any> | FieldReadFunction<any>
  maxDrawdown?: FieldPolicy<any> | FieldReadFunction<any>
  period?: FieldPolicy<any> | FieldReadFunction<any>
  poolID?: FieldPolicy<any> | FieldReadFunction<any>
  protocol?: FieldPolicy<any> | FieldReadFunction<any>
  totalReturn?: FieldPolicy<any> | FieldReadFunction<any>
  volatility?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserCampaignAirdropPortfolioKeySpecifier = (
  | 'id'
  | 'tasks'
  | 'userTotalEarnedReyieldTokens'
  | 'userTotalInvestingAmount'
  | 'userTotalProfit'
  | UserCampaignAirdropPortfolioKeySpecifier
)[]
export type UserCampaignAirdropPortfolioFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  tasks?: FieldPolicy<any> | FieldReadFunction<any>
  userTotalEarnedReyieldTokens?: FieldPolicy<any> | FieldReadFunction<any>
  userTotalInvestingAmount?: FieldPolicy<any> | FieldReadFunction<any>
  userTotalProfit?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserInfoKeySpecifier = (
  | 'aICompletionRecords'
  | 'avatar'
  | 'campaignAirdropPortfolio'
  | 'campaignAirdropPositions'
  | 'cefiDepositAddress'
  | 'cefiWallets'
  | 'defiTokensBalanceAmount'
  | 'defiWallets'
  | 'externalAPIKeys'
  | 'gasFeeWallets'
  | 'gimCoolPayPaymentInfo'
  | 'id'
  | 'isBitfinexAPIKeyBind'
  | 'nftCollections'
  | 'nftMerkleTreeHexProof'
  | 'nftWhitelistInfoByPhrases'
  | 'numOfListedTool'
  | 'numOfListingLicense'
  | 'ownedToolsSetting'
  | 'privilege'
  | 'reyieldLicense'
  | 'strategies'
  | 'strategyPerformance'
  | 'strategyProfit'
  | 'strategyProgress'
  | 'strategyRecords'
  | 'tools'
  | UserInfoKeySpecifier
)[]
export type UserInfoFieldPolicy = {
  aICompletionRecords?: FieldPolicy<any> | FieldReadFunction<any>
  avatar?: FieldPolicy<any> | FieldReadFunction<any>
  campaignAirdropPortfolio?: FieldPolicy<any> | FieldReadFunction<any>
  campaignAirdropPositions?: FieldPolicy<any> | FieldReadFunction<any>
  cefiDepositAddress?: FieldPolicy<any> | FieldReadFunction<any>
  cefiWallets?: FieldPolicy<any> | FieldReadFunction<any>
  defiTokensBalanceAmount?: FieldPolicy<any> | FieldReadFunction<any>
  defiWallets?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeys?: FieldPolicy<any> | FieldReadFunction<any>
  gasFeeWallets?: FieldPolicy<any> | FieldReadFunction<any>
  gimCoolPayPaymentInfo?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isBitfinexAPIKeyBind?: FieldPolicy<any> | FieldReadFunction<any>
  nftCollections?: FieldPolicy<any> | FieldReadFunction<any>
  nftMerkleTreeHexProof?: FieldPolicy<any> | FieldReadFunction<any>
  nftWhitelistInfoByPhrases?: FieldPolicy<any> | FieldReadFunction<any>
  numOfListedTool?: FieldPolicy<any> | FieldReadFunction<any>
  numOfListingLicense?: FieldPolicy<any> | FieldReadFunction<any>
  ownedToolsSetting?: FieldPolicy<any> | FieldReadFunction<any>
  privilege?: FieldPolicy<any> | FieldReadFunction<any>
  reyieldLicense?: FieldPolicy<any> | FieldReadFunction<any>
  strategies?: FieldPolicy<any> | FieldReadFunction<any>
  strategyPerformance?: FieldPolicy<any> | FieldReadFunction<any>
  strategyProfit?: FieldPolicy<any> | FieldReadFunction<any>
  strategyProgress?: FieldPolicy<any> | FieldReadFunction<any>
  strategyRecords?: FieldPolicy<any> | FieldReadFunction<any>
  tools?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserStrategyInfoKeySpecifier = (
  | 'ceFiStatus'
  | 'closedAt'
  | 'createdAt'
  | 'errMsg'
  | 'id'
  | 'infos'
  | 'parameters'
  | 'performance'
  | 'profits'
  | 'sid'
  | 'updatedAt'
  | UserStrategyInfoKeySpecifier
)[]
export type UserStrategyInfoFieldPolicy = {
  ceFiStatus?: FieldPolicy<any> | FieldReadFunction<any>
  closedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  errMsg?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  infos?: FieldPolicy<any> | FieldReadFunction<any>
  parameters?: FieldPolicy<any> | FieldReadFunction<any>
  performance?: FieldPolicy<any> | FieldReadFunction<any>
  profits?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserStrategyParametersKeySpecifier = (
  | 'key'
  | 'value'
  | UserStrategyParametersKeySpecifier
)[]
export type UserStrategyParametersFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserStrategyPerformanceKeySpecifier = (
  | 'duration'
  | 'initialValue'
  | 'performances'
  | UserStrategyPerformanceKeySpecifier
)[]
export type UserStrategyPerformanceFieldPolicy = {
  duration?: FieldPolicy<any> | FieldReadFunction<any>
  initialValue?: FieldPolicy<any> | FieldReadFunction<any>
  performances?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserStrategyProfitKeySpecifier = (
  | 'sid'
  | 'totalProfit'
  | 'type'
  | UserStrategyProfitKeySpecifier
)[]
export type UserStrategyProfitFieldPolicy = {
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  totalProfit?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserStrategyRecordKeySpecifier = (
  | 'amount'
  | 'currency'
  | 'id'
  | 'rate'
  | 'time'
  | 'type'
  | UserStrategyRecordKeySpecifier
)[]
export type UserStrategyRecordFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  rate?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapOnchainPositionInfoKeySpecifier = (
  | 'apy'
  | 'chain'
  | 'closedPositionAmount'
  | 'positionId'
  | 'positionIdFromSmartContractPositionManager'
  | 'roi'
  | 'runningPositionAmount'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token1Address'
  | 'token1DisplayInfo'
  | 'totalInvestAmountUsdValue'
  | UserUniswapOnchainPositionInfoKeySpecifier
)[]
export type UserUniswapOnchainPositionInfoFieldPolicy = {
  apy?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  closedPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  positionId?: FieldPolicy<any> | FieldReadFunction<any>
  positionIdFromSmartContractPositionManager?: FieldPolicy<any> | FieldReadFunction<any>
  roi?: FieldPolicy<any> | FieldReadFunction<any>
  runningPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  totalInvestAmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapPositionInfoKeySpecifier = (
  | 'apy'
  | 'campaignId'
  | 'chain'
  | 'closedAt'
  | 'closedPositionAmount'
  | 'createdAt'
  | 'feeTier'
  | 'id'
  | 'onchainPositionInfo'
  | 'performanceFeeChargeUsdValue'
  | 'poolAddress'
  | 'positionId'
  | 'positionIdFromSmartContractPositionManager'
  | 'progresses'
  | 'publicToolInfo'
  | 'reyieldTokens'
  | 'roi'
  | 'runningPositionAmount'
  | 'status'
  | 'tickLowerDiff'
  | 'tickUpperDiff'
  | 'token0'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token0TotalFeeUsdValue'
  | 'token1'
  | 'token1Address'
  | 'token1DisplayInfo'
  | 'token1TotalFeeUsdValue'
  | 'tokenPriceFluctuationsUsd'
  | 'toolDescription'
  | 'toolId'
  | 'toolIsOfficial'
  | 'toolName'
  | 'toolPerformanceFeeRatio'
  | 'toolSetting'
  | 'totalInvestAmountUsdValue'
  | 'uniswapIncomeUsd'
  | 'unrealizedPnLUsd'
  | 'updatedAt'
  | 'userAddress'
  | UserUniswapPositionInfoKeySpecifier
)[]
export type UserUniswapPositionInfoFieldPolicy = {
  apy?: FieldPolicy<any> | FieldReadFunction<any>
  campaignId?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  closedAt?: FieldPolicy<any> | FieldReadFunction<any>
  closedPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  onchainPositionInfo?: FieldPolicy<any> | FieldReadFunction<any>
  performanceFeeChargeUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
  poolAddress?: FieldPolicy<any> | FieldReadFunction<any>
  positionId?: FieldPolicy<any> | FieldReadFunction<any>
  positionIdFromSmartContractPositionManager?: FieldPolicy<any> | FieldReadFunction<any>
  progresses?: FieldPolicy<any> | FieldReadFunction<any>
  publicToolInfo?: FieldPolicy<any> | FieldReadFunction<any>
  reyieldTokens?: FieldPolicy<any> | FieldReadFunction<any>
  roi?: FieldPolicy<any> | FieldReadFunction<any>
  runningPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiff?: FieldPolicy<any> | FieldReadFunction<any>
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token0TotalFeeUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1TotalFeeUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
  tokenPriceFluctuationsUsd?: FieldPolicy<any> | FieldReadFunction<any>
  toolDescription?: FieldPolicy<any> | FieldReadFunction<any>
  toolId?: FieldPolicy<any> | FieldReadFunction<any>
  toolIsOfficial?: FieldPolicy<any> | FieldReadFunction<any>
  toolName?: FieldPolicy<any> | FieldReadFunction<any>
  toolPerformanceFeeRatio?: FieldPolicy<any> | FieldReadFunction<any>
  toolSetting?: FieldPolicy<any> | FieldReadFunction<any>
  totalInvestAmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
  uniswapIncomeUsd?: FieldPolicy<any> | FieldReadFunction<any>
  unrealizedPnLUsd?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userAddress?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapToolOutOfRangeInfoKeySpecifier = (
  | 'earnSpendRate'
  | 'maxRebalanceTimes'
  | 'standbyInterval'
  | 'standbyMaxInterval'
  | UserUniswapToolOutOfRangeInfoKeySpecifier
)[]
export type UserUniswapToolOutOfRangeInfoFieldPolicy = {
  earnSpendRate?: FieldPolicy<any> | FieldReadFunction<any>
  maxRebalanceTimes?: FieldPolicy<any> | FieldReadFunction<any>
  standbyInterval?: FieldPolicy<any> | FieldReadFunction<any>
  standbyMaxInterval?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapToolSettingInfoKeySpecifier = (
  | 'chain'
  | 'closedAt'
  | 'createdAt'
  | 'description'
  | 'feeTier'
  | 'id'
  | 'isOfficial'
  | 'name'
  | 'outOfLowerRange'
  | 'outOfUpperRange'
  | 'performanceFeeRatio'
  | 'poolAddress'
  | 'position'
  | 'publicToolInfo'
  | 'status'
  | 'tags'
  | 'tickLowerDiff'
  | 'tickUpperDiff'
  | 'token0'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token1'
  | 'token1Address'
  | 'token1DisplayInfo'
  | 'totalUserUsed'
  | 'updatedAt'
  | UserUniswapToolSettingInfoKeySpecifier
)[]
export type UserUniswapToolSettingInfoFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  closedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isOfficial?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  outOfLowerRange?: FieldPolicy<any> | FieldReadFunction<any>
  outOfUpperRange?: FieldPolicy<any> | FieldReadFunction<any>
  performanceFeeRatio?: FieldPolicy<any> | FieldReadFunction<any>
  poolAddress?: FieldPolicy<any> | FieldReadFunction<any>
  position?: FieldPolicy<any> | FieldReadFunction<any>
  publicToolInfo?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  tags?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiff?: FieldPolicy<any> | FieldReadFunction<any>
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  totalUserUsed?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapv3PositionProgressKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'index'
  | 'status'
  | 'steps'
  | 'type'
  | 'updatedAt'
  | UserUniswapv3PositionProgressKeySpecifier
)[]
export type UserUniswapv3PositionProgressFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  index?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  steps?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapv3PositionProgressStepKeySpecifier = (
  | 'status'
  | 'type'
  | UserUniswapv3PositionProgressStepKeySpecifier
)[]
export type UserUniswapv3PositionProgressStepFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletBalancesKeySpecifier = (
  | 'currencyBalances'
  | 'wallet'
  | WalletBalancesKeySpecifier
)[]
export type WalletBalancesFieldPolicy = {
  currencyBalances?: FieldPolicy<any> | FieldReadFunction<any>
  wallet?: FieldPolicy<any> | FieldReadFunction<any>
}
export type aICompletionRecordsKeySpecifier = (
  | 'completedAt'
  | 'content'
  | 'createdAt'
  | 'id'
  | 'meta'
  | 'platform'
  | 'promptMutationInput'
  | 'promptMutationName'
  | 'suggestions'
  | 'suggestions_aggregate'
  | 'updatedAt'
  | 'userAddress'
  | 'userId'
  | aICompletionRecordsKeySpecifier
)[]
export type aICompletionRecordsFieldPolicy = {
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  platform?: FieldPolicy<any> | FieldReadFunction<any>
  promptMutationInput?: FieldPolicy<any> | FieldReadFunction<any>
  promptMutationName?: FieldPolicy<any> | FieldReadFunction<any>
  suggestions?: FieldPolicy<any> | FieldReadFunction<any>
  suggestions_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userAddress?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type suggestStrategyToolWithAllocationsKeySpecifier = (
  | 'aICompletionRecordId'
  | 'allocation'
  | 'createdAt'
  | 'description'
  | 'highestAnnualReturn'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'sid'
  | 'systemInvestAmountSuggestion'
  | 'systemInvestCurrencySuggestion'
  | 'updatedAt'
  | suggestStrategyToolWithAllocationsKeySpecifier
)[]
export type suggestStrategyToolWithAllocationsFieldPolicy = {
  aICompletionRecordId?: FieldPolicy<any> | FieldReadFunction<any>
  allocation?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  highestAnnualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestAmountSuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestCurrencySuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type suggestStrategyToolWithAllocations_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | suggestStrategyToolWithAllocations_aggregateKeySpecifier
)[]
export type suggestStrategyToolWithAllocations_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type suggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | suggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier
)[]
export type suggestStrategyToolWithAllocations_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type suggestStrategyToolWithAllocations_max_fieldsKeySpecifier = (
  | 'aICompletionRecordId'
  | 'allocation'
  | 'createdAt'
  | 'description'
  | 'highestAnnualReturn'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'sid'
  | 'systemInvestAmountSuggestion'
  | 'systemInvestCurrencySuggestion'
  | 'updatedAt'
  | suggestStrategyToolWithAllocations_max_fieldsKeySpecifier
)[]
export type suggestStrategyToolWithAllocations_max_fieldsFieldPolicy = {
  aICompletionRecordId?: FieldPolicy<any> | FieldReadFunction<any>
  allocation?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  highestAnnualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestAmountSuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestCurrencySuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type suggestStrategyToolWithAllocations_min_fieldsKeySpecifier = (
  | 'aICompletionRecordId'
  | 'allocation'
  | 'createdAt'
  | 'description'
  | 'highestAnnualReturn'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'sid'
  | 'systemInvestAmountSuggestion'
  | 'systemInvestCurrencySuggestion'
  | 'updatedAt'
  | suggestStrategyToolWithAllocations_min_fieldsKeySpecifier
)[]
export type suggestStrategyToolWithAllocations_min_fieldsFieldPolicy = {
  aICompletionRecordId?: FieldPolicy<any> | FieldReadFunction<any>
  allocation?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  highestAnnualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestAmountSuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestCurrencySuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrictTypedTypePolicies = {
  ApyHistory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ApyHistoryKeySpecifier | (() => undefined | ApyHistoryKeySpecifier)
    fields?: ApyHistoryFieldPolicy
  }
  Attributes?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AttributesKeySpecifier | (() => undefined | AttributesKeySpecifier)
    fields?: AttributesFieldPolicy
  }
  Campaign?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CampaignKeySpecifier | (() => undefined | CampaignKeySpecifier)
    fields?: CampaignFieldPolicy
  }
  CampaignAirdrop?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CampaignAirdropKeySpecifier
      | (() => undefined | CampaignAirdropKeySpecifier)
    fields?: CampaignAirdropFieldPolicy
  }
  CampaignAirdropExplore?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CampaignAirdropExploreKeySpecifier
      | (() => undefined | CampaignAirdropExploreKeySpecifier)
    fields?: CampaignAirdropExploreFieldPolicy
  }
  CampaignAirdropTask?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CampaignAirdropTaskKeySpecifier
      | (() => undefined | CampaignAirdropTaskKeySpecifier)
    fields?: CampaignAirdropTaskFieldPolicy
  }
  CefiDepositAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CefiDepositAddressKeySpecifier
      | (() => undefined | CefiDepositAddressKeySpecifier)
    fields?: CefiDepositAddressFieldPolicy
  }
  CefiWallet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CefiWalletKeySpecifier | (() => undefined | CefiWalletKeySpecifier)
    fields?: CefiWalletFieldPolicy
  }
  CoinPrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CoinPriceKeySpecifier | (() => undefined | CoinPriceKeySpecifier)
    fields?: CoinPriceFieldPolicy
  }
  Creator?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CreatorKeySpecifier | (() => undefined | CreatorKeySpecifier)
    fields?: CreatorFieldPolicy
  }
  CurrencyAmount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CurrencyAmountKeySpecifier | (() => undefined | CurrencyAmountKeySpecifier)
    fields?: CurrencyAmountFieldPolicy
  }
  CurrencyBalance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrencyBalanceKeySpecifier
      | (() => undefined | CurrencyBalanceKeySpecifier)
    fields?: CurrencyBalanceFieldPolicy
  }
  CurrencyBalances?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrencyBalancesKeySpecifier
      | (() => undefined | CurrencyBalancesKeySpecifier)
    fields?: CurrencyBalancesFieldPolicy
  }
  CurrencyInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CurrencyInfoKeySpecifier | (() => undefined | CurrencyInfoKeySpecifier)
    fields?: CurrencyInfoFieldPolicy
  }
  DefiWallet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DefiWalletKeySpecifier | (() => undefined | DefiWalletKeySpecifier)
    fields?: DefiWalletFieldPolicy
  }
  ExternalAPIKey?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ExternalAPIKeyKeySpecifier | (() => undefined | ExternalAPIKeyKeySpecifier)
    fields?: ExternalAPIKeyFieldPolicy
  }
  FrontendVersion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FrontendVersionKeySpecifier
      | (() => undefined | FrontendVersionKeySpecifier)
    fields?: FrontendVersionFieldPolicy
  }
  GasFeeWallet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | GasFeeWalletKeySpecifier | (() => undefined | GasFeeWalletKeySpecifier)
    fields?: GasFeeWalletFieldPolicy
  }
  GimCoolPayPaymentInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GimCoolPayPaymentInfoKeySpecifier
      | (() => undefined | GimCoolPayPaymentInfoKeySpecifier)
    fields?: GimCoolPayPaymentInfoFieldPolicy
  }
  GimCoolPayPriceResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GimCoolPayPriceResponseKeySpecifier
      | (() => undefined | GimCoolPayPriceResponseKeySpecifier)
    fields?: GimCoolPayPriceResponseFieldPolicy
  }
  GimCoolPayReadyToPurchasePrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GimCoolPayReadyToPurchasePriceKeySpecifier
      | (() => undefined | GimCoolPayReadyToPurchasePriceKeySpecifier)
    fields?: GimCoolPayReadyToPurchasePriceFieldPolicy
  }
  Metric?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MetricKeySpecifier | (() => undefined | MetricKeySpecifier)
    fields?: MetricFieldPolicy
  }
  MetricPosition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MetricPositionKeySpecifier | (() => undefined | MetricPositionKeySpecifier)
    fields?: MetricPositionFieldPolicy
  }
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier)
    fields?: MutationFieldPolicy
  }
  NFTCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | NFTCollectionsKeySpecifier | (() => undefined | NFTCollectionsKeySpecifier)
    fields?: NFTCollectionsFieldPolicy
  }
  NFTMerkleTreeHexProof?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NFTMerkleTreeHexProofKeySpecifier
      | (() => undefined | NFTMerkleTreeHexProofKeySpecifier)
    fields?: NFTMerkleTreeHexProofFieldPolicy
  }
  NFTWhitelistInfoByPhrase?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NFTWhitelistInfoByPhraseKeySpecifier
      | (() => undefined | NFTWhitelistInfoByPhraseKeySpecifier)
    fields?: NFTWhitelistInfoByPhraseFieldPolicy
  }
  PositionManagerInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PositionManagerInfoKeySpecifier
      | (() => undefined | PositionManagerInfoKeySpecifier)
    fields?: PositionManagerInfoFieldPolicy
  }
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier)
    fields?: QueryFieldPolicy
  }
  RateHistorical?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RateHistoricalKeySpecifier | (() => undefined | RateHistoricalKeySpecifier)
    fields?: RateHistoricalFieldPolicy
  }
  ReyieldLicense?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReyieldLicenseKeySpecifier | (() => undefined | ReyieldLicenseKeySpecifier)
    fields?: ReyieldLicenseFieldPolicy
  }
  SmartContract?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SmartContractKeySpecifier | (() => undefined | SmartContractKeySpecifier)
    fields?: SmartContractFieldPolicy
  }
  StrategyInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StrategyInfoKeySpecifier | (() => undefined | StrategyInfoKeySpecifier)
    fields?: StrategyInfoFieldPolicy
  }
  StrategyInfos?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StrategyInfosKeySpecifier | (() => undefined | StrategyInfosKeySpecifier)
    fields?: StrategyInfosFieldPolicy
  }
  StrategyParameters?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyParametersKeySpecifier
      | (() => undefined | StrategyParametersKeySpecifier)
    fields?: StrategyParametersFieldPolicy
  }
  StrategyPerformance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyPerformanceKeySpecifier
      | (() => undefined | StrategyPerformanceKeySpecifier)
    fields?: StrategyPerformanceFieldPolicy
  }
  StrategyProgress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyProgressKeySpecifier
      | (() => undefined | StrategyProgressKeySpecifier)
    fields?: StrategyProgressFieldPolicy
  }
  StrategyProgressStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyProgressStepKeySpecifier
      | (() => undefined | StrategyProgressStepKeySpecifier)
    fields?: StrategyProgressStepFieldPolicy
  }
  StrategyStartResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyStartResponseKeySpecifier
      | (() => undefined | StrategyStartResponseKeySpecifier)
    fields?: StrategyStartResponseFieldPolicy
  }
  StrategyStopResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyStopResponseKeySpecifier
      | (() => undefined | StrategyStopResponseKeySpecifier)
    fields?: StrategyStopResponseFieldPolicy
  }
  StrategyUpdateResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyUpdateResponseKeySpecifier
      | (() => undefined | StrategyUpdateResponseKeySpecifier)
    fields?: StrategyUpdateResponseFieldPolicy
  }
  TokenBalance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TokenBalanceKeySpecifier | (() => undefined | TokenBalanceKeySpecifier)
    fields?: TokenBalanceFieldPolicy
  }
  TokenDisplayInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TokenDisplayInfoKeySpecifier
      | (() => undefined | TokenDisplayInfoKeySpecifier)
    fields?: TokenDisplayInfoFieldPolicy
  }
  TotalProfits?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TotalProfitsKeySpecifier | (() => undefined | TotalProfitsKeySpecifier)
    fields?: TotalProfitsFieldPolicy
  }
  UniswapOnchainPositionAmountInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapOnchainPositionAmountInfoKeySpecifier
      | (() => undefined | UniswapOnchainPositionAmountInfoKeySpecifier)
    fields?: UniswapOnchainPositionAmountInfoFieldPolicy
  }
  UniswapPoolInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapPoolInfoKeySpecifier
      | (() => undefined | UniswapPoolInfoKeySpecifier)
    fields?: UniswapPoolInfoFieldPolicy
  }
  UniswapPoolPosition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapPoolPositionKeySpecifier
      | (() => undefined | UniswapPoolPositionKeySpecifier)
    fields?: UniswapPoolPositionFieldPolicy
  }
  UniswapPoolTick?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapPoolTickKeySpecifier
      | (() => undefined | UniswapPoolTickKeySpecifier)
    fields?: UniswapPoolTickFieldPolicy
  }
  UniswapQuote?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UniswapQuoteKeySpecifier | (() => undefined | UniswapQuoteKeySpecifier)
    fields?: UniswapQuoteFieldPolicy
  }
  UniswapToolFeeTier?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapToolFeeTierKeySpecifier
      | (() => undefined | UniswapToolFeeTierKeySpecifier)
    fields?: UniswapToolFeeTierFieldPolicy
  }
  UniswapToolInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapToolInfoKeySpecifier
      | (() => undefined | UniswapToolInfoKeySpecifier)
    fields?: UniswapToolInfoFieldPolicy
  }
  UniswapToolTag?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UniswapToolTagKeySpecifier | (() => undefined | UniswapToolTagKeySpecifier)
    fields?: UniswapToolTagFieldPolicy
  }
  UniswapToolToken?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapToolTokenKeySpecifier
      | (() => undefined | UniswapToolTokenKeySpecifier)
    fields?: UniswapToolTokenFieldPolicy
  }
  UniswapToolTokenPriceChart?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapToolTokenPriceChartKeySpecifier
      | (() => undefined | UniswapToolTokenPriceChartKeySpecifier)
    fields?: UniswapToolTokenPriceChartFieldPolicy
  }
  UniswapToolTokenPriceChartPrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapToolTokenPriceChartPriceKeySpecifier
      | (() => undefined | UniswapToolTokenPriceChartPriceKeySpecifier)
    fields?: UniswapToolTokenPriceChartPriceFieldPolicy
  }
  UniswapV3BacktestResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapV3BacktestResultKeySpecifier
      | (() => undefined | UniswapV3BacktestResultKeySpecifier)
    fields?: UniswapV3BacktestResultFieldPolicy
  }
  UserCampaignAirdropPortfolio?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserCampaignAirdropPortfolioKeySpecifier
      | (() => undefined | UserCampaignAirdropPortfolioKeySpecifier)
    fields?: UserCampaignAirdropPortfolioFieldPolicy
  }
  UserInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserInfoKeySpecifier | (() => undefined | UserInfoKeySpecifier)
    fields?: UserInfoFieldPolicy
  }
  UserStrategyInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserStrategyInfoKeySpecifier
      | (() => undefined | UserStrategyInfoKeySpecifier)
    fields?: UserStrategyInfoFieldPolicy
  }
  UserStrategyParameters?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserStrategyParametersKeySpecifier
      | (() => undefined | UserStrategyParametersKeySpecifier)
    fields?: UserStrategyParametersFieldPolicy
  }
  UserStrategyPerformance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserStrategyPerformanceKeySpecifier
      | (() => undefined | UserStrategyPerformanceKeySpecifier)
    fields?: UserStrategyPerformanceFieldPolicy
  }
  UserStrategyProfit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserStrategyProfitKeySpecifier
      | (() => undefined | UserStrategyProfitKeySpecifier)
    fields?: UserStrategyProfitFieldPolicy
  }
  UserStrategyRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserStrategyRecordKeySpecifier
      | (() => undefined | UserStrategyRecordKeySpecifier)
    fields?: UserStrategyRecordFieldPolicy
  }
  UserUniswapOnchainPositionInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapOnchainPositionInfoKeySpecifier
      | (() => undefined | UserUniswapOnchainPositionInfoKeySpecifier)
    fields?: UserUniswapOnchainPositionInfoFieldPolicy
  }
  UserUniswapPositionInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapPositionInfoKeySpecifier
      | (() => undefined | UserUniswapPositionInfoKeySpecifier)
    fields?: UserUniswapPositionInfoFieldPolicy
  }
  UserUniswapToolOutOfRangeInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapToolOutOfRangeInfoKeySpecifier
      | (() => undefined | UserUniswapToolOutOfRangeInfoKeySpecifier)
    fields?: UserUniswapToolOutOfRangeInfoFieldPolicy
  }
  UserUniswapToolSettingInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapToolSettingInfoKeySpecifier
      | (() => undefined | UserUniswapToolSettingInfoKeySpecifier)
    fields?: UserUniswapToolSettingInfoFieldPolicy
  }
  UserUniswapv3PositionProgress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapv3PositionProgressKeySpecifier
      | (() => undefined | UserUniswapv3PositionProgressKeySpecifier)
    fields?: UserUniswapv3PositionProgressFieldPolicy
  }
  UserUniswapv3PositionProgressStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapv3PositionProgressStepKeySpecifier
      | (() => undefined | UserUniswapv3PositionProgressStepKeySpecifier)
    fields?: UserUniswapv3PositionProgressStepFieldPolicy
  }
  WalletBalances?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WalletBalancesKeySpecifier | (() => undefined | WalletBalancesKeySpecifier)
    fields?: WalletBalancesFieldPolicy
  }
  aICompletionRecords?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | aICompletionRecordsKeySpecifier
      | (() => undefined | aICompletionRecordsKeySpecifier)
    fields?: aICompletionRecordsFieldPolicy
  }
  suggestStrategyToolWithAllocations?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | suggestStrategyToolWithAllocationsKeySpecifier
      | (() => undefined | suggestStrategyToolWithAllocationsKeySpecifier)
    fields?: suggestStrategyToolWithAllocationsFieldPolicy
  }
  suggestStrategyToolWithAllocations_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | suggestStrategyToolWithAllocations_aggregateKeySpecifier
      | (() => undefined | suggestStrategyToolWithAllocations_aggregateKeySpecifier)
    fields?: suggestStrategyToolWithAllocations_aggregateFieldPolicy
  }
  suggestStrategyToolWithAllocations_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | suggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier
      | (() => undefined | suggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier)
    fields?: suggestStrategyToolWithAllocations_aggregate_fieldsFieldPolicy
  }
  suggestStrategyToolWithAllocations_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | suggestStrategyToolWithAllocations_max_fieldsKeySpecifier
      | (() => undefined | suggestStrategyToolWithAllocations_max_fieldsKeySpecifier)
    fields?: suggestStrategyToolWithAllocations_max_fieldsFieldPolicy
  }
  suggestStrategyToolWithAllocations_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | suggestStrategyToolWithAllocations_min_fieldsKeySpecifier
      | (() => undefined | suggestStrategyToolWithAllocations_min_fieldsKeySpecifier)
    fields?: suggestStrategyToolWithAllocations_min_fieldsFieldPolicy
  }
}
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies
