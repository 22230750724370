import fetch from 'isomorphic-fetch'

import { switchWalletAddressDialogVar } from '@/apollo/localState'

export const ENCRYPTED_ERROR_INPUT_PARAMS = ['password', 'key', 'secret', 'code', 'code2FA']

export enum LogLevel {
  ERROR = 'Error',
  WARNING = 'Warning',
  INFO = 'Info',
  DEBUG = 'Debug',
}

const LevelColor = {
  [LogLevel.ERROR]: '#dc2626',
  [LogLevel.WARNING]: '#ea580c',
  [LogLevel.INFO]: '#0284c7',
  [LogLevel.DEBUG]: '#525252',
}

/**
 * Log error to Slack Bot
 *
 * @param level Log level
 * @param message message to log
 * @param params
 *
 */
const Logger = (level: LogLevel, message: string, params?: any) => {
  const { currentAddress } = switchWalletAddressDialogVar()
  const loggerTemplate = {
    channel: 'client-error-monitor',
    attachments: [
      {
        blocks: [
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: `REYIELD Client ${level}`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'plain_text',
              text: `${currentAddress}`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `> *Message*\n ${message}`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `> *URL*\n ${document ? document.location.href : '--'}`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `> *User-Agent*\n ${document ? navigator.userAgent : '--'}`,
            },
          },
        ],
        color: LevelColor[level],
      },
    ],
  }

  // log others parameter
  if (params) {
    Object.keys(params).forEach((item) => {
      loggerTemplate.attachments[0].blocks.push({
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `> *${item ? `${item[0].toUpperCase()}${item.slice(1)}` : ''}*\n ${params[item]}`,
        },
      })
    })
  }

  fetch('/api/log', {
    method: 'POST',
    body: JSON.stringify(loggerTemplate),
  })
}

/**
 * Handle encrypted error input params
 *
 * @param inputVariables operation variables
 * @returns string
 *
 */
export const handleEncrypted = (inputVariables: any) => {
  let inputString = JSON.stringify(inputVariables)

  ENCRYPTED_ERROR_INPUT_PARAMS.forEach(
    (item) =>
      (inputString = inputString
        .replace(inputVariables.input ? inputVariables.input[`${item}`] : undefined, '*****')
        .replace(/"/g, "'"))
  )

  return inputString
}

export default Logger
