// @ts-nocheck
import Big from 'big.js'

export const RM = {
  ROUND_DOWN: 0,
  ROUND_HALF_UP: 1,
  ROUND_HALF_EVEN: 2,
  ROUND_UP: 3,
}

Big.RM = RM.ROUND_DOWN

const catchWraper =
  (fn) =>
  (...args) => {
    try {
      return fn(...args)
    } catch (e) {
      return undefined
    }
  }

const OperationGenerator =
  (op) =>
  (...args) =>
    args.reduce((sum, val) => (sum ? sum[op](val) : Big(val)), undefined).toFixed()

const ComparisonGenerator = (op) => (arg1, arg2) => Big(arg1)[op](Big(arg2))

export const add = catchWraper(OperationGenerator('plus'))
export const sub = catchWraper(OperationGenerator('minus'))
export const mul = catchWraper(OperationGenerator('times'))
export const div = catchWraper(OperationGenerator('div'))
export const mod = catchWraper(OperationGenerator('mod'))
export const cmp = catchWraper(OperationGenerator('cmp'))
export const pow = catchWraper(OperationGenerator('pow'))

export const eq = catchWraper(ComparisonGenerator('eq'))
export const gte = catchWraper(ComparisonGenerator('gte'))
export const lte = catchWraper(ComparisonGenerator('lte'))
export const gt = catchWraper(ComparisonGenerator('gt'))
export const lt = catchWraper(ComparisonGenerator('lt'))

// console.log(min("0.1", "0.2", "0.3")); // 0.1
export const min = catchWraper((...numbers) => {
  const [firstNumber, ...restNumbers] = numbers
  const finalNumber = restNumbers.reduce((min, number) => {
    if (lt(number, min) === undefined) return undefined
    return lt(number, min) ? number : min
  }, firstNumber)
  return typeof finalNumber === 'undefined' ? undefined : String(finalNumber)
})

export const abs = catchWraper((num) => Big(num).abs().toFixed())

export const round = catchWraper((num, precision = 10, type = RM.ROUND_DOWN) =>
  Big(num).round(precision, type).toFixed()
)
export const roundUp = catchWraper((num, precision = 10, type = RM.ROUND_UP) =>
  Big(num).round(precision, type).toFixed()
)
export const roundDown = catchWraper((num, precision = 10, type = RM.ROUND_DOWN) =>
  Big(num).round(precision, type).toFixed()
)

export const toPrecision = catchWraper((num, precision) => Big(num).toPrecision(precision))

export const toFixed = catchWraper((num, precision) => Big(num).toFixed(precision))
