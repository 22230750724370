import { ReyieldBaseApiResponse } from '../backend'

// this is the enum for the wallet type a user is logging in
// if new wallet type is added, please add it here
export enum WalletTypeReyieldBackend {
  okx = 'okx',
  metamask = 'metamask',
}

export function isStringInWalletTypeReyieldBackend(value: string): boolean {
  return Object.values(WalletTypeReyieldBackend).includes(value as WalletTypeReyieldBackend)
}

export type ReyieldAppPublicUniswapV3ToolStatus = 'listed' | 'deprecated'

// if the api is from backendReyield, then the type name should be BackendReyieldXXX
export interface ReyieldAppPublicUniswapV3Tool {
  id: string
  creator_id: string
  creator_address: string
  chain_id: number
  name: string
  description: string
  pool_address: string
  token0_address: string
  token1_address: string
  fee_tier: string
  tick_lower_diff: string
  tick_upper_diff: string
  total_user_used: number
  is_official: boolean
  performance_fee_ratio: string
  created_at: string
  apy_highest_current: string
  apy_highest_24h: string
  apy_highest_7d: string
  apy_highest_30d: string
  tvl_tool: string
  tvl_pool: string
  status: ReyieldAppPublicUniswapV3ToolStatus
  creator_name?: string
  creator_description?: string
}

export interface UserStrategyProfitGetApiResponse extends ReyieldBaseApiResponse {
  data: {
    profits: ReyieldAppUserCeFiStrategyProfit[] | null
  }
}
export interface UserStrategyPerformanceGetApiResponse extends ReyieldBaseApiResponse {
  data: ReyieldAppUserCeFiStrategyPerformance
}

export interface UserStrategyGetApiResponse extends ReyieldBaseApiResponse {
  data: {
    strategies: ReyieldAppUserCeFiStrategy[] | null
  }
}

export type StrategiesStatus =
  | 'preparing'
  | 'running'
  | 'stopping'
  | 'wait_for_user_action'
  | 'stopped'
  | 'error'

export interface StrategyParameters {
  key: string
  value: string
}
export interface ReyieldAppUserCeFiStrategy {
  closed_at: string
  created_at: string
  err_msg: string
  id: string
  parameters: StrategyParameters[]
  sid: string
  status: StrategiesStatus
  updated_at: string
}

export interface ReyieldAppUserCeFiStrategyProfit {
  sid: string
  total_profit: {
    amount: number
    currency: string
  }[]
  type: string
}

interface ReyieldAppUserCeFiStrategyPerformance {
  duration: number
  initial_value: string
  performances: {
    amount: number
    apy: number
    currency: string
    ror: number
  }[]
}

export interface PublicUniswapV3ToolGetApiResponse extends ReyieldBaseApiResponse {
  data: {
    tools: ReyieldAppPublicUniswapV3Tool[]
  }
}
export interface ReyieldAppUserAirdropCampaignTask {
  end_time: string
  id: string
  name: string
  start_time: string
  tweet_link: string
  user_task?: {
    finished_at: string
    user_id: string
  }
}

export interface UserAirdropCampaignTasksGetApiResponse extends ReyieldBaseApiResponse {
  data: {
    tasks: ReyieldAppUserAirdropCampaignTask[]
  }
}

interface ReyieldAppPublicUniswapV3ToolRate {
  time: string
  rate: string
}

export interface PublicUniswapV3ToolApyHistoryGetApiResponse extends ReyieldBaseApiResponse {
  data: {
    rates: ReyieldAppPublicUniswapV3ToolRate[]
  }
}

export interface UserCefiBalancesInfo {
  exchange: string
  wallet_balances: {
    currency_balances: {
      balance: {
        available: number
        total: number
      }
      currency: string
    }[]
    wallet: string
  }[]
}

export interface UserCefiBalancesGetApiResponse extends ReyieldBaseApiResponse {
  data: {
    infos: UserCefiBalancesInfo[]
  }
}

interface CoinPrice {
  symbol: string
  usdt_price: string
}
export interface PublicCoinPriceGetApiResponse extends ReyieldBaseApiResponse {
  data: {
    infos: CoinPrice[]
  }
}

interface UniswapV3Token {
  address: string
  chain_id: number
  decimals: number
  symbol: string
}
export interface PublicUniswapV3TokensGetApiResponse extends ReyieldBaseApiResponse {
  data: {
    tokens: UniswapV3Token[] | []
  }
}

export interface CreateUniswapv3ToolPostResponse extends ReyieldBaseApiResponse {
  data: {
    tool_id: string
  }
}
export interface UpdateUserOwnedUniswapv3ToolsInfoPatchResponse extends ReyieldBaseApiResponse {
  data: {
    tool_id: string
  }
}
export interface ListUserOwnedUniswapv3ToolsInfoPatchResponse extends ReyieldBaseApiResponse {
  data: {
    tool_id: string
  }
}
export interface DelistUserOwnedUniswapv3ToolsInfoPatchResponse extends ReyieldBaseApiResponse {
  data: {
    tool_id: string
  }
}
export interface GetUserNumberOfListingLicenseResponse extends ReyieldBaseApiResponse {
  data: {
    number_of_listing_license: number
  }
}
export interface GetUserNumberOfListedToolResponse extends ReyieldBaseApiResponse {
  data: {
    number_of_listed_tool: number
  }
}

export interface GetUserPrivilegeResponse extends ReyieldBaseApiResponse {
  data: {
    is_privilege: boolean
  }
}
export interface GetUniswapv3PositionOnchainDataResponse extends ReyieldBaseApiResponse {
  data: ReyieldAppUniswapv3PositionOnchainData
}
export interface CreateUniswapv3PositionPostResponse extends ReyieldBaseApiResponse {
  data: {
    position_id: string
  }
}
export interface DeleteUniswapv3PositionPostResponse extends ReyieldBaseApiResponse {
  data: {
    position_id: string
  }
}
export interface UserUniswapv3PositionGetResponse extends ReyieldBaseApiResponse {
  data: {
    positions: ReyieldAppUniswapv3PositionInfo[]
  }
}
export interface UserUniswapv3PositionProgressesResponse extends ReyieldBaseApiResponse {
  data: {
    progresses: ReyieldAppUniswapv3PositionProgress[]
  }
}
export interface UserUniswapv3ToolGetResponse extends ReyieldBaseApiResponse {
  data: {
    tools: ReyieldAppUniswapv3ToolType[]
  }
}
export interface GetCampaignAirdropResponse extends ReyieldBaseApiResponse {
  data: GetCampaignAirdropResponseData
}
export interface GetCampaignAirdropExploreResponse extends ReyieldBaseApiResponse {
  data: GetCampaignAirdropExploreResponseData
}
export interface GetUserCampaignAirdropPortfolioResponse extends ReyieldBaseApiResponse {
  data: GetUserCampaignAirdropPortfolioResponseData
}
export interface GetUserCampaignAirdropPositionResponse extends ReyieldBaseApiResponse {
  data: {
    positions: ReyieldAppAirdropUniswapv3PositionInfo[]
  }
}
export interface GetUserCampaignAirdropPositionReyieldResponse extends ReyieldBaseApiResponse {
  data: {
    reyield_tokens: string
  }
}

/*
  pending -> create tool 後, 尚未開單
  created -> 成功開單
  listing -> 上架中
  listed -> 上架完成
  deprecated -> 下架 (原本的 tool deprecated)
*/
export type Uniswapv3ToolTypeStatus = 'pending' | 'created' | 'listing' | 'listed' | 'deprecated'

export interface ReyieldAppUniswapv3ToolType {
  id: string
  user_id: string
  chain_id: number
  name: string
  description: string
  pool_address: string
  token0_address: string
  token1_address: string
  fee_tier: string
  tick_lower_diff: string
  tick_upper_diff: string
  total_user_used: number
  is_official: boolean
  performance_fee_ratio: string | null
  status: Uniswapv3ToolTypeStatus
  created_at: string
  updated_at: string
  out_of_lower_range: {
    max_rebalance_times: number
    standby_interval: number
    standby_max_interval: number
    earn_spend_rate: string
  }
  out_of_upper_range: {
    max_rebalance_times: number
    standby_interval: number
    standby_max_interval: number
    earn_spend_rate: string
  }
}

/*
  pending -> 尚未確認上鏈
  running -> 已確認上鏈執行中
  closing -> 關單中 (等待關單流程配套措施完成)
  closed -> 關單完成
*/

export type Uniswapv3PositionTypeStatus = 'pending' | 'running' | 'closing' | 'closed'

export interface ReyieldAppUniswapv3PositionInfo {
  id: string
  chain_id: number
  position_on_chain_id: number | null
  tool_id: string
  user_id: string
  status: Uniswapv3PositionTypeStatus
  tool_name: string
  tool_description: string
  pool_address: string
  token0_address: string
  token1_address: string
  fee_tier: string
  tick_lower_diff: string
  tick_upper_diff: string
  tool_is_official: boolean
  tool_performance_fee_ratio: string | null
  created_at: string
  updated_at: string
  closed_at: null | string
  apy: string
  roi: string
  total_invest_amount_usd_value: string
  running_position_amount: {
    token0_amount: string
    token1_amount: string
    token0_amount_usd_value: string
    token1_amount_usd_value: string
  } | null
  closed_position_amount: {
    token0_amount: string
    token1_amount: string
    token0_amount_usd_value: string
    token1_amount_usd_value: string
  } | null
  token0_total_fee_usd_value: string
  token1_total_fee_usd_value: string
}

type ReyieldAppUniswapv3PositionProgressStatus =
  | 'prepared'
  | 'processing'
  | 'success'
  | 'failed'
  | 'cancelled'

type ReyieldAppUniswapv3PositionProgressStepStatus = 'waiting' | 'processing' | 'success' | 'failed'
type ReyieldAppUniswapv3PositionProgressStepType =
  | 'uniswapv3_create_position_watching'
  | 'uniswapv3_create_listed_tool_position_watching'
  | 'uniswapv3_close_position_watching'
  | 'uniswapv3_list_tool_watching'
interface ReyieldAppUniswapv3PositionProgressStep {
  status: ReyieldAppUniswapv3PositionProgressStepStatus
  type: ReyieldAppUniswapv3PositionProgressStepType
}
type ReyieldAppUniswapv3PositionProgressType =
  | 'uniswapv3_create_position_watching'
  | 'uniswapv3_create_listed_tool_position_watching'
  | 'uniswapv3_close_position_watching'
  | 'uniswapv3_list_tool_watching'

export interface ReyieldAppUniswapv3PositionProgress {
  id: string
  created_at: string
  index: number
  status: ReyieldAppUniswapv3PositionProgressStatus
  steps: ReyieldAppUniswapv3PositionProgressStep[]
  type: ReyieldAppUniswapv3PositionProgressType
  updated_at: string
}

export interface Uniswapv3ExplorePostApiParams {
  chain_id: string
  name: string
  description: string
  token0_address: string
  token1_address: string
  fee_tier: string
  tick_lower_diff: string
  tick_upper_diff: string
  out_of_lower_range_max_rebalance_times: number
  out_of_lower_range_standby_interval: number
  out_of_lower_range_standby_max_interval: number
  out_of_lower_range_earn_spend_rate: number
  out_of_upper_range_max_rebalance_times: number
  out_of_upper_range_standby_interval: number
  out_of_upper_range_standby_max_interval: number
  out_of_upper_range_earn_spend_rate: number
}

export interface ReyieldAppUniswapv3PositionOnchainData {
  apy: number
  closed_position_amount: {
    token0_amount: number
    token0_amount_usd_value: number
    token1_amount: number
    token1_amount_usd_value: number
  }
  roi: number
  running_position_amount: {
    token0_amount: number
    token0_amount_usd_value: number
    token1_amount: number
    token1_amount_usd_value: number
  }
  total_invest_amount_usd_value: number
}

export type CampaignAirdropType = 'airdrop'
export interface GetCampaignAirdropResponseData {
  campaign: {
    created_at: string
    end_time: string
    id: string
    is_enable: true
    name: string
    start_time: string
    type: CampaignAirdropType
    updated_at: string
  }
  tool_ids: [string]
  total_airdrop_token: string
  tvl: string
}

export interface GetCampaignAirdropExploreResponseData {
  current_hourly_airdrop_token: string
  total_airdrop_token: string
  tvl: string
}

export interface GetUserCampaignAirdropPortfolioResponseData {
  user_total_earned_reyield_tokens: string
  user_total_investing_amount: string
  user_total_profit: string
}

export interface ReyieldAppAirdropUniswapv3PositionInfo extends ReyieldAppUniswapv3PositionInfo {
  reyield_tokens: string
}
