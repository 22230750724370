import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Paper(theme: Theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          background: theme.reyieldPalette.primary.main,
        },
      },
    },
  }
}
