import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { SentryLink } from 'apollo-link-sentry'
import { createClient } from 'graphql-ws'
import { IncomingMessage, ServerResponse } from 'http'
import fetch from 'isomorphic-fetch'
import { useMemo } from 'react'
import WebSocket from 'ws'

import SnackbarUtils from '@/components/snackbar/SnackbarUtils'
import { BFF } from '@/config'
import Logger, { handleEncrypted, LogLevel } from '@/utils/logger'

import { switchWalletAddressDialogVar, typePolicies } from './localState'
let apolloClient: ApolloClient<NormalizedCacheObject> | undefined
export type ResolverContext = {
  req?: IncomingMessage
  res?: ServerResponse
}

const wsLink = new GraphQLWsLink(
  typeof window === 'undefined'
    ? createClient({
        webSocketImpl: WebSocket,
        url: BFF.HASURA_GRAPHQL_SOCKET_URL,
      })
    : createClient({
        keepAlive: Infinity,
        url: BFF.HASURA_GRAPHQL_SOCKET_URL,
        connectionParams: async () => {
          const { currentAddress } = switchWalletAddressDialogVar()
          return {
            headers: {
              'x-hasura-user-id': currentAddress,
            },
          }
        },
      })
)

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 10,
    retryIf: (error, operation) => {
      const { currentAddress } = switchWalletAddressDialogVar()
      return !!error || operation.getContext().headers['x-hasura-user-id'] !== currentAddress
    },
  },
})

const httpLink = new HttpLink({
  uri: '/api/graphql',
  credentials: 'same-origin',
  fetch,
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

const cache = new InMemoryCache({
  typePolicies,
})

export const NOT_LOGGED_ERROR = [
  'invalid authorization',
  'no record found',
  'invalid_verification_code_error',
  'external_exchange_Invalid_nonce',
  'external_exchange_invalid_nonce',
]

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  graphQLErrors?.forEach(({ message, locations, path, extensions }) => {
    switch (message) {
      case 'authorization_error': {
        // TODO handle authorization error
        SnackbarUtils.enqueueSnackbar(message, {
          variant: 'error',
        })
        break
      }

      default: {
        if (NOT_LOGGED_ERROR.indexOf(message) === -1) {
          SnackbarUtils.enqueueSnackbar(message, {
            variant: 'error',
          })
          Logger(
            LogLevel.ERROR,
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            {
              variables: handleEncrypted(operation.variables),
              response: JSON.stringify({ ...extensions }).replace(/"/g, "'"),
            }
          )
        }
        break
      }
    }
  })
})

const sentryLink = new SentryLink({})

const links = from([sentryLink, retryLink, errorLink, splitLink])

function createApolloClient(context?: ResolverContext) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: links,
    cache,
    connectToDevTools: !(process.env.NODE_ENV === 'production'),
  })
}

export function initializeApollo(
  initialState: any = null,
  // Pages with Next.js data fetching methods, like `getStaticProps`, can send
  // a custom context which will be used by `SchemaLink` to server render pages
  context?: ResolverContext
) {
  const _apolloClient = apolloClient ?? createApolloClient(context)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
